<div class="content-container mb-28">
  <div class="flex flex-col">
    <h2 class="text-primary-base text-h1-heading uppercase mt-30 mb-10">
      {{ title }}
    </h2>
    <div class="flex flex-row">
      <div
        class="w-95 h-106 bg-gray-white border-[3px] border-gray-stroke rounded-30 mx-2.5"
        *ngFor="let card of cardArray">
        <div>
          <div>
            <img
              class="object-cover rounded-t-30"
              src="{{ card.imgUrl }}"
              alt="{{ card.title }}" />
          </div>
          <div class="flex flex-row justify-between pt-5 pr-8 pl-10">
            <div class="w-65 text-h3-subheading">{{ card.title }}</div>
            <div
              class="cursor-pointer text-accent-base hover:text-secondary-base active:text-secondary-additional"
              routerLink="{{ card.redirectTo }}">
              <svg
                width="30px"
                height="30px"
                icon="right-arrow-small"
                iconCollection="button-icons-sprite"
                class="svg fill-accent-base hover:fill-secondary-additional active:fill-secondary-additional"></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
