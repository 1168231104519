import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface MediaAboutUsModel {
  id?: number;
  pageName: string;
  key: string;
  title: string;
  imageURL: string;
  logoImageURL: string;
  buttonURL: string;
  contentType: string;
  publicationDate: Date;
  File?: File;
  Logo?: File;
}

@Injectable({
  providedIn: 'root',
})
export class MediaAboutUsService {
  urlApi: string =
    environment.apiUrl + '/SocialMediaAboutUs/GetAllSocialMediaAboutUs';
  adminApi: string = environment.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getCards(): Observable<MediaAboutUsModel[]> {
    return this.http.get<MediaAboutUsModel[]>(this.urlApi);
  }

  createMediaCard(data: FormData) {
    return this.http.post(this.adminApi + 'AddSocialMediaAboutUs', data);
  }

  updateMediaCard(data: FormData) {
    return this.http.put(this.adminApi + 'UpdateSocialMediaAboutUs', data);
  }

  deleteMediaCard(data: MediaAboutUsModel) {
    return this.http.delete(this.adminApi + 'DeleteSocialMediaAboutUs', {
      body: data,
    });
  }
}
