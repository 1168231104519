import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-of-content',
  templateUrl: './table-of-content.component.html',
  styleUrls: ['./table-of-content.component.css'],
})
export class ContentsComponent implements OnInit {
  @Input() links: any;

  constructor() {}

  ngOnInit(): void {}
}
