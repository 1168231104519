import { Component, OnInit, Input } from '@angular/core';
import ILetter from 'src/app/models/letterForDonation.interface';
import { EmailForSubscriptionMockData } from 'src/mockData/letterForDonationMockData';

@Component({
  selector: 'app-email-for-subscription',
  templateUrl: './email-for-subscription.component.html',
  styleUrls: ['./email-for-subscription.component.css'],
})
export class EmailForSubscriptionComponent implements OnInit {

  letterText: ILetter[] = EmailForSubscriptionMockData;

  constructor() {}

  ngOnInit(): void {}
}
