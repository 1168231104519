import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-abroad',
  templateUrl: './help-abroad.component.html',
  styleUrls: ['./help-abroad.component.css'],
})
export class HelpAbroadComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
