import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  sumEURArray,
  sumUAHArray,
  sumUSDArray,
  typeOfCurrency,
} from 'src/mockData/summsArray';
import { emailValidator } from 'src/app/components/forms/validators/email-validator';
import { nameValidator } from 'src/app/components/forms/validators/name-validator';

import { LiqPayService } from '../../../services/liq-pay.service';
import { TextContentModel } from '../../../services/api/text-content.service';

export interface sumDonate {
  value: string;
  checked?: boolean;
}

@Component({
  selector: 'app-single-help',
  templateUrl: './single-help.component.html',
  styleUrls: ['./single-help.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleHelpComponent implements OnInit {
  @Input() tabsData: TextContentModel[] = [];
  oneTimeFormText: TextContentModel | null = null;
  choosedArray: sumDonate[] = [];
  currency: string[] = typeOfCurrency;
  formOnTimeSubs!: FormGroup;
  isSafari: boolean = false;
  clicked: boolean = false;
  safaryLink = '';
  name = new FormControl('', [Validators.required, nameValidator]);
  surname = new FormControl('', [Validators.required, nameValidator]);
  email = new FormControl('', [Validators.required, emailValidator]);
  phone = new FormControl('', [Validators.required]);
  countryCode = new FormControl('+380');
  chosedSum = new FormControl('', [
    Validators.maxLength(7),
    Validators.minLength(1),
    Validators.pattern(/^(?!0)[1-9]\d{0,6}$/),
    Validators.required,
  ]);
  private UahSumArray: sumDonate[] = sumUAHArray;
  private UsdSumArray: sumDonate[] = sumUSDArray;
  private EurSumArray: sumDonate[] = sumEURArray;
  private chosedCurrency: string = 'uah';

  constructor(
    public fb: FormBuilder,
    private liqPay: LiqPayService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.choosedArray = this.UahSumArray;
    this.formOnTimeSubs = this.fb.group({
      nameDonator: this.name,
      surnameDonator: this.surname,
      emailDonator: this.email,
      sumDonate: this.chosedSum,
      telDonator: this.phone,
      telCode: this.countryCode,
      read: [false, Validators.requiredTrue],
    });
    this.oneTimeFormText = this.tabsData.find(
      el => el.key === 'one_time_text',
    )!;
    this.checkClient();
  }

  onSubmit() {
    const res = this.formOnTimeSubs.value;
    res.chosedCurrency = this.chosedCurrency;
    if (this.formOnTimeSubs.valid) {
      this.liqPay.sendData(this.formOnTimeSubs.value).subscribe(res => {
        if (res) {
          this.safaryLink = this.liqPay.openPaymentWindow(res);
          if (this.isSafari) this.clicked = true;
          this.cdr.detectChanges();
        }
      });
      this.formOnTimeSubs.reset();
    } else {
      this.validateAllFields(this.formOnTimeSubs);
    }
  }

  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  public changeSelectedCurrency(value: string): void {
    if (value === 'uah') {
      this.choosedArray = this.UahSumArray;
      this.chosedCurrency = 'uah';
    }
    if (value === 'eur') {
      this.choosedArray = this.EurSumArray;
      this.chosedCurrency = 'eur';
    }
    if (value === 'usd') {
      this.choosedArray = this.UsdSumArray;
      this.chosedCurrency = 'usd';
    }
  }

  formatDate(date: Date): string {
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());
    const day = this.padZero(date.getDate());
    const month = this.padZero(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${hours}-${minutes}-${seconds}-${day}-${month}-${year}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  checkClient() {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('Safari') !== -1) {
      this.isSafari = true;
    }
  }
}
