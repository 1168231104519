<div class="mx-auto w-full bg-primary-base">
  <div class="flex flex-col items-center content-container py-30">
    <div
      class="flex w-full gap-5 mb-15 text-white flex-wrap"
      *ngIf="ourAchievementsTextBlock">
      <h1 class="pl-4 md:pl-0 uppercase text-h1-heading w-full md:w-95">
        {{ ourAchievementsTextBlock.title }}
      </h1>
      <p
        *ngIf="ourAchievementsTextBlock.bodyText"
        class="pt-1 text-main-text w-195"
        [innerHTML]="ourAchievementsTextBlock.bodyText"></p>
    </div>
    <div
      class="flex flex-col md:flex-row md:flex-wrap md:place-content-center gap-5 mb-15">
      <div *ngFor="let influence of influences">
        <app-impact-card [influence]="influence"></app-impact-card>
      </div>
    </div>

    <app-link-button
      [url]="ourAchievementsTextBlock.buttonURL"
      [linkButtonType]="'Primary'"
      [title]="'Перейти до звітів'"
      class="w-70 md:w-55">
    </app-link-button>
  </div>
</div>
