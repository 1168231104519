import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BreakpointObserverService } from 'src/app/services/breakpoint-observer.service';

@Component({
  selector: 'app-adaptive-slider',
  templateUrl: './adaptive-slider.component.html',
  styleUrls: ['./adaptive-slider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdaptiveSliderComponent implements OnInit, AfterViewInit {
  @ViewChild('sliderTrack') sliderTrack!: ElementRef;
  public currentIndex: number = 0;
  private sliderWidth!: number;
  private slideWidth!: number;
  public slidesPerPage!: number;
  public lastEl!: number;
  public amount!: number;
  private isMobile$ = this.points.isMobile();
  private isMobile: boolean = false;
  private isLaptop$ = this.points.isLaptop();
  private isLaptop: boolean = false;

  constructor(
    private points: BreakpointObserverService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.isMobile$.subscribe(el => {
      if (el) {
        this.isMobile = true;
        this.amount = 1;
      } else {
        this.isMobile = false;
        this.amount = 0;
      }
    });
    this.isLaptop$.subscribe(el => {
      if (el) {
        this.isLaptop = true;
        this.amount = 2;
      } else {
        this.isLaptop = false;
        this.amount = 1;
      }
    });
  }

  ngAfterViewInit(): void {
    this.calculating();
  }

  calculating() {
    const sliderTrack = this.sliderTrack.nativeElement;
    if (
      sliderTrack &&
      sliderTrack.children[0] &&
      sliderTrack.children[0].children[0]
    ) {
      this.sliderWidth = this.sliderTrack.nativeElement.children[0].offsetWidth;
      this.slideWidth =
        this.sliderTrack.nativeElement.children[0].children[0].offsetWidth;
      this.slidesPerPage = Math.floor(this.sliderWidth / this.slideWidth);
      this.lastEl =
        this.sliderTrack.nativeElement.children[0].children.length - 1;
      this.cdr.markForCheck();
    }
  }

  onNextSlide() {
    this.calculating();
    if (this.isMobile) {
      if (this.currentIndex < this.lastEl) {
        this.currentIndex++;
        this.sliderTrack.nativeElement.style.transform = `translateX(-${
          this.slideWidth * this.currentIndex + 20 * this.currentIndex
        }px)`;
        this.cdr.markForCheck();
      }
    }
    if (this.isLaptop) {
      if (this.currentIndex < this.lastEl - 1) {
        this.currentIndex++;
        this.sliderTrack.nativeElement.style.transform = `translateX(-${
          this.slideWidth * this.currentIndex + 20 * this.currentIndex
        }px)`;
        this.cdr.markForCheck();
      }
    }
  }

  onPrevSlide() {
    this.calculating();
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.sliderTrack.nativeElement.style.transform = `translateX(-${
        this.slideWidth * this.currentIndex + 20 * this.currentIndex
      }px)`;
      this.cdr.markForCheck();
    }
  }
}
