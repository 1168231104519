import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import {
  GetSupportApiService,
  GetSupportPageInterface,
} from 'src/app/services/api/getSupportPages/get-support-api.service';
import { ScrollService } from 'src/app/services/scroll.service';
import {
  BannerPageService,
  IBannerPage,
} from '../../services/api/banner-page.service';
import {
  NavigationalCardsService,
  navigationalLinksModel,
} from '../../services/api/navigational-cards.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NeedHelpComponent implements OnInit {
  subs: Subscription = new Subscription();
  needHelpPageDataLink: string = PAGE_ROUTES.contacts;
  bannerLoading: boolean = true;
  needHelpPageData: GetSupportPageInterface = {
    bannerText: '',
    firstBlock: [{ title: '', text: '', link: '' }],
    secondBlockTitle: { title: '', text: '', link: '', tag: '', id: 0 },
    secondBlock: [{ title: '', text: '', link: '', titleLinkId: '' }],
    tabsBlockTitle: { title: '', text: '' },
    needHelpDataTab: [{ title: '', text: '', active: false, linkTo: '' }],
    lastBlock: { title: '', text: '' },
  };
  firstBlockCard: navigationalLinksModel[] = [];
  secondBlockCard: navigationalLinksModel[] = [];
  bgImage: string = '';
  pageTitle: string = '';
  bannerText: string = '';
  constructor(
    private pageService: GetSupportApiService,
    private cdr: ChangeDetectorRef,
    private scrollService: ScrollService,
    private pageBannerService: BannerPageService,
    private navLinksService: NavigationalCardsService,
  ) {}
  ngOnInit(): void {
    const sub = this.pageService
      .getPageData()
      .subscribe((res: GetSupportPageInterface) => {
        this.needHelpPageData = res;
        this.needHelpPageData.firstBlock =
          this.needHelpPageData.firstBlock.sort((a, b) => {
            if (a.id !== undefined && b.id !== undefined) {
              return a.id - b.id;
            }
            return 0;
          });
        this.cdr.markForCheck();
        // this.scrollService.setLoaded(true);
      });
    this.subs.add(sub);
    this.getBannerData();
    this.getFirstBlockCards();
    this.getSecondBlockCards();
  }

  getBannerData() {
    this.bannerLoading = true;
    const sub = this.pageBannerService
      .getBannersData('get-support')
      .subscribe(el => {
        this.bgImage = `linear-gradient(90deg, rgba(255, 255, 255, 0) 47.55%, rgb(255, 255, 255) 100%), url("${el.imgUrl}")`;
        this.pageTitle = el.title;
        this.bannerText = el.text;
        this.bannerLoading = false;
        this.cdr.markForCheck();
      });
    this.subs.add(sub);
  }

  private getFirstBlockCards(): void {
    const sub = this.navLinksService
      .getNavigationalCardsByPageName('get-support_1')
      .subscribe((data: navigationalLinksModel[]) => {
        this.firstBlockCard = data;
        this.cdr.markForCheck();
      });
    this.subs.add(sub);
  }

  private getSecondBlockCards(): void {
    const sub = this.navLinksService
      .getNavigationalCardsByPageName('get-support_2')
      .subscribe((data: navigationalLinksModel[]) => {
        this.secondBlockCard = data;
        this.cdr.markForCheck();
      });
    this.subs.add(sub);
  }
}
