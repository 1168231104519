<div
  class="content-container min-h-[calc(100vh_-_650px)] flex md:flex-row items-center md:items-start flex-col pt-15 gap-2 md:gap-25 px-4 md:px-0 xl:overflow-hidden">
  <div
    class="md:w-[481px] flex-col md:justify-start md:items-start gap-2 md:gap-[60px] flex shrink-0">
    <div
      class="self-stretch text-primary-base text-h1-360 md:text-4xl font-semibold uppercase">
      сторінка в розробці
    </div>

    <div
      class="self-stretch text-zinc-700 text-smaller-main md:text-lg font-normal md:leading-7">
      Ми вже працюємо над тим, щоб ця інформація з’явилася якнайшвидше.
    </div>
    <div class="SecondaryBtn justify-start items-center gap-3 inline-flex">
      <app-link-button
        (click)="goBack()"
        [linkButtonType]="'LeftArrow'"
        [title]="'Повернутись на попередню сторінку'"
        class="mt-5 md:mt-0 flex md:justify-end">
      </app-link-button>
    </div>
  </div>
  <div class="pt-10 pb-30 shrink-0 self-center">
    <img
      alt="Сторінка на розробці"
      class="xl:h-[780px] md:h-[490px] h-[300px] shrink-0 object-cover overflow-visible"
      src="./../../../assets/images/flower.svg" />
  </div>
</div>
