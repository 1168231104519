import { Component, OnDestroy, OnInit } from '@angular/core';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { HttpClient } from '@angular/common/http';
import {
  BannerPageService,
  IBannerPage,
} from 'src/app/services/api/banner-page.service';
import IInfoPage from 'src/app/models/infoPage.interface';
import IGlossary from 'src/app/models/glossary.interface';
import {
  TextContentModel,
  TextContentService,
} from '../../../services/api/text-content.service';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  portalImageLinkModel,
  PortalLinksService,
} from '../../../services/api/portal-links.service';

@Component({
  selector: 'app-crisis-assistance',
  templateUrl: './crisis-assistance.component.html',
  styleUrls: ['./crisis-assistance.component.css'],
})
export class CrisisAssistanceComponent implements OnInit, OnDestroy {
  pageName: string = 'crisis-assistance';
  pageData: TextContentModel[] = [];
  combinedData: {
    first_block: TextContentModel;
    second_block: TextContentModel;
    second_block_second: TextContentModel;
    second_block_third: TextContentModel;
    third_block: TextContentModel;
  } | null = null;
  subs: Subscription = new Subscription();
  imageListFirst: portalImageLinkModel[] = [];
  imageListSecond: portalImageLinkModel[] = [];
  imageListThird: portalImageLinkModel[] = [];

  links: { id: string; title: string }[] = [];

  aboutUsCardConfig: IInfoPage[] = [
    {
      title: 'Інші види допомоги',
      text: 'Читайте, яку ще допомогу ви можете отримати вже зараз.',
      link: PAGE_ROUTES.getSupport,
    },
    {
      title: 'Переглянути звіти',
      text: 'Ознайомтеся з результатами нашої роботи за місяць чи попередній рік.',
      link: PAGE_ROUTES.reports,
    },
  ];

  cards: IInfoPage[] = this.aboutUsCardConfig;
  mapUrl: SafeResourceUrl = '';

  constructor(
    private apiService: TextContentService,
    private sanitizer: DomSanitizer,
    private portalImageLinkService: PortalLinksService,
  ) {}

  ngOnInit(): void {
    this.getPageTextData();
    this.getPortalImageLinks();
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.combinedData = {
          first_block: this.pageData.find(el => el.key === 'first_block')!,
          second_block: this.pageData.find(el => el.key === 'second_block')!,
          second_block_second: this.pageData.find(
            el => el.key === 'second_block_second',
          )!,
          second_block_third: this.pageData.find(
            el => el.key === 'second_block_third',
          )!,
          third_block: this.pageData.find(el => el.key === 'third_block')!,
        };
        this.links = this.pageData
          .filter(el => el.groupType === 'main_text')
          .map(el => ({ id: el.key, title: el.title }));
        this.mapUrl = this.sanitizeUrl(
          this.combinedData.second_block_second.buttonURL,
        );
      });
    this.subs.add(sub);
  }

  getPortalImageLinks() {
    const sub = this.portalImageLinkService
      .getPortalLinksByPageName(this.pageName)
      .subscribe(res => {
        this.imageListFirst = res.filter(el => el.tag === 'first_gallery');
        this.imageListSecond = res.filter(el => el.tag === 'second_gallery');
        this.imageListThird = res.filter(el => el.tag === 'third_gallery');
      });
    this.subs.add(sub);
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
