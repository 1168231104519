import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PressRelaseCardInterface } from 'src/app/models/press-relase-card.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PressReleasesApiService {
  url = environment.apiUrl + '/PressRelease';
  private adminUrl: string = environment.apiUrl + '/AdminPanel/';

  constructor(private http: HttpClient) {}

  getData(
    skip: string | number,
    take: string | number,
  ): Observable<PressRelaseCardInterface[]> {
    return this.http.get<PressRelaseCardInterface[]>(
      this.url + `/short?skip=${skip}&take=${take}`,
    );
  }

  PressReleaseCardById(
    id: string | number,
  ): Observable<PressReleaseCardByIdInterfaceResponse> {
    return this.http.get<PressReleaseCardByIdInterfaceResponse>(
      this.url + `/full/${id}`,
    );
  }

  deletePressRelease(data: any) {
    return this.http.delete(
      this.adminUrl +
        `DeletePressRelease?id=${data.id}&documentURL=${data.documentUrl}`,
    );
  }

  createPressRelease(data: any) {
    return this.http.post(this.adminUrl + 'AddPressRelease', data);
  }

  updatePressRelease(data: any) {
    return this.http.put(this.adminUrl + 'UpdatePressRelease', data);
  }
}
export interface PressReleaseCardInterfaceResponse {
  id: number;
  title: string;
  dateOfPublication: string;
  shortText: string;
}
export interface PressReleaseCardByIdInterfaceResponse {
  breadcrumb: string;
  dateOfPublication: string;
  fullText: string;
  id: number;
  shortText: string;
  title: string;
  documentUrl: string;
}
