import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-with-generators',
  templateUrl: './help-with-generators.component.html',
  styleUrls: ['./help-with-generators.component.css'],
})
export class HelpWithGeneratorsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
