<main class="pb-1">
  <section class="mb-30">
    <app-page-banner-item [pageName]="pageName"></app-page-banner-item>
  </section>

  <section class="px-4 md:px-15 xl:px-0" *ngIf="combinedData; else loader">
    <app-table-of-content [links]="links"></app-table-of-content>
  </section>

  <!-- Хто такі “Незабутні” -->
  <section *ngIf="combinedData" class="px-4 md:px-15 xl:px-0">
    <div
      [id]="combinedData!.first_block.key"
      class="content-container pt-30 mb-30">
      <h2 class="text-h2-heading uppercase text-primary-base mb-5">
        {{ combinedData!.first_block.title }}
      </h2>

      <div appPhoneLink [innerHtml]="combinedData.first_block.bodyText"></div>
    </div>
  </section>

  <!-- Наша історія -->
  <section *ngIf="combinedData" class="bg-gray-card">
    <div
      [id]="combinedData!.second_block.key"
      class="mx-auto content-container text-start px-4 md:px-0 py-30 sm:px-10">
      <h2 class="text-h2-heading uppercase text-primary-base mb-5">
        {{ combinedData!.second_block.title }}
      </h2>
      <div class="md:flex">
        <div class="">
          <img
            class="hidden md:block rounded-3xl md:ml-5 mt-5 md:mt-0 md:px-0 xl:w-[480px] xl:h-[600px] md:w-[410px] md:h-[512px] md:float-right object-cover"
            [alt]="combinedData!.second_block.title"
            [src]="combinedData!.second_block.imageURL" />
          <div
            class="text-main-text text-gray-dark mb-5 w-full"
            appPhoneLink
            [innerHtml]="combinedData!.second_block.bodyText"></div>
        </div>
        <div class="flex justify-center">
          <img
            class="block md:hidden rounded-3xl md:ml-5 sm:mr-10 mt-5 md:mt-0 md:px-0 w-[328px] h-[410px] object-cover"
            [alt]="combinedData!.second_block.title"
            [src]="combinedData!.second_block.imageURL" />
        </div>
      </div>
    </div>
  </section>

  <!-- Наші цінності -->
  <section *ngIf="combinedData" class="px-4 md:px-15 xl:px-0">
    <div
      [id]="combinedData!.third_block.key"
      class="mx-auto content-container text-start px-4 md:px-0 py-30 sm:px-10">
      <h2 class="text-h2-heading uppercase text-primary-base mb-5">
        {{ combinedData!.third_block.title }}
      </h2>
      <div class="md:flex">
        <div class="">
          <img
            class="hidden md:block rounded-3xl md:ml-5 mt-5 md:mt-0 md:px-0 xl:w-[480px] xl:h-[600px] md:w-[410px] md:h-[512px] md:float-right object-cover"
            [alt]="combinedData!.third_block.title"
            [src]="combinedData!.third_block.imageURL" />
          <div
            class="text-main-text text-gray-dark mb-5 w-full"
            appPhoneLink
            [innerHtml]="combinedData.third_block.bodyText"></div>
        </div>
        <div class="flex justify-center">
          <img
            class="block md:hidden rounded-3xl md:ml-5 sm:mr-10 mt-5 md:mt-0 md:px-0 w-[328px] h-[410px] object-cover"
            [alt]="combinedData!.third_block.title"
            [src]="combinedData!.third_block.imageURL" />
        </div>
      </div>
    </div>
  </section>

  <!-- Наш символ -->
  <section *ngIf="combinedData" class="bg-primary-base px-4 md:px-15 xl:px-0">
    <div
      [id]="combinedData!.fourth_block.key"
      class="flex flex-col items-center pt-30 mb-10">
      <div class="content-container flex flex-col xl:flex-row justify-between">
        <h2 class="text-h2-heading uppercase text-gray-white">
          {{ combinedData!.fourth_block.title }}
        </h2>

        <div
          class="xl:w-195 text-gray-white"
          appPhoneLink
          [innerHtml]="combinedData!.fourth_block.bodyText"></div>
      </div>
      <div class="content-container px-4 md:px-0 justify-center">
        <video
          #videoPlayer
          (touchstart)="playVideo()"
          (ended)="onVideoEnded()"
          class="mx-auto mt-20 w-full md:w-[840px] sm:h-[473px] xl:h-[665px] rounded-30"
          poster="assets/video/poster.png"
          controls>
          <source
            [src]="combinedData!.fourth_block.imageURL"
            type="video/mp4" />
        </video>
      </div>
    </div>

    <div class="bg-primary-base pb-30">
      <div
        class="content-container h-full bg-gray-white rounded-30 px-5 md:px-15 xl:px-25 py-10 md:py-20"
        appPhoneLink
        [innerHtml]="combinedData.last_block.bodyText"></div>
    </div>
  </section>

  <app-help-block [type]="'secondary'"></app-help-block>
</main>

<ng-template #loader>
  <div
    class="mt-30 mb-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
