<div class="flex flex-col content-container py-30">
  <div
    class="h-32.5 md:h-12 flex flex-col md:flex-row px-4 md:px-0 items-start justify-between md:items-center xl:items-end mb-10">
    <h2 class="text-h1-heading text-primary-base uppercase">
      Новини та історії
    </h2>
    <div class="w-60 group/arrow">
      <app-link-button
        [linkButtonType]="'RightArrow'"
        [title]="'Переглянути всі новини'"
        routerLink="./about-us/news-and-media/news"></app-link-button>
    </div>
  </div>

  <app-adaptive-slider
    class="flex flex-col items-center mx-auto w-[340px] md:w-210 xl:w-295 overflow-hidden">
    <div
      class="flex justify-between items-center mt-10 mb-10 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
      <ng-container *ngFor="let card of cardInfo">
        <app-card
          [cardInfo]="card"
          class="shrink-0 w-[340px] md:w-[410px] xl:w-[380px]">
        </app-card>
      </ng-container>
    </div>
  </app-adaptive-slider>
</div>
