<section class="content-container text-start" *ngIf="pageData; else loader">
  <ul class="mt-10 px-4 mb-30">
    <li class="mb-15 md:mb-30">
      <h3 class="text-h1-360 md:text-h1-heading text-primary-base mb-5">
        {{ pageData.header | uppercase }}
      </h3>
      <div
        class="flex md:block flex-col md:flex-row items-center md:items-start">
        <img
          class="w-full order-0 md:order-0 mb-5 md:mb-0 xl:mb-1 md:ml-6 md:float-right rounded-30 md:w-[410px] md:h-[282px] xl:w-[580px] xl:h-[400px] object-cover"
          [src]="pageData.imgLink"
          [alt]="pageData.header" />
        <div
          class="text-smaller-main md:text-main-text order-0 md:order-1 text-gray-dark main-text mb-10"
          appPhoneLink
          [innerHTML]="pageData.introText"></div>
        <div
          class="mb-10 order-{{ i + 2 }} md:order-none"
          *ngFor="let card of pageData.mainText; let i = index">
          <h3
            class="text-h2-360 md:text-h2-heading text- mb-5 text-primary-base">
            {{ card.title | uppercase }}
          </h3>
          <div
            class="text-smaller-main md:text-lg text-gray-dark main-text"
            appPhoneLink
            [innerHTML]="card.text"></div>
        </div>
      </div>
    </li>
  </ul>
</section>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
