import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Input,
  Output,
} from '@angular/core';
import {
  FormGroup,
  Validators,
  NonNullableFormBuilder,
  FormControl,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import {
  IContactForm,
  IContactFormData,
} from '../../models/contact-form.interface';
import { nameValidator } from 'src/app/components/forms/validators/name-validator';
import { emailValidator } from 'src/app/components/forms/validators/email-validator';
import { AgreementConfig } from 'src/mockData/agreement-docs-config';
import { FundMemberPageService } from 'src/app/services/api/fundMemberPage/fund-member-page.service';
import { Router } from '@angular/router';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { OpenPdf } from 'src/app/services/pdfOpen.service';
import { TextContentModel } from '../../services/api/text-content.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css'],
})
export class ContactFormComponent implements OnInit, OnDestroy {
  contactForm!: FormGroup<IContactForm>;
  private pdf: any;
  private fundPolicySub!: Subscription;
  @Input() title: string = 'Напишіть нам';
  @Input() mailTo: string = 'site@nezabutni.org';
  @Output() closePopUpWindow: EventEmitter<any> = new EventEmitter<any>();
  @Input() formsData!: TextContentModel;
  @Input() formsDataAgreement!: TextContentModel;
  show = false;
  showForm = true;
  showCross = false;

  openPopUp() {
    this.show = true;
    this.showForm = false;
    this.contactForm.reset();
  }
  closePopUp() {
    this.showForm = true;
    this.show = false;
    this.contactForm.reset();
  }

  closeModal() {
    this.closePopUpWindow.emit();
  }
  constructor(
    private openPdf: OpenPdf,
    private router: Router,
    private fb: NonNullableFormBuilder,
    private config: AgreementConfig,
    private sanitazer: DomSanitizer,
    private apiMailService: FundMemberPageService,
  ) {
    this.showCross = this.router.url.includes(PAGE_ROUTES.fundTeam);
  }

  ngOnInit(): void {
    this._initContactForm();
  }

  ngOnDestroy(): void {
    this.fundPolicySub && this.fundPolicySub.unsubscribe();
  }

  public onSubmit(): void {
    const data: IContactFormData = {
      UserName: this.contactForm.value.name?.trim(),
      UserEmail: this.contactForm.value.email?.trim(),
      Text: this.contactForm.value.message?.trim(),
      EmailTo: this.mailTo,
    };
    if (this.contactForm.valid) {
      this.sendEmailToFundMember(data).subscribe(response => {
        if (response.status === 200) {
          this.openPopUp();
        }
      });
    } else {
      this.validateAllFields(this.contactForm);
    }
  }
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  onOpenPublic_offerPDF(event: Event, link: string, title: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.openPdf.getPDF(link, title);
  }

  private _initContactForm(): void {
    this.contactForm = this.fb.group({
      name: this.fb.control('', [Validators.required, nameValidator]),
      email: this.fb.control('', [Validators.required, emailValidator]),
      message: this.fb.control('', [Validators.required]),
      policy: this.fb.control(false, [
        Validators.required,
        Validators.requiredTrue,
      ]),
    });
  }

  sendEmailToFundMember(id: any): Observable<any> {
    return this.apiMailService.sendEmail(id);
  }
}
