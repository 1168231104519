import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-your-story',
  templateUrl: './share-your-story.component.html',
  styleUrls: ['./share-your-story.component.css'],
})
export class ShareYourStoryComponent implements OnInit {
  title = 'Поділіться своєю історією';
  text =
    'Якщо у вас деменція, або ви підтримуєте когось, хто живе з цим захворюванням, і хочете поділитися своєю історією – зв’яжіться з нами.';
  button = 'Поділитись історією';
  constructor() {}

  ngOnInit(): void {}
}
