import { Component, OnInit, Input } from '@angular/core';
import moreIInfo from 'src/app/models/moreInfo.interface';

@Component({
  selector: 'app-more-info-card',
  templateUrl: './more-info-card.component.html',
  styleUrls: ['./more-info-card.component.css'],
})
export class MoreInfoCardComponent implements OnInit {
  @Input() moreInfo!: moreIInfo;
  constructor() {}

  ngOnInit(): void {}
}
