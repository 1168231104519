import { Component, OnInit } from '@angular/core';
import {
  TextContentModel,
  TextContentService,
} from '../../services/api/text-content.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css'],
})
export class MainPageComponent implements OnInit {
  isLoaded: boolean = false;
  aboutDementiaTextBlock!: TextContentModel;
  aboutDementiaTabBlock!: TextContentModel[];
  preventionTextBlock!: TextContentModel;
  preventionCardsBlock!: TextContentModel[];
  aboutFondTextBlock!: TextContentModel[];
  ourAchievementsTextBlock!: TextContentModel;
  ourAchievementsCardsBlock!: TextContentModel[];
  bannersArray: TextContentModel[] = [];
  allBannersData: TextContentModel[] = [];
  textContentData: TextContentModel[] = [];

  constructor(private readonly contentService: TextContentService) {}

  ngOnInit(): void {
    this.contentService.getTextsContentByPageName('main').subscribe(res => {
      if (res) {
        this.aboutDementiaTabBlock = res
          .filter(obj => obj.groupType === 'AboutDementia')
          .sort((a, b) => a.id - b.id);
        this.aboutDementiaTextBlock = res.find(
          el => el.key === 'main_about-dementia',
        )!;
        this.preventionTextBlock = res.find(
          el => el.key === 'main_prevention',
        )!;
        this.preventionCardsBlock = res
          .filter(obj => obj.groupType === 'Prevention')
          .sort((a, b) => a.id - b.id);
        this.aboutFondTextBlock = res
          .filter(obj => obj.groupType === 'AboutFondBlock')
          .sort((a, b) => a.id - b.id);
        this.ourAchievementsTextBlock = res.find(
          el => el.key === 'main_our-achievements',
        )!;
        this.ourAchievementsCardsBlock = res.filter(
          obj => obj.groupType === 'OurAchievements',
        );
        this.bannersArray = res
          .filter(
            el =>
              el.groupType === 'main_banner' &&
              !el.isHidden &&
              !el.key.includes('tab') &&
              !el.key.includes('mob') &&
              !el.key.includes('logo'),
          )
          .sort((a, b) => a.orderId! - b.orderId!);
        this.allBannersData = res.filter(el => el.groupType === 'main_banner');
        this.textContentData = res;
        this.isLoaded = true;
      }
    });
  }
}
