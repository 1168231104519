import { Component, Input, OnInit } from '@angular/core';
import { CardHelpVolunteerInterface } from 'src/app/models/card-help-volunteer.interface';

@Component({
  selector: 'app-card-help',
  templateUrl: './card-help.component.html',
  styleUrls: ['./card-help.component.css'],
})
export class CardHelpComponent implements OnInit {
  @Input() title!: string;

  @Input() cardArray!: CardHelpVolunteerInterface[];

  constructor() {}

  ngOnInit(): void {}
}
