import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-page-link-cards-block',
  templateUrl: './page-link-cards-block.component.html',
  styleUrls: ['./page-link-cards-block.component.css'],
})
export class PageLinkCardsBlockComponent implements OnInit, OnChanges {
  @Input() cardBlockTitle: string = '';
  @Input() cardBlockId: string = '';
  @Input() cardList!: any;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.cardList &&
      this.cardList.subMenuCards &&
      this.cardList.subMenuCards.length
    ) {
      this.cardList.subMenuCards.sort(
        (a: any, b: any) => a.orderId - b.orderId,
      );
    }
  }
}
