<main *ngIf="combinedData; else loader">
  <section class="flex justify-center mb-19.5">
    <div
      class="w-82 md:w-210 xl:w-295 flex flex-col xl:flex-row xl:justify-between">
      <h1
        class="text-primary-base text-h1-360 md:text-h1-heading md:w-95 uppercase mb-5.5">
        {{ combinedData.first_block.title }}
      </h1>
      <div
        class="xl:w-195 text-smaller-main md:text-main-text text-gray-dark"
        appPhoneLink
        [innerHtml]="combinedData.first_block.bodyText"></div>
    </div>
  </section>
  <section class="flex justify-center mb-30">
    <div class="w-82 md:w-210 xl:w-295 flex flex-col xl:flex-wrap">
      <h2
        class="text-h2-360 md:text-h2-heading text-primary-base uppercase mb-5">
        {{ combinedData.second_block.title }}
      </h2>
      <div
        class="text-smaller-main md:text-main-text text-gray-dark mb-10"
        appPhoneLink
        [innerHtml]="combinedData.second_block.bodyText"></div>
      <div class="flex flex-row flex-wrap">
        <app-visionary-card
          [cards]="combinedData.visioners"></app-visionary-card>
      </div>
    </div>
  </section>
  <section>
    <app-fund-partners-block></app-fund-partners-block>
  </section>
  <section
    *ngIf="helpCards; else loader"
    class="py-30 overflow-hidden bg-primary-base">
    <div
      class="flex content-container justify-start flex-wrap gap-5 mb-5 md:mb-0 px-4 md:px-0">
      <h2
        class="text-h2-heading md:text-h1-heading uppercase xl:w-95 w-full text-start xl:text-left text-gray-white">
        {{ textBlock.title }}
      </h2>

      <p
        class="w-195 text-smaller-main md:text-main-text text-gray-white"
        [innerText]="textBlock.text"></p>
    </div>
    <app-adaptive-slider
      class="flex flex-col items-center mx-auto w-[328px] md:w-210 xl:w-295 overflow-hidden">
      <div
        class="flex justify-between items-center mt-10 mb-10 mx-auto md:mb-15 flex-row gap-5 relative w-[328px] md:w-210 xl:w-295">
        <ng-container *ngFor="let helpCard of helpCards">
          <app-help-card [card]="helpCard" [type]=""></app-help-card>
        </ng-container>
      </div>
    </app-adaptive-slider>

    <app-link-button
      [title]="'Переглянути всі варіанти'"
      [linkButtonType]="'Primary'"
      class="flex justify-center w-75 mx-auto mt-16 xl:mt-0"
      [routerLink]="'/get-involved'">
    </app-link-button>
  </section>
</main>
<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
