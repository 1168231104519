import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pages: number = 0;
  @Input() newsOnPage: number = 0;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  pagesArray: number[] = [];
  currentPage: number = 1;

  constructor() {}

  ngOnInit(): void {}

  previous() {
    this.currentPage--;
    this.pageChange.emit(this.currentPage);
  }

  setCurrent(value: number) {
    this.currentPage = value;
    this.pageChange.emit(this.currentPage);
  }

  countingPages() {
    const countOfPages = Math.ceil(this.pages / this.newsOnPage);
    for (let i = 1; i <= countOfPages; i++) {
      this.pagesArray.push(i);
    }
    this.pagesArray = [...new Set(this.pagesArray)];
  }

  next() {
    this.currentPage++;
    this.pageChange.emit(this.currentPage);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.countingPages();
  }
}
