import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-employee',
  templateUrl: './medical-employee.component.html',
  styleUrls: ['./medical-employee.component.css'],
})
export class MedicalEmployeeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
