import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-under-construction-page',
  templateUrl: './under-construction-page.component.html',
  styleUrls: ['./under-construction-page.component.css'],
})
export class UnderConstructionPageComponent implements OnInit {
  backLink!: void;
  constructor(private location: Location) {}

  ngOnInit(): void {}
  goBack() {
    this.location.back();
  }
}
