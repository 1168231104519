import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PAGE_ROUTES, PageRoutes } from 'src/app/constants/page_routes';

@Injectable({
  providedIn: 'root',
})
export class MedicalAssistanceService {
  url = environment.apiUrl;
  constructor(private http: HttpClient, private _sanitizer: DomSanitizer) {}
  getDataPage(): Observable<MedicalAssistanceInterface> {
    return this.http
      .get<FetchResponse>(this.url + '/Support/medical-assistance')
      .pipe(
        map((el: FetchResponse): MedicalAssistanceInterface => {
          return {
            linkSection: el.linkSection.map(item => ({
              title: item.text,
              id: item.title,
            })),
            consultations: el.consultations.map(item => ({
              ...item,
              text: item.bodyText,
            })),
            webinar: el.webinar.map(item => ({
              ...item,
              link: this._sanitizer.bypassSecurityTrustResourceUrl(item.link),
              text: item.bodyText,
            })),
            medicalAssistanceCards: el.medicalAssistanceCards.map(item => ({
              date: item.date,
              link: PAGE_ROUTES[item.link as keyof PageRoutes],
              location: item.location,
              img: item.image,
              header: item.title,
              content: item.text,
            })),
            mainText: el.mainText.map(item => ({
              ...item,
              text: item.bodyText,
            })),
            accordionList: el.accordionList.map(item => ({
              ...item,
              HTML: this._sanitizer.bypassSecurityTrustHtml(item.html),
            })),
          };
        }),
      );
  }
}

interface IConsultationsResponse {
  id: number;
  title: string;
  link: string;
  bodyText: string;
  tag: string;
}
interface IWebinarResponse {
  id: number;
  title: string;
  link: string;
  bodyText: string;
  tag: string;
}
interface IMedicalAssistanceCardsResponse {
  id: number;
  title: string;
  text: string;
  image: string;
  link: string;
  date: null;
  location: string;
  pinnedPage: [];
}
interface IAccordionListResponse {
  id: number;
  tag: string;
  title: string;
  html: string;
}
interface ILinkResponse {
  id: number;
  tag: string;
  text: string;
  title: string;
}
interface FetchResponse {
  consultations: IConsultationsResponse[];
  webinar: IWebinarResponse[];
  medicalAssistanceCards: IMedicalAssistanceCardsResponse[];
  mainText: IConsultationsResponse[];
  accordionList: IAccordionListResponse[];
  linkSection: ILinkResponse[];
}
export interface MedicalAssistanceInterface {
  linkSection: ILinkSection[];
  consultations: IConsultations[];
  webinar: IWebinar[];
  medicalAssistanceCards: IMedicalAssistanceCards[];
  mainText: IMainText[];
  accordionList: IAccordionList[];
}
interface IMedicalAssistanceCards {
  header: string;
  content: string;
  img: string;
  link: string;
  date: string | null;
  location: string;
}

interface ILinkSection {
  title: string;
  id: string;
}
interface IConsultations {
  id: number;
  title: string;
  link: string;
  text: string;
}

interface IWebinar {
  title: string;
  link: SafeResourceUrl;
  text: string;
}
interface IMainText {
  title: string;
  text: string;
}
interface IAccordionList {
  title: string;
  html: string;
}
