<section
  *ngIf="helpCards; else loader"
  class="py-30 overflow-hidden"
  [ngClass]="BackgroundColorType">
  <div
    class="flex content-container justify-start flex-wrap gap-5 mb-5 md:mb-0 px-4 md:px-0"
    *ngIf="loaded">
    <h2
      *ngIf="showTitle"
      class="text-h2-heading md:text-h1-heading uppercase xl:w-95 w-full text-start xl:text-left"
      [ngClass]="HeadingColorType">
      {{ title }}
    </h2>

    <p
      *ngIf="showText && howCanIHelpTextBlock?.bodyText"
      class="w-195 text-smaller-main md:text-main-text"
      [ngClass]="ParagraphColorType"
      [innerHtml]="howCanIHelpTextBlock?.bodyText"></p>
  </div>
  <app-adaptive-slider
    class="flex flex-col items-center mx-auto w-[328px] md:w-210 xl:w-295 overflow-hidden">
    <div
      class="flex justify-between items-center mt-10 mb-10 mx-auto md:mb-15 flex-row gap-5 relative w-[328px] md:w-210 xl:w-295">
      <ng-container *ngFor="let helpCard of helpCards">
        <app-help-card [card]="helpCard" [type]="type"></app-help-card>
      </ng-container>
    </div>
  </app-adaptive-slider>

  <app-link-button
    *ngIf="showButton && loaded && howCanIHelpTextBlock?.buttonURL"
    [title]="'Переглянути всі варіанти'"
    [linkButtonType]="'Primary'"
    class="flex justify-center w-75 mx-auto mt-16 xl:mt-0"
    [routerLink]="howCanIHelpTextBlock?.buttonURL">
  </app-link-button>
</section>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
