<div
  [hidden]="!active"
  class="mx-auto w-full h-full absolute top-0 left-0 animate-menu-appear">
  <div
    *ngIf="bannerData.key === 'dementia_banner'"
    class="flex overflow-hidden mx-auto md:w-full h-170 md:h-137.5 xl:h-full relative justify-center bg-primary-base">
    <div class="flex wide-container h-full relative">
      <img
        class="hidden xl:block absolute top-0 right-0 h-170 pc-position md:h-137.5 xl:h-full w-[1350px] object-cover"
        alt="{{ bannerData.title }}"
        src="{{ bannerData.imageURL }}" />
      <img
        class="hidden md:block xl:hidden absolute mob-position top-0 right-0 h-170 md:h-137.5 xl:h-full object-contain"
        alt="{{ bannerData.title }}"
        src="{{ bannerData.imageURL }}" />
      <img
        class="block md:hidden absolute -top-20 right-0 h-170 md:h-137.5 xl:h-full mob-position object-cover"
        alt="{{ bannerData.title }}"
        src="{{ bannerData.imageURL }}" />
      <div
        class="mx-auto w-full h-full z-10 bg-[linear-gradient(0deg,#6E285F_27.17%,rgba(110,40,95,0.991615)_31.63%,rgba(110,40,95,0.967585)_35.27%,rgba(110,40,95,0.9296)_38.2%,rgba(110,40,95,0.879348)_40.55%,rgba(110,40,95,0.818519)_42.46%,rgba(110,40,95,0.7488)_44.05%,rgba(110,40,95,0.671881)_45.44%,rgba(110,40,95,0.589452)_46.78%,rgba(110,40,95,0.5032)_48.17%,rgba(110,40,95,0.414815)_49.76%,rgba(110,40,95,0.325985)_51.66%,rgba(110,40,95,0.2384)_54.02%,rgba(110,40,95,0.153748)_56.95%,rgba(110,40,95,0.0737185)_60.59%,rgba(110,40,95,0)_65.05%)] md:bg-[linear-gradient(90deg,#6E285F_40.16%,rgba(110,40,95,0.991615)_43.09%,rgba(110,40,95,0.967585)_45.48%,rgba(110,40,95,0.9296)_47.41%,rgba(110,40,95,0.879348)_48.95%,rgba(110,40,95,0.818519)_50.21%,rgba(110,40,95,0.7488)_51.25%,rgba(110,40,95,0.671881)_52.17%,rgba(110,40,95,0.589452)_53.04%,rgba(110,40,95,0.5032)_53.96%,rgba(110,40,95,0.414815)_55%,rgba(110,40,95,0.325985)_56.25%,rgba(110,40,95,0.2384)_57.8%,rgba(110,40,95,0.153748)_59.73%,rgba(110,40,95,0.0737185)_62.12%,rgba(110,40,95,0)_65.05%)]"></div>
    </div>
    <div class="content-container absolute z-20 pb-10 md:pb-20 bottom-0">
      <div class="flex items-end h-full pb-20 md:pb-39">
        <div
          class="flex flex-col items-center md:items-start justify-end text-gray-white px-4 md:px-0 w-full sm:w-120">
          <h3 class="text-h2-heading md:text-h1-heading mb-5">
            {{ bannerData.title | uppercase }}
          </h3>
          <p class="text-smaller-main md:text-main-text">
            {{ bannerData.bodyText }}
          </p>
          <div
            *ngIf="bannerData.buttonColor && bannerData.buttonColor === 'white'"
            class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
            <a
              [href]="bannerData.buttonURL"
              target="_blank"
              class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
              {{ bannerData.buttonName }}
            </a>
          </div>
          <div
            class="md:w-55 w-full mt-10"
            *ngIf="
              !bannerData.buttonColor || bannerData.buttonColor === 'pink'
            ">
            <app-link-button
              class="btn"
              [title]="bannerData.buttonName"
              [linkButtonType]="'Primary'"
              [routerLink]="bannerData.buttonURL"></app-link-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="bannerData.key === 'dementia_banner_yellow'"
    class="flex overflow-hidden mx-auto md:w-full h-170 md:h-137.5 xl:h-full relative justify-center bg-primary-base">
    <div class="flex wide-container h-full relative">
      <img
        class="hidden xl:block absolute top-0 right-0 h-170 pc-position md:h-137.5 xl:h-full w-[1350px] object-cover"
        alt="{{ bannerData.title }}"
        src="{{ bannerData.imageURL }}" />
      <img
        class="hidden md:block xl:hidden absolute mob-position top-0 right-0 h-170 md:h-137.5 xl:h-full object-contain"
        alt="{{ bannerData.title }}"
        src="{{ bannerData.imageURL }}" />
      <img
        class="block md:hidden absolute -top-20 right-0 h-170 md:h-137.5 xl:h-full mob-position object-cover"
        alt="{{ bannerData.title }}"
        src="{{ bannerData.imageURL }}" />
      <div
        class="mx-auto w-full h-full z-10 bg-[linear-gradient(0deg,#F2B500_27.17%,rgba(255,190,0,0.991615)_31.63%,rgba(255,190,0,0.967585)_35.27%,rgba(255,190,0,0.9296)_38.2%,rgba(255,190,0,0.879348)_40.55%,rgba(255,190,0,0.818519)_42.46%,rgba(255,190,0,0.7488)_44.05%,rgba(255,190,0,0.671881)_45.44%,rgba(255,190,0,0.589452)_46.78%,rgba(255,190,0,0.5032)_48.17%,rgba(255,190,0,0.414815)_49.76%,rgba(255,190,0,0.325985)_51.66%,rgba(255,190,0,0.2384)_54.02%,rgba(255,190,0,0.153748)_56.95%,rgba(255,190,0,0.0737185)_60.59%,rgba(255,190,0,0)_65.05%)] md:bg-[linear-gradient(90deg,#F2B500_40.16%,rgba(255,190,0,0.991615)_43.09%,rgba(255,190,0,0.967585)_45.48%,rgba(255,190,0,0.9296)_47.41%,rgba(255,190,0,0.879348)_48.95%,rgba(255,190,0,0.818519)_50.21%,rgba(255,190,0,0.7488)_51.25%,rgba(255,190,0,0.671881)_52.17%,rgba(255,190,0,0.589452)_53.04%,rgba(255,190,0,0.5032)_53.96%,rgba(255,190,0,0.414815)_55%,rgba(255,190,0,0.325985)_56.25%,rgba(255,190,0,0.2384)_57.8%,rgba(255,190,0,0.153748)_59.73%,rgba(255,190,0,0.0737185)_62.12%,rgba(255,190,0,0)_65.05%)]"></div>
    </div>
    <div class="content-container absolute z-20 pb-10 md:pb-20 bottom-0">
      <div class="flex items-end h-full pb-20 md:pb-39">
        <div
          class="flex flex-col items-center md:items-start justify-end text-gray-dark px-4 md:px-0 w-full sm:w-120">
          <h3 class="text-h2-heading md:text-h1-heading mb-5">
            {{ bannerData.title | uppercase }}
          </h3>
          <p class="text-smaller-main md:text-main-text">
            {{ bannerData.bodyText }}
          </p>
          <div
            *ngIf="bannerData.buttonColor && bannerData.buttonColor === 'white'"
            class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
            <a
              [href]="bannerData.buttonURL"
              target="_blank"
              class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
              {{ bannerData.buttonName }}
            </a>
          </div>
          <div
            class="md:w-55 w-full mt-10"
            *ngIf="
              !bannerData.buttonColor || bannerData.buttonColor === 'pink'
            ">
            <app-link-button
              class="btn"
              [title]="bannerData.buttonName"
              [linkButtonType]="'Primary'"
              [routerLink]="bannerData.buttonURL"></app-link-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="bannerData.key === 'howtohelp_banner'">
    <div
      class="w-full bg-secondary-base overflow-hidden h-170 md:h-137.5 xl:h-162">
      <div class="relative w-full h-[680px] md:h-auto z-0">
        <img
          [src]="'assets/images/bannerPicture/yellow-flower.png'"
          class="absolute hidden md:block md:-top-30 right-0 w-full z-[1] min-h-[400px] md:h-[700px] xl:min-h-[994px] object-cover" />
        <img
          [src]="'assets/images/bannerPicture/yellow-flower-mobi.png'"
          class="absolute block md:hidden w-full h-[600px] z-[1] -top-10 object-cover" />
        <img
          [src]="bannerData.imageURL"
          class="absolute z-0 md:min-w-[1050px] -top-10 sm:top-0 md:-top-10 md:left-[25%] min-h-[400px] md:h-[636px] xl:h-[800px] object-cover md:object-contain" />
        <div class="bg-no-repeat relative md:hidden w-full h-full z-[1]">
          <div
            class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
            <div
              class="flex flex-col h-full pb-12.5 md:pb-33 xl:pb-35 items-start justify-end text-gray-dark w-full sm:w-125 xl:w-119 px-4.5 md:px-0 md:pl-15 xl:pl-0">
              <h3 class="text-h2-heading md:text-h1-heading mb-5">
                {{ bannerData.title | uppercase }}
              </h3>
              <div
                *ngIf="
                  bannerData.buttonColor && bannerData.buttonColor === 'white'
                "
                class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
                <a
                  [href]="bannerData.buttonURL"
                  target="_blank"
                  class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
                  {{ bannerData.buttonName }}
                </a>
              </div>
              <div
                class="md:w-55 w-full mt-10"
                *ngIf="
                  !bannerData.buttonColor || bannerData.buttonColor === 'pink'
                ">
                <app-link-button
                  class="btn"
                  [title]="bannerData.buttonName"
                  [linkButtonType]="'Primary'"
                  [routerLink]="bannerData.buttonURL"></app-link-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-no-repeat hidden md:block relative w-full h-full z-[1]">
        <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
          <div
            class="flex flex-col h-full pb-12.5 md:pb-33 xl:pb-35 items-start justify-end text-gray-dark w-full sm:w-125 xl:w-119 px-4.5 md:px-0 md:pl-15 xl:pl-0">
            <h3 class="text-h2-heading md:text-h1-heading mb-5">
              {{ bannerData.title | uppercase }}
            </h3>
            <div
              *ngIf="
                bannerData.buttonColor && bannerData.buttonColor === 'white'
              "
              class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
              <a
                [href]="bannerData.buttonURL"
                target="_blank"
                class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
                {{ bannerData.buttonName }}
              </a>
            </div>
            <div
              class="md:w-55 w-full mt-10"
              *ngIf="
                !bannerData.buttonColor || bannerData.buttonColor === 'pink'
              ">
              <app-link-button
                class="btn"
                [title]="bannerData.buttonName"
                [linkButtonType]="'Primary'"
                [routerLink]="bannerData.buttonURL"></app-link-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="bannerData.key === 'howtohelp_banner_violet'">
    <div
      class="w-full bg-primary-base overflow-hidden h-170 md:h-137.5 xl:h-162">
      <div class="relative w-full h-[680px] md:h-auto z-0">
        <img
          [src]="'assets/images/bannerPicture/violet-flower.png'"
          class="absolute hidden md:block md:-top-30 right-0 w-full z-[1] min-h-[400px] md:h-[700px] xl:min-h-[994px] object-cover" />
        <img
          [src]="'assets/images/bannerPicture/violet-flower-mobi.png'"
          class="absolute block md:hidden w-full h-[600px] z-[1] -top-10 object-cover" />
        <img
          [src]="bannerData.imageURL"
          class="absolute z-0 md:min-w-[1050px] -top-10 sm:top-0 md:-top-10 md:left-[25%] min-h-[400px] md:h-[636px] xl:h-[800px] object-cover md:object-contain" />
        <div class="bg-no-repeat relative md:hidden w-full h-full z-[1]">
          <div
            class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
            <div
              class="flex flex-col h-full pb-12.5 md:pb-33 xl:pb-35 items-start justify-end text-gray-white w-full sm:w-125 xl:w-119 px-4.5 md:px-0 md:pl-15 xl:pl-0">
              <h3 class="text-h2-heading md:text-h1-heading mb-5">
                {{ bannerData.title | uppercase }}
              </h3>
              <div
                *ngIf="
                  bannerData.buttonColor && bannerData.buttonColor === 'white'
                "
                class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
                <a
                  [href]="bannerData.buttonURL"
                  target="_blank"
                  class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
                  {{ bannerData.buttonName }}
                </a>
              </div>
              <div
                class="md:w-55 w-full mt-10"
                *ngIf="
                  !bannerData.buttonColor || bannerData.buttonColor === 'pink'
                ">
                <app-link-button
                  class="btn"
                  [title]="bannerData.buttonName"
                  [linkButtonType]="'Primary'"
                  [routerLink]="bannerData.buttonURL"></app-link-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-no-repeat hidden md:block relative w-full h-full z-[1]">
        <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
          <div
            class="flex flex-col h-full pb-12.5 md:pb-33 xl:pb-35 items-start justify-end text-gray-white w-full sm:w-125 xl:w-119 px-4.5 md:px-0 md:pl-15 xl:pl-0">
            <h3 class="text-h2-heading md:text-h1-heading mb-5">
              {{ bannerData.title | uppercase }}
            </h3>
            <div
              *ngIf="
                bannerData.buttonColor && bannerData.buttonColor === 'white'
              "
              class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
              <a
                [href]="bannerData.buttonURL"
                target="_blank"
                class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
                {{ bannerData.buttonName }}
              </a>
            </div>
            <div
              class="md:w-55 w-full mt-10"
              *ngIf="
                !bannerData.buttonColor || bannerData.buttonColor === 'pink'
              ">
              <app-link-button
                class="btn"
                [title]="bannerData.buttonName"
                [linkButtonType]="'Primary'"
                [routerLink]="bannerData.buttonURL"></app-link-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="bannerData.key === 'first_banner'"
    class="w-full overflow-hidden h-170 md:h-137.5 xl:h-162 flex-center justify-end">
    <div
      class="w-full h-full bg-no-repeat bgMobilePicture md:bg-left bg-cover"
      [ngStyle]="{ 'background-image': bgBannerImage(bannerData.imageURL) }">
      <div
        class="flex w-full h-full bg-mobilegradienFirstBanner md:bg-gradientFirstBanner">
        <div class="content-container flex items-end h-full pb-20 md:pb-39">
          <div
            class="flex flex-col items-center md:items-start justify-end text-gray-white px-4 md:px-0 w-full sm:w-120">
            <h3 class="text-h2-heading md:text-h1-heading mb-5">
              {{ bannerData.title | uppercase }}
            </h3>
            <p class="text-smaller-main md:text-main-text">
              {{ bannerData.bodyText }}
            </p>
            <div
              *ngIf="
                bannerData.buttonColor && bannerData.buttonColor === 'white'
              "
              class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
              <a
                [href]="bannerData.buttonURL"
                target="_blank"
                class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
                {{ bannerData.buttonName }}
              </a>
            </div>
            <div
              class="md:w-55 w-full mt-10"
              *ngIf="
                !bannerData.buttonColor || bannerData.buttonColor === 'pink'
              ">
              <app-link-button
                class="btn"
                [title]="bannerData.buttonName"
                [linkButtonType]="'Primary'"
                [routerLink]="bannerData.buttonURL"></app-link-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="bannerData.key === 'fourth_banner'"
    class="w-full h-170 md:h-137.5 xl:h-162 overflow-hidden">
    <div class="relative w-full h-[680px] md:h-auto z-0">
      <img
        [src]="'assets/images/bannerPicture/yellow.png'"
        class="absolute hidden md:block md:top-0 md:right-[28%] xl:right-[20%] w-full z-[1] md:h-[550px] xl:h-[648px] xl:object-contain" />
      <img
        [src]="'assets/images/bannerPicture/yellow-mobi.png'"
        class="absolute block md:hidden w-full min-w-[360px] sm:min-w-[980px] z-[1] top-[32%] sm:top-[29%] object-cover" />
      <img
        [src]="bannerData.imageURL"
        class="absolute z-0 md:min-w-[1050px] top-0 md:left-[40%] xl:left-[49%] min-h-[60%] sm:min-h-[57%] md:h-[550px] xl:h-[648px] object-cover md:object-contain" />
      <div
        class="bg-no-repeat bg-center block md:hidden relative sm:bg-top md:bg-center bg-cover h-full w-full z-[1]">
        <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
          <div
            class="flex flex-col h-full pb-20 md:pb-21 xl:pb-35 items-start justify-end text-gray-dark w-120 md:w-160.25 px-4 md:pl-15 xl:pl-0 md:px-0">
            <h3
              class="text-h2-heading uppercase md:text-h1-heading md:mb-2.5 text-gray-dark"
              [innerHTML]="bannerData.title"></h3>
            <div
              class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
              <a
                [href]="bannerData.buttonURL"
                target="_blank"
                class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
                {{ bannerData.buttonName }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-no-repeat bg-center hidden md:block relative sm:bg-top md:bg-center bg-cover h-full w-full z-[1]">
      <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
        <div
          class="flex flex-col h-full pb-20 md:pb-21 xl:pb-35 items-start justify-end text-gray-dark w-120 md:w-160.25 px-4 md:pl-15 xl:pl-0 md:px-0">
          <h3
            class="text-h2-heading uppercase md:text-h1-heading md:mb-2.5 text-gray-dark"
            [innerHTML]="bannerData.title"></h3>
          <div
            *ngIf="bannerData.buttonColor && bannerData.buttonColor === 'white'"
            class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
            <a
              [href]="bannerData.buttonURL"
              target="_blank"
              class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
              {{ bannerData.buttonName }}
            </a>
          </div>
          <div
            class="md:w-55 w-full mt-10"
            *ngIf="
              !bannerData.buttonColor || bannerData.buttonColor === 'pink'
            ">
            <app-link-button
              class="btn"
              [title]="bannerData.buttonName"
              [linkButtonType]="'Primary'"
              [routerLink]="bannerData.buttonURL"></app-link-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="bannerData.key === 'third_banner'"
    class="w-full h-170 md:h-137.5 xl:h-162 overflow-hidden">
    <div class="relative w-full h-[680px] md:h-auto z-0">
      <img
        [src]="'assets/images/bannerPicture/violet.png'"
        class="absolute hidden md:block md:top-0 md:right-[28%] xl:right-[18%] w-full z-[1] md:h-[550px] xl:h-[648px] xl:object-contain" />
      <img
        [src]="'assets/images/bannerPicture/violet-mobi.png'"
        class="absolute block md:hidden w-full min-w-[360px] sm:min-w-[980px] z-[1] top-[32%] sm:top-[29%] object-cover" />
      <img
        [src]="bannerData.imageURL"
        class="absolute z-0 md:min-w-[1050px] top-0 md:left-[40%] xl:left-[49%] min-h-[60%] sm:min-h-[57%] md:h-[550px] xl:h-[648px] object-cover md:object-contain" />
      <div
        class="bg-no-repeat bg-center block md:hidden relative sm:bg-top md:bg-center bg-cover h-full w-full z-[1]">
        <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
          <div
            class="flex flex-col h-full pb-20 md:pb-21 xl:pb-35 items-start justify-end text-gray-white w-120 md:w-160.25 px-4 md:pl-15 xl:pl-0 md:px-0">
            <h3
              class="text-h2-heading md:text-h1-heading md:mb-2.5 text-gray-white">
              {{ bannerData.title | uppercase }}
            </h3>
            <div class="flex flex-row justify-start w-55 mt-5 md:mt-8">
              <div
                class="group/social flex justify-center items-center mr-10 w-12 h-12 bg-white rounded-full cursor-pointer">
                <a href="https://www.instagram.com/nezabutni_/" target="_blank"
                  ><svg
                    width="24px"
                    height="24px"
                    icon="instagram"
                    iconCollection="social-icons-sprite"
                    class="fill-accent-base group-hover/social:fill-secondary-additional active:fill-secondary-additional"></svg
                ></a>
              </div>
              <div
                class="group/social flex justify-center items-center mr-10 w-12 h-12 bg-white rounded-full cursor-pointer">
                <a href="https://www.facebook.com/nezabutni.org" target="_blank"
                  ><svg
                    width="16px"
                    height="29px"
                    icon="facebook"
                    iconCollection="social-icons-sprite"
                    class="fill-accent-base group-hover/social:fill-secondary-additional active:fill-secondary-additional"></svg
                ></a>
              </div>
              <div
                class="group/social flex justify-center hover:fill-secondary-additional items-center w-12 h-12 bg-white rounded-full cursor-pointer">
                <a
                  href="https://www.youtube.com/channel/UCIhdADy2Q8xzImxhzQK549w/featured"
                  target="_blank"
                  ><svg
                    width="27px"
                    height="18px"
                    icon="youtube"
                    iconCollection="social-icons-sprite"
                    class="fill-accent-base group-hover/social:fill-secondary-additional active:fill-secondary-additional"></svg
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-no-repeat bg-center hidden md:block relative sm:bg-top md:bg-center bg-cover h-full w-full z-[1]">
      <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
        <div
          class="flex flex-col h-full pb-20 md:pb-21 xl:pb-35 items-start justify-end text-gray-white w-120 md:w-160.25 px-4 md:pl-15 xl:pl-0 md:px-0">
          <h3
            class="text-h2-heading md:text-h1-heading md:mb-2.5 text-gray-white">
            {{ bannerData.title | uppercase }}
          </h3>
          <div class="flex flex-row justify-start w-55 mt-5 md:mt-8">
            <div
              class="group/social flex justify-center items-center mr-10 w-12 h-12 bg-white rounded-full cursor-pointer">
              <a href="https://www.instagram.com/nezabutni_/" target="_blank"
                ><svg
                  width="24px"
                  height="24px"
                  icon="instagram"
                  iconCollection="social-icons-sprite"
                  class="fill-accent-base group-hover/social:fill-secondary-additional active:fill-secondary-additional"></svg
              ></a>
            </div>
            <div
              class="group/social flex justify-center items-center mr-10 w-12 h-12 bg-white rounded-full cursor-pointer">
              <a href="https://www.facebook.com/nezabutni.org" target="_blank"
                ><svg
                  width="16px"
                  height="29px"
                  icon="facebook"
                  iconCollection="social-icons-sprite"
                  class="fill-accent-base group-hover/social:fill-secondary-additional active:fill-secondary-additional"></svg
              ></a>
            </div>
            <div
              class="group/social flex justify-center hover:fill-secondary-additional items-center w-12 h-12 bg-white rounded-full cursor-pointer">
              <a
                href="https://www.youtube.com/channel/UCIhdADy2Q8xzImxhzQK549w/featured"
                target="_blank"
                ><svg
                  width="27px"
                  height="18px"
                  icon="youtube"
                  iconCollection="social-icons-sprite"
                  class="fill-accent-base group-hover/social:fill-secondary-additional active:fill-secondary-additional"></svg
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="bannerData.key === 'fifth_banner'"
    class="w-full h-170 md:h-137.5 xl:h-162 overflow-hidden">
    <div class="relative w-full h-[680px] md:h-auto z-0">
      <img
        [src]="'assets/images/bannerPicture/violet.png'"
        class="absolute hidden md:block md:top-0 md:right-[28%] xl:right-[18%] w-full z-[1] md:h-[550px] xl:h-[648px] xl:object-contain" />
      <img
        [src]="'assets/images/bannerPicture/violet-mobi.png'"
        class="absolute block md:hidden w-full min-w-[360px] sm:min-w-[980px] z-[1] top-[32%] sm:top-[29%] object-cover" />
      <img
        [src]="bannerData.imageURL"
        class="absolute z-0 md:min-w-[1050px] top-0 md:left-[40%] xl:left-[49%] min-h-[60%] sm:min-h-[57%] md:h-[550px] xl:h-[648px] object-cover md:object-contain" />
      <div
        class="bg-no-repeat bg-center block md:hidden relative sm:bg-top md:bg-center bg-cover h-full w-full z-[1]">
        <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
          <div
            class="flex flex-col h-full pb-20 md:pb-21 xl:pb-35 items-start justify-end text-gray-white w-120 md:w-160.25 px-4 md:pl-15 xl:pl-0 md:px-0">
            <h3
              class="text-h2-heading uppercase md:text-h1-heading md:mb-2.5 text-primary-additional"
              [innerHTML]="bannerData.title"></h3>
            <div
              class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
              <a
                [href]="bannerData.buttonURL"
                target="_blank"
                class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
                {{ bannerData.buttonName }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-no-repeat bg-center hidden md:block relative sm:bg-top md:bg-center bg-cover h-full w-full z-[1]">
      <div class="mx-auto w-full md:w-240 xl:w-295 flex justify-start h-full">
        <div
          class="flex flex-col h-full pb-20 md:pb-21 xl:pb-35 items-start justify-end text-gray-white w-120 md:w-160.25 px-4 md:pl-15 xl:pl-0 md:px-0">
          <h3
            class="text-h2-heading uppercase md:text-h1-heading md:mb-2.5 text-gray-white"
            [innerHTML]="bannerData.title"></h3>
          <p class="text-smaller-main md:text-main-text text-gray-white">
            {{ bannerData.bodyText }}
          </p>
          <div
            *ngIf="bannerData.buttonColor && bannerData.buttonColor === 'white'"
            class="flex flex-row justify-start w-full md:w-70 mt-5 md:mt-8">
            <a
              [href]="bannerData.buttonURL"
              target="_blank"
              class="w-full py-2 border-2 text-center text-main-text font-semibold bg-gray-white hover:text-secondary-base active:text-secondary-additional rounded-10 duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer text-accent-base border-accent-base">
              {{ bannerData.buttonName }}
            </a>
          </div>
          <div
            class="md:w-55 w-full mt-10"
            *ngIf="
              !bannerData.buttonColor || bannerData.buttonColor === 'pink'
            ">
            <app-link-button
              class="btn"
              [title]="bannerData.buttonName"
              [linkButtonType]="'Primary'"
              [routerLink]="bannerData.buttonURL"></app-link-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
