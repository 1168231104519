import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface TextContentModel {
  id: number;
  pageName: string;
  key: string;
  title: string;
  bodyText: string;
  buttonURL: string;
  imageURL: string;
  groupType: string;
  File?: string | File;
  orderId?: number;
  buttonName: string;
  isHidden?: boolean;
  buttonColor?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class TextContentService {
  private url: string = environment.apiUrl + '/Text/GetTextsByPageName';
  adminUrl: string = environment.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getTextsContentByPageName(pageName: string) {
    return this.http.get<TextContentModel[]>(
      this.url + `?pageName=${encodeURIComponent(pageName)}`,
    );
  }

  updateTextContent(data: any) {
    if (!data.orderId) data.orderId = 1;
    if (!data.isHidden) data.isHidden = false;
    const formData = new FormData();
    for (let str in data) {
      formData.append(str, data[str]);
    }
    return this.http.put(this.adminUrl + 'UpdateText', formData);
  }

  createTextContent(data: any) {
    const formData = new FormData();
    for (let str in data) {
      formData.append(str, data[str]);
    }
    return this.http.post(this.adminUrl + 'AddText', formData);
  }

  deleteTextContent(data: { id: number; imageURL?: string }, pageName: string) {
    const delData = {
      ...data,
      imageURL: data && data.imageURL ? data.imageURL : '',
      pageName: pageName,
    };
    return this.http.delete(this.adminUrl + 'DeleteText', { body: delData });
  }

  updateTextContentOrder(data: any) {
    return this.http.put(this.adminUrl + 'UpdateTextOrder', data);
  }

  removeGroupCard(texts: TextContentModel[], pageName: string) {
    const textsData = texts.map(el => ({
      id: el.id,
      imageURL: el && el.imageURL ? el.imageURL : '',
      pageName: pageName,
    }));
    return this.http.delete(this.adminUrl + 'DeleteTexts', { body: textsData });
  }
}
