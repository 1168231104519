<main *ngIf="pageData.length">
  <div class="pb-1">
    <div
      class="content-container px-4 md:px-0 flex flex-col items-center md:items-start pt-15.5 mb-30 xl:mb-60">
      <ng-container *ngIf="!isShowSuccessMessage; else elseBlock">
        <h1 class="w-full text-h1-360 md:text-h1-narrow text-primary-base mb-5">
          {{ mainText.title }}
        </h1>
        <div
          class="w-full text-smaller-main md:text-main-text text-gray-dark mb-14.75"
          appPhoneLink
          [innerHtml]="mainText.bodyText"></div>

        <form
          class="w-82 md:w-120"
          [formGroup]="unsubscribeForm"
          (ngSubmit)="onSubmit()">
          <app-input
            [control]="unsubscribeForm.controls.userEmail"
            [labelName]="'E-mail'"
            type="email"
            inputID="email"></app-input>

          <app-button
            class="w-82 md:w-120 mt-5"
            [buttonType]="
              mainText.buttonColor === 'Primary' ? 'Secondary' : 'Primary'
            "
            [title]="mainText.buttonName"
            type="submit"></app-button>
        </form>
      </ng-container>

      <ng-template #elseBlock>
        <div>
          <h1
            class="w-full text-h1-360 md:text-h1-narrow text-primary-base uppercase mb-5">
            {{ additionalText.title }}
          </h1>
          <div
            *ngIf="additionalText.bodyText && additionalText.bodyText.length"
            class="w-full text-smaller-main md:text-main-text text-gray-dark mb-14.75"
            appPhoneLink
            [innerHtml]="additionalText.bodyText"></div>
        </div>
      </ng-template>
    </div>

    <app-help-block [type]="'primary'"></app-help-block>
  </div>
</main>
