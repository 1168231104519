import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import IFundMember from '../models/fundMember.interface';
import { fundMembersMock } from 'src/mockData/fundMembersMock';
import {
  FundTeamService,
  IFundMemberResponse,
} from 'src/app/services/api/aboutUsPages/FundTeamPages/fund-team.service';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbFundMemberResolver  {
  isHovered = 'false';
  fundMembers: IFundMember[] = fundMembersMock;
  constructor(private apiService: FundTeamService) {}
  fetchFundTeam(id: string): Observable<IFundMemberResponse> {
    return this.apiService.getFundMemberById(id);
  }
  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<(IFundMemberResponse & { breadcrumb: string }) | null> {
    const memberID = route.paramMap.get('id');
    if (memberID) {
      return this.fetchFundTeam(memberID).pipe(
        map(data => {
          return { breadcrumb: data.title, ...data };
        }),
      );
    } else return of(null);
  }
}
