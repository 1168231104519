import { Component, OnInit, Input } from '@angular/core';
import IInfoPage from 'src/app/models/infoPage.interface';

@Component({
  selector: 'app-block-text',
  templateUrl: './block-text.component.html',
})
export class BlockTextComponent implements OnInit {
  constructor() {}
  @Input() text: IInfoPage | null = null;
  ngOnInit(): void {}
}
