<div class="flex flex-wrap justify-center md:grid md:grid-cols-2 gap-5">
  <div *ngFor="let partner of cards">
    <a [href]="partner.buttonURL" target="_blank">
      <div
        class="w-82 md:w-102.5 xl:w-145 overflow-hidden border-2 border-gray-stroke rounded-20 md:rounded-30 flex flex-col items-center justify-between p-5 pt-15.5 md:p-10 md:pt-18 xl:pt-10 cursor-pointer hover:border-secondary-base duration-300 group">
        <img
          class="mb-22 md:mb-18.75 xl:mb-10.25 h-[222px]"
          [src]="partner.imageURL"
          alt="infopulse" />
        <div
          class="w-72 md:w-82 xl:w-125 mb-16.5 md:mb-18 h-28"
          [innerHTML]="partner.bodyText"></div>
        <span
          class="flex justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 group-hover:bg-secondary-base group-active:bg-secondary-additional disabled:bg-gray-gray2 py-2.5 cursor-pointer">
          Детальніше
        </span>
      </div>
    </a>
  </div>
</div>
