import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TextContentModel } from '../../../services/api/text-content.service';

@Component({
  selector: 'app-subscribe-help',
  templateUrl: './subscribe-help.component.html',
  styleUrls: ['./subscribe-help.component.css'],
})
export class SubscribeHelpComponent implements OnInit {
  @Input() tabsData: TextContentModel[] = [];
  bankChecked: boolean = false;
  form!: FormGroup;

  banksFormData: TextContentModel[] = [];
  subscribeFormText: TextContentModel | null = null;
  selectedBankText: string = '';
  constructor() {}

  ngOnInit(): void {
    this.form = new FormGroup({
      bankName: new FormControl('', Validators.required),
    });
    this.subscribeFormText = this.tabsData.find(
      el => el.key === 'subscribe_text',
    )!;
    this.banksFormData = this.tabsData.filter(el => el.key === 'banks_data');
  }

  refreshData() {
    this.form.reset();
    this.bankChecked = false;
    this.selectedBankText = '';
  }

  findPdfInstruction() {
    return this.banksFormData.find(
      el => el.buttonURL === this.form.controls['bankName'].value,
    )?.buttonName;
  }

  clickBankingSubscribe(item: TextContentModel) {
    this.bankChecked = true;
    this.selectedBankText = item.bodyText;
  }
}

