<div class="content-container xl:flex xl:items-center mx-auto mb-2 px-4 md:p-0">
  <div class="xl:flex items-start gap-5">
    <h3 class="text-primary-base md:text-h1-heading text-h1-360 xl:w-95 mb-5">
      {{ title | uppercase }}
    </h3>
    <div
      class="xl:w-195 text-gray-dark md:text-main-text text-smaller-main"
      appPhoneLink
      [innerHtml]="text"></div>
  </div>
</div>
