<div class="relative flex flex-col">
  <input
    [id]="inputID"
    [name]="inputID"
    [formControl]="control"
    [maxlength]="maxLength"
    class="block w-full h-12 rounded-10 pl-5 border-2 border-gray-stroke outline-none appearance-none focus:outline-none focus:ring-0 peer focus:border-primary-base"
    [ngClass]="{
      'border-attention-base text-attention-base placeholder:text-attention-base':
        isInvalid
    }"
    [type]="type"
    [placeholder]="darkInput ? labelName : ' '" />
  <label
    *ngIf="!darkInput"
    [for]="inputID"
    class="absolute text-details text-gray-details bg-white duration-300 transform -translate-y-4 top-2 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-primary-base peer-placeholder-shown:text-main-text peer-placeholder-shown:-translate-y-1 peer-placeholder-shown:top-3.5 peer-focus:top-2 peer-focus:text-details peer-focus:-translate-y-4 left-3 cursor-text"
    [ngClass]="{
      'text-attention-base peer-focus:text-attention-base ': isInvalid
    }"
    >{{ labelName }}</label
  >
  <span
    class="absolute top-2.5 right-2.5 text-attention-base text-main-text bg-gray-card w-7 rounded-full text-center"
    *ngIf="isInvalid"
    >!</span
  >
  <div
    class="text-details -bottom-6 left-1 animate-error-appear"
    *ngIf="control.touched && control.hasError('required')"
    [ngClass]="darkInput ? 'text-gray-white' : 'text-attention-base'">
    {{
      displayErrorMessage(formFields.Name, formErrors.Required)?.errorMessage
    }}
  </div>
  <div
    class="text-details -bottom-6 left-1 animate-error-appear"
    *ngIf="control.dirty && control.hasError('invalidName')"
    [ngClass]="darkInput ? 'text-gray-white' : 'text-attention-base'">
    {{
      displayErrorMessage(formFields.Name, formErrors.InvalidName)?.errorMessage
    }}
  </div>
  <div
    class="text-details -bottom-6 left-1 animate-error-appear"
    *ngIf="control.dirty && control.hasError('email')"
    [ngClass]="darkInput ? 'text-gray-white' : 'text-attention-base'">
    {{ displayErrorMessage(formFields.Email, formErrors.Email)?.errorMessage }}
  </div>
</div>
