import { Component, OnInit } from '@angular/core';
import IFooter from '../../models/footer.interface';
import { ISocialLink } from 'src/app/models/social-link.interface';
import { socialLinksMockData } from 'src/mockData/socialLinksMockData';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { OpenPdf } from 'src/app/services/pdfOpen.service';
import {
  TextContentModel,
  TextContentService,
} from '../../services/api/text-content.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  pageName: string = 'other-parts';
  subs: Subscription = new Subscription();
  politicsLink: TextContentModel | null = null;
  offertLink: TextContentModel | null = null;
  pageData: TextContentModel[] = [];
  currentYear!: Date;
  footerLinks: IFooter[] = [
    {
      text: 'Головна',
      link: '/',
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Звіти та документи',
      link: PAGE_ROUTES.reports,
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Напрями діяльності',
      link: PAGE_ROUTES.ourPrograms,
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Стати волонтером',
      link: PAGE_ROUTES.becomeVolunteer,
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Новини',
      link: PAGE_ROUTES.news,
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Стати партнером',
      link: PAGE_ROUTES.becomePartner,
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Повідомити про проблему\nз сайтом',
      link: PAGE_ROUTES.contacts,
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Контакти',
      link: PAGE_ROUTES.contacts,
      class: 'p-2 hover:text-accent-base',
    },
  ];
  footerSocialLinks: ISocialLink[] = socialLinksMockData;
  footerLink!: IFooter;
  constructor(
    private openPdf: OpenPdf,
    private apiService: TextContentService,
  ) {}

  ngOnInit(): void {
    this.currentYear = new Date();
    this.getPageTextData();
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.politicsLink = this.pageData.find(
          el => el.key === 'politics_link',
        )!;
        this.offertLink = this.pageData.find(el => el.key === 'offert_link')!;
      });
    this.subs.add(sub);
  }
  onOpenPublic_offerPDF(link: string, title: string): void {
    this.openPdf.getPDF(link, title);
  }
  onOpenTermsPDF(link: string, title: string): void {
    this.openPdf.getPDF(link, title);
  }
}
