<a
  href="tel:0800308830"
  class="underline text-accent-base md:hidden inline-block"
  ><div class="mr-1 inline-flex items-baseline w-full">
    <svg
      width="15px"
      height="15px"
      icon="phone"
      iconCollection="button-icons-sprite"
      class="fill-accent-base w-6 h-6 self-center relative"></svg>
    <div class="underline font-semibold">0 800 30 88 30</div>
  </div></a
><span class="hidden text-accent-base md:inline-block">0 800 30 88 30</span>
