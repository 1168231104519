<app-page-banner-item [pageName]="pageName"></app-page-banner-item>
<section class="mt-30 px-[16px] md:px-0">
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="links"></app-table-of-content>
</section>

<section class="py-30 px-[16px] md:px-0 flex gap-5 content-container">
  <div class="flex flex-col gap-15">
    <div class="flex flex-col xl:flex-row gap-5">
      <div *ngIf="combinedData">
        <img
          [src]="combinedData.first_block.imageURL"
          [alt]="combinedData.first_block.title"
          class="w-[328px] md:w-145 h-[226px] md:h-100 rounded-30 md:float-right md:ml-5 mb-10 mx-auto md:mb-5" />
        <h2
          [id]="combinedData.first_block.key"
          class="text-h2-heading text-primary-base">
          {{ combinedData.first_block.title }}
        </h2>
        <div
          class="text-gray-dark text-main-text mt-5"
          appPhoneLink
          [innerHTML]="combinedData.first_block.bodyText"></div>
      </div>
    </div>
  </div>
</section>

<section class="bg-gray-card py-30 px-[16px] md:px-0" *ngIf="combinedData">
  <div class="flex flex-col gap-15 content-container">
    <div class="flex flex-col xl:flex-row gap-5">
      <div *ngIf="combinedData">
        <h2
          [id]="combinedData.second_block.key"
          class="text-h2-heading text-primary-base w-[328px] md:w-full xl:w-145">
          {{ combinedData.second_block.title }}
        </h2>
        <div
          class="text-gray-dark text-main-text mt-5"
          appPhoneLink
          [innerHTML]="combinedData.second_block.bodyText"></div>
      </div>
    </div>
    <div class="flex flex-col xl:flex-row gap-5">
      <div *ngIf="combinedData">
        <img
          [src]="combinedData.second_block_second.imageURL"
          [alt]="combinedData.second_block_second.title"
          class="w-[328px] md:w-145 h-[226px] md:h-100 rounded-30 md:float-right md:ml-5 mb-10 mx-auto md:mb-5" />
        <h2
          [id]="combinedData.second_block_second.key"
          class="text-h3-subheading text-primary-base w-[328px] md:w-full xl:w-145">
          {{ combinedData.second_block_second.title }}
        </h2>
        <div
          class="text-gray-dark text-main-text mt-5"
          appPhoneLink
          [innerHTML]="combinedData.second_block_second.bodyText"></div>
      </div>
    </div>
    <div class="flex flex-col gap-5 md:w-full">
      <ul
        class="flex gap-x-5 flex-wrap justify-between md:justify-start w-full md:w-48">
        <li *ngFor="let socialLink of socialLinks">
          <app-social-link
            class="inline-block w-12 h-12 shrink-0"
            [socialLink]="socialLink"></app-social-link>
        </li>
      </ul>
    </div>
  </div>
</section>

<section
  class="py-30 px-[16px] md:px-0 flex flex-col gap-30 content-container"
  *ngIf="combinedData">
  <div class="flex flex-col gap-10">
    <div class="flex flex-col gap-5">
      <h2
        [id]="combinedData.third_block.key"
        class="text-h2-heading text-primary-base w-[328px] md:w-full xl:w-145">
        {{ combinedData.third_block.title }}
      </h2>
      <div
        class="text-main-text text-gray-dark"
        appPhoneLink
        [innerHTML]="combinedData.third_block.bodyText"></div>
    </div>
    <div *ngIf="thirdBlockUrl">
      <iframe
        class="rounded-30 mx-auto w-[327px] md:w-210 xl:w-295 h-[184px] md:h-[473px] xl:h-166.25"
        [src]="thirdBlockUrl"
        frameborder="0"
        allowfullscreen></iframe>
    </div>
  </div>

  <div class="flex flex-col gap-10">
    <div class="flex flex-col gap-5">
      <h2
        [id]="combinedData.fourth_block.key"
        class="text-h2-heading text-primary-base w-[328px] md:w-full xl:w-145">
        {{ combinedData.fourth_block.title }}
      </h2>
      <div appPhoneLink [innerHTML]="combinedData.fourth_block.bodyText"></div>
    </div>
    <div *ngIf="fourthBlockUrl">
      <iframe
        class="rounded-30 mx-auto w-[327px] md:w-210 xl:w-295 h-[184px] md:h-[473px] xl:h-166.25"
        [src]="fourthBlockUrl"
        frameborder="0"
        allowfullscreen></iframe>
    </div>
    <app-adaptive-page-slider
      *ngIf="imageListFirst.length"
      [skipLast]="false"
      [counterOfPages]="true"
      [amountOffCardsBlock]="imageListFirst.length"
      [hideButton]="[false, false, false]"
      class="mx-auto w-full md:w-210 xl:w-295 overflow-hidden">
      <div
        class="flex justify-start items-center mt-10 mb-10 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
        <img
          *ngFor="let image of imageListFirst"
          [src]="image.imageUrl"
          [alt]="image.imageUrl"
          class="shrink-0 h-[350px] md:h-[332px] xl:h-[474px] w-[280px] md:w-[267px] xl:w-[380px] object-cover rounded-[30px] border-2 border-gray-card" />
      </div>
    </app-adaptive-page-slider>

    <div
      class="flex items-stretch"
      appPhoneLink
      [innerHTML]="combinedData.fourth_block_second.bodyText"></div>
  </div>

  <div id="AdviceInformMentalHealth" class="flex flex-col gap-10">
    <div class="flex flex-col gap-5">
      <h2
        [id]="combinedData.fiveth_block_second.key"
        class="text-h2-heading text-primary-base">
        {{ combinedData.fiveth_block_second.title }}
      </h2>
      <div
        class="text-main-text text-gray-dark"
        appPhoneLink
        [innerHtml]="combinedData.fiveth_block_second.bodyText"></div>
    </div>
    <app-accordion
      *ngIf="accordionData.length"
      [AccordionsList]="accordionData"
      [options]="{ multi: false }"></app-accordion>
  </div>
</section>

<app-more-info [moreInfos]="moreInfosAid" [mainText]="mainText"></app-more-info>
