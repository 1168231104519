<section class="pb-36" *ngIf="textContentData.length">
  <!-- School -->
  <div class="flex flex-col items-center">
    <div class="pt-30 relative content-container my-0">
      <!-- wrapper -->
      <div
        class="flex justify-between md:items-center xl:flex-row flex-col px-4 md:px-0">
        <div
          class="flex flex-col pb-2.5 md:flex-row flex-wrap md:justify-between xl:flex-1">
          <h2
            class="text-h2-heading md:text-h1-heading uppercase text-primary-base flex-1 xl:basis-145">
            {{ ourActivitiesTextBlock.title }}
          </h2>
          <app-link-button
            [url]="ourActivitiesTextBlock.buttonURL"
            [linkButtonType]="'RightArrow'"
            [title]="'Переглянути більше'"
            class="w-55 mt-5 md:mt-0 flex md:justify-end">
          </app-link-button>
          <div class="md:order-1">
            <div
              class="text-smaller-main md:text-main-text text-gray-dark w-full mt-5"
              [innerHTML]="ourActivitiesTextBlock.bodyText"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Help with medicine -->
    <div class="py-15 xl:pt-15 xl:pb-20 w-full flex justify-center">
      <div
        class="content-container flex flex-col md:flex-row items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
        <!-- wrapper -->
        <div class="flex flex-col items-start">
          <h3 class="text-h3-360 md:text-h2-heading-bold text-primary-base">
            {{ helpWithMedicineBlock.title | uppercase }}
          </h3>
          <div
            class="w-full md:w-86 xl:w-[580px] pb-10 mt-2.5 xl:mt-7.5 text-smaller-main md:text-main-text"
            [innerHTML]="helpWithMedicineBlock.bodyText"></div>

          <app-link-button
            [url]="helpWithMedicineBlock.buttonURL"
            [linkButtonType]="'Secondary'"
            [title]="'Детальніше'"
            class="w-full md:w-55 flex">
          </app-link-button>
        </div>
        <img
          [src]="helpWithMedicineBlock.imageURL"
          [alt]="helpWithMedicineBlock.title"
          class="rounded-15 md:w-102.5 xl:w-120 mx-auto" />
      </div>
    </div>
  </div>

  <!-- help-with-power -->
  <div
    class="flex justify-center items-center bg-gray-card pt-18 xl:pt-14 pb-20 rounded-30">
    <div
      class="content-container flex flex-col md:flex-row-reverse items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
      <!-- wrapper -->
      <div class="flex flex-col items-start">
        <h3 class="text-h3-360 md:text-h2-heading-bold text-primary-base">
          {{ helpWithGeneratorsBlock.title | uppercase }}
        </h3>
        <div
          class="w-full md:w-86 xl:w-[580px] pb-10 mt-2.5 xl:mt-7.5 text-smaller-main md:text-main-text"
          [innerHTML]="helpWithGeneratorsBlock.bodyText"></div>

        <app-link-button
          [url]="helpWithGeneratorsBlock.buttonURL"
          [linkButtonType]="'Secondary'"
          [titleLinkId]="'help_with_generators'"
          [title]="'Детальніше'"
          class="w-full md:w-55 flex">
        </app-link-button>
      </div>
      <img
        [src]="helpWithGeneratorsBlock.imageURL"
        [alt]="helpWithGeneratorsBlock.title"
        class="rounded-15 md:w-102.5 xl:w-120 mx-auto" />
    </div>
  </div>

  <!-- Psychological support -->
  <div
    class="flex justify-center items-center bg-gray-card pt-18 xl:pt-14 pb-20 rounded-30">
    <div
      class="content-container flex flex-col md:flex-row items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
      <!-- wrapper -->
      <div class="flex flex-col items-start">
        <h3 class="text-h3-360 md:text-h2-heading-bold text-primary-base">
          {{ psychologicalSupportBlock.title | uppercase }}
        </h3>

        <div
          class="w-full md:w-86 xl:w-[580px] pb-10 mt-2.5 xl:mt-7.5 text-smaller-main md:text-main-text"
          [innerHTML]="psychologicalSupportBlock.bodyText"></div>
        <app-link-button
          [url]="psychologicalSupportBlock.buttonURL"
          [linkButtonType]="'Secondary'"
          [title]="'Детальніше'"
          class="w-full md:w-55 flex">
        </app-link-button>
      </div>
      <img
        [src]="psychologicalSupportBlock.imageURL"
        [alt]="psychologicalSupportBlock.title"
        class="rounded-15 md:w-102.5 xl:w-120 mx-auto" />
    </div>
  </div>

  <!-- cafe -->
  <div
    class="flex justify-center items-center bg-primary-base pt-18 xl:pt-14 pb-20">
    <div
      class="content-container text-white flex flex-col md:flex-row-reverse items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
      <!-- wrapper -->
      <div class="flex flex-col items-start">
        <h3 class="text-h3-360 md:text-h2-heading-bold bg-clip-text">
          {{ kafeUnforgettableBlock.title | uppercase }}
        </h3>
        <div
          class="w-full md:w-86 xl:w-[580px] pb-10 mt-2.5 xl:mt-7.5 text-smaller-main md:text-main-text"
          [innerHTML]="kafeUnforgettableBlock.bodyText"></div>

        <app-link-button
          [url]="kafeUnforgettableBlock.buttonURL"
          [linkButtonType]="'Primary'"
          [title]="'Детальніше'"
          [titleLinkId]="'CafeUnforgettable'"
          class="w-full md:w-55 flex">
        </app-link-button>
      </div>
      <img
        [src]="kafeUnforgettableBlock.imageURL"
        [alt]="kafeUnforgettableBlock.title"
        class="rounded-15 md:w-102.5 xl:w-120 mx-auto" />
    </div>
  </div>

  <!-- plan -->

  <div class="flex justify-center items-center pt-18 xl:pt-14 pb-20 rounded-30">
    <div
      class="content-container flex flex-col md:flex-row items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
      <!-- wrapper -->
      <div class="flex flex-col items-center md:items-start">
        <h3 class="text-h3-360 md:text-h2-heading-bold text-primary-base">
          {{ nationalDementiaPlanBlock.title | uppercase }}
        </h3>

        <div
          class="w-full md:w-86 xl:w-[580px] pb-10 mt-2.5 xl:mt-7.5 text-smaller-main md:text-main-text"
          [innerHTML]="nationalDementiaPlanBlock.bodyText"></div>
        <app-link-button
          [url]="nationalDementiaPlanBlock.buttonURL"
          [linkButtonType]="'Secondary'"
          [title]="'Детальніше'"
          class="w-full md:w-55 flex">
        </app-link-button>
      </div>
      <img
        [src]="nationalDementiaPlanBlock.imageURL"
        [alt]="nationalDementiaPlanBlock.title"
        class="rounded-15 md:w-102.5 xl:w-120 mx-auto" />
    </div>
  </div>
  <div
    class="content-container flex flex-col md:flex-row items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
    <app-link-button
      [url]="ourActivitiesTextBlock.buttonURL"
      [linkButtonType]="'Primary'"
      [title]="'Переглянути більше'"
      class="w-full md:w-70 mx-auto flex">
    </app-link-button>
  </div>
</section>
