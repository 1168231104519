import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-dynamic-html-component',
  template: ` <ng-container *ngIf="htmlContent">
    <div [innerHTML]="sanitizedHtmlContent"></div>
  </ng-container>`,
  styleUrls: ['./dynamic-html-component.component.css'],
})
export class DynamicHtmlComponentComponent implements OnChanges {
  @Input() htmlContent!: string;

  sanitizedHtmlContent!: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['htmlContent']) {
      this.sanitizeHtmlContent();
    }
  }

  private sanitizeHtmlContent() {
    this.sanitizedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(
      this.htmlContent,
    );
  }
}
