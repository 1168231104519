import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, map, Observable, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../../../environments/environment';
import { log } from '@angular-devkit/build-angular/src/builders/ssr-dev-server';
import { ToastService } from '../../components/toast/toast.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  env = environment.apiUrl;
  refreshedFlag: boolean = false;
  constructor(
    private auth: AuthService,
    private toast: ToastService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.apiUrl + '/Admin')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !this.refreshedFlag) {
          this.refreshedFlag = true;
          return this.auth.refreshToken().pipe(
            switchMap(() => {
              this.toast.newMessage({
                message: 'Переавторизація',
                style: 'success',
              });
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`,
                },
              });
              this.refreshedFlag = false;
              return next.handle(request);
            }),
            catchError(err => {
              this.toast.newMessage({
                message: 'Потрібна переавторизація',
                style: 'error',
              });
              this.refreshedFlag = false;
              this.auth.logOut();
              throw error;
            }),
          );
        }
        throw error;
      }),
    );
  }
}
