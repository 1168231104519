import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type ButtonType = 'Donate' | 'Primary' | 'Secondary';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class ButtonComponent implements OnInit {
  @Input() title: string = '';
  @Input() buttonType: ButtonType = 'Primary';
  @Input() disabled?: boolean = false;
  @Input() svg?: boolean = false;
  @Input() type?: string;
  isHovered = false;
  isFocused = false;
  constructor() {}
  name = 'Angular';
  showFirstIcon = true;

  onClickButton(event: any) {
    this.isFocused = true;
    // this.onClick.emit(event);
  }
  ngOnInit(): void {}
}
