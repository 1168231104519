<div class="content-container" *ngIf="pageTextData.length; else loader">
  <div class="text-gray-dark text-main-text px-4 md:px-0">
    <div class="flex flex-col md:flex-row justify-between mb-20">
      <h2 class="text-primary-base uppercase text-h1-heading w-full md:w-95">
        {{ pageTextData[0].title }}
      </h2>
      <div
        class="w-full md:w-195"
        appPhoneLink
        [innerHTML]="pageTextData[0].bodyText"></div>
    </div>
    <app-accordion
      *ngIf="faq.length; else emptyBlock"
      [AccordionsList]="faq"
      [classList]="'mb-30'"></app-accordion>
    <div class="flex flex-col justify-center items-start mb-18">
      <div class="flex flex-col items-start pb-11">
        <h2 class="text-h2-heading text-primary-base pb-8 uppercase">
          {{ pageTextData[1].title }}
        </h2>
        <div
          class="text-main-text"
          appPhoneLink
          [innerHTML]="pageTextData[1].bodyText"></div>
      </div>
    </div>
  </div>
</div>

<app-subscribe-news></app-subscribe-news>

<ng-template #emptyBlock>
  <div class="content-container mb-15">
    Часті питання скоро з'являться...
  </div></ng-template
>
<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
