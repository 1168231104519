<div (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
  <ng-container *ngIf="type === 'button'">
    <a
      [routerLink]="url"
      class="flex justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer"
      [ngClass]="{
        'bg-secondary-base': isHovered && !disabled,
        'bg-accent-base': !isHovered && !disabled,
        'bg-gray-card': disabled
      }"
      [type]="type">
      <span class="text-main-text font-semibold">{{ title }}</span>
    </a>
  </ng-container>

  <ng-container *ngIf="type === 'outlined'">
    <a
      [routerLink]="url"
      class="flex justify-center w-full text-accent-base rounded-10 border-2 duration-300 ease-in-out active:bg-secondary-additional disabled:bg-gray-gray2 py-2.5 cursor-pointer"
      [ngClass]="{
        'border-accent-base bg-secondary-base text-gray-white': isHovered,
        'border-accent-base': !isHovered,
        'bg-gray-card': disabled
      }"
      [type]="type">
      <span class="text-main-text font-semibold">{{ title }}</span>
    </a>
  </ng-container>

  <ng-container *ngIf="type === 'disabled'">
    <a
      [routerLink]="url"
      class="flex justify-center w-full text-gray-white rounded-10 bg-gray-card duration-300 ease-in-out active:bg-gray-card disabled:bg-gray-gray2 py-2.5 cursor-not-allowed"
      [type]="type">
      <span class="text-main-text font-semibold">{{ title }}</span>
    </a>
  </ng-container>

  <ng-container *ngIf="type === 'green'">
    <a
      [routerLink]="url"
      class="flex justify-center w-full text-accent-base rounded-10 border-2 duration-300 ease-in-out active:bg-gray-card disabled:bg-gray-card py-2.5 cursor-pointer"
      [ngClass]="{
        'border-accent-base bg-secondary-base text-gray-white': isHovered,
        'bg-green': !isHovered,
        'bg-gray-card': disabled
      }"
      [type]="type">
      <span class="text-main-text font-semibold">{{ title }}</span>
    </a>
  </ng-container>
</div>
