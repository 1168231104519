<div
  class="flex relative items-center px-5 cursor-pointer hover:text-secondary-base py-2.5 group/name">
  <img
    src="{{ 'assets/icons/flags-icon-svg/' + country.flagIcon + '.svg' }}"
    alt="{{ country.name }}"
    class="w-4 h-6" />

  <span
    class="ml-1 absolute bg-gray-white rounded-full px-3 -top-4 group-hover/name:flex hidden"
    >{{ country.nameUa }}</span
  >
  <span class="ml-1" [title]="country.name">{{
    country.phoneCountryCode
  }}</span>
</div>
