import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LiqPayService } from '../../../services/liq-pay.service';
import { PAGE_ROUTES } from '../../../constants/page_routes';

@Component({
  selector: 'app-result-payment',
  templateUrl: './result-payment.component.html',
  styleUrls: ['./result-payment.component.css'],
})
export class ResultPaymentComponent implements OnInit {
  id: string = 'id';
  protected paymentTime: Date = new Date();
  showLoader: boolean = true;
  protected success: boolean = false;
  protected error: boolean = false;
  protected readonly PAGE_ROUTES = PAGE_ROUTES;
  cards: any = [
    {
      imageLink: '../assets/images/other-method-to-join/regular-payment.png',
      route: '/about-us/programs/research',
      text: 'Взяти участь у дослідженні',
    },
    {
      imageLink: '../assets/images/other-method-to-join/make-donate.png',
      route: '/get-involved/become-a-volunteer',
      text: 'Стати волонтером',
    },
    {
      imageLink: '../assets/images/other-method-to-join/become-volonteer.png',
      route: '/get-involved/to-help',
      text: 'Регулярний платіж ',
      queryParams: { subscribe: 'monthly' },
    },
  ];

  constructor(private route: ActivatedRoute, private liqPay: LiqPayService) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.liqPay.checkStatus(this.id).subscribe(res => {
      if (res.status === 'success' || res.status === 'sandbox') {
        this.success = true;
      } else {
        this.error = true;
      }
      this.showLoader = false;
    });
  }
}
