<ng-container *ngIf="!showLoader; else loader">
  <ng-container *ngIf="error">
    <div
      class="wide-container h-162 bg-primary-base flex flex-col justify-end px-5 md:px-10 xl:pl-87.75 pb-19.75">
      <h2 class="text-h1-heading text-gray-white uppercase">
        Щось пішло не так :(
      </h2>
      <p class="text-gray-white text-h3-subheading mt-2.25">
        Будь ласка, спробуйте ще раз.
      </p>
      <div class="w-55 mt-10">
        <app-link-button
          [linkButtonType]="'Donate'"
          [title]="'СПРОБУВАТИ ЗНОВУ'"
          [url]="'../../'">
        </app-link-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="success">
    <app-page-banner-item
      [imgUrl]="'assets/images/bannerPicture/successPayment.png'"
      text="Ваша допомога – це підтримка людей, які живуть з деменцією, та їхніх родичів. Гроші будуть спрямовані на допомогу з ліками, а також інформаційну, юридичну та психологічну допомогу. Дякуємо!"
      title="Дякуємо за ваш внесок!">
      <p
        *ngIf="paymentTime"
        class="mx-5 md:mx-0 text-main-text-bold text-gray-white mb-4.75"
        paymentTimeBlock>
        {{ paymentTime | date : 'dd.MM.yyyy HH:mm' }}
      </p>
      <div class="mx-5 md:mx-0 w-55 mt-4.75">
        <app-link-button
          [linkButtonType]="'Primary'"
          [title]="'Переглянути звіти'"
          [url]="PAGE_ROUTES.reports">
        </app-link-button>
      </div>
    </app-page-banner-item>
    <app-other-method-to-join
      [inputCards]="cards"
      [type]="'secondary'"></app-other-method-to-join>
  </ng-container>
</ng-container>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
