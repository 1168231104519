<div id="{{ linkId!.id }}" class="bg-primary-base mt-28">
  <div class="content-container pt-30 pb-20 px-4 md:px-15 xl:px-0">
    <h2 class="uppercase text-h2-heading text-gray-white mb-6">
      {{ blockData?.title }}
    </h2>
    <div
      class="text-main-text text-gray-white mb-10"
      [innerHtml]="blockData?.bodyText"></div>
  </div>
</div>
