<section>
  <app-page-banner-item [pageName]="pageName"></app-page-banner-item>
</section>

<section class="my-30 px-4 md:px-15 xl:px-0" *ngIf="cards.length; else loader">
  <app-wide-card [cards]="cards"></app-wide-card>
</section>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
