<ng-container *ngIf="formSubscribeNews && !success">
  <div class="flex w-full bg-primary-base">
    <div
      class="flex flex-col xl:flex-row wide-container relative md:h-133 xl:h-100 h-auto bg-primary-base bg-[url('src/assets/images/bgPic/nezabutkamobileSubscribeForm.png')] md:bg-[url('src/assets/images/bgPic/nezabutkaFlower.png')] bg-auto md:bg-contain bg-no-repeat bg-right-bottom md:bg-[top_right_-16.5rem] xl:bg-[top_right_14rem]">
      <div
        class="flex md:flex-row flex-col content-container text-left justify-center md:justify-start text-gray-white">
        <div
          class="flex flex-col h-full items-center md:items-start pt-30 xl:pt-25 w-full md:w-102.5 xl:w-165 px-4 md:px-0 xl:pl-25 bg-[url('src/assets/images/bgPic/emailBG.png')] bg-no-repeat bg-[top_0.5rem_left_1.5rem] md:bg-[top_0.5rem_left_-2.5rem] xl:bg-[top_6px_left_1rem]">
          <div
            class="flex flex-col items-start w-[328px] md:w-102.5 xl:w-145 pb-5 md:pb-10">
            <h2 class="text-h2-heading-bold pb-5 uppercase">
              {{ subscribeNewsData.title }}
            </h2>
            <p class="text-main-text" [innerHTML]="subscribeNewsData.text"></p>
          </div>

          <div
            class="flex-col w-full hidden xl:flex md:flex-row items-center justify-center md:justify-start h-full md:h-12 flex-wrap md:flex-nowrap">
            <ul
              class="flex gap-y-4.5 gap-x-5 flex-wrap justify-between md:justify-start w-full md:w-48 pt-6 md:pt-8 shrink-0">
              <li *ngFor="let socialLink of socialsLinks">
                <app-social-link
                  class="inline-block w-12 h-12 shrink-0"
                  [socialLink]="socialLink"
                  [type]="'Secondary'"></app-social-link>
              </li>
            </ul>
            <div
              class="flex w-full items-center text-main-text-bold pl-4 justify-center">
              {{ subscribeNewsData.socialText }}
            </div>
          </div>
        </div>
        <div
          class="flex flex-col w-full md:pt-30 xl:pt-24.5 xl:pl-5 justify-start text-gray-dark items-center">
          <form
            [formGroup]="formSubscribeNews"
            (ngSubmit)="onSubmit()"
            class="w-82 md:w-102.5 md:ml-5 xl:w-70">
            <div class="input-wrap relative mb-10 flex flex-col gap-5">
              <app-input
                [control]="name"
                [labelName]="'Імʼя'"
                [maxLength]="'60'"
                [darkInput]="true"
                inputID="name"
                class="w-full"></app-input>
              <app-input
                [control]="email"
                [labelName]="'E-mail'"
                [darkInput]="true"
                type="email"
                inputID="email"
                class="w-full"></app-input>
            </div>

            <app-button
              [disabled]="
                (formSubscribeNews.touched && !formSubscribeNews.valid) ||
                submitted
              "
              [buttonType]="'Primary'"
              [title]="'Підписатись'"
              class="w-full"
              [type]="'submit'"></app-button>
          </form>
        </div>
      </div>
      <div
        class="flex pt-10 md:pt-15 xl:hidden content-container flex-col md:flex-row items-center justify-start h-70 md:h-12 flex-wrap md:flex-nowrap px-4 md:px-0">
        <ul
          class="flex gap-x-2 flex-wrap justify-between md:justify-start w-[300px] shrink-0 pb-5">
          <li *ngFor="let socialLink of socialsLinks">
            <app-social-link
              class="inline-block w-12 h-12 shrink-0"
              [socialLink]="socialLink"
              [type]="'Secondary'"></app-social-link>
          </li>
        </ul>
        <div
          class="flex w-full items-center text-main-text-bold justify-center md:justify-start text-gray-white ml-0 md:ml-5 text-center">
          {{ subscribeNewsData.socialText }}
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="submitted && success">
  <div class="flex w-full bg-primary-base">
    <div
      class="flex flex-row wide-container relative h-auto md:h-100 bg-primary-base bg-[url('src/assets/images/bgPic/nezabutkamobileSubscribeForm.png')] md:bg-[url('src/assets/images/bgPic/nezabutkaFlower.png')] bg-auto md:bg-contain bg-no-repeat bg-right-bottom md:bg-[top_right_-16.5rem] xl:bg-[top_right_14rem]">
      <div
        class="flex flex-row content-container justify-start text-gray-white">
        <div
          class="flex flex-col items-start px-4 md:pl-20 pt-17.5 md:mr-30 bg-[url('src/assets/images/bgPic/emailBG.png')] bg-no-repeat">
          <div class="flex flex-col items-start pb-11">
            <h2 class="text-h2-heading pb-5 uppercase w-full xl:w-220">
              {{ SubscribeNewsThansMessageData.title }}
            </h2>
            <p
              class="text-main-text text-gray-white w-full xl:w-233"
              [innerHTML]="SubscribeNewsThansMessageData.text"></p>
          </div>
          <div
            class="flex flex-col md:flex-row items-center justify-start md:h-12 h-40 gap-5">
            <div class="flex gap-5">
              <div
                [routerLink]="SubscribeNewsThansMessageData.instagramm"
                class="flex shrink-0 justify-center items-center bg-gray-white rounded-full w-12 h-12 text-accent-base cursor-pointer hover:text-secondary-base active:text-secondary-additional">
                <svg
                  class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional"
                  [icon]="'instagram'"
                  iconCollection="social-icons-sprite"
                  width="24px"
                  height="24px"></svg>
              </div>
              <div
                [routerLink]="SubscribeNewsThansMessageData.facebook"
                class="flex shrink-0 w-12 justify-center items-center bg-gray-white rounded-full h-12 text-accent-base cursor-pointer hover:text-secondary-base active:text-secondary-additional">
                <svg
                  class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional"
                  [icon]="'facebook'"
                  iconCollection="social-icons-sprite"
                  width="16px"
                  height="29px"></svg>
              </div>
              <div
                [routerLink]="SubscribeNewsThansMessageData.telegramm"
                class="flex shrink-0 justify-center items-center bg-gray-white rounded-full h-12 w-12 text-accent-base cursor-pointer hover:text-secondary-base active:text-secondary-additional">
                <svg
                  class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional"
                  [icon]="'telegram'"
                  iconCollection="social-icons-sprite"
                  width="26px"
                  height="22px"></svg>
              </div>
            </div>
            <div class="flex w-full items-center text-main-text-bold">
              {{ SubscribeNewsThansMessageData.socialText }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
