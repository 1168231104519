import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-downcheck',
  templateUrl: './down-svgrepo-com.svg',
})
export class DowncheckComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
