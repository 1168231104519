import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { BannerPageService } from '../../services/api/banner-page.service';
import { Subscription } from 'rxjs';

/**
 * @template
 *
 * ```
 * <app-page-banner-item
 *     [imgUrl]="'assets/images/banner/banner-help-with-medicine.jpg'"
 *     title="Допомога з ліками"
 *     text="Тут ви отримаєте інформацію про те, як отримати допомогу з ліками">
 *    <your-extra-content></your-extra-content> // you can put here all extra content in the end of banner
 *   </app-page-banner-item>
 *
 * ```
 */
@Component({
  selector: 'app-page-banner-item',
  templateUrl: './page-banner-item.component.html',
  styleUrls: ['./page-banner-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageBannerItemComponent implements OnInit, OnChanges, OnDestroy {
  subs: Subscription = new Subscription();
  @Input() text: string = '';
  @Input() title: string = '';
  @Input() imgUrl: string = '';

  @Input() pageName: string = '';

  constructor(
    private pageBannerService: BannerPageService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this.pageName && this.pageName.length) this.getBannerData();
  }

  getBannerData() {
    const sub = this.pageBannerService.getBannersData(this.pageName).subscribe(
      el => {
        this.title = el.title;
        this.text = el.text;
        this.imgUrl = el.imgUrl;
        this.cdr.markForCheck();
      },
      error => {
        this.title = 'Помилка 404';
        this.text = 'Завантаження банера не відбулось';
        this.imgUrl = '';
        this.cdr.markForCheck();
        throw error.message;
      },
    );
    this.subs.add(sub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['text'] && changes['text'].currentValue) {
      this.text = changes['text'].currentValue;
    }
    if (changes['title'] && changes['title'].currentValue) {
      this.title = changes['title'].currentValue;
    }
    if (changes['imgUrl'] && changes['imgUrl'].currentValue) {
      this.imgUrl = changes['imgUrl'].currentValue;
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
