import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { OpenPdf } from 'src/app/services/pdfOpen.service';
import { Subscription } from 'rxjs';
import {
  DocsReportModel,
  ReportsDocsService,
} from '../../../../../services/api/aboutUsPages/AboutFondPages/ReportsAndDocsPages/reports-docs.service';
import {
  PortalTextModel,
  PortalTextsService,
} from '../../../../../services/api/portal-texts-by-tag.service';

export interface ReportsCardInterface {
  title: string;
  text: string[];
  reportsLinks?: ReportsPrewInterface[];
}
export interface ReportsPrewInterface {
  title: string;
  link: string;
}
@Component({
  selector: 'app-reports-card',
  templateUrl: './reports-card.component.html',
  styleUrls: ['./reports-card.component.css'],
})
export class ReportsCardComponent implements OnInit, OnDestroy {
  subs: Subscription = new Subscription();
  reportsData: DocsReportModel[] = [];
  @Input() type: string = '';
  @Input() edit: boolean = false;
  @Input() defaultPortalBlock!: PortalTextModel | null;
  portalTextBlock!: PortalTextModel | null;
  isLoadingDocs: boolean = false;
  constructor(
    private docsServise: ReportsDocsService,
    private openPdf: OpenPdf,
    private portalText: PortalTextsService,
  ) {}

  ngOnInit() {
    this.getPageText();
    this.getDocs();
  }

  getDocs() {
    this.isLoadingDocs = true;
    const sub = this.docsServise.getReportsByType(this.type).subscribe(res => {
      this.reportsData = res;
      console.log(res);
      this.isLoadingDocs = false;
    });
    this.subs.add(sub);
  }

  getPageText() {
    const sub = this.portalText
      .getPortalTextByTag(this.defaultPortalBlock!.tag)
      .subscribe(res => {
        if (!res) {
          this.portalTextBlock = this.defaultPortalBlock;
        } else {
          this.portalTextBlock = res;
        }
      });
    this.subs.add(sub);
  }

  onOpenPDF(link: string, title: string): void {
    if (!this.edit) this.openPdf.getPDF(link, title);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
