<ng-container *ngIf="bannerLoading; else banner">
  <div class="pt-30">
    <div
      class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full gradient-animation"></div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #banner>
  <div
    *ngIf="bgImage"
    class="w-full z-0 flex bg-no-repeat bg-cover bg-center"
    [ngStyle]="{ 'background-image': bgImage }">
    <div class="w-full my-15 flex justify-end">
      <div
        class="ml-4 md:ml-0 bg-gray-white/[0.6] w-187.5 rounded-30 flex justify-start">
        <div
          class="py-5 md:py-15 px-4 md:pl-10 opacity-100 text-main-text flex flex-col">
          <div>
            <h3
              class="text-h2-360 md:text-h1-360 text-primary-base uppercase mb-3">
              {{ pageTitle }}
            </h3>
            <div class="max-w-135" [innerHTML]="bannerText" appPhoneLink></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="py-30 w-full xl:w-295 mx-auto">
  <app-info-cards [cards]="firstBlockCard"></app-info-cards>
</div>

<div class="py-30 w-full bg-gray-card">
  <div class="w-full xl:w-295 mx-auto">
    <div
      class="mb-10 pl-10 xl:px-0"
      *ngIf="needHelpPageData.secondBlockTitle.id !== 0">
      <h2 class="text-h1-heading text-primary-base uppercase mb-5">
        {{ needHelpPageData.secondBlockTitle.title }}
      </h2>
      <div
        appPhoneLink
        [innerHTML]="needHelpPageData.secondBlockTitle.text"></div>
      <div
        class="md:w-55 w-full flex mt-10"
        *ngIf="needHelpPageData.secondBlockTitle.link.length">
        <a
          [href]="needHelpPageData.secondBlockTitle.link"
          target="_blank"
          class="flex justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer"
          ><span class="text-main-text font-semibold">Залишити заявку</span></a
        >
      </div>
    </div>
    <app-info-cards [cards]="secondBlockCard"></app-info-cards>
  </div>
</div>

<div class="w-full bg-primary-base py-30" id="whatToDoIf">
  <div class="w-full xl:w-295 mx-auto px-2 md:pl-10 xl:px-0">
    <div
      class="flex flex-col md:flex-row justify-between mb-10 pl-10 xl:px-0 text-gray-white">
      <h3
        class="w-full xl:w-95 text-h3-subheading md:text-h1-heading uppercase">
        {{ needHelpPageData.tabsBlockTitle.title }}
      </h3>
      <p
        class="w-full xl:w-195"
        [innerHTML]="needHelpPageData.tabsBlockTitle.text"></p>
    </div>
    <app-tabs-horizontal
      [tabsArray]="needHelpPageData.needHelpDataTab"></app-tabs-horizontal>
  </div>
</div>
<div
  class="w-full xl:w-245 mx-auto text-center py-30 flex flex-col justify-center items-center">
  <h2 class="text-h2-heading text-primary-base uppercase mb-5">
    {{ needHelpPageData.lastBlock.title }}
  </h2>
  <div
    class="wide-container"
    [innerHTML]="needHelpPageData.lastBlock.text"></div>
  <div class="w-full mt-10 flex flex-row justify-center">
    <a
      href="tel:0800308830"
      class="underline text-h2-heading-bold md:text-h1-heading text-accent-base xl:hidden"
      ><div class="mr-1 flex flex-row items-center">
        <div class="mr-1">
          <svg
            width="15px"
            height="15px"
            icon="phone"
            iconCollection="button-icons-sprite"
            class="fill-accent-base w-7 h-7 md:w-10 md:h-10"></svg>
        </div>
        <div class="underline">0 800 30 88 30</div>
      </div></a
    >
    <div class="hidden text-accent-base text-h1-heading xl:inline-block">
      <div class="mr-1 flex flex-row items-center">
        <div class="mr-1">
          <svg
            width="15px"
            height="15px"
            icon="phone"
            iconCollection="button-icons-sprite"
            class="fill-accent-base w-6 h-6 md:w-10 md:h-10"></svg>
        </div>
        <div class="underline">0 800 30 88 30</div>
      </div>
    </div>
  </div>
</div>
