import { Component, OnInit } from '@angular/core';
import ILearning from '../../../models/learning.interface';
import { learningTextDataMock } from '../../../../mockData/learningDataMock';
import { PAGE_ROUTES } from '../../../constants/page_routes';
import {
  helpOfspecialistsDataMock,
  volonteeringAtEventsDataMock,
  volonteeringInProjectsDataMock,
} from '../../../../mockData/becomeVolonteerDataMock';
import { FundTeamService } from '../../../services/api/aboutUsPages/FundTeamPages/fund-team.service';
import { Volunteer } from '../../about-us/our-people/fund-team/fund-team.component';
import {
  IVolunteer,
  IVolunteerResponse,
} from '../../../models/volunteer.interface';
import { Observable, Subscription } from 'rxjs';
import {
  TextContentModel,
  TextContentService,
} from '../../../services/api/text-content.service';

@Component({
  selector: 'app-become-volunteer',
  templateUrl: './become-volunteer.component.html',
  styleUrls: ['./become-volunteer.component.css'],
})
export class BecomeVolunteerComponent implements OnInit {
  pageName: string = 'get-involved/become-a-volunteer';
  combinedData: {
    after_banner_text: TextContentModel;
    first_block: TextContentModel;
    second_block: TextContentModel;
    third_block: TextContentModel;
    fourth_block: TextContentModel;
    [key: string]: TextContentModel;
  } | null = null;

  pageData: TextContentModel[] = [];
  subs: Subscription = new Subscription();

  volunteers: Volunteer[] = [];

  links: { id: string; title: string }[] = [];
  constructor(
    private apiVolonteerService: FundTeamService,
    private apiService: TextContentService,
  ) {}
  ngOnInit(): void {
    this.getVolunteerTeam();
    this.getPageTextData();
  }

  getVolunteerTeam() {
    this.apiVolonteerService
      .getVolunteerTeam()
      .subscribe((data: IVolunteerResponse[]) => {
        if (data) {
          const slicedArrVolonteers = data.slice(0, 3);
          this.volunteers = slicedArrVolonteers.map(
            (fundMember): IVolunteer => ({
              imgUrl: fundMember.imagePath,
              text: fundMember.text,
              title: fundMember.title,
            }),
          );
        }
      });
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.combinedData = {
          after_banner_text: this.pageData.find(
            el => el.key === 'after_banner_text',
          )!,
          first_block: this.pageData.find(el => el.key === 'first_block')!,
          second_block: this.pageData.find(el => el.key === 'second_block')!,
          third_block: this.pageData.find(el => el.key === 'third_block')!,
          fourth_block: this.pageData.find(el => el.key === 'fourth_block')!,
        };
        this.links = this.pageData
          .filter(el => el.groupType === 'main_text')
          .map(el => ({ id: el.key, title: el.title }));
      });
    this.subs.add(sub);
  }
}
