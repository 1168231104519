import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, Observable } from 'rxjs';
import { PAGE_ROUTES, PageRoutes } from 'src/app/constants/page_routes';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { navigationalLinksModel } from '../navigational-cards.service';

@Injectable({
  providedIn: 'root',
})
export class GetSupportApiService {
  url = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  getPageData(): Observable<GetSupportPageInterface> {
    return this.http
      .get<IGetSupportPageResponse>(this.url + '/Support/support-page')
      .pipe(
        map(el => {
          return {
            bannerText: el.bannerText.text,
            firstBlock: el.firstBlock.map(item => ({
              ...item,
              text: item.bodyText,
              link: PAGE_ROUTES[item.link as keyof PageRoutes],
            })),
            secondBlockTitle: {
              ...el.secondBlockTitle,
              title: el.secondBlockTitle.title,
              text: el.secondBlockTitle.bodyText,
            },
            secondBlock: el.secondBlock.map(item => ({
              title: item.title,
              text: item.bodyText,
              link: PAGE_ROUTES[item.link.split('/')[0] as keyof PageRoutes],
              titleLinkId: item.link.split('/')[1],
            })),
            tabsBlockTitle: {
              ...el.tabsBlockTitle,
              title: el.tabsBlockTitle.title,
              text: el.tabsBlockTitle.bodyText,
            },
            lastBlock: {
              ...el.lastBlock,
              title: el.lastBlock.title,
              text: el.lastBlock.bodyText,
            },
            needHelpDataTab: el.needHelpDataTab
              .map(
                (el: {
                  bodyText: string;
                  link: string;
                  title: string;
                  id: number;
                }) => {
                  return {
                    ...el,
                    text: el.bodyText,
                    linkTo:
                      PAGE_ROUTES[el.link.split('/')[0] as keyof PageRoutes],
                    title: el.title,
                  };
                },
              )
              .sort((a, b) => a.id - b.id),
          };
        }),
      );
  }
}

export interface GetSupportPageInterface {
  bannerText: SafeHtml;
  firstBlock: any[];
  secondBlockTitle: {
    title: string;
    text: string;
    id: number;
    tag: string;
    link: string;
  };
  secondBlock: any[];
  tabsBlockTitle: { title: string; text: string };
  needHelpDataTab: {
    id?: number;
    title: string;
    text: string;
    active?: boolean;
    linkTo: string;
    link?: string;
    bodyText?: string;
  }[];
  lastBlock: { title: string; text: string };
}

interface IGetSupportPageResponse {
  bannerText: {
    id: number;
    tag: string;
    text: string;
  };
  firstBlock: IGetSupportPageResponseTextBlock[];
  secondBlockTitle: IGetSupportPageResponseTextBlock;
  secondBlock: IGetSupportPageResponseTextBlock[];
  tabsBlockTitle: IGetSupportPageResponseTextBlock;
  lastBlock: IGetSupportPageResponseTextBlock;
  needHelpDataTab: IGetSupportPageResponseTextBlock[];
}

interface IGetSupportPageResponseTextBlock {
  id: number;
  title: string;
  link: string;
  bodyText: string;
  tag: string;
}
