import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IsHoverService } from 'src/app/services/main-menu.service';
import { MenuItemsMock } from 'src/mockData/menuMockData';
@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css'],
})
export class MainMenuComponent implements OnInit, OnDestroy {
  isHovered = false;
  subscription!: Subscription;
  constructor(private isHover: IsHoverService) {}

  setIsHovered(event: Event) {
    if (event.target && (event.target as HTMLElement).children.length > 0) {
      this.isHovered = true;
    }
  }

  menuItems = MenuItemsMock;
  ngOnInit(): void {
    this.subscription = this.isHover.currentHover.subscribe(
      hover => (this.isHovered = hover),
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  mainAction($event: any) {
    // console.log($event.path[0].outerText);
    $event.stopPropagation();
    this.isHovered = !this.isHovered;
  }
  functionCall1(event: any) {
    console.log('functionCall', event);
  }
  functionCall2(event: any) {
    console.log('functionCall', event);
  }
}
