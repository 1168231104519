<a
  [attr.aria-label]="socialLink.image"
  target="_blank"
  class="flex justify-center items-center w-12 h-12 rounded-full cursor-pointer border-2 border-accent-base hover:border-secondary-base focus:border-secondary-additional focus:outline-0 group/svg"
  [ngClass]="{ 'border-0 bg-gray-white': type === 'Secondary' }"
  [href]="socialLink.link"
  title="{{ socialLink.image }}">
  <!-- <img [src]="socialLink?.image" alt="" /> -->
  <svg
    class="fill-accent-base cursor-pointer group-hover/svg:fill-secondary-base active:fill-secondary-additional"
    [icon]="socialLink.image"
    iconCollection="social-icons-sprite"
    [attr.width]="socialLink.width"
    [attr.height]="socialLink.height"></svg>
</a>
