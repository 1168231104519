<div *ngIf="visitTemplate">
  <section
    *ngIf="detailedEvents; else loader"
    class="content-container text-start px-4 md:px-0 mb-30">
    <div class="lg:flex justify-between">
      <h3
        class="md:text-h2-heading text-h2-360 text-primary-base uppercase w-full mb-5">
        На даний захід реєстрація не потрібна! Приходьте в зазначений час і
        місце! Чекаємо на вас! :)
      </h3>
    </div>
    <div
      class="SecondaryBtn justify-start items-center gap-3 inline-flex pt-2.5">
      <app-link-button
        (click)="visitTemplate = false"
        [linkButtonType]="'LeftArrow'"
        [title]="'Повернутись до заходу'"
        class="mt-5 md:mt-0 flex md:justify-end">
      </app-link-button>
    </div>
  </section>
</div>
<ng-container *ngIf="!visitTemplate">
  <section
    *ngIf="detailedEvents; else loader"
    class="content-container text-start px-4 md:px-0 mb-30">
    <div class="lg:flex justify-between">
      <h3
        class="md:text-h2-heading text-h2-360 text-primary-base uppercase w-full lg:w-[580px] mb-5">
        {{ detailedEvents.title }}
      </h3>
    </div>

    <div class="flex">
      <div class="w-full">
        <div
          class="block md:hidden rounded-30 bg-gray-card md:h-[254px] md:w-102.5 xl:h-[274px] xl:w-145 mb-5 md:mb-10 p-5 xl:p-10">
          <div class="flex h-full flex-col items-start justify-between">
            <div>
              Коли:
              <span class="text-main-text-bold pl-1">{{
                detailedEvents.when
              }}</span>
            </div>
            <div class="mb-5">
              Де:
              <span class="text-main-text-bold pl-1">{{
                detailedEvents.where
              }}</span>
            </div>
            <div class="mb-5 text-details">
              {{ detailedEvents.shouldYouRegisterText }}
            </div>
            <div class="w-full xl:w-55">
              <app-link-button
                (click)="visitEvent()"
                [linkButtonType]="'Primary'"
                [title]="'Відвідати захід'">
              </app-link-button>
            </div>
          </div>
        </div>
        <img
          class="md:h-[282px] md:w-102.5 xl:h-100 xl:w-145 object-cover md:float-right mx-auto md:pb-0 xl:ml-5 rounded-30 mb-5 md:mb-0"
          [src]="detailedEvents.imageUrl"
          alt="{{ detailedEvents.title }}" />
        <div
          class="hidden md:block rounded-30 bg-gray-card md:h-[254px] md:w-102.5 xl:h-[274px] xl:w-145 mb-10 p-5 xl:p-10">
          <div class="flex h-full flex-col items-start justify-between">
            <div>
              Коли:
              <span class="text-main-text-bold pl-1">{{
                detailedEvents.when
              }}</span>
            </div>
            <div class="mb-5">
              Де:
              <span class="text-main-text-bold pl-1">{{
                detailedEvents.where
              }}</span>
            </div>
            <div class="mb-5 text-details">
              {{ detailedEvents.shouldYouRegisterText }}
            </div>
            <div class="w-full xl:w-55">
              <app-link-button
                (click)="visitEvent()"
                [linkButtonType]="'Primary'"
                [title]="'Відвідати захід'">
              </app-link-button>
            </div>
          </div>
        </div>
        <p
          class="text-gray-dark md:text-main-text text-smaller-main"
          [innerHTML]="detailedEvents.description"
          appPhoneLink></p>
      </div>
    </div>
  </section>
</ng-container>
<app-subscribe-news
  [ngClass]="{ 'relative -top-2.5': visitTemplate }"></app-subscribe-news>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
