import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverService {
  private isMobile$: Observable<boolean>; //(max-width: 599.98px)
  private isTablet$: Observable<boolean>; //(min-width: 600px) and (max-width: 959.98px)
  private isLaptop$: Observable<boolean>; //(min-width: 960px) and (max-width: 1279.98px)
  private isPC$: Observable<boolean>; //(min-width: 1280px) and (max-width: 1919.98px)
  private isXXLScreen$: Observable<boolean>; //(min-width: 1920px)
  constructor(private breakpointObserver: BreakpointObserver) {
    this.isMobile$ = this.breakpointObserver
      .observe(Breakpoints.XSmall)
      .pipe(map(result => result.matches));
    this.isTablet$ = this.breakpointObserver
      .observe(Breakpoints.Small)
      .pipe(map(result => result.matches));
    this.isLaptop$ = this.breakpointObserver
      .observe(Breakpoints.Medium)
      .pipe(map(result => result.matches));
    this.isPC$ = this.breakpointObserver
      .observe(Breakpoints.Large)
      .pipe(map(result => result.matches));
    this.isXXLScreen$ = this.breakpointObserver
      .observe(Breakpoints.XLarge)
      .pipe(map(result => result.matches));
  }
  public isMobile(): Observable<boolean> {
    return this.isMobile$;
  }

  public isTablet(): Observable<boolean> {
    return this.isTablet$;
  }

  public isLaptop(): Observable<boolean> {
    return this.isLaptop$;
  }
  public isPC(): Observable<boolean> {
    return this.isPC$;
  }
  public isXXLScreen(): Observable<boolean> {
    return this.isXXLScreen$;
  }
}
