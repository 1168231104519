<div class="flex w-full bg-primary-base">
  <div class="grid wide-container relative h-170 md:h-137.5 xl:h-162">
    <div
      class="flex flex-row w-full mx-auto absolute bottom-2 md:bottom-5 justify-center items-center text-lg text-gray-white fill-gray-white z-50">
      <div
        class="w-16 flex flex-row text-gray-white justify-end pr-4 items-center h-8">
        <div
          *ngIf="firstSlide"
          class="arrow opacity-60 hover:opacity-100"
          (click)="prevSlide()">
          <svg
            class="cursor-pointer"
            [icon]="'left-arrow'"
            iconCollection="button-icons-sprite"
            width="32px"
            height="32px"></svg>
        </div>
        <div class="pl-1.5 pt-0.5 opacity-60">{{ ind }}</div>
      </div>
      <div class="flex flex-row opacity-60">/</div>
      <div class="w-16 flex flex-row pl-1.5 h-8">
        <div class="pl-1.5 pr-1.5 pt-0.5 opacity-60 h-8">
          {{ slides.length }}
        </div>
        <div
          *ngIf="lastSlide"
          class="arrow opacity-60 hover:opacity-100"
          (click)="nextSlide()">
          <svg
            class="cursor-pointer"
            [icon]="'right-arrow'"
            iconCollection="button-icons-sprite"
            width="32px"
            height="32px"></svg>
        </div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
