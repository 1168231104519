import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import IFundMember from 'src/app/models/fundMember.interface';
import {
  FundTeamService,
  IFundMemberResponse,
} from 'src/app/services/api/aboutUsPages/FundTeamPages/fund-team.service';
import {
  TextContentModel,
  TextContentService,
} from '../../../../../services/api/text-content.service';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-fund-member',
  templateUrl: './fund-member.component.html',
  styleUrls: ['./fund-member.component.css'],
})
export class FundMemberComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription | null = null;
  showPopUp: boolean = false;
  mailTo: string = 'site@nezabutni.org';

  currentMember: IFundMember = {
    img: '',
    position: '',
    text: '',
    title: '',
    memberID: '',
  };
  correctMember: boolean = true;
  img: string = '';
  currentBreadcrumb: string = 'Ірина Шевченко';
  fundLink: string = PAGE_ROUTES.fundTeam;
  formsData: TextContentModel | null = null;
  formsDataAgreement: TextContentModel | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: FundTeamService,
    private contentService: TextContentService,
  ) {}

  memberID = this.route.snapshot.params['id'];

  closePopUp() {
    this.showPopUp = false;
  }
  openPopUp() {
    this.showPopUp = true;
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.data.subscribe(data => {
      const apiData = data['apiData'] as IFundMemberResponse & {
        breadcrumb: string;
      };

      this.mailTo = apiData.email;
      this.currentBreadcrumb = apiData.title;
      this.currentMember = {
        img: apiData.img,
        position: apiData.position,
        text: apiData.description,
        title: apiData.title,
        memberID: apiData.id,
      };
    });
    this.getFormData();
  }

  getFormData() {
    this.contentService
      .getTextsContentByPageName('about-us/contacts')
      .subscribe(res => {
        if (res) {
          this.formsData = res.find(el => el.key === 'contacts_form-data')!;
          this.formsDataAgreement = res.find(
            el => el.key === 'contacts_form-agreement',
          )!;
        }
      });
  }

  fetchFundTeam(id: string): Observable<IFundMemberResponse> {
    return this.apiService.getFundMemberById(id);
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
