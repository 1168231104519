import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomEditorComponent } from 'src/app/admin/components/custom-editor/custom-editor.component';
import { EditorMenuComponent } from 'src/app/admin/components/custom-editor/editor-menu/editor-menu.component';

@NgModule({
  declarations: [CustomEditorComponent, EditorMenuComponent],
  imports: [CommonModule],
  exports: [CustomEditorComponent, EditorMenuComponent],
})
export class CustomEditorModule {}
