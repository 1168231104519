<div *ngIf="pageData; else loader">
  <div
    class="w-82 md:w-210 xl:w-295 mx-auto"
    *ngIf="!isSlice; else slicedPartners">
    <div class="items-center mb-10" *ngIf="textContent">
      <h2 class="text-h2-heading text-primary-base uppercase mb-5">
        {{ textContent!.title }}
      </h2>
      <div
        class="text-main-text text-gray-dark"
        [innerHtml]="textContent!.bodyText"></div>
    </div>
    <div class="flex flex-wrap gap-2 md:gap-5 mb-11.5 md:mb-9.75 xl:mb-4.5">
      <div *ngFor="let item of ourPartners | slice: 0 : showCountOfLogos">
        <a href="{{ item.buttonURL }}" target="blank">
          <div
            class="w-39.75 md:w-48.75 xl:w-70 h-39.75 md:h-48.75 xl:h-70 flex items-center justify-center hover:border-2 rounded-30 border-gray-stroke">
            <img
              class="w-19.5 md:w-28.5 xl:w-50 h-50 object-contain"
              [src]="item.imageURL" />
          </div>
        </a>
      </div>
    </div>

    <div
      class="flex flex-col md:flex-row justify-center gap-y-5 md:gap-x-5 my-30">
      <app-button
        *ngIf="showCountOfLogos < ourPartners.length"
        (click)="showMore()"
        title="Дивитись більше"
        buttonType="Secondary"
        class="w-full md:w-55"></app-button>

      <app-link-button
        class="w-full md:w-55"
        [title]="'Стати партнером'"
        [linkButtonType]="'Primary'"
        routerLink="/get-involved/become-a-partner">
      </app-link-button>
    </div>
  </div>

  <ng-template #slicedPartners>
    <div class="grid grid-cols-2 md:grid-cols-4 gap-5">
      <div *ngFor="let item of ourPartners | slice: 0 : 4">
        <a href="{{ item.buttonURL }}" target="blank">
          <div
            class="w-40 h-40 md:w-[195px] md:h-[195px] xl:w-70 xl:h-70 flex items-center justify-center hover:border-2 rounded-30 border-gray-stroke">
            <img
              class="w-full md:w-28.5 xl:w-50 h-50 object-contain"
              [src]="item.imageURL" />
          </div>
        </a>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
