import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-test-backend',
  templateUrl: './test-backend.component.html',
  styleUrls: ['./test-backend.component.css'],
})
export class TestBackendComponent {
  data: any;
  environment: any;
  constructor(private _http: HttpClient) {
    console.log('[TestBackendComponent] IsProduction:', environment.production); // Logs false for development environment
    console.log('[TestBackendComponent] ApiUrl:', environment.apiUrl); // Logs false for development environment
    this.environment = JSON.stringify(environment, null, 2);
  }

  getData() {
    return this._http.get(`${environment.apiUrl}/news/Cards`);
  }

  ngOnInit() {
    this.getData().subscribe(res => {
      this.data = JSON.stringify(res, null, 2);
    });
  }
}
