import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import {
  PressReleaseCardInterfaceResponse,
  PressReleasesApiService,
} from 'src/app/services/api/aboutUsPages/NewsAndMedia/press-releases-api.service';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbPressReleasesDetailsResolver  {
  constructor(
    private apiService: PressReleasesApiService,
    private datePipe: DatePipe,
  ) {}
  fetchPressRelease(id: string): Observable<PressReleaseCardInterfaceResponse> {
    return this.apiService.PressReleaseCardById(id);
  }
  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<
    (PressReleaseCardInterfaceResponse & { breadcrumb: string }) | null
  > {
    const pressReleaseId = route.paramMap.get('id');
    if (pressReleaseId) {
      return this.fetchPressRelease(pressReleaseId).pipe(
        map(data => {
          return {
            breadcrumb: `Пресреліз від ${this.datePipe.transform(
              data.dateOfPublication,
              'dd.MM.YYYY',
            )}`,
            ...data,
          };
        }),
      );
    } else return of(null);
  }
}
