<section class="content-container text-start px-4 md:px-0">
  <div class="lg:flex justify-between">
    <h3
      class="md:text-h2-heading text-h2-360 text-primary-base uppercase w-full lg:w-[580px] mb-5">
      {{ detailedNews.title }}
    </h3>
    <div class="flex mb-5 xl:mb-0">
      <svg
        width="24px"
        height="24px"
        icon="book"
        iconCollection="button-icons-sprite"
        class="svg items-start fill-gray-medium"></svg>
      <span class="text-gray-medium text-main-text ml-2 justify-end">{{
        detailedNews.date | date: 'dd.MM.yyyy' : 'UTC+3' : 'uk-UA'
      }}</span>
    </div>
  </div>

  <div>
    <img
      class="md:h-100 md:w-145 w-full object-cover xl:float-right md:mx-auto md:pb-0 xl:ml-5 rounded-30 mb-5 md:mb-5"
      [src]="detailedNews.pictureUrl"
      alt="{{ detailedNews.title }}" />
    <p
      class="text-gray-dark md:text-main-text text-smaller-main sm:w-full mb-30"
      [innerHTML]="detailedNews.text"
      appPhoneLink></p>
  </div>
</section>
<app-subscribe-news></app-subscribe-news>
