import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ourPartnersMockData } from 'src/mockData/ourPartnersMockData';
import {
  TextContentModel,
  TextContentService,
} from '../../services/api/text-content.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fund-partners-block',
  templateUrl: './fund-partners-block.component.html',
  styleUrls: ['./fund-partners-block.component.css'],
})
export class FundPartnersBlockComponent implements OnInit, OnDestroy {
  pageName: string = 'about-us/our-people/our-partners/partners-block';
  @Input() isSlice: boolean = false;
  showCountOfLogos = 8;
  ourPartners: TextContentModel[] = [];
  shufledNumberStart = 0;
  pageData: TextContentModel[] | null = null;
  combinedData: any | null = null;
  textContent: TextContentModel | null = null;
  subs: Subscription = new Subscription();
  showMore() {
    this.showCountOfLogos += 8;
  }

  constructor(private apiService: TextContentService) {}

  ngOnInit(): void {
    this.getPageTextData();
  }

  shufledStart() {
    this.shufledNumberStart = Math.abs(
      Math.floor(Math.random() * this.ourPartners.length - 4),
    );
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res;
        this.textContent = res.find(el => el.key === 'partners_text')!;
        this.ourPartners = res
          .filter(el => el.groupType === 'partners_block')
          .sort((a, b) => a.orderId! - b.orderId!);
        this.shufledStart();
      });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
