<div
  class="px-10 py-[38.5px] flex flex-col justify-between h-full text-details md:text-main-text">
  <div class="" [innerHTML]="this.tabData.text"></div>
  <div class="mt-5 md:w-55">
    <app-link-button
      [linkButtonType]="'Secondary'"
      [title]="'Детальніше'"
      [routerLink]="tabData.linkTo"
      (click)="tabData.active = false"></app-link-button>
  </div>
</div>
