import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { merge, startWith, switchMap } from 'rxjs';
import { CountryOptionComponent } from 'src/app/components/forms/phone-input/country-code-select/country-option/country-option.component';
import { Country } from 'src/app/models/country.model';

@Component({
  selector: 'app-country-code-select',
  templateUrl: './country-code-select.component.html',
  styleUrls: ['./country-code-select.component.css'],
  animations: [
    trigger('dropDown', [
      state('void', style({ transform: 'scaleY(0)', opacity: 0 })),
      state('*', style({ transform: 'scaleY(1)', opacity: 1 })),
      transition(':enter', [animate('320ms cubic-bezier(0, 1, 0.45, 1.34)')]),
      transition(':leave', [
        animate('420ms cubic-bezier(0.88, -0.7, 0.86, 0.85)'),
      ]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryCodeSelectComponent),
      multi: true,
    },
  ],
})
export class CountryCodeSelectComponent
  implements AfterContentInit, ControlValueAccessor, OnChanges
{
  @Input()
  selectedCountry: Country = {
    name: 'Ukraine',
    nameUa: 'Україна',
    countryCode: 'ua',
    phoneCountryCode: '+380',
    flagIcon: 'ua',
    placeholder: '00-000-00-00',
    mask: '00-000-00-00',
    preferred: true,
  };

  @Input()
  set value(value: string | null) {
    this.selectionModel.clear();
    if (value) {
      this.selectionModel.select(value);
    }
  }
  get value() {
    return this.selectionModel.selected[0] || null;
  }
  private selectionModel = new SelectionModel<string>();

  isOpen = false;
  @Output()
  readonly opened = new EventEmitter<void>();

  @Output()
  readonly closed = new EventEmitter<void>();

  @Output()
  readonly currentCountry = new EventEmitter();

  @HostListener('click')
  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  mapCountry!: Country[];

  @ContentChildren(CountryOptionComponent, { descendants: true })
  options!: QueryList<CountryOptionComponent>;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedCountry']) {
      this.onChange(changes['selectedCountry'].currentValue.phoneCountryCode);
    }
  }
  protected onChange: (newValue: any) => void = () => {};
  protected onTouched: (newValue: any) => void = () => {};

  writeValue(value: any): void {
    this.selectionModel.select(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngAfterContentInit(): void {
    this.selectionModel.changed.subscribe(value => {
      value.removed.forEach(rv => this.findOptionByCountry(rv)?.deselect());
    });
    this.options.changes
      .pipe(
        startWith<QueryList<CountryOptionComponent>>(this.options),
        switchMap(options => merge(...options.map(o => o.selected))),
      )
      .subscribe(selectedOption => {
        selectedOption.country &&
          this.selectionModel.select(selectedOption.country.name);
        this.selectedCountry = selectedOption.country;
        this.currentCountry.emit(this.selectedCountry);
        this.close();
      });
  }

  onPanelAnimationDone({ fromState, toState }: AnimationEvent) {
    if (fromState === 'void' && toState === null && this.isOpen) {
      this.opened.emit();
    }
    if (fromState === null && toState === 'void' && !this.isOpen) {
      this.closed.emit();
    }
  }

  private findOptionByCountry(country: string | null) {
    return this.options && this.options.find(o => o.country.name === country);
  }
}
