import { Component, OnInit } from '@angular/core';
import IInfoPage from 'src/app/models/infoPage.interface';
import { keyDementiaCardsConfig } from 'src/mockData/keyDementiaCardsConfigMockData';
import {
  TextContentModel,
  TextContentService,
} from '../../../../../services/api/text-content.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import {
  DocsReportModel,
  ReportsDocsService,
} from '../../../../../services/api/aboutUsPages/AboutFondPages/ReportsAndDocsPages/reports-docs.service';
import { OpenPdf } from '../../../../../services/pdfOpen.service';

@Component({
  selector: 'app-key-facts-about-dementia',
  templateUrl: './key-facts-about-dementia.component.html',
  styleUrls: ['./key-facts-about-dementia.component.css'],
})
export class KeyFactsAboutDementiaComponent implements OnInit {
  pageName: string =
    'about-us/news-and-media/media-centre/key-facts-about-dementia';
  combinedData: {
    after_banner_text: TextContentModel;
    first_block: TextContentModel;
    second_block: TextContentModel;
    third_block: TextContentModel;
    fourth_block: TextContentModel;
    [key: string]: TextContentModel;
  } | null = null;

  AccordionData: any[] = [];
  pageData: TextContentModel[] = [];
  subs: Subscription = new Subscription();
  keyDementiaCards: IInfoPage[] = keyDementiaCardsConfig;
  pageDocsData: DocsReportModel[] | null = null;

  links: { id: string; title: string }[] = [];
  constructor(
    private openPdf: OpenPdf,
    private apiService: TextContentService,
    private getDocs: ReportsDocsService,
    private _sanitizer: DomSanitizer,
  ) {}
  ngOnInit(): void {
    this.getPageTextData();
    this.getPageDocs();
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.combinedData = {
          after_banner_text: this.pageData.find(
            el => el.key === 'after_banner_text',
          )!,
          first_block: this.pageData.find(el => el.key === 'first_block')!,
          second_block: this.pageData.find(el => el.key === 'second_block')!,
          third_block: this.pageData.find(el => el.key === 'third_block')!,
          fourth_block: this.pageData.find(el => el.key === 'fourth_block')!,
        };
        this.AccordionData = this.accordionPreparing(
          this.pageData.filter(el => el.key === 'accordion'),
        );
        this.links = this.pageData
          .filter(el => el.groupType === 'main_text')
          .map(el => ({ id: el.key, title: el.title }));
      });
    this.subs.add(sub);
  }

  accordionPreparing(arr: TextContentModel[]) {
    return arr
      .map(faq => ({
        id: faq.id,
        title: faq.title,
        HTML: this._sanitizer.bypassSecurityTrustHtml(faq.bodyText),
        key: 'accordion',
        tag: faq.pageName,
        orderId: faq.orderId,
      }))
      .sort((a, b) => a.orderId! - b.orderId!);
  }

  getPageDocs() {
    const sub = this.getDocs.getReportsByType(this.pageName).subscribe(res => {
      this.pageDocsData = res.sort((a, b) => a.orderId - b.orderId);
    });
    this.subs.add(sub);
  }

  public downloadPdfFile(): void {
    if (this.pageDocsData![0].documentUrl)
      this.openPdf.getPDF(
        this.pageDocsData![0].documentUrl,
        'Key Facts About dementia',
      );
  }
}
