import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

export interface navigationalLinksModel {
  id: number;
  title: string;
  text: string;
  pageName: string;
  link: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationalCardsService {
  private url: string = environment.apiUrl + '/NavigationalCard/';
  adminUrl: string = environment.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getNavigationalCardsByPageName(
    pageName: string,
  ): Observable<navigationalLinksModel[]> {
    return this.http.get<navigationalLinksModel[]>(
      this.url +
        `GetNavigationalCardsByPageName?pageName=${encodeURIComponent(pageName)}`,
    );
  }

  updateNavigationalCards(card: navigationalLinksModel) {
    return this.http.put(this.adminUrl + 'UpdateNavigationalCard', card);
  }
}
