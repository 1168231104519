import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private isLoadedSubject: Subject<boolean> = new Subject<boolean>();
  isLoaded$ = this.isLoadedSubject.asObservable();

  constructor() {}

  setLoaded(isLoaded: boolean): void {
    this.isLoadedSubject.next(isLoaded);
  }
}
