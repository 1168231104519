import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import moreIInfo from 'src/app/models/moreInfo.interface';

export const moreInfoMemoryLoss: moreIInfo[] = [
  {
    header: 'Які є найпоширеніші типи деменції?',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду різних станів.',
    img: './assets/images/news/image1.png',
  },
  {
    header: 'Які симптоми допоможуть розпізнати деменцію?',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду...',
    img: './assets/images/news/old_woman.png',
  },
  {
    header: 'Які є найпоширеніші типи деменції?',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду різних станів.',
    img: './assets/images/news/old_man.png',
  },
];

export const moreInfoLegalAid: moreIInfo[] = [
  {
    header: 'Психологічна підтримка',
    content:
      'Долучайтеся до зустрічей груп підтримки або запишіться на індивідуальну сесію з психологом',
    img: './assets/images/news/image1.png',
  },
  {
    header: 'Юридична допомога',
    content:
      'Знаходьте інформацію про встановлення інвалідності, оформлення опікунства, пільги та відповіді на інші юридичні питання',
    img: './assets/images/news/old_woman.png',
  },
  {
    header: 'Консультація лікаря',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду різних станів.',
    img: './assets/images/news/old_man.png',
  },
];

export const moreInfoPsychSup: moreIInfo[] = [
  {
    header: 'Допомога з доглядом',
    content:
      'Отримайте консультацію з фахівцем та дивіться вебінари з питань догляду.',
    img: './assets/images/psychological-support/image1.png',
    link: PAGE_ROUTES.careConsultation,
  },
  {
    header: 'Юридична допомога',
    content:
      'Знаходьте інформацію про встановлення інвалідності, оформлення опікунства, пільги та відповіді на інші юридичні питання.',
    img: './assets/images/psychological-support/image2.png',
    link: PAGE_ROUTES.legalAssistance,
  },
  {
    header: 'Медична допомога',
    content:
      'Отримайте безкоштовну консультацію лікаря-психіатра або долучайтеся до вебінарів з медичних питань.',
    img: './assets/images/psychological-support/image3.png',
    link: PAGE_ROUTES.medicalAssistance,
  },
];

export const moreInfoCareConsultation: moreIInfo[] = [
  {
    header: 'Психологічна підтримка',
    content:
      'Долучайтеся до зустрічей груп підтримки або запишіться на індивідуальну сесію з психологом',
    img: './assets/images/care-consultation/image_support.webp',
    link: PAGE_ROUTES.psychologicalSupport,
  },
  {
    header: 'Юридична допомога',
    content:
      'Знаходьте інформацію про встановлення інвалідності, оформлення опікунства, пільги та відповіді на інші юридичні питання',
    img: './assets/images/moreInfo/old_man.jpg',
    link: PAGE_ROUTES.legalAssistance,
  },
  {
    header: 'Допомога з ліками',
    content:
      'Отримайте безкоштовні ліки при деменції для вас, вашої близької людини чи підопічних вашої установи.',
    img: './assets/images/care-consultation/image_pills.webp',
    link: PAGE_ROUTES.helpWithMedicine,
  },
];
