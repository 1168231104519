import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Country } from 'src/app/models/country.model';

@Component({
  selector: 'app-country-option',
  templateUrl: './country-option.component.html',
  styleUrls: ['./country-option.component.css'],
})
export class CountryOptionComponent implements OnInit {
  @Input()
  country: Country = {
    name: 'Ukraine',
    nameUa: 'Україна',
    countryCode: 'ua',
    phoneCountryCode: '+380',
    flagIcon: 'ua',
    placeholder: '00-000-00-00',
    mask: '00-000-00-00',
    preferred: true,
  };

  @Input()
  value: string | null = null;

  @HostBinding('class.text-gray-details')
  protected isSelected = false;

  @Output()
  selected = new EventEmitter<CountryOptionComponent>();

  @HostListener('click')
  select() {
    this.isSelected = true;
    this.selected.emit(this);
  }

  constructor() {}

  ngOnInit(): void {}

  deselect() {
    this.isSelected = false;
  }
}
