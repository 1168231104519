import {
  Component,
  HostListener,
  Input,
  ElementRef,
  ContentChild,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-image-reader',
  templateUrl: './image-reader.component.html',
  styleUrls: ['./image-reader.component.css'],
})
export class ImageReaderComponent {
  @ContentChild('myPicture', { static: true }) myPicture!: ElementRef;
  @Output() updatedImg = new EventEmitter<string>();
  @Output() uploadedFile = new EventEmitter<File>();
  @Input() edit: boolean = false;
  @Input() imgUrl: string = '';
  @Input() classList: string = '';
  @Input() position: string = 'right-0 ';
  isEditing!: boolean;
  constructor(private elementRef: ElementRef) {}

  @HostListener('dblclick')
  startEditing() {
    if (this.edit) {
      this.isEditing = true;
    }
  }

  @HostListener('click', ['$event'])
  hostClick(event: Event) {
    event.stopPropagation();
  }

  @HostListener('window:click', ['$event'])
  stopEditing(event: Event) {
    if (event.target !== this.elementRef.nativeElement) {
      this.isEditing = false;
    }
  }

  public handleFileInput(files: any) {
    const file = files.target.files[0];
    if (file) {
      this.convertImageToBase64(file);
    }
  }

  convertImageToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const imageDataUrl = event.target.result;
      this.displayBase64Image(imageDataUrl);
      this.stopEditing(event);
      this.uploadedFile.emit(file);
    };
    reader.readAsDataURL(file);
  }

  displayBase64Image(base64Image: string): void {
    this.imgUrl = base64Image;
    this.updatedImg.emit(this.imgUrl);
  }
}
