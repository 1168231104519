import { IHelpCard } from 'src/app/models/help-card.interface';

export const helpCardsForOurPartners: any[] = [
  {
    imageURL: '../../../../../assets/images/help block/volunteer.png',
    title: 'Стати волонтером',
    buttonURL: '/get-involved/become-a-volunteer',
  },
  {
    imageURL: '../../../../../assets/images/help block/donate.png',
    title: 'Зробити благодійний внесок',
    buttonURL: '/get-involved/to-help',
  },
  {
    imageURL: '../../../../../assets/images/help block/partners.png',
    title: 'Взяти участь у дослідженні',
    buttonURL: '/about-us/programs/research',
  },
];
