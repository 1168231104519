import { Component, OnInit, Input } from '@angular/core';
import ICardInfo from 'src/app/models/cardInfo.interface';
import { Router } from '@angular/router';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { NewsApiService } from 'src/app/services/api/aboutUsPages/NewsAndMedia/news-api.service';
import { Observable } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeUk from '@angular/common/locales/uk';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent implements OnInit {
  @Input() cardInfo!: ICardInfo;
  isHovered: boolean = false;

  constructor(
    private router: Router,
    private newsApiService: NewsApiService,
  ) {
    registerLocaleData(localeUk, 'uk-UA');
  }

  ngOnInit(): void {}

  public navigate(id: number) {
    if (!this.cardInfo.link || this.cardInfo.link === '') {
      this.router.navigate([PAGE_ROUTES.news + '/' + id.toString()]);
    } else {
      if (this.cardInfo.link) {
        this.redirectToOtherSite(this.cardInfo.link);
      }
    }
  }

  extractTextFromHtml(html: string): string {
    const regex = /<[^>]*>/g;
    const text = html.replace(regex, '');
    return text.length > 110 ? text.substring(0, 110) : text;
  }

  redirectToOtherSite(link: string) {
    window.open(link, '_blank');
  }

  public getNewsItemById(id: string): Observable<ICardInfo> {
    return this.newsApiService.getNewsById(id);
  }
}
