import { Component, OnInit, Input } from '@angular/core';
import { MediaAboutUsModel } from '../../services/api/aboutUsPages/NewsAndMedia/media-about-us.service';
import { registerLocaleData } from '@angular/common';
import localeUk from '@angular/common/locales/uk';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit {
  @Input() productsCard!: MediaAboutUsModel;
  isHovered: boolean = false;

  constructor() {
    registerLocaleData(localeUk, 'uk-UA');
  }

  ngOnInit(): void {}

  openChannel(link: string) {
    window.open(link);
  }
}
