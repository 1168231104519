import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map, Observable } from 'rxjs';

export interface OtherPublicationCard {
  id?: number;
  pageName: string;
  publicationDate: Date;
  title: string;
  buttonURL: string;
  orderId: number;
}

export interface researchDataModel {
  id: number;
  title: string;
  fundResearchSecond: researchCardModel[];
  key?: string;
}
export interface researchCardModel {
  id?: number;
  title: string;
  publicationDate: string;
  linkTranslit: string;
  fundResearchThird?: null;
  fundResearchFirstId: number;
  fundResearchFirst?: null;
}

export interface researchDetails {
  id: number | null;
  title: string;
  bodyText: string;
  imageURL: string;
  linkTranslit: string;
  fundResearchFourth: researchFourth;
  fundResearchSecondId?: number;
  fundResearchSecond: null;
  File?: File;
}

export interface researchFourth {
  id?: number;
  title: string;
  text: string;
  buttonURL: string;
  fundResearchThirdId?: number;
  fundResearchThird: null;
  pdfFile?: File;
}

@Injectable({
  providedIn: 'root',
})
export class ResearchService {
  private url: string = environment.apiUrl;
  adminUrl: string = environment.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getOtherPublications(): Observable<OtherPublicationCard[]> {
    return this.http.get<OtherPublicationCard[]>(
      this.url + '/OtherPublication/GetOtherPublications',
    );
  }

  getResearchPublications(): Observable<researchDataModel> {
    return this.http
      .get<researchDataModel>(this.url + '/FundResearch/GetFundResearchFirst')
      .pipe(
        map(res => ({
          ...res,
          fundResearchSecond: res.fundResearchSecond.sort((a, b) => {
            return (
              Date.parse(b.publicationDate) - Date.parse(a.publicationDate)
            );
          }),
        })),
      );
  }

  getPageDataByLinkTranslit(linkTranskit: string): Observable<researchDetails> {
    return this.http.get<researchDetails>(
      this.url +
        `/FundResearch/GetFundResearchByLinkTranslit?linkTranslit=${linkTranskit}`,
    );
  }

  updateOtherPublications(
    data: OtherPublicationCard,
  ): Observable<OtherPublicationCard> {
    return this.http.put<OtherPublicationCard>(
      this.adminUrl + 'UpdateOtherPublication',
      data,
    );
  }

  createOtherpublications(
    data: OtherPublicationCard,
  ): Observable<OtherPublicationCard> {
    return this.http.post<OtherPublicationCard>(
      this.adminUrl + 'AddOtherPublication',
      data,
    );
  }

  deleteOtherPublication(
    data: OtherPublicationCard,
  ): Observable<OtherPublicationCard> {
    return this.http.delete<OtherPublicationCard>(
      this.adminUrl + `DeleteOtherPublication?id=${data.id}`,
    );
  }

  deleteOurResearch(data: researchCardModel): Observable<researchCardModel> {
    return this.http.delete<researchCardModel>(
      this.adminUrl + `DeleteFundResearchSecond?id=${data.id}`,
    );
  }

  changeOtherPublicationOrder(data: OtherPublicationCard[]) {
    return this.http.put(this.adminUrl + 'ChangeOtherPublicationOrder', data);
  }

  createSecondth(data: FormData) {
    return this.http.post(this.adminUrl + 'AddFundResearchSecond', data);
  }

  updateResearchThird(data: FormData) {
    return this.http.put(this.adminUrl + 'UpdateFundResearchThird', data);
  }

  updateResearchSecond(data: researchCardModel) {
    return this.http.put(this.adminUrl + 'UpdateFundResearchSecond', data);
  }

  updateResearchFourth(data: FormData) {
    return this.http.put(this.adminUrl + 'UpdateFundResearchFourth', data);
  }

  updateResearchFirst(data: { id: number; title: string }) {
    return this.http.put(this.adminUrl + 'UpdateFundResearchFirst', data);
  }
}
