<ng-container *ngIf="url !== '/404' && !url.includes('/admin-nezabutni')">
  <header
    class="h-15 md:h-20 xl:h-32.5 mb-px w-full flex flex-row md:justify-center border-b border-gray-stroke bg-gray-white">
    <app-header
      class="flex mt-1 md:mt-0 h-15 md:h-20 xl:h-18 px-2 sm:px-5 xl:px-0 justify-center wide-container fixed z-[100] mb-0 xl:mb-36 border-b bg-gray-white border-gray-stroke"
      [headerButtonVisible]="true"></app-header>
  </header>
  <nav
    #navbar
    *ngIf="
      (isMobile$ | async) === false &&
      (isTablet$ | async) === false &&
      (isLaptop$ | async) === false
    "
    class="grid fixed justify-center wide-container z-[90] top-18 transition-[top] duration-1000 bg-gray-white border-b border-gray-stroke"
    id="navbar">
    <app-main-menu class="flex content-container"></app-main-menu>
  </nav>
  <app-breadcrumb></app-breadcrumb>
  <div>
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</ng-container>

<ng-container *ngIf="url === '/404'">
  <app-404-not-found></app-404-not-found>
</ng-container>
<div
  *ngIf="showBtn$ | async"
  (click)="gotoTop()"
  id="scrolled"
  class="group/scroll fixed bottom-10 md:left-[calc(50vw_+_360px)] xl:left-[calc(50vw_+_530px)] cursor-pointer w-12 h-12 flex rounded-full border-4 justify-center items-center border-accent-additional hover:border-secondary-additional hover:shadow-2xl z-50 right-5">
  <svg
    width="20px"
    height="15px"
    icon="arrow-bottom"
    iconCollection="button-icons-sprite"
    class="fill-accent-base group-hover/scroll:fill-secondary-additional rotate-180"
    style="visibility: visible !important; display: block !important"></svg>
</div>

<div *ngIf="url.includes('/admin-nezabutni')">
  <router-outlet></router-outlet>
</div>
