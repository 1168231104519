<div class="relative">
  <ng-content *ngIf="!isEditing"></ng-content>
  <ng-container *ngIf="isEditing">
    <label class="cursor-pointer" for="CardImg">
      <input
        (change)="handleFileInput($event)"
        class="absolute hidden"
        accept="image/*"
        id="CardImg"
        type="file" />
      <img alt="" class="{{ classList }}" src="{{ imgUrl }}" />
      <div
        [ngClass]="position"
        class="absolute top-0 bg-opacity-50 bg-gray-card {{ classList }}">
        <div class="flex w-full h-full items-center justify-center text-[50px]">
          &#x21BB;
        </div>
      </div>
    </label>
  </ng-container>
</div>
