<ng-container *ngIf="card">
  <!--    [queryParams]="card.queryParams"-->
  <a
    [routerLink]="card.buttonURL"
    aria-label="Дізнатися більше"
    class="group/help">
    <div
      class="w-82 md:w-102.5 xl:w-95 h-87 md:h-106 bg-gray-white rounded-30 flex items-center flex-col">
      <div class="w-full xl:w-95 h-65 md:h-75 rounded-t-30">
        <img
          [src]="card.imageURL"
          [alt]="card.title"
          class="w-full xl:w-95 h-65 md:h-75 rounded-t-30" />
      </div>

      <div
        class="flex justify-between rounded-b-30 border-t-0 border-gray-stroke w-full h-22 md:h-full p-5 md:pl-10 md:pr-9 md:pt-0 group-hover/help:border-secondary-additional group-active/help:border-secondary-additional duration-300"
        [ngClass]="borderType">
        <p
          class="text-smaller-bold md:text-h3-subheading text-gray-dark md:mt-5 w-62">
          {{ card.title }}
        </p>
        <svg
          width="28px"
          height="16px"
          icon="arrow-right-medium"
          iconCollection="button-icons-sprite"
          class="inline-block fill-accent-base group-hover/help:fill-secondary-additional group-active/help:fill-secondary-additional w-7 h-4 mt-1 md:mt-7 duration-300"></svg>
      </div>
    </div>
  </a>
</ng-container>
