import { Component, OnDestroy, OnInit } from '@angular/core';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import {
  TextContentModel,
  TextContentService,
} from '../../../services/api/text-content.service';
import { Subscription } from 'rxjs';
import {
  portalImageLinkModel,
  PortalLinksService,
} from '../../../services/api/portal-links.service';

@Component({
  selector: 'app-organize-fundraiser',
  templateUrl: './organize-fundraiser.component.html',
  styleUrls: ['./organize-fundraiser.component.css'],
})
export class OrganizeFundraiserComponent implements OnInit, OnDestroy {
  pageName: string = 'get-involved/organize-a-fundraiser';
  combinedData: {
    after_banner_text: TextContentModel;
    first_block: TextContentModel;
    second_block: TextContentModel;
    third_block: TextContentModel;
    fourth_block: TextContentModel;
    last_block: TextContentModel;
    [key: string]: TextContentModel;
  } | null = null;

  ideaCards: TextContentModel[] = [];
  pageData: TextContentModel[] = [];
  imageListFirst: portalImageLinkModel[] = [];
  subs: Subscription = new Subscription();

  cards: any = [
    {
      imageLink: '../assets/images/other-method-to-join/regular-payment.png',
      route: '/get-involved/to-help',
      text: 'Регулярний платіж',
      queryParams: { subscribe: 'monthly' },
    },
    {
      imageLink: '../assets/images/other-method-to-join/make-donate.png',
      route: '/get-involved/become-a-partner',
      text: ' Стати партнером фонду ',
    },
    {
      imageLink: '../assets/images/other-method-to-join/become-volonteer.png',
      route: '/get-involved/become-a-volunteer',
      text: 'Стати волонтером',
    },
  ];

  constructor(
    private apiService: TextContentService,
    private portalImageLinkService: PortalLinksService,
  ) {}

  ngOnInit(): void {
    this.getPortalImageLinks();
    this.getPageTextData();
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.combinedData = {
          after_banner_text: this.pageData.find(
            el => el.key === 'after_banner_text',
          )!,
          first_block: this.pageData.find(el => el.key === 'first_block')!,
          second_block: this.pageData.find(el => el.key === 'second_block')!,
          third_block: this.pageData.find(el => el.key === 'third_block')!,
          fourth_block: this.pageData.find(el => el.key === 'fourth_block')!,
          last_block: this.pageData.find(el => el.key === 'last_block')!,
        };
        this.ideaCards = this.pageData.filter(
          el => el.groupType === 'idea_card',
        );
      });
    this.subs.add(sub);
  }

  getPortalImageLinks() {
    const sub = this.portalImageLinkService
      .getPortalLinksByPageName(this.pageName)
      .subscribe(res => {
        this.imageListFirst = res.filter(el => el.tag === 'first_gallery');
      });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
