<div
  class="relative flex flex-col content-container pl-25 py-11 rounded-t-30 shadow-cookies overflow-hidden">
  <app-icon-flower
    id="animate-area24"
    class="absolute -top-11 -right-15 fill-gray-card h-110 w-125 hover:animate-none focus:animate-none"></app-icon-flower>
  <div class="items-center mb-10 text-gray-dark z-10">
    <p class="text-h3-subheading">
      Ми використовуємо файли Cookie, щоб забезпечити ваш найкращий досвід.
    </p>
    <p class="text-h3-subheading mb-4">
      Продовжуючи користуватись цим сайтом, ви погоджуєтесь на це використання.
    </p>
    <p class="pt-1 text-lg">
      Для додаткової інформації подивіться нашу
      <a href="" class="underline-offset-4 underline"
        >Політику конфіденційності</a
      >.
    </p>
  </div>
  <div class="flex gap-10">
    <app-button
      (onClick)="functionCall($event)"
      [buttonType]="'Secondary'"
      [title]="'Дозволити'"
      class="w-55">
    </app-button>
    <div
      (onClick)="functionCall2($event)"
      class="flex text-gray-dark hover:text-secondary-base focus:text-secondary-additional h-12 justify-center items-center text-main-text-bold">
      Заборонити
    </div>
  </div>
</div>
