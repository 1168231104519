import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fund-member-card-item',
  templateUrl: './fund-member-card-item.component.html',
  styleUrls: ['./fund-member-card-item.component.css'],
})
export class FundMemberCardItemComponent {
  @Input() text: string = '';
  @Input() title: string = '';
  @Input() imgUrl: string = '';
  @Input() url: string = '';
  @Input() useModal: boolean = false;
  @Input() description: string = '';

  isModalOpen: boolean = false;

  openModal(): void {
    if (this.useModal) {
      this.isModalOpen = true;
    } else {
      window.location.href = this.url;
    }
  }

  closeModal(): void {
    this.isModalOpen = false;
  }
}
