<div class="flex w-95 h-140 relative justify-center">
  <div class="flex mx-auto w-95 h-140 relative">
    <img
      src="{{ imgUrl }}"
      alt="{{ title }}"
      class="absolute right-0 rounded-30 object-cover w-full h-full" />
    <div
      class="mx-auto w-95 h-52 rounded-b-30 bottom-0 absolute z-10 bg-[linear-gradient(0deg,#6E285F_43%,rgba(110,40,95,0)_100%)]"></div>
  </div>
  <div class="w-75 mx-auto absolute z-20 pb-7.5 bottom-0">
    <h2 class="text-h2-heading text-gray-white uppercase pb-2 w-75">
      {{ title }}
    </h2>
    <p class="text-gray-white text-smaller-main w-75 pb-2">{{ text }}</p>
  </div>
</div>
