<div
  (click)="openChannel(productsCard.buttonURL)"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false"
  class="xl:w-145 md:w-102.5 w-full group cursor-pointer rounded overflow-hidden px-4 md:p-0 mb-5 md:mb-0">
  <div class="h-75">
    <img
      class="w-full object rounded-t-3xl h-full object-cover"
      [src]="productsCard.imageURL"
      alt="{{ productsCard.title }}" />
  </div>

  <div
    class="md:px-8 px-4 border-4 border-gray-stroke border-t-gray-white rounded-3xl rounded-t-none group-hover:border-t-gray-white group-hover:border-secondary-base group-focus:border-secondary-base">
    <div class="lg:flex mt-3 items-center justify-start">
      <a href="" class="">
        <img
          class="h-[35px] w-35 object-contain object-center"
          alt="{{ productsCard.title }}"
          [src]="productsCard.logoImageURL"
          width="140" />
      </a>
      <div class="flex justify-between mt-3 lg:mt-0">
        <h3
          class="lg:pl-5 text-gray-details md:text-main-text text-smaller-main">
          {{ productsCard.contentType }}
        </h3>
        <div
          class="flex text-gray-details md:text-main-text text-smaller-main md:pl-10 items-center justify-start">
          <svg
            width="22px"
            height="20px"
            icon="book"
            iconCollection="button-icons-sprite"
            class="svg fill-gray-details"></svg>
          <p class="ml-2">
            {{
              productsCard.publicationDate
                | date: 'dd MMMM yyyy ' : 'UTC+3' : 'uk-UA'
            }}
          </p>
        </div>
      </div>
    </div>
    <p
      class="font-bold md:text-h3-subheading text-h3-360 my-5 text-gray-dark wrapper h-14">
      {{ productsCard.title }}
    </p>

    <div
      class="mt-10 mb-10 w-full md:w-82.5 xl:w-55 h-12 content-center flex-none">
      <app-link-button
        [title]="'Перейти'"
        [linkButtonType]="'Primary'"
        [isHovered]="isHovered">
      </app-link-button>
    </div>
  </div>
</div>
