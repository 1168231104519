import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

interface LiqpayFormData {
  nameDonator: string;
  surnameDonator: string;
  emailDonator: string;
  phoneNumber: string;
  telCode: string;
  telDonator: string;
  chosedCurrency: string;
  sumDonate: string;
}

export interface LiqpayStatusResponse {
  status: string;
  description: string;
  err_description: any;
  result: string;
}

@Injectable({
  providedIn: 'root',
})
export class LiqPayService {
  url = environment.liqPay.url;
  apiUrlGetSignature: string =
    environment.apiUrl + '/LiqPay/GetDataAndSignature';
  apiUrlGetStatus: string =
    environment.apiUrl + '/LiqPay/GetPaymentStatus?orderID=';

  constructor(private http: HttpClient, private router: Router) {}

  sendData(
    data: LiqpayFormData,
  ): Observable<{ data: string; signature: string }> {
    return this.http.post<{ data: string; signature: string }>(
      this.apiUrlGetSignature,
      {
        firstName: data.nameDonator,
        secondName: data.surnameDonator,
        email: data.emailDonator,
        phoneNumber: (data.telCode + data.telDonator).toString(),
        currency: data.chosedCurrency,
        donateAmount: data.sumDonate,
      },
    );
  }
  openPaymentWindow(data: { data: string; signature: string }): string {
    const url: string =
      this.url + '?' + 'data=' + data.data + '&signature=' + data.signature;
    window.location.href = url;
    return url;
  }

  checkStatus(orderId: string): Observable<LiqpayStatusResponse> {
    return this.http.get<LiqpayStatusResponse>(this.apiUrlGetStatus + orderId);
  }
}
