import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-flower-svg',
  templateUrl: './flower.component.svg',
})
export class FlowerSvgComponent {
  @Input()
  public digit: number | undefined;

  constructor() {}
}
