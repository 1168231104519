import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appScrollToSection]',
  standalone: true,
})
export class ScrollToSectionDirective {
  @Input() scrollToSection!: string;

  constructor() {}

  @HostBinding('style.scroll-padding-top') scrollPaddingTop: string = '0px';

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    event.preventDefault();
    const section = document.getElementById(this.scrollToSection);
    if (section) {
      const topOffset = section.offsetTop - 140;
      this.scrollPaddingTop = `${topOffset}px`;
      window.scroll({ top: topOffset, behavior: 'smooth' });
    }
  }
}
