<!--<section class="grid">-->
<!--  <app-header-->
<!--    class="flex mx-auto h-18 w-full fixed bg-gray-white border-b border-gray-stroke"-->
<!--  ></app-header>-->
<!--</section>-->
<!-- <section class="grid mt-18">
  <app-submenu-about-dementia
    class="flex mx-auto w-full"
  ></app-submenu-about-dementia>
</section> -->
<section class="grid mt-18 mx-auto w-125">
  <app-phone-input></app-phone-input>
</section>

<section class="grid mt-18">
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="links"></app-table-of-content>
</section>
<section class="grid mt-18">
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="links"></app-table-of-content>
</section>

<section class="grid">
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="links2"></app-table-of-content>
</section>

<section class="grid" id="influence">
  <app-cookies class="grid mx-auto w-full"></app-cookies>
</section>
