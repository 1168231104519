<ng-container>
  <button
    *ngIf="buttonType === 'Primary'"
    (click)="onClickButton($event)"
    class="w-full text-gray-white rounded-10 bg-accent-base active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent"
    [disabled]="disabled"
    [type]="type">
    <div class="text-main-text font-semibold flex flex-row justify-center">
      <ng-content *ngIf="svg" select=".svg"></ng-content>
      <div>{{ title }}</div>
    </div>
  </button>
  <button
    *ngIf="buttonType === 'Donate'"
    (click)="onClickButton($event)"
    class="group/donate relative flex w-full overflow-hidden text-white bg-no-repeat fill-accent-additional hover:animate-none hover:bg-secondary-base active:bg-secondary-additional rounded-10 py-2.5 bg-accent-base"
    [type]="type">
    <app-icon-flower
      id="animate-area24"
      class="absolute top-0 left-2.5 w-17 h-12 fill-inherit animate-flower-pulse hover:animate-none focus:animate-none group-hover/donate:fill-gray-white group-hover/donate:opacity-30 group-hover/donate:animate-none group-active/donate:fill-gray-white group-active/donate:opacity-30 group-active/donate:animate-none"></app-icon-flower>
    <div class="z-10 mx-auto text-main-text font-semibold uppercase">
      {{ title }}
    </div>
  </button>
  <button
    *ngIf="buttonType === 'Secondary'"
    (click)="onClickButton($event)"
    class="w-full py-2 border-2 bg-gray-white text-accent-base hover:text-secondary-base active:text-secondary-additional disabled:text-gray-gray2 rounded-10 border-accent-base hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2"
    [disabled]="disabled"
    [type]="type">
    <div class="text-main-text font-semibold flex flex-row justify-center">
      <ng-content *ngIf="svg" select=".svg"></ng-content>
      <div>{{ title }}</div>
    </div>
  </button>
</ng-container>
