import { Component } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent {
  pageName: string = 'reports-and-documents';
  cardsTag: string = 'about-us/reports-and-documents';
}
