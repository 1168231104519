import { Component, OnInit, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Data } from '@angular/router';
import { filter } from 'rxjs/operators';

export interface IBreadCrumb {
  label: string;
  url: string;
}
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css'],
})
export class BreadcrumbComponent implements OnInit, AfterContentInit {
  public breadcrumbs: IBreadCrumb[];
  private indexSignatureBreadcrumb: string = 'breadcrumb';
  private indexSignatureApiData: string = 'apiData';
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.breadcrumbs = this._buildBreadcrumbs(this.activatedRoute.root);
  }
  ngAfterContentInit(): void {}
  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = this._buildBreadcrumbs(this.activatedRoute.root);
      });
  }

  private _buildBreadcrumbs(
    activatedRoute: ActivatedRoute,
    url: string = '',
  ): IBreadCrumb[] {
    const children: ActivatedRoute[] = activatedRoute.children;

    if (children.length === 0) {
      return [];
    }

    let breadcrumbs: IBreadCrumb[] = [];
    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join('/');

      if (routeURL.length > 0) {
        url += `/${routeURL}`;
        const routeData: Data = child.snapshot.data;
        if (routeData[this.indexSignatureApiData]) {
          if (typeof routeData[this.indexSignatureApiData] === 'object') {
            breadcrumbs.push({
              label:
                routeData[this.indexSignatureApiData][
                  this.indexSignatureBreadcrumb
                ],
              url: url,
            });
          } else {
            breadcrumbs.push({
              label: routeData[this.indexSignatureApiData],
              url: url,
            });
          }
        } else if (routeData[this.indexSignatureBreadcrumb]) {
          breadcrumbs.push({
            label: routeData[this.indexSignatureBreadcrumb],
            url: url,
          });
        }
      }
      breadcrumbs = breadcrumbs.concat(this._buildBreadcrumbs(child, url));
    }
    return breadcrumbs;
  }
}
