<div *ngFor="let card of cards">
  <a
    [routerLink]="card.buttonURL"
    class="w-[328px] md:w-[840px] xl:w-295 block mx-auto mb-5 group/arrow">
    <div
      class="flex flex-col justify-between md:flex-row border-2 border-gray-stroke rounded-30 hover:border-secondary-base cursor-pointer">
      <img
        class="rounded-tl-30 rounded-tr-30 md:rounded-bl-30 md:rounded-tr-none object-cover w-full md:w-[380px] h-full md:h-[520px] xl:h-[396px]"
        alt="{{ card.title }}"
        [src]="card.imageURL" />

      <div
        class="flex flex-col justify-between p-5 md:p-7.5 xl:p-10 max-w-[800px]">
        <div>
          <h3 class="text-h3-subheading text-gray-dark pb-[10px] md:pb-5">
            {{ card.title }}
          </h3>
          <p
            class="text-main-text text-gray-dark"
            [innerHtml]="card.bodyText"></p>
        </div>
        <div class="w-27.5">
          <app-link-button
            [linkButtonType]="'RightArrow'"
            [title]="'Перейти'"></app-link-button>
        </div>
      </div>
    </div>
  </a>
</div>
