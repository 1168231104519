import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IVolunteerResponse } from 'src/app/models/volunteer.interface';

export interface IFundMemberResponse {
  id: number;
  title: string;
  img: string;
  position: string;
  description: string;
  email: string;
  projectRole: 'FundMember' | 'FundWatcher';
  orderId: number;
}

@Injectable({
  providedIn: 'root',
})
export class FundTeamService {
  private apiUrl: string = environment.apiUrl;
  private adminUrl: string = environment.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getFundTeam(): Observable<IFundMemberResponse[]> {
    return this.http.get<IFundMemberResponse[]>(
      `${this.apiUrl}/FundTeam/people`,
    );
  }
  getFundMemberById(id: string): Observable<IFundMemberResponse> {
    return this.http.get<IFundMemberResponse>(
      `${this.apiUrl}/FundTeam/people/${id}`,
    );
  }
  getVolunteerTeam(): Observable<IVolunteerResponse[]> {
    return this.http.get<IVolunteerResponse[]>(
      `${this.apiUrl}/FundTeam/volonteers`,
    );
  }
  getVolunteerMemberById(id: string): Observable<IFundMemberResponse> {
    const params = new HttpParams().append('id', id);
    return this.http.get<IFundMemberResponse>(
      `${this.apiUrl}/FundTeam/volonteers`,
      {
        params,
      },
    );
  }

  deleteFundMember(data: any, endpoint: 'DeleteVolunteer' | 'DeleteFundTeam') {
    return this.http.delete(this.adminUrl + endpoint, { body: data });
  }

  updateVolunteer(data: any) {
    return this.http.put(this.adminUrl + 'UpdateVolunteer', data);
  }

  createVolunteer(data: any) {
    return this.http.post(this.adminUrl + 'AddVolunteer', data);
  }

  updateFundMemeber(data: any) {
    return this.http.put(this.adminUrl + 'UpdateFundTeam', data);
  }

  createFundMember(data: any) {
    return this.http.post(this.adminUrl + 'AddFundTeam', data);
  }

  updateFundMembersOrder(data: any) {
    return this.http.put(this.adminUrl + 'ChangeFundMembersOrder', data);
  }

  updateVolunteersOrder(data: any) {
    return this.http.put(this.adminUrl + 'ChangeVolunteersOrder', data);
  }
}
