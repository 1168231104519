<div class="flex w-full flex-col">
  <div
    #contentWrapper
    class="flex overflow-hidden mx-auto gap-5 w-full relative text-gray-dark bg-gray-white">
    <ng-content></ng-content>
  </div>
  <div
    class="flex flex-row w-full mx-auto mt-10 justify-center items-center fill-accent-base z-50">
    <div class="w-16 flex flex-row justify-end items-center h-8">
      <div class="hover:fill-secondary-base" (click)="prevSlide()">
        <svg
          class="cursor-pointer"
          [icon]="'left-arrow'"
          iconCollection="button-icons-sprite"
          width="32px"
          height="32px"></svg>
      </div>
      <div class="pl-10">{{ currentSlideNumber }}</div>
    </div>
    <div class="flex flex-row px-2">/</div>
    <div class="w-16 flex flex-row h-8">
      <div class="pr-10 pt-0.5 h-8">
        {{ carouselSlide.length }}
      </div>
      <div class="hover:fill-secondary-base" (click)="nextSlide()">
        <svg
          class="cursor-pointer"
          [icon]="'right-arrow'"
          iconCollection="button-icons-sprite"
          width="32px"
          height="32px"></svg>
      </div>
    </div>
  </div>
</div>
