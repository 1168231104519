<app-send-request
  *ngIf="show"
  [formsData]="formsData"
  (closePopUp)="closeModal()"
  (closeWindow)="closePopUp()">
</app-send-request>
<form
  *ngIf="showForm"
  class="w-full md:w-[410px] xl:w-145 border-2 bg-white border-gray-stroke rounded-30 p-5 md:p-10"
  (ngSubmit)="onSubmit()"
  [formGroup]="contactForm">
  <div class="flex justify-between items-start">
    <h2 class="text-h2-heading text-primary-base uppercase mb-10">
      {{ formsDataAgreement ? formsDataAgreement.title : title }}
    </h2>
    <svg
      *ngIf="showCross"
      width="19px"
      height="19px"
      [icon]="'close'"
      (click)="closeModal()"
      iconCollection="button-icons-sprite"
      class="text-gray-dark cursor-pointer"></svg>
  </div>
  <div class="text-main-text text-gray-dark">
    <div class="mb-5">
      <app-input
        [control]="contactForm.controls.name"
        [labelName]="'Ваше імʼя'"
        [maxLength]="'60'"
        inputID="contactName"
        class="w-full"></app-input>
    </div>
    <div class="mb-5">
      <app-input
        [control]="contactForm.controls.email"
        [labelName]="'E-mail'"
        type="email"
        inputID="contactEmail"
        class="w-full"></app-input>
    </div>

    <div class="mb-10">
      <app-textarea
        [control]="contactForm.controls.message"
        [maxLength]="'2000'"
        [labelName]="'Текст повідомлення'"
        [textAreaID]="'message'"></app-textarea>
    </div>
  </div>

  <div class="flex mb-5">
    <div class="flex">
      <label class="flex" for="checkbox">
        <input
          type="checkbox"
          name=""
          id="checkbox"
          class="appearance-none peer"
          formControlName="policy" />
        <span
          class="w-8 h-8 grid place-items-center border-2 border-gray-stroke rounded-8 cursor-pointer peer-checked:bg-primary-additional peer-checked:border-0 peer-focus:border-0 peer-focus:shadow-checkbox peer-checked:peer-focus:shadow-none peer-invalid:border-attention-base"
          [ngClass]="{
            'bg-primary-base': contactForm.get('policy')?.valid,
            'border-attention-base border':
              contactForm.get('policy')?.invalid &&
              contactForm.get('policy')?.touched
          }">
          <svg
            width="26px"
            height="26px"
            [icon]="'checkBox'"
            iconCollection="button-icons-sprite"
            class="fill-gray-white"></svg>
        </span>
      </label>
      <div
        class="ml-3.75 text-details text-gray-dark"
        [innerHtml]="formsDataAgreement.bodyText"></div>
    </div>
  </div>
  <app-button [title]="'Відправити'" [type]="'submit'"></app-button>
</form>
