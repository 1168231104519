import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, ContentChildren, OnInit, QueryList } from '@angular/core';
import { CarouselSlideComponent } from 'src/app/components/carousel/carousel-slide/carousel-slide.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
})
export class CarouselComponent implements OnInit {
  @ContentChildren(CarouselSlideComponent, { descendants: true })
  carouselSlide!: QueryList<CarouselSlideComponent>;
  currentSlideNumber: number = 1;
  constructor() {}

  ngOnInit(): void {}

  @Output()
  readonly changeSlide = new EventEmitter<string>();
  prevSlide() {
    if (this.currentSlideNumber > 1) {
      this.currentSlideNumber = this.currentSlideNumber - 1;
    } else {
      this.currentSlideNumber = this.carouselSlide.length;
    }

    this.changeSlide.emit('prevSlide');
  }

  nextSlide() {
    if (this.currentSlideNumber < this.carouselSlide.length) {
      this.currentSlideNumber = this.currentSlideNumber + 1;
    } else {
      this.currentSlideNumber = 1;
    }
    this.changeSlide.emit('nextSlide');
  }
}
