import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-during-the-war',
  templateUrl: './help-during-the-war.component.html',
  styleUrls: ['./help-during-the-war.component.css'],
})
export class HelpDuringTheWarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
