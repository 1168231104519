import { Component, OnInit } from '@angular/core';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { ROUTE_PART_NAMES } from 'src/app/constants/route_part_names';

@Component({
  selector: 'app-submenu-join',
  templateUrl: './submenu-join.component.html',
  styleUrls: ['./submenu-join.component.css'],
})
export class SubmenuJoinComponent implements OnInit {
  constructor() {}
  links = ROUTE_PART_NAMES;
  donateLink = PAGE_ROUTES.donate;
  becomeVolunteerLink = PAGE_ROUTES.becomeVolunteer;
  becomePartnerLink = PAGE_ROUTES.becomePartner;
  organizeFundraisingLink = PAGE_ROUTES.organizeFundraising;
  ngOnInit(): void {}
}
