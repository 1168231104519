import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          height: 0,
          opacity: 0,
        }),
      ),
      transition('* <=> void', [animate('500ms ease-in')]),
    ]),
  ],
})
export class FilterComponent implements OnInit {
  @Input() selectedFilter!: string;
  @Input() ListArray!: string[];
  @Output() chosenValue = new EventEmitter<string>();

  public choosedElem = '';
  public showList = false;
  constructor() {}

  ngOnInit(): void {
    this.choosedElem = this.ListArray[0];
    this.chosenValue.emit(this.choosedElem);
  }

  changeValue(opt: string) {
    this.ListArray.unshift(opt);
    this.ListArray = Array.from(new Set(this.ListArray));
    this.choosedElem = opt;
    this.chosenValue.emit(this.choosedElem);
    this.closeDropDown();
  }

  public openDropDown() {
    if (!this.showList) {
      this.showList = true;
    } else {
      this.closeDropDown();
    }
  }

  public closeDropDown() {
    this.showList = false;
  }
}
