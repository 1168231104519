<div class="max-w-full bg-primary-base">
  <div
    class="flex flex-row mx-auto relative h-full bg-primary-base bg-nezabutkaFlower360 md:bg-nezabutkaFlower960 xl:bg-nezabutkaFlower bg-no-repeat bg-[top_left_44px] md:bg-[top_right_10px] xl:bg-[top_right_14rem]">
    <div
      class="flex flex-row content-container justify-start items-center text-gray-white">
      <div class="flex flex-col items-start ml-4 md:ml-0 my-30 xl:my-26">
        <h2
          class="text-h2-360 md:text-h2-heading w-82 md:w-full pb-5 uppercase h-fit">
          {{ bannerData.h2 }}
        </h2>
        <p class="w-82 md:w-210 xl:w-195 text-smaller-main md:text-main-text">
          {{ bannerData.text }}
        </p>
      </div>
    </div>
  </div>
</div>
