import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface IBannerPage {
  id?: number | null;
  title: string;
  text: string;
  imgUrl: string;
  pageName: string;
  base64Image?: string;
  imageName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BannerPageService {
  private url: string =
    environment.apiUrl + '/Banner/GetBannerByPageName?pageName=';
  private adminUrl = environment.apiUrl + '/AdminPanel/UpdateBanner';
  headers = new HttpHeaders({
    Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')!),
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getBannersData(pageTitle: string): Observable<IBannerPage> {
    return this.http.get<IBannerPage>(this.url + encodeURIComponent(pageTitle));
  }

  createBannersData(
    pageTitle: string,
    newBannersPageData: IBannerPage,
  ): Observable<IBannerPage> {
    return this.http.post<IBannerPage>(this.url, newBannersPageData);
  }

  updateBannerData(data: any): Observable<any> {
    return this.http.put<any>(this.adminUrl, data);
  }

  deleteBannersData(id: number): Observable<string> {
    return this.http.delete<string>(`${this.url}?id=${id}`);
  }
}
