<div class="flex items-center justify-between content-container">
  <section class="flex pt-10 pb-20 gap-5">
    <div class="flex gap-5 gap-y-15 flex-wrap">
      <app-submenu-items-list
        *ngFor="let item of cards; let i = index"
        class="w-[380px]"
        [title]="item?.title"
        [links]="item.subMenuItems"
        [queryParams]="item.queryParams"
        [titleLink]="item.link"></app-submenu-items-list>
    </div>
  </section>
</div>
