<div
  class="bg-[url('src/assets/images/not-found/notFound-bg.png')] min-h-screen min-w-screen pt-19.75 bg-no-repeat bg-center bg-cover">
  <app-header [headerButtonVisible]="false"></app-header>
  <main class="pb-25">
    <div class="container max-w-295 mx-auto">
      <img
        class="mt-14 mb-22.25 ml-0.5"
        width="354"
        height="156"
        src="assets/images/svg-images/404-not-found/404.svg"
        alt="404" />
      <p
        class="text-not-found-title text-primary-base uppercase tracking-not_found_page mt-9 mb-5">
        Сторінку не знайдено
      </p>
      <p class="text-main-text text-gray-dark mt-6 mb-17">
        Ми вже працюємо над цим непорозумінням.
      </p>
      <div class="w-55">
        <app-link-button
          [title]="'На головну'"
          [linkButtonType]="'Primary'"
          routerLink="/">
        </app-link-button>
      </div>
    </div>
  </main>
</div>
