import { Component, OnInit } from '@angular/core';
import { ActivitiesPageService } from '../../../services/api/activities-page/activities-page.service';
import { ActivatedRoute } from '@angular/router';
import { ActivitiesModel } from '../../../admin/pages/activities-adm/activities';

@Component({
  selector: 'app-detailed-events',
  templateUrl: './detailed-events.component.html',
  styleUrls: ['./detailed-events.component.css'],
})
export class DetailedEventsComponent implements OnInit {
  eventId: number;
  detailedEvents!: ActivitiesModel;
  visitTemplate: boolean = false;
  constructor(
    private apiService: ActivitiesPageService,
    private route: ActivatedRoute,
  ) {
    this.eventId = this.route.snapshot.params['id'];
  }
  ngOnInit() {
    this.apiService.getEventById(this.eventId).subscribe(res => {
      this.detailedEvents = res;
    });
  }

  visitEvent() {
    if (this.detailedEvents.visitEventButtonUrl) {
      window.open(this.detailedEvents.visitEventButtonUrl, '_blank');
    } else {
      this.visitTemplate = true;
    }
  }
}
