import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItemsMock, SocialLinkMock } from 'src/mockData/menuMockData';
import { ISocialLink } from 'src/app/models/social-link.interface';
import IInfoPages from 'src/app/components/main-menu/submenu/submenu-about-dementia/submenu-about-dementia.component';
import { HttpClient } from '@angular/common/http';
import { PAGE_ROUTES } from '../../../constants/page_routes';

@Component({
  selector: 'app-mobile-main-menu',
  templateUrl: './mobile-main-menu.component.html',
  styleUrls: ['./mobile-main-menu.component.css'],
})
export class MobileMainMenuComponent implements OnInit {
  donateLink = PAGE_ROUTES.donate;
  isHamburguer = true;
  menuItems: any = MenuItemsMock;
  socialLink: ISocialLink[] = SocialLinkMock;
  constructor(private router: Router, private http: HttpClient) {}
  cards: IInfoPages[] = [];

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && !this.isHamburguer) {
        this.openClose();
      }
    });
  }

  public openClose() {
    this.isHamburguer = !this.isHamburguer;
    if (!this.isHamburguer) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }
}
