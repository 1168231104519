import { sumDonate } from 'src/app/components/forms/single-help/single-help.component';

export const sumUAHArray: sumDonate[] = [
  { value: '50' },
  { value: '100' },
  { value: '200', checked: true },
  { value: '500' },
  { value: '1000' },
];

export const sumEURArray: sumDonate[] = [
  { value: '25', checked: true },
  { value: '50' },
  { value: '100' },
  { value: '200' },
  { value: '500' },
];
export const sumUSDArray: sumDonate[] = [
  { value: '25', checked: true },
  { value: '50' },
  { value: '100' },
  { value: '200' },
  { value: '500' },
];

export const typeOfCurrency: string[] = ['uah', 'eur', 'usd'];
