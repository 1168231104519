import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IContactFormData } from 'src/app/models/contact-form.interface';
import { IUnsubscribeFormData } from 'src/app/pages/unsubscribe-page/unsubscribe-page.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private url: string = environment.apiUrl;
  constructor(private http: HttpClient) {}
  sendEmail(data: IContactFormData): Observable<any> {
    return this.http.post<any>(`${this.url}/Email/subscribe`, data, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response',
    });
  }

  unsubscribe(data: IUnsubscribeFormData): Observable<null> {
    return this.http.post<null>(`${this.url}/Email/unsubscribe`, data);
  }
}
