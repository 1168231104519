import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, map, of } from 'rxjs';

import { ActivitiesPageService } from '../services/api/activities-page/activities-page.service';

@Injectable({
  providedIn: 'root',
})
export class breadcumbDetailedEventResolver  {
  constructor(private apiService: ActivitiesPageService) {}
  fetchPressRelease(id: number | string): Observable<any> {
    return this.apiService.getEventById(id);
  }
  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<(any & { breadcrumb: string }) | null> {
    const pressReleaseId = route.paramMap.get('id');
    if (pressReleaseId) {
      return this.fetchPressRelease(pressReleaseId).pipe(
        map(data => {
          return {
            breadcrumb: data.title,
            ...data,
          };
        }),
      );
    } else return of(null);
  }
}
