import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  MonthData,
  MonthlyCardTextData,
} from 'src/app/models/monthly-reports.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReportsMonthlyService {
  url = environment.apiUrl + '/Report/';
  private adminUrl: string = environment.apiUrl + '/AdminPanel/';

  constructor(private http: HttpClient) {}

  getMonthlyReportsArray(): Observable<MonthData[]> {
    return this.http.get<MonthData[]>(this.url + 'GetAllMonthlyReports');
  }

  getMonthlyReportsPageData(): Observable<MonthlyCardTextData> {
    return this.http.get<MonthlyCardTextData>(
      this.url + 'GetAllMonthlyReports',
    );
  }

  updateMonthlyReport(data: any): Observable<any> {
    return this.http.put(this.adminUrl + 'UpdateMonthlyReport', data);
  }

  createMonthlyReport(data: any): Observable<any> {
    return this.http.post(this.adminUrl + 'AddMonthlyReport', data);
  }

  deleteMonthlyReport(data: MonthData): Observable<any> {
    return this.http.delete(
      this.adminUrl +
        `DeleteMonthlyReport?id=${data.id}&documentURL=${data.imgUrl}`,
    );
  }
}
