<app-page-banner-item [pageName]="pageName">
  <div class="pl-5 md:pl-0 flex flex-col text-gray-white" *ngIf="combinedData">
    <div class="flex flex-row items-center mt-2.5">
      <div class="mr-1">
        <svg
          width="30px"
          height="30px"
          icon="phone"
          iconCollection="button-icons-sprite"
          class="fill-accent-base w-6 h-6 md:w-7 md:h-7"></svg>
      </div>
      <div class="hidden md:block text-h1-heading font-semibold mr-2">
        {{ combinedData.banner_text.buttonName }}
      </div>
      <a
        [href]="combinedData.banner_text.buttonURL"
        class="block md:hidden text-h2-heading underline font-semibold mr-2"
        >{{ combinedData.banner_text.buttonName }}</a
      >
    </div>
    <div
      appPhoneLink
      [innerHtml]="combinedData.banner_text.bodyText"></div></div
></app-page-banner-item>

<section class="mt-30 px-[16px] md:px-0">
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="links"></app-table-of-content>
</section>

<section
  *ngIf="combinedData"
  [id]="combinedData.first_block.key"
  class="py-30 px-[16px] md:px-0 flex gap-5 content-container">
  <div class="flex flex-col gap-15">
    <div class="flex flex-col xl:flex-row gap-5">
      <div>
        <h2 class="text-h2-heading text-primary-base">
          {{ combinedData.first_block.title }}
        </h2>
        <div
          appPhoneLink
          class="text-gray-dark text-main-text mt-5"
          [innerHtml]="combinedData.first_block.bodyText"></div>
      </div>
    </div>
  </div>
</section>

<section
  *ngIf="combinedData"
  [id]="combinedData.second_block.key"
  class="bg-gray-card py-30 px-[16px] md:px-0">
  <div class="flex flex-col gap-15 content-container">
    <div>
      <h2 class="text-h2-heading text-primary-base">
        {{ combinedData.second_block.title }}
      </h2>
      <div
        appPhoneLink
        class="text-gray-dark text-main-text mt-5"
        [innerHtml]="combinedData.second_block.bodyText"></div>
      <ul class="list-none">
        <li *ngFor="let button of buttonsLink">
          <a [href]="button.buttonURL" target="_blank">
            <app-link-button
              [title]="button.buttonName"
              [linkButtonType]="'RightArrowBig'"
              [isHovered]="isHovered"></app-link-button
          ></a>
        </li>
      </ul>
    </div>
  </div>
</section>

<section
  *ngIf="combinedData"
  [id]="combinedData.third_block.key"
  class="py-30 px-[16px] md:px-0 flex gap-5 content-container">
  <div class="flex flex-col gap-15">
    <div class="flex flex-col xl:flex-row gap-5">
      <div>
        <h2 class="text-h2-heading text-primary-base">
          {{ combinedData.third_block.title }}
        </h2>
        <div class="text-gray-dark text-main-text mt-5">
          <a
            [href]="combinedData.third_block.buttonURL"
            class="md:hidden underline text-h2-heading text-accent-base"
            ><div class="mr-1 flex flex-row">
              <div class="mr-1">
                <svg
                  width="30px"
                  height="30px"
                  icon="phone"
                  iconCollection="button-icons-sprite"
                  class="fill-accent-base w-6 h-6 md:w-7 md:h-7"></svg>
              </div>
              <div>{{ combinedData.third_block.buttonName }}</div>
            </div>
          </a>
          <div class="hidden md:block text-h2-heading text-accent-base">
            <div class="mr-1 flex flex-row">
              <div class="mr-1">
                <svg
                  width="30px"
                  height="30px"
                  icon="phone"
                  iconCollection="button-icons-sprite"
                  class="fill-accent-base w-6 h-6 md:w-7 md:h-7"></svg>
              </div>
              <div>{{ combinedData.third_block.buttonName }}</div>
            </div>
          </div>
        </div>
        <div class="flex flex-col xl:flex-row gap-5">
          <div
            appPhoneLink
            class="text-gray-dark text-main-text mt-5"
            [innerHtml]="combinedData.third_block.bodyText"></div>
          <app-contact-form
            *ngIf="formsDataAgreement && formsData"
            [formsDataAgreement]="formsDataAgreement!"
            [formsData]="formsData!"></app-contact-form>
        </div>
        <div
          appPhoneLink
          class="flex items-stretch my-10 md:my-15"
          [innerHtml]="combinedData.third_block_second.bodyText"></div>
      </div>
    </div>
  </div>
</section>

<section
  *ngIf="combinedData"
  [id]="combinedData.fourth_block.key"
  class="bg-gray-card py-30 px-[16px] md:px-0">
  <div class="flex flex-col gap-15 content-container">
    <div>
      <h2 class="text-h2-heading text-primary-base">
        {{ combinedData.fourth_block.title }}
      </h2>
      <div
        appPhoneLink
        class="text-gray-dark text-main-text mt-5"
        [innerHtml]="combinedData.fourth_block.bodyText"></div>
      <ol class="list-none">
        <li>
          <a [href]="combinedData.fourth_block.buttonURL">
            <app-link-button
              [title]="combinedData.fourth_block.buttonName"
              [linkButtonType]="'RightArrowBig'"
              [isHovered]="isHovered"></app-link-button
          ></a>
        </li>
      </ol>
    </div>
  </div>
</section>
<app-more-info [moreInfos]="moreInfosAid" [mainText]="mainText"></app-more-info>
