import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { TextContentModel } from '../../services/api/text-content.service';

@Component({
  selector: 'app-send-request',
  templateUrl: './send-request.component.html',
  styleUrls: ['./send-request.component.css'],
})
export class SendRequestComponent implements OnInit {
  @Output() closePopUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeWindow: EventEmitter<any> = new EventEmitter<any>();
  @Input() formsData: any = {
    title: 'Дякуємо за звернення!',
    bodyText:
      "Ми розглянемо Ваш запит, і наш співробітник зв'яжеться з Вами протягом двох\n" +
      '    робочих днів.',
    imageURL: 'assets/images/email-sent1.svg',
  };
  constructor(private router: Router) {}
  onClosePopUp() {
    if (this.router.url.includes(PAGE_ROUTES.fundTeam)) {
      this.closePopUp.emit();
    } else {
      this.closeWindow.emit();
    }
  }
  onClose() {
    this.closeWindow.emit();
  }
  ngOnInit(): void {}
}
