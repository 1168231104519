import { Component, Input } from '@angular/core';
import { TextContentModel } from '../../../services/api/text-content.service';

@Component({
  selector: 'app-main-slide',
  templateUrl: './main-slide.component.html',
  styleUrl: './main-slide.component.css',
})
export class MainSlideComponent {
  active: boolean = false;
  @Input() bannerData!: TextContentModel;
  @Input() allBannersData!: TextContentModel[];
  @Input() index: number = 0;

  bgBannerImage(imageUrl: string) {
    return `url(${imageUrl})`;
  }
}
