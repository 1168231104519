import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { ROUTE_PART_NAMES } from 'src/app/constants/route_part_names';
import { ISocialLink } from 'src/app/models/social-link.interface';
interface SubMenuItem {
  id: string;
  title: string;
  link: string;
  dementiaMenuId?: number;
}

interface SubMenu {
  id?: number | string;
  title: string;
  link: string;
  titleLinkId?: string;
  subMenuItems?: SubMenuItem[] | any[];
  queryParams?: { subscribe: string };
}
interface MenuItem {
  name: string;
  link: string;
  component: string;
  subMenu?: SubMenu[];
  menuSubTitle?: any[];
  titleLinkId?: string;
}

export const MenuItemsMock: MenuItem[] = [
  {
    name: 'Головна',
    link: '/',
    component: 'about-dementia1',
  },

  {
    name: 'Про деменцію',
    link: PAGE_ROUTES.aboutDementia,
    component: 'about-dementia',
    subMenu: [
      {
        title: 'Що таке деменція?',
        link: PAGE_ROUTES.whatDementiaIs,
        subMenuItems: [
          {
            id: 'what-dementia-is-4047',
            title: 'Основне про деменцію',
            link: PAGE_ROUTES.whatDementiaIs,
            dementiaMenuId: 2023,
          },
          {
            id: 'what-dementia-is-4048',
            title: 'Стадії деменції',
            link: PAGE_ROUTES.whatDementiaIs,
            dementiaMenuId: 2023,
          },
        ],
      },
      {
        id: 2024,
        title: 'Типи деменції',
        link: PAGE_ROUTES.dementiaTypes,
        subMenuItems: [
          {
            id: 'dementia-types-4049',
            title: 'Поширені типи деменції',
            link: PAGE_ROUTES.dementiaTypes,
            dementiaMenuId: 2024,
          },
          {
            id: 'dementia-types-4050',
            title: 'Що таке рання деменція?',
            link: PAGE_ROUTES.dementiaTypes,
            dementiaMenuId: 2024,
          },
          {
            id: 'dementia-types-4051',
            title: "Пов'язані стани",
            link: PAGE_ROUTES.dementiaTypes,
            dementiaMenuId: 2024,
          },
        ],
      },
      {
        id: 2025,
        title: 'Діагностика та терапія',
        link: PAGE_ROUTES.diagnosis,
        subMenuItems: [
          {
            id: 'diagnosis-therapy-4052',
            title: 'Діагностика деменції',
            link: PAGE_ROUTES.diagnosis,
            dementiaMenuId: 2025,
          },
          {
            id: 'diagnosis-therapy-4053',
            title: 'Лікування та медична терапія',
            link: PAGE_ROUTES.diagnosis,
            dementiaMenuId: 2025,
          },
          {
            id: 'diagnosis-therapy-4054',
            title: 'Нефармакологічна терапія',
            link: PAGE_ROUTES.diagnosis,
            dementiaMenuId: 2025,
          },
          {
            id: 'diagnosis-therapy-4055',
            title: 'Альтернативні практики',
            link: PAGE_ROUTES.diagnosis,
            dementiaMenuId: 2025,
          },
        ],
      },
      {
        id: 2026,
        title: 'Зменшення ризику та профілактика',
        link: PAGE_ROUTES.prophylaxis,
        subMenuItems: [
          {
            id: 'prophylaxis-4047',
            title: 'Фактори ризику деменції',
            link: PAGE_ROUTES.prophylaxis,
            dementiaMenuId: 2026,
          },
          {
            id: 'prophylaxis-4048',
            title: 'Профілактика деменції',
            link: PAGE_ROUTES.prophylaxis,
            dementiaMenuId: 2026,
          },
        ],
      },
      {
        id: 2027,
        title: 'Часті питання',
        link: PAGE_ROUTES.faq,
        subMenuItems: [],
      },
      {
        id: 2028,
        title: 'Фотопроєкт "Ми є. Люди з деменцією"',
        link: PAGE_ROUTES.photoProject,
        subMenuItems: [],
      },
    ],
  },
  {
    name: 'Потрібна допомога?',
    link: PAGE_ROUTES.getSupport,
    component: 'get-support',
    subMenu: [
      {
        title: 'Допомога з ліками',
        link: PAGE_ROUTES.helpWithMedicine,
      },
      {
        title: 'Психологічна підтримка',
        link: PAGE_ROUTES.psychologicalSupport,
      },
      {
        title: 'Юридична допомога',
        link: PAGE_ROUTES.legalAssistance,
      },
      {
        title: 'Медична допомога',
        link: PAGE_ROUTES.medicalAssistance,
      },
      {
        title: 'Допомога з доглядом',
        link: PAGE_ROUTES.careConsultation,
      },
      {
        title: 'Гаряча лінія “Незабутні”',
        link: PAGE_ROUTES.informationalAssistance,
      },
      {
        title: 'Кризова допомога',
        link: PAGE_ROUTES.crisisAssistance,
        titleLinkId: 'crisis-assistance',
        subMenuItems: [
          {
            id: 'help_with_generators',
            title: 'Допомога з генераторами',
            link: PAGE_ROUTES.crisisAssistance,
          },
          {
            id: 'assistance_with_relocation',
            title: 'Допомога з переміщенням',
            link: PAGE_ROUTES.crisisAssistance,
          },
          {
            id: 'help_abroad',
            title: 'Допомога за кордоном',
            link: PAGE_ROUTES.crisisAssistance,
          },
        ],
      },
      {
        title: 'Що робити, якщо',
        link: PAGE_ROUTES.getSupport,
        titleLinkId: 'whatToDoIf',
        subMenuItems: [
          {
            id: '',
            title: 'Я турбуюся, що в мене деменція',
            link: PAGE_ROUTES.iWorryAboutMemory,
          },
          {
            id: '',
            title: 'У мене деменція',
            link: PAGE_ROUTES.IHaveDementia,
          },

          {
            id: '',
            title: 'Я доглядальник, родич або друг',
            link: PAGE_ROUTES.relativeHasDementia,
          },
        ],
      },
    ],
  },
  {
    name: 'Долучитися',
    link: PAGE_ROUTES.join,
    component: 'join',
    subMenu: [
      {
        queryParams: { subscribe: 'monthly' },
        title: 'Регулярний внесок',
        link: PAGE_ROUTES.donate,
      },
      {
        title: 'Стати волонтером',
        link: PAGE_ROUTES.becomeVolunteer,
      },
      {
        title: 'Стати партнером',
        link: PAGE_ROUTES.becomePartner,
      },
      {
        title: 'Організувати збір коштів',
        link: PAGE_ROUTES.organizeFundraising,
      },
    ],
  },
  {
    name: 'Заходи',
    link: ROUTE_PART_NAMES.activities,
    component: PAGE_ROUTES.activities,
  },
  {
    name: 'Про нас',
    link: PAGE_ROUTES.aboutUs,
    component: 'about-us',
    subMenu: [
      {
        title: 'Про фонд',
        link: PAGE_ROUTES.ourIdea,
        subMenuItems: [
          {
            id: '',
            title: 'Наша ідея',
            link: PAGE_ROUTES.ourIdea,
          },
          {
            id: 'second_block',
            title: 'Історія',
            link: PAGE_ROUTES.ourIdea,
          },
          {
            id: '',
            title: 'Звіти та документи',
            link: PAGE_ROUTES.reports,
          },
          {
            id: 'payment',
            title: 'Реквізити',
            link: PAGE_ROUTES.donate,
          },
          {
            id: '',
            title: 'Контакти',
            link: PAGE_ROUTES.contacts,
          },
        ],
      },
      {
        title: 'Наші люди',
        link: PAGE_ROUTES.ourPeople,
        subMenuItems: [
          {
            id: '',
            title: 'Команда фонду',
            link: PAGE_ROUTES.fundTeam,
          },

          {
            id: '',
            title: 'Наші партнери',
            link: PAGE_ROUTES.ourPartners,
          },
          {
            id: '',
            title: 'Вакансії',
            link: PAGE_ROUTES.vacancies,
          },
        ],
      },
      {
        title: 'Напрями діяльності',
        link: PAGE_ROUTES.ourPrograms,
        subMenuItems: [
          {
            id: '',
            title: 'Допомога та підтримка',
            link: PAGE_ROUTES.getSupport,
          },
          {
            id: '',
            title: 'Адвокація',
            link: PAGE_ROUTES.advocacy,
          },
          {
            id: '',
            title: 'Дружнє суспільство',
            link: PAGE_ROUTES.friendlySociety,
          },
          {
            id: '',
            title: 'Навчання',
            link: PAGE_ROUTES.study,
          },
          {
            id: '',
            title: 'Дослідження',
            link: PAGE_ROUTES.research,
          },
        ],
      },
      {
        title: 'Новини та медіа',
        link: PAGE_ROUTES.newsAndMedia,
        subMenuItems: [
          {
            id: '',
            title: 'Новини',
            link: PAGE_ROUTES.news,
          },
          {
            id: '',
            title: 'Статистика',
            link: PAGE_ROUTES.statistic,
          },
          {
            id: '',
            title: 'Медіацентр',
            link: PAGE_ROUTES.mediaCenter,
          },
          {
            id: '',
            title: 'ЗМІ про нас',
            link: PAGE_ROUTES.mediaAboutUs,
          },
        ],
      },
    ],
  },
];

export const SocialLinkMock: ISocialLink[] = [
  {
    link: 'https://www.instagram.com/nezabutni_/',
    image: 'instagram',
    width: '24px',
    height: '24px',
  },
  {
    image: 'facebook',
    link: 'https://www.facebook.com/nezabutni.org',
    width: '16px',
    height: '29px',
  },
  {
    image: 'youtube',
    link: 'https://www.youtube.com/channel/UCIhdADy2Q8xzImxhzQK549w/featured',
    width: '26px',
    height: '20px',
  },
  {
    image: 'telegram',
    link: 'https://t.me/NezabutniCF',
    width: '26',
    height: '22',
  },
  {
    image: 'viber',
    link: 'https://invite.viber.com/?g2=AQBrcrOiC1eYD0%2FwbncaUKw%2BDHJik%2FgYMT8bUv%2F0H3d28l1XNHIZLBdrDQKEoglQ',
    width: '26',
    height: '29',
  },
];
