<app-page-banner-item [pageName]="pageName"></app-page-banner-item>

<section class="grid mt-30 px-2 xl:px-0 sm:px-10" *ngIf="combinedData">
  <div
    class="content-container pb-10"
    appPhoneLink
    [innerHtml]="combinedData.after_banner_text.bodyText"></div>
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="links"></app-table-of-content>
</section>

<div class="wide-container" *ngIf="combinedData">
  <div
    [id]="combinedData.first_block.key"
    class="mx-auto content-container text-start px-4 md:px-0 py-30 sm:px-10">
    <div class="md:flex">
      <div class="">
        <img
          class="hidden md:block rounded-3xl md:ml-5 mt-5 md:mt-0 md:px-0 xl:w-[480px] xl:h-[600px] md:w-[410px] md:h-[512px] md:float-right object-cover"
          [alt]="combinedData.first_block.title"
          src="{{ combinedData.first_block.imageURL }}" />
        <h2 class="text-h2-heading uppercase text-primary-base mb-5">
          {{ combinedData.first_block.title }}
        </h2>
        <div
          class="text-main-text text-gray-dark mb-5 w-full"
          appPhoneLink
          [innerHTML]="combinedData.first_block.bodyText"></div>
        <div
          class="w-55 mt-10"
          *ngIf="
            combinedData.first_block.buttonURL &&
            combinedData.first_block.buttonURL.length
          ">
          <a
            class="flex text-main-text font-semibold justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer"
            href="{{ combinedData.first_block.buttonURL }}"
            target="_blank"
            >{{ combinedData.first_block.buttonName }}
          </a>
        </div>
      </div>
      <div class="flex justify-center">
        <img
          class="block md:hidden rounded-3xl md:ml-5 sm:mr-10 mt-5 md:mt-0 md:px-0 w-[328px] h-[410px] object-cover"
          [alt]="combinedData.first_block.title"
          src="{{ combinedData.first_block.imageURL }}" />
      </div>
    </div>
  </div>
</div>

<div class="wide-container bg-gray-card" *ngIf="combinedData">
  <div
    [id]="combinedData.second_block.key"
    class="mx-auto content-container text-start py-30">
    <div class="md:flex">
      <img
        class="hidden md:block rounded-3xl md:mr-5 mt-5 md:mt-0 md:px-0 xl:w-[480px] xl:h-[600px] md:w-[410px] md:h-[512px] md:float-left object-cover"
        [alt]="combinedData.second_block.title"
        src="{{ combinedData.second_block.imageURL }}" />
      <div class="px-5 md:px-0">
        <h2
          class="text-h2-heading uppercase text-primary-base md:mt-0 mt-10 mb-5">
          {{ combinedData.second_block.title }}
        </h2>
        <div
          class="text-main-text text-gray-dark mb-5 w-full"
          appPhoneLink
          [innerHTML]="combinedData.second_block.bodyText"></div>
        <div
          class="w-55 mt-10"
          *ngIf="
            combinedData.second_block.buttonURL &&
            combinedData.second_block.buttonURL.length
          ">
          <a
            class="flex text-main-text font-semibold justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer"
            href="{{ combinedData.second_block.buttonURL }}"
            target="_blank"
            >{{ combinedData.second_block.buttonName }}
          </a>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <img
        class="block md:hidden rounded-3xl md:ml-5 sm:mr-10 mt-5 md:mt-0 md:px-0 w-[328px] h-[410px] object-cover"
        [alt]="combinedData.second_block.title"
        src="{{ combinedData.second_block.imageURL }}" />
    </div>
  </div>
</div>

<div class="wide-container" *ngIf="combinedData">
  <div
    [id]="combinedData.third_block.key"
    class="mx-auto content-container text-start px-4 py-30 md:px-0 sm:px-10">
    <div class="md:flex">
      <div class="">
        <img
          class="hidden md:block rounded-3xl md:ml-5 mt-5 md:mt-0 md:px-0 xl:w-[480px] xl:h-[600px] md:w-[410px] md:h-[512px] md:float-right object-cover"
          [alt]="combinedData.third_block.title"
          src="{{ combinedData.third_block.imageURL }}" />
        <h2 class="text-h2-heading uppercase text-primary-base mb-5">
          {{ combinedData.third_block.title }}
        </h2>
        <div
          class="text-main-text text-gray-dark mb-5 w-full"
          appPhoneLink
          [innerHTML]="combinedData.third_block.bodyText"></div>
        <div
          class="w-55 mt-10"
          *ngIf="
            combinedData.third_block.buttonURL &&
            combinedData.third_block.buttonURL.length
          ">
          <a
            class="flex text-main-text font-semibold justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer"
            href="{{ combinedData.third_block.buttonURL }}"
            target="_blank"
            >{{ combinedData.third_block.buttonName }}
          </a>
        </div>
      </div>
      <div class="flex justify-center">
        <img
          class="block md:hidden rounded-3xl md:ml-5 sm:mr-10 mt-5 md:mt-0 md:px-0 w-[328px] h-[410px] object-cover"
          [alt]="combinedData.third_block.title"
          src="{{ combinedData.third_block.imageURL }}" />
      </div>
    </div>
  </div>
</div>

<div class="flex flex-col content-container -mt-2 pb-30">
  <div
    *ngIf="combinedData"
    [id]="combinedData.fourth_block.key"
    class="h-32.5 md:h-12 flex flex-col md:flex-row px-4 md:px-0 items-start justify-between md:items-center xl:items-end mb-8">
    <h2 class="w-full text-h2-heading text-primary-base uppercase pb-2">
      {{ combinedData.fourth_block.title }}
    </h2>
    <div class="w-44 group/arrow">
      <app-link-button
        [linkButtonType]="'RightArrow'"
        [title]="combinedData.fourth_block.buttonName"
        titleLinkId="become-volunteer"
        appScrollToSectionAfterLoadData
        scrollToSection="{{ 'volonteersBlock' }}"
        [routerLink]="combinedData.fourth_block.buttonURL"></app-link-button>
    </div>
  </div>
  <app-adaptive-slider>
    <div
      class="flex justify-between items-center content-container mb-5 md:mb-10 xl:mb-0 gap-5">
      <app-volunteer-card-item
        *ngFor="let member of volunteers"
        text="{{ member.text }}"
        title="{{ member.title }}"
        [imgUrl]="member.imgUrl"></app-volunteer-card-item>
    </div>
  </app-adaptive-slider>
</div>
