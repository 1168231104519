import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-our-people',
  templateUrl: './our-people.component.html',
  styleUrls: ['./our-people.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OurPeopleComponent {
  cardsTag = 'about-us/our-people';
  pageName = 'our-people';
}
