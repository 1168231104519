import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ScrollService } from 'src/app/services/scroll.service';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import {
  IAboutDementiaSecondLevelPage,
  ISecondLevelPageCard,
  ISecondPageSubtitle,
} from 'src/app/models/aboutDementia.interface';
import { AboutDementiaSecondLevelPageService } from 'src/app/services/api/aboutDementiaPages/about-dementia-second-level-page.service';
import { ROUTE_PART_NAMES } from 'src/app/constants/route_part_names';
@Component({
  selector: 'app-relative-has-dementia',
  templateUrl: './relative-has-dementia.component.html',
  styleUrls: ['./relative-has-dementia.component.css'],
})
export class RelativeHasDementiaComponent implements OnInit, OnDestroy {
  lightText = '';
  darkText = '';
  cards: any[] = [
    {
      id: 'relative-has-dementia-4047',
      title: 'Як підготуватися до майбутнього',
      link: PAGE_ROUTES.relativeHasDementia,
      dementiaMenuId: 2023,
    },
    {
      id: 'jrelative-has-dementia-4048',
      title: 'Як подбати про себе',
      link: PAGE_ROUTES.relativeHasDementia,
      dementiaMenuId: 2023,
    },
    {
      id: 'relative-has-dementia-4050',
      title: 'Як реагувати на зміну поведінки у людини з деменцією',
      link: PAGE_ROUTES.relativeHasDementia,
      dementiaMenuId: 2023,
    },
    {
      id: 'relative-has-dementia-4051',
      title: 'Як взаємодіяти з близьким, що живе з деменцією',
      link: PAGE_ROUTES.relativeHasDementia,
      dementiaMenuId: 2023,
    },
    {
      id: 'relative-has-dementia-4052',
      title: 'Організація догляду вдома',
      link: PAGE_ROUTES.relativeHasDementia,
      dementiaMenuId: 2023,
    },
    {
      id: 'relative-has-dementia-4053',
      title: 'Фахова допомога з доглядом',
      link: PAGE_ROUTES.relativeHasDementia,
      dementiaMenuId: 2023,
    },
  ];
  sectionCards: any[] = [];
  currentBreadcrumb: string | null = 'second';
  isLoaded = false;
  private routeSubscription: Subscription | null = null;
  pageName: string =
    'get-support/what-to-do-if/i-am-a-caregiver,-a-sibling-or-a-friend';

  constructor(
    private apiService: AboutDementiaSecondLevelPageService,
    private route: ActivatedRoute,
    private router: Router,
    private scrollService: ScrollService,
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(params => {
      const routeId = params['secondId'];
      this.router.navigate([], { state: { id: routeId } });
      this.fetchCardList()
        .pipe(
          tap((data: IAboutDementiaSecondLevelPage) => {
            if (!data) {
              this.router.navigate([ROUTE_PART_NAMES.pageNotFound]);
              return;
            }
            this.lightText = data.contentLight;
            this.darkText = data.contentDark;
            this.sectionCards = data.secondPageSubtitles.map(
              (el: ISecondPageSubtitle) => ({
                subMenuCards: [
                  ...el.secondLevelPageCards.map(
                    (card: ISecondLevelPageCard) => ({
                      orderId: card.orderId,
                      link:
                        PAGE_ROUTES.relativeHasDementia +
                        '/' +
                        card.linkTranslit,
                      cardTitle: card.cardText,
                    }),
                  ),
                ],
              }),
            );
            this.currentBreadcrumb = data.menuTitle;
            const element = document.querySelector(
              '[data-breadcrumb="second"]',
            );
            if (element) {
              element.innerHTML = data.menuTitle;
            }
            // this.scrollService.setLoaded(true);
          }),
        )
        .subscribe();
    });
  }

  fetchCardList(): Observable<IAboutDementiaSecondLevelPage> {
    return this.apiService.getData('relativeHasDementia');
  }
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
