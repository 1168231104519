import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  TextContentModel,
  TextContentService,
} from '../../../../services/api/text-content.service';

interface IAccordionList {
  title: string;
  HTML: SafeHtml;
}

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.css'],
})
export class VacanciesComponent implements OnInit {
  pageName: string = 'about-us/our-people/vacancies';
  vacanciesList: IAccordionList[] = [];
  pageText!: TextContentModel;

  constructor(
    private sanitizer: DomSanitizer,
    private readonly contentService: TextContentService,
  ) {}

  ngOnInit(): void {
    this.getTextContent();
  }

  getTextContent() {
    this.contentService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        res.sort((a, b) => a.id - b.id);
        this.pageText = res.find(el => (el.key = 'page-text'))!;
        this.vacanciesList = res
          .map(vacancy => ({
            id: vacancy.id,
            title: vacancy.title,
            HTML: this.sanitizer.bypassSecurityTrustHtml(vacancy.bodyText),
            key: vacancy.key,
            tag: vacancy.pageName,
            orderId: vacancy.orderId,
          }))
          .filter(el => el.key === 'accordion')
          .sort((a, b) => a.orderId! - b.orderId!);
      });
  }
}
