<div class="text-center justify-center justify-items-center mt-30">
  <h3 class="text-h2-heading text-primary-base mb-5">
    {{ title | uppercase }}
  </h3>
  <p class="text-lg text-gray-dark main-text items-center px-20 mb-10">
    {{ text }}
  </p>
  <button
    class="w-55 px-2 text-gray-white rounded-10 bg-accent-base focus:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-dark py-2.5 bg-accent mb-30">
    {{ button }}
  </button>
</div>
