import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { sumDonate } from 'src/app/components/forms/single-help/single-help.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-summ-donate',
  templateUrl: './summ-donate.component.html',
  styleUrls: ['./summ-donate.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummDonateComponent implements OnChanges {
  @Input() sumControl: FormControl = new FormControl();
  @Input() sumArray!: sumDonate[];
  sumValue: string | number = 'Інша сума';
  inputActive: boolean = false;
  constructor() {}

  public setSumValue(elem: Event, chosed: sumDonate): void {
    this.inputFalse(chosed.value);
    this.removeCheckedValue();
    chosed.checked = true;
  }

  private removeCheckedValue(): sumDonate[] {
    this.sumArray.forEach((el: sumDonate) => {
      el.checked = false;
    });
    return this.sumArray;
  }

  @HostListener('document:click', ['$event'])
  checkTarget(event: Event) {
    const el = event.target as HTMLElement;
    if (el.id !== 'summInput') {
      this.inputActive = false;
      if (this.sumValue == '') {
        this.sumValue = 'Інша сума';
      }
    }
  }

  public active(): void {
    this.inputActive = true;
    this.removeCheckedValue();
    if (this.sumValue == 'Інша сума') {
      this.sumValue = '';
    }
    this.sumControl.setValue(this.sumValue);
  }

  inputFalse(sum: string) {
    this.sumValue = 'Інша сума';
    this.inputActive = false;
    this.sumControl.setValue(sum);
  }

  public changeVal(event: Event): void {
    const val = event.target as HTMLInputElement;
    val.value = this.checkString(val.value);
    this.sumControl.setValue(val.value);
  }

  // if user will paste copied value
  checkString(string: string): string {
    if (
      string.includes('e') ||
      string[0] === 'e' ||
      string.includes('-') ||
      string[0] === '-' ||
      string.includes('+') ||
      string[0] === '+'
    ) {
      string = this.sumValue = '0';
    }
    return string;
  }

  private checkCheckedValue(array: sumDonate[]): string {
    let chosedSum = '0';
    array.forEach((el: sumDonate) => {
      if (el.checked) {
        chosedSum = el.value;
      }
    });
    return chosedSum;
  }

  public numberOnly(event: KeyboardEvent) {
    if (
      event.key === 'e' ||
      event.key === '+' ||
      event.key === '-' ||
      event.key === ',' ||
      event.key === '.'
    ) {
      return false;
    }
    return true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.inputFalse(this.checkCheckedValue(this.sumArray));
  }
}
