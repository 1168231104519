import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'app-pagination-media-about-us',
  templateUrl: './pagination-media-about-us.component.html',
  styleUrls: ['./pagination-media-about-us.component.css'],
})
export class PaginationMediaAboutUsComponent implements OnInit {
  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
