import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supervisory-board',
  templateUrl: './supervisory-board.component.html',
  styleUrls: ['./supervisory-board.component.css'],
})
export class SupervisoryBoardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
