import {
  Component,
  Output,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-editor-menu',
  templateUrl: './editor-menu.component.html',
  styleUrls: ['./editor-menu.component.css'],
})
export class EditorMenuComponent {
  @ContentChild('switchButton') switchButton!: ElementRef;
  @Input() selected!: string;
  @Input() text!: string;
  @Output() edditedText: EventEmitter<string> = new EventEmitter();
  @Output() switchToggle: EventEmitter<boolean> = new EventEmitter();
  @Output() allText: EventEmitter<string> = new EventEmitter();
  @Output() addBreack: EventEmitter<boolean> = new EventEmitter();
  checked = false;

  public Change(el: HTMLInputElement) {
    this.checked = !this.checked;
    this.switchToggle.emit(el.checked);
  }

  public addBoldTag() {
    this.selected = '<b>' + this.selected + '</b>';
    this.edditedText.emit(this.selected);
  }

  public addUnderline() {
    this.selected = '<u>' + this.selected + '</u>';
    this.edditedText.emit(this.selected);
  }

  public addItalic() {
    this.selected = '<i>' + this.selected + '</i>';
    this.edditedText.emit(this.selected);
  }

  public makeStd() {
    const newText = this.text.replace(/<\/?(b|u|i)\b[^>]*>|<br>/gi, '');
    this.allText.emit(newText);
  }

  public addBrTag() {
    this.addBreack.emit(true);
  }
}
