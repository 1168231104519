<div *ngIf="isLoaded; else loader">
  <app-main-slider *ngIf="bannersArray.length && allBannersData.length">
    <app-main-slide
      *ngFor="let banner of bannersArray; let i = index"
      [index]="i"
      [bannerData]="banner"
      [allBannersData]="allBannersData"></app-main-slide>
  </app-main-slider>

  <app-about-dementia-content
    [textData]="aboutDementiaTextBlock"
    [tabData]="aboutDementiaTabBlock"></app-about-dementia-content>

  <app-prevention-block
    [preventionTextBlock]="preventionTextBlock"
    [preventionCardsBlock]="preventionCardsBlock"></app-prevention-block>

  <app-about-block [aboutFondTextBlock]="aboutFondTextBlock"></app-about-block>

  <app-our-influence
    [ourAchievementsTextBlock]="ourAchievementsTextBlock"
    [ourAchievementsCardsBlock]="ourAchievementsCardsBlock"></app-our-influence>

  <app-our-projects-block
    [textContentData]="textContentData"></app-our-projects-block>

  <app-help-block [type]="'primary'" [pageName]="'main'"></app-help-block>

  <app-block-news></app-block-news>

  <app-subscribe-news></app-subscribe-news>
</div>

<ng-template #loader>
  <div class="pt-30">
    <div
      class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full gradient-animation"></div>
      </div>
    </div>
  </div>
</ng-template>
