<div class="flex text-main-text-bold relative">
  <input class="hidden" id="select" type="text" value="{{ choosedElem }}" />
  <div class="flex relative w-16 md:w-18 h-10">
    <div
      (mouseenter)="showList = true"
      (mouseleave)="showList = false"
      class="flex flex-col border-2 border-gray-white hover:border-2 hover:border-gray-stroke rounded-10 bg-gray-white z-10 absolute transition:all">
      <div
        class="flex flex-row border-gray-white hover:border-gray-stroke md:py-2 px-1 cursor-pointer w-18"
        for="select">
        <div class="w-10">{{ choosedElem | uppercase }}</div>
        <div class="w-5 h-5 flex justify-center pt-[12px]">
          <span class="triangle"></span>
        </div>
      </div>
      <ng-container *ngIf="showList">
        <div
          (click)="changeValue(opt)"
          *ngFor="
            let opt of ListArray | slice : 1 : ListArray.length;
            let i = index
          "
          class="flex flex-row border-t-2 border-gray-stroke cursor-pointer py-2 px-1 hover:text-secondary-base hover:bg-gray-card">
          {{ opt | uppercase }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
