<app-page-banner-item [pageName]="pageName"></app-page-banner-item>

<div class="content-container" *ngIf="pageText; else loader">
  <div class="text-gray-dark text-main-text px-4 md:px-0">
    <div class="flex flex-col justify-center items-start mt-30 mb-18">
      <div class="flex flex-col items-start pb-11">
        <h2
          *ngIf="pageText.title"
          class="text-h2-heading text-primary-base pb-8 uppercase">
          {{ pageText.title }}
        </h2>
        <div
          class="text-main-text"
          appPhoneLink
          [innerHTML]="pageText.bodyText"></div>
      </div>
    </div>
    <app-accordion
      *ngIf="vacanciesList.length; else emptyBlock"
      [AccordionsList]="vacanciesList"
      [classList]="'mb-30'"></app-accordion>
  </div>
</div>

<ng-template #emptyBlock>
  <div class="content-container my-15">
    Щойно у нас з’являться відкриті вакансії, ми опублікуємо їх на цій
    сторінці...
  </div></ng-template
>
<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>

<app-help-block [type]="'primary'" class="pt-30 block"></app-help-block>
