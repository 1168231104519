<form
  [formGroup]="form"
  class="subscribe-wrap flex flex-col justify-between h-full">
  <div class="flex flex-col pt-1">
    <h3 class="text-smaller-bold md:text-main-text-bold mb-5">
      {{ subscribeFormText!.title }}
    </h3>
    <div
      class="text-details md:text-main-text mb-7 w-155"
      appPhoneLink
      [innerHtml]="subscribeFormText!.bodyText"></div>
    <div class="flex flex-row justify-start items-center flex-wrap mb-7">
      <label
        for="{{ item.title }}"
        class="w-75 h-25 my-2.5 md:odd:mr-5 cursor-pointer flex justify-center items-center border-2 border-gray-stroke rounded-30 p-5"
        [ngClass]="{
          'border-primary-base': itemBank.checked,
          'hover:border-secondary-base': !itemBank.checked
        }"
        *ngFor="let item of banksFormData"
        (click)="clickBankingSubscribe(item)">
        <img
          class="w-55 h-12 object-contain"
          src="{{ item.imageURL }}"
          alt="{{ item.title }}"
          [title]="item.title" />
        <input
          #itemBank
          id="{{ item.title }}"
          class="hidden"
          type="radio"
          formControlName="bankName"
          name="bankName"
          value="{{ item.buttonURL }}" />
      </label>
    </div>
  </div>
  <ng-container *ngIf="bankChecked">
    <div class="flex flex-col">
      <div
        class="text-details md:text-main-text"
        appPhoneLink
        [innerHtml]="selectedBankText"></div>
      <div
        class="flex flex-col md:flex-row items-center md:items-start justify-between mt-5">
        <a
          [href]="findPdfInstruction()"
          target="_blank"
          class="w-72 my-2 md:my-0 md:w-75 flex justify-center text-main-text font-semibold py-2 border-2 bg-transparent text-accent-base hover:text-secondary-base active:text-secondary-additional disabled:text-gray-gray2 rounded-10 border-accent-base duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer"
          >Відкрити інструкцію</a
        >
        <a
          class="md:ml-2.5 w-72 md:w-75"
          [href]="this.form.controls['bankName'].value"
          target="_blank">
          <app-button
            class="w-72 md:w-75"
            (click)="refreshData()"
            [buttonType]="'Primary'"
            [disabled]="!form.valid"
            [title]="'Оформити підписку'"></app-button>
        </a>
      </div>
    </div>
  </ng-container>
</form>
