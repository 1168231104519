<app-page-banner-item [pageName]="pageName"></app-page-banner-item>
<section
  class="py-30 px-4 md:px-15 xl:px-0"
  *ngIf="combinedData && links.length; else loader">
  <div>
    <app-table-of-content [links]="links"></app-table-of-content>
  </div>

  <div id="{{ links[0].id }}" class="content-container mt-30">
    <h2 class="text-h2-360 md:text-h2-heading text-primary-base mb-10">
      {{ combinedData.research.title | uppercase }}
    </h2>
    <app-research-card [researchCards]="publicationsArray"></app-research-card>
    <div class="flex justify-start xl:justify-center">
      <app-button
        *ngIf="
          combinedData.research.fundResearchSecond.length > 3 &&
          publicationsArray.length !==
            combinedData.research.fundResearchSecond.length
        "
        type="submit"
        (click)="showMore()"
        class="w-56 text-main-text-bold mb-15 mt-10"
        [buttonType]="'Secondary'"
        [title]="'Дивитись більше'">
      </app-button>
    </div>
  </div>

  <div id="{{ links[1].id }}" class="content-container mt-30">
    <h2 class="text-h2-360 md:text-h2-heading text-primary-base mb-5">
      {{ combinedData.textContent[0].title | uppercase }}
    </h2>
    <div
      class="text-main-text text-gray-dark mb-10"
      [innerHtml]="combinedData.textContent[0].bodyText"></div>
    <app-other-publications-card
      [cards]="combinedData.otherPublication"></app-other-publications-card>
  </div>
</section>

<app-current-research-info
  *ngIf="combinedData && links.length; else loader"
  [linkId]="links[2]"
  [blockData]="combinedData.textContent[1]"></app-current-research-info>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
