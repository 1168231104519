<div
  *ngIf="edit"
  class="absolute right-0 -top-15 flex gap-5 cursor-pointer text-center border-2 border-gray-card rounded-10 p-2 mb-2">
  <div
    (click)="saveBlock(showSubmitState ? formsData : formsDataAgreement)"
    class="lowercase text-main-text text-gray-white cursor-pointer text-center w-10 h-10 rounded-full bg-primary-base border-2 border-gray-white p-1 hover:opacity-80"
    title="Зберегти текстовий блок">
    🖫
  </div>
  <div
    *ngIf="!showSubmitState && !isEditFormData"
    (click)="isEditFormData = true"
    class="p-2 text-main-text text-gray-details rounded-full text-[40px] w-10 h-10 bg-gray-card flex items-center justify-center hover:opacity-80"
    title="Редагувати текст форми">
    ✎
  </div>
  <div
    *ngIf="showSubmitState && !isEditSubmittedData"
    (click)="isEditSubmittedData = true"
    class="p-2 text-main-text text-gray-details rounded-full text-[40px] w-10 h-10 bg-gray-card flex items-center justify-center hover:opacity-80"
    title="Редагувати вікно після відправки">
    ✎
  </div>
  <div
    (click)="showSubmitState = !showSubmitState"
    class="p-2 text-main-text text-gray-dark rounded-full text-[40px] w-10 h-10 bg-gray-card flex items-center justify-center hover:opacity-80"
    title="Переключити на інший екран форми">
    <mat-icon *ngIf="!showSubmitState">chevron_right</mat-icon>
    <mat-icon *ngIf="showSubmitState">chevron_left</mat-icon>
  </div>
</div>
<form
  *ngIf="!showSubmitState"
  class="w-full md:w-[410px] xl:w-145 border-2 bg-white border-gray-stroke rounded-30 p-5 md:p-10 min-h-156"
  (ngSubmit)="onSubmit()"
  [formGroup]="contactForm">
  <div class="flex justify-between items-start relative">
    <app-custom-editor
      [ngClass]="{ relative: isEditSubmittedData }"
      (editedText)="changedText($event, 'formsDataAgreement')"
      [edit]="isEditFormData"
      [menu]="false"
      [removeBtn]="false">
      <h3
        #myElement
        class="text-h2-heading uppercase text-primary-base w-120 mb-10">
        {{ formsDataAgreement ? formsDataAgreement.title : title }}
      </h3>
      <span *ngIf="isEditFormData" class="absolute -top-2 -left-5">✎</span>
    </app-custom-editor>
    <svg
      *ngIf="showCross"
      width="19px"
      height="19px"
      [icon]="'close'"
      (click)="closeModal()"
      iconCollection="button-icons-sprite"
      class="text-gray-dark cursor-pointer"></svg>
  </div>
  <div *ngIf="!isEditFormData" class="text-main-text text-gray-dark">
    <div class="mb-5">
      <app-input-adm
        [control]="contactForm.controls.name"
        [labelName]="'Ваше імʼя'"
        [maxLength]="'60'"
        inputID="contactName"
        class="w-full"></app-input-adm>
    </div>
    <div class="mb-5">
      <app-input-adm
        [control]="contactForm.controls.email"
        [labelName]="'E-mail'"
        type="email"
        inputID="contactEmail"
        class="w-full"></app-input-adm>
    </div>

    <div class="mb-10">
      <app-text-area-adm
        [control]="contactForm.controls.message"
        [maxLength]="'2000'"
        [labelName]="'Текст повідомлення'"
        [textAreaID]="'message'"></app-text-area-adm>
    </div>
  </div>

  <div class="flex mb-5" *ngIf="!isEditFormData">
    <div class="flex">
      <label class="flex" for="checkbox">
        <input
          type="checkbox"
          name=""
          id="checkbox"
          class="appearance-none peer"
          formControlName="policy" />
        <span
          class="w-8 h-8 grid place-items-center border-2 border-gray-stroke rounded-8 cursor-pointer peer-checked:bg-primary-additional peer-checked:border-0 peer-focus:border-0 peer-focus:shadow-checkbox peer-checked:peer-focus:shadow-none peer-invalid:border-attention-base"
          [ngClass]="{
            'bg-primary-base': contactForm.get('policy')?.valid,
            'border-attention-base border':
              contactForm.get('policy')?.invalid &&
              contactForm.get('policy')?.touched
          }">
          <svg
            width="26px"
            height="26px"
            [icon]="'checkBox'"
            iconCollection="button-icons-sprite"
            class="fill-gray-white"></svg>
        </span>
      </label>
      <div
        class="ml-3.75 text-details text-gray-dark"
        [innerHtml]="formsDataAgreement.bodyText"></div>
    </div>
  </div>
  <div class="relative">
    <div
      *ngIf="isEditFormData"
      (click)="copyToClickboard()"
      class="absolute -top-10 right-0 rounded-full w-7.5 h-7.5 cursor-help text-gray-white bg-accent-additional hover:bg-secondary-base flex justify-center items-center"
      title="Посилання на файл в випадку проблем з відкриттям у новому вікні - https://nezabutni.org/assets/pdf/terms_and_conditions.pdf, натисніть щоб скопіювати">
      🛈
    </div>
    <app-html-editor
      *ngIf="isEditFormData"
      [edit]="edit"
      [text]="formsDataAgreement.bodyText"
      (editedHtml)="
        editedText($event, 'isEditFormData', 'formsDataAgreement')
      "></app-html-editor>
  </div>
  <app-link-button
    *ngIf="!isEditFormData"
    [title]="'Відправити'"></app-link-button>
</form>

<div
  *ngIf="showSubmitState"
  class="w-full md:w-[410px] flex flex-col justify-between xl:w-145 border-2 bg-white border-gray-stroke rounded-30 p-2 md:p-10 min-h-156">
  <div>
    <div class="flex justify-between items-center relative">
      <app-custom-editor
        [ngClass]="{ relative: isEditSubmittedData }"
        (editedText)="changedText($event, 'formsData')"
        [edit]="isEditSubmittedData"
        [menu]="false"
        [removeBtn]="false">
        <h3
          #myElement
          class="text-h2-heading uppercase text-primary-base w-120">
          {{ formsData.title }}
        </h3>
        <span *ngIf="isEditSubmittedData" class="absolute -top-2 -left-5"
          >✎</span
        >
      </app-custom-editor>
    </div>

    <div
      class="text-main-text text-gray-dark mt-5 mb-10"
      *ngIf="!isEditSubmittedData"
      [innerHtml]="formsData.bodyText"></div>
    <app-html-editor
      *ngIf="isEditSubmittedData"
      [edit]="edit"
      [text]="formsData.bodyText"
      (editedHtml)="
        editedText($event, 'isEditSubmittedData', 'formsData')
      "></app-html-editor>
    <div class="flex items-center justify-center relative">
      <div
        *ngIf="isEditSubmittedData"
        class="absolute top-0 right-16 rounded-full w-7.5 h-7.5 cursor-help text-gray-white bg-accent-additional hover:bg-secondary-base flex justify-center items-center"
        title="Рекомендований розмір зображення 308px*308px, або в тих самих пропорціях">
        🛈
      </div>
      <app-image-reader
        (updatedImg)="changedImage($event)"
        (uploadedFile)="uploadedFile($event)"
        [edit]="isEditSubmittedData"
        [classList]="
          'w-[308px] h-[308px] overflow-hidden flex object-cover rounded-30'
        "
        [imgUrl]="formsData.imageURL">
        <img
          class="w-[308px] h-[308px] overflow-hidden flex object-cover rounded-30"
          src="{{ formsData.imageURL }}"
          alt="{{ formsData.title }}" />
      </app-image-reader>
    </div>
  </div>
  <app-link-button
    *ngIf="!isEditSubmittedData"
    [title]="'Надіслати ще лист'"></app-link-button>
</div>
