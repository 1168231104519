import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';

export interface DocsReportModel {
  id: number | null;
  title: string;
  documentUrl: string;
  type: string;
  orderId: number;
  file?: File;
}

@Injectable({
  providedIn: 'root',
})
export class ReportsDocsService {
  private url = environment.apiUrl + '/Report/';
  private adminUrl: string = environment.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getReportsByType(type: string): Observable<DocsReportModel[]> {
    return this.http.get<DocsReportModel[]>(
      this.url + `GetReportsByType?type=${encodeURIComponent(type)}`,
    );
  }

  updateReportsOrder(data: DocsReportModel[]) {
    return this.http.put(this.adminUrl + 'UpdateReportOrder', data);
  }

  createReport(data: FormData) {
    return this.http.post(this.adminUrl + 'AddReport', data);
  }

  updateReport(data: FormData) {
    return this.http.put(this.adminUrl + 'UpdateReport', data);
  }

  deleteReport(data: { id: number; documentUrl: string }) {
    return this.http.delete(
      this.adminUrl +
        `DeleteReport?id=${data.id}&documentURL=${data.documentUrl}`,
    );
  }
}
