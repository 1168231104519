<app-page-banner-item [pageName]="pageName"> </app-page-banner-item>

<section
  class="mb-30 container pt-30 px-4 md:px-15 xl:px-0"
  *ngIf="links.length">
  <app-table-of-content [links]="links"></app-table-of-content>
</section>

<section class="py-30 px-4 md:px-15 xl:px-0" *ngIf="combinedData">
  <div class="content-container" id="help_with_generators">
    <h2
      [id]="combinedData.first_block.key"
      class="md:text-h2-heading text-h2-360 uppercase text-primary-base mb-5 md:w-145">
      {{ combinedData.first_block.title }}
    </h2>
    <div class="mb-30">
      <app-adaptive-page-slider
        *ngIf="imageListFirst.length"
        [skipLast]="false"
        [counterOfPages]="true"
        [amountOffCardsBlock]="imageListFirst.length"
        [hideButton]="[false, false, false]"
        class="hidden md:block relative w-full md:w-[410px] xl:w-[580px] overflow-hidden md:float-right">
        <div
          class="flex justify-start items-center ml-5 mb-5 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
          <img
            *ngFor="let image of imageListFirst"
            [src]="image.imageUrl"
            [alt]="image.imageUrl"
            class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[480px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[600px] object-cover rounded-[30px] border-2 border-gray-card" />
        </div>
      </app-adaptive-page-slider>
      <div appPhoneLink [innerHtml]="combinedData.first_block.bodyText"></div>
    </div>
    <a
      *ngIf="combinedData.first_block.buttonURL"
      [href]="combinedData.first_block.buttonURL"
      target="_blank"
      ><app-button
        [buttonType]="'Primary'"
        [title]="combinedData.first_block.buttonName"
        class="sm:w-55 flex justify-start items-center mt-10">
      </app-button
    ></a>
  </div>
  <div class="flex mt-10 md:hidden justify-center">
    <app-adaptive-page-slider
      *ngIf="imageListFirst.length"
      [skipLast]="false"
      [counterOfPages]="true"
      [amountOffCardsBlock]="imageListFirst.length"
      [hideButton]="[false, false, false]"
      class="relative w-full md:w-[410px] xl:w-[580px] overflow-hidden md:float-right">
      <div
        class="flex justify-start items-center ml-5 mb-5 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
        <img
          *ngFor="let image of imageListFirst"
          [src]="image.imageUrl"
          [alt]="image.imageUrl"
          class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[480px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[600px] object-cover rounded-[30px] border-2 border-gray-card" />
      </div>
    </app-adaptive-page-slider>
  </div>
</section>

<section class="bg-gray-card py-30 px-4 md:px-15 xl:px-0" *ngIf="combinedData">
  <div class="pb-5 content-container">
    <div id="assistance_with_relocation">
      <h2
        [id]="combinedData.second_block.key"
        class="md:text-h2-heading text-h2-360 uppercase text-primary-base mb-5 md:w-145">
        {{ combinedData.second_block.title }}
      </h2>
      <div class="">
        <app-adaptive-page-slider
          *ngIf="imageListSecond.length"
          [skipLast]="false"
          [counterOfPages]="true"
          [amountOffCardsBlock]="imageListSecond.length"
          [hideButton]="[false, false, false]"
          class="hidden md:block relative w-full md:w-[410px] xl:w-[580px] overflow-hidden md:float-right">
          <div
            class="flex justify-start items-center ml-5 mb-5 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
            <img
              *ngFor="let image of imageListSecond"
              [src]="image.imageUrl"
              [alt]="image.imageUrl"
              class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[480px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[600px] object-cover rounded-[30px] border-2 border-gray-card" />
          </div>
        </app-adaptive-page-slider>
        <div
          appPhoneLink
          [innerHtml]="combinedData.second_block.bodyText"></div>
      </div>
    </div>
    <div class="flex mt-10 md:hidden justify-center">
      <app-adaptive-page-slider
        *ngIf="imageListSecond.length"
        [skipLast]="false"
        [counterOfPages]="true"
        [amountOffCardsBlock]="imageListSecond.length"
        [hideButton]="[false, false, false]"
        class="relative w-full md:w-[410px] xl:w-[580px] overflow-hidden md:float-right">
        <div
          class="flex justify-start items-center ml-5 mb-5 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
          <img
            *ngFor="let image of imageListSecond"
            [src]="image.imageUrl"
            [alt]="image.imageUrl"
            class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[480px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[600px] object-cover rounded-[30px] border-2 border-gray-card" />
        </div>
      </app-adaptive-page-slider>
    </div>
  </div>
  <div id="" class="content-container">
    <div class="flex flex-col w-full md:flex-row gap-5 mt-20">
      <div class="w-full md:w-[410px] xl:w-[580px]">
        <h2 class="text-h3-subheading text-primary-base mb-5">
          {{ combinedData.second_block_second.title }}
        </h2>
        <div
          appPhoneLink
          [innerHtml]="combinedData.second_block_second.bodyText"></div>
      </div>
      <div
        class="flex justify-center md:block pt-5 md:pt-10"
        *ngIf="mapUrl.toString().length">
        <iframe
          [src]="mapUrl"
          class="rounded-30 w-[328px] md:w-[410px] xl:w-[580px] h-[307px] xl:h-[435px]"></iframe>
      </div>
    </div>

    <div class="mx-auto mt-20 w-full">
      <h2 class="text-h3-subheading text-primary-base mb-5">
        {{ combinedData.second_block_third.title }}
      </h2>
      <div
        appPhoneLink
        [innerHtml]="combinedData.second_block_third.bodyText"></div>
    </div>

    <div
      class="mx-auto mt-20 w-full"
      *ngIf="
        combinedData.second_block_third.buttonURL &&
        combinedData.second_block_third.buttonURL.length
      ">
      <a [href]="combinedData.second_block_third.buttonURL" target="_blank">
        <app-button
          [buttonType]="'Primary'"
          [title]="combinedData.second_block_third.buttonName"
          class="sm:w-55 flex justify-start items-center">
        </app-button
      ></a>
    </div>
  </div>
</section>

<section
  class="py-30 px-4 md:px-15 xl:px-0 xl:min-h-[1000px]"
  *ngIf="combinedData">
  <div class="content-container" id="help_abroad">
    <h2
      [id]="combinedData.third_block.key"
      class="md:text-h2-heading text-h2-360 uppercase text-primary-base mb-5 md:w-145">
      {{ combinedData.third_block.title }}
    </h2>
    <div class="">
      <app-adaptive-page-slider
        *ngIf="imageListThird.length"
        [skipLast]="false"
        [counterOfPages]="true"
        [amountOffCardsBlock]="imageListThird.length"
        [hideButton]="[false, false, false]"
        class="hidden md:block relative w-full md:w-[410px] xl:w-[580px] overflow-hidden md:float-right pb-30">
        <div
          class="flex justify-start items-center ml-5 mb-5 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
          <img
            *ngFor="let image of imageListThird"
            [src]="image.imageUrl"
            [alt]="image.imageUrl"
            class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[480px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[600px] object-cover rounded-[30px] border-2 border-gray-card" />
        </div>
      </app-adaptive-page-slider>
      <div appPhoneLink [innerHtml]="combinedData.third_block.bodyText"></div>
    </div>
    <a
      *ngIf="combinedData.third_block.buttonURL"
      [href]="combinedData.third_block.buttonURL"
      target="_blank">
      <app-button
        [buttonType]="'Primary'"
        [title]="combinedData.third_block.buttonName"
        class="sm:w-55 flex justify-start items-center mt-20">
      </app-button
    ></a>
  </div>
  <div class="flex mb-10 md:hidden justify-center pt-10">
    <app-adaptive-page-slider
      *ngIf="imageListThird.length"
      [skipLast]="false"
      [counterOfPages]="true"
      [amountOffCardsBlock]="imageListThird.length"
      [hideButton]="[false, false, false]"
      class="relative w-full md:w-[410px] xl:w-[580px] overflow-hidden md:float-right">
      <div
        class="flex justify-start items-center ml-5 mb-5 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
        <img
          *ngFor="let image of imageListThird"
          [src]="image.imageUrl"
          [alt]="image.imageUrl"
          class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[480px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[600px] object-cover rounded-[30px] border-2 border-gray-card" />
      </div>
    </app-adaptive-page-slider>
  </div>
</section>

<section class="bg-primary-base py-30">
  <div class="content-container">
    <div class="mt-15">
      <app-info-cards
        [cards]="cards"
        [classList]="'flex my-30'"></app-info-cards>
    </div>
  </div>
</section>
