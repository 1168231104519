<div class="flex items-center justify-left content-container">
  <section class="flex pt-10 pb-11.5 gap-5 flex-wrap justify-left">
    <app-submenu-items-list
      class="flex w-95"
      [title]="'Регулярний внесок'"
      [queryParams]="{ subscribe: 'monthly' }"
      [titleLink]="donateLink"></app-submenu-items-list>
    <app-submenu-items-list
      class="flex w-95"
      [title]="'Стати партнером'"
      [titleLink]="becomePartnerLink"></app-submenu-items-list>
    <app-submenu-items-list
      class="flex w-95"
      [title]="'Стати волонтером'"
      [titleLink]="becomeVolunteerLink"></app-submenu-items-list>
    <app-submenu-items-list
      class="flex w-95"
      [title]="'Організувати збір коштів'"
      [titleLink]="organizeFundraisingLink"></app-submenu-items-list>
  </section>
</div>
