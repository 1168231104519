import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BreakpointObserverService } from '../../services/breakpoint-observer.service';
import { skipLast, Subscription } from 'rxjs';

@Component({
  selector: 'app-adaptive-page-slider',
  templateUrl: './adaptive-page-slider.component.html',
  styleUrls: ['./adaptive-page-slider.component.css'],
})
export class AdaptivePageSliderComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('sliderTrack') sliderTrack!: ElementRef;
  @Input() hideButton!: boolean[];
  @Input() amountOffCardsBlock?: number = 0;
  @Input() counterOfPages?: boolean = false;
  @Input() skipLast: boolean = true;
  public currentIndex: number = 0;
  private sliderWidth!: number;
  private slideWidth!: number;
  public slidesPerPage!: number;
  public lastEl!: number;
  public amount!: number;
  public isMobile$ = this.points.isMobile();
  private isMobile: boolean = false;
  private isLaptop$ = this.points.isLaptop();
  private isLaptop: boolean = false;
  subs: Subscription = new Subscription();

  constructor(
    private points: BreakpointObserverService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    const mob = this.isMobile$.subscribe(el => {
      if (el) {
        this.isMobile = true;
        this.amount = 1;
      } else {
        this.isMobile = false;
        this.amount = 0;
      }
    });
    this.subs.add(mob);
    const laptop = this.isLaptop$.subscribe(el => {
      if (el) {
        this.isLaptop = true;
        this.amount = 2;
      } else {
        this.isLaptop = false;
        this.amount = 1;
      }
    });
    if (!this.skipLast) {
      this.isMobile = true;
      this.amount = 1;
    }
    this.subs.add(laptop);
  }

  ngAfterViewInit(): void {
    this.calculating();
  }

  calculating() {
    const sliderTrack = this.sliderTrack.nativeElement;
    if (
      sliderTrack &&
      sliderTrack.children[0] &&
      sliderTrack.children[0].children[0]
    ) {
      this.sliderWidth = sliderTrack.children[0].offsetWidth;
      this.slideWidth = sliderTrack.children[0].children[0].offsetWidth;
      this.slidesPerPage = Math.floor(this.sliderWidth / this.slideWidth);
      this.lastEl = sliderTrack.children[0].children.length - 1;
      this.cdr.detectChanges();
    }
  }

  onNextSlide() {
    this.calculating();
    if (this.isMobile || !this.skipLast) {
      if (this.currentIndex < this.lastEl) {
        this.currentIndex++;
        this.sliderTrack.nativeElement.style.transform = `translateX(-${
          this.slideWidth * this.currentIndex + 20 * this.currentIndex
        }px)`;
        Promise.resolve().then(() => this.cdr.detectChanges());
      }
    } else {
      if (this.currentIndex < this.lastEl - 1) {
        this.currentIndex++;
        this.sliderTrack.nativeElement.style.transform = `translateX(-${
          this.slideWidth * this.currentIndex + 20 * this.currentIndex
        }px)`;
        Promise.resolve().then(() => this.cdr.detectChanges());
      }
    }
  }

  onPrevSlide() {
    this.calculating();
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.sliderTrack.nativeElement.style.transform = `translateX(-${
        this.slideWidth * this.currentIndex + 20 * this.currentIndex
      }px)`;
      Promise.resolve().then(() => this.cdr.detectChanges());
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
