import { Component, OnInit, Input } from '@angular/core';
import { TabsHorizontal } from 'src/app/models/tabs-horizontal';

@Component({
  selector: 'app-tab-horizontal-view',
  templateUrl: './tab-horizontal-view.component.html',
  styleUrls: ['./tab-horizontal-view.component.css'],
})
export class TabHorizontalViewComponent implements OnInit {
  @Input() tabData!: TabsHorizontal;
  constructor() {}

  ngOnInit(): void {}
}
