import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface TextCardModel {
  id: number;
  title: string;
  link: string;
  bodyText: string;
  tag: string;
}

@Injectable({
  providedIn: 'root',
})
export class TextCardService {
  private url: string = environment.apiUrl + '/TextCard/GetTextCardsByTag';
  adminUrl: string = environment.apiUrl + '/AdminPanel/UpdateTextCard';
  constructor(private http: HttpClient) {}

  getTextsCardByTag(tag: string) {
    return this.http.get<TextCardModel[]>(
      this.url + `?tag=${encodeURIComponent(tag)}`,
    );
  }

  updateTextCard(data: TextCardModel) {
    return this.http.put(this.adminUrl, data);
  }
}
