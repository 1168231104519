import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';

export default interface IInfoPages {
  title: string;
  text: string;
  whatIsDementiaParagraph?: string;
  whatIsDementiaParagraphSecond?: string;
  link?: string;
  id?: number;
  src?: string;
  menuSubTitle: any;
  subMenuItems?: any[];
}

@Component({
  selector: 'app-submenu-about-dementia',
  templateUrl: './submenu-about-dementia.component.html',
  styleUrls: ['./submenu-about-dementia.component.css'],
})
export class SubmenuAboutDementiaComponent implements OnInit {
  constructor(private http: HttpClient) {}

  menuItems: any[] = [];

  cards: any[] = [
    {
      title: 'Що таке деменція?',
      link: PAGE_ROUTES.whatDementiaIs,
      subMenuItems: [
        {
          id: 'what-dementia-is-4047',
          title: 'Основне про деменцію',
          link: PAGE_ROUTES.whatDementiaIs,
          dementiaMenuId: 2023,
        },
        {
          id: 'what-dementia-is-4048',
          title: 'Стадії деменції',
          link: PAGE_ROUTES.whatDementiaIs,
          dementiaMenuId: 2023,
        },
      ],
    },
    {
      id: 2024,
      title: 'Типи деменції',
      link: PAGE_ROUTES.dementiaTypes,
      subMenuItems: [
        {
          id: 'dementia-types-4049',
          title: 'Поширені типи деменції',
          link: PAGE_ROUTES.dementiaTypes,
          dementiaMenuId: 2024,
        },
        {
          id: 'dementia-types-4050',
          title: 'Що таке рання деменція?',
          link: PAGE_ROUTES.dementiaTypes,
          dementiaMenuId: 2024,
        },
        {
          id: 'dementia-types-4051',
          title: "Пов'язані стани",
          link: PAGE_ROUTES.dementiaTypes,
          dementiaMenuId: 2024,
        },
      ],
    },
    {
      id: 2025,
      title: 'Діагностика та терапія',
      link: PAGE_ROUTES.diagnosis,
      subMenuItems: [
        {
          id: 'diagnosis-therapy-4052',
          title: 'Діагностика деменції',
          link: PAGE_ROUTES.diagnosis,
          dementiaMenuId: 2025,
        },
        {
          id: 'diagnosis-therapy-4053',
          title: 'Лікування та медична терапія',
          link: PAGE_ROUTES.diagnosis,
          dementiaMenuId: 2025,
        },
        {
          id: 'diagnosis-therapy-4054',
          title: 'Нефармакологічна терапія',
          link: PAGE_ROUTES.diagnosis,
          dementiaMenuId: 2025,
        },
        {
          id: 'diagnosis-therapy-4055',
          title: 'Альтернативні практики',
          link: PAGE_ROUTES.diagnosis,
          dementiaMenuId: 2025,
        },
      ],
    },
    {
      id: 2026,
      title: 'Зменшення ризику та профілактика',
      link: PAGE_ROUTES.prophylaxis,
      subMenuItems: [
        {
          id: 'prophylaxis-4047',
          title: 'Фактори ризику деменції',
          link: PAGE_ROUTES.prophylaxis,
          dementiaMenuId: 2026,
        },
        {
          id: 'prophylaxis-4048',
          title: 'Профілактика деменції',
          link: PAGE_ROUTES.prophylaxis,
          dementiaMenuId: 2026,
        },
      ],
    },
    {
      id: 2027,
      title: 'Часті питання',
      link: PAGE_ROUTES.faq,
      subMenuItems: [],
    },
    {
      id: 2028,
      title: 'Фотопроєкт "Ми є. Люди з деменцією"',
      link: PAGE_ROUTES.photoProject,
      subMenuItems: [],
    },
  ];
  ngOnInit(): void {}
}
