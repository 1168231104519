import { Component, OnInit } from '@angular/core';
import ICardInfo from '../../../../../models/cardInfo.interface';
import { NewsApiService } from 'src/app/services/api/aboutUsPages/NewsAndMedia/news-api.service';

@Component({
  selector: 'app-block-news',
  templateUrl: './block-news.component.html',
  styleUrls: ['./block-news.component.css'],
})
export class BlockNewsComponent implements OnInit {
  cardInfo: ICardInfo[] = [];
  constructor(private newsApiService: NewsApiService) {}

  ngOnInit(): void {
    this.getNews();
  }

  getNews(): void {
    const pageNumber = 1;
    const cardsPerPage = 3;
    this.newsApiService
      .getNews(pageNumber, cardsPerPage, false)
      .subscribe(response => {
        this.cardInfo = response.items;
      });
  }
}
