<div class="content-container flex flex-col" *ngIf="!isLoading">
  <div
    class="flex flex-col xl:flex-row justify-between px-4 md-px-0"
    *ngIf="monthlyData$ | async as data; else loader">
    <div class="flex w-95 mb-10 xl:mb-0 xl:h-42.5">
      <h2 class="text-primary-base text-h1-heading">
        {{ data.title || data.tag | uppercase }}
      </h2>
    </div>
    <div class="flex flex-col w-full xl:w-195">
      <div class="md:pb-10" [innerHTML]="data.text"></div>
    </div>
  </div>
  <div
    *ngIf="dataArray.length; else emptyBlock"
    class="flex flex-col items-center md:flex-row mt-4 md:mt-13 px-4 md:px-0">
    <div class="relative w-full md:w-75 md:mr-2.5">
      <select
        id="year"
        #year
        (input)="chosedYear($event)"
        style="background-position: calc(100% - 2rem) center"
        class="select w-full my-2 md:my-0 md:w-75 h-12 rounded-10 pl-5 border-2 border-gray-stroke outline-primary-base mr-5 appearance-none text-gray-details bg-[16rem] bg-no-repeat bg-[url('src/assets/icons/svg-sprites/button-icons-sprite.svg#chevron(viewBox(0,0,22,22))')]">
        <option value="" disabled selected>Оберіть рік</option>
        <option
          *ngFor="let item of yearsArray; let i = index"
          value="{{ item }}"
          [selected]="i === 0 ? true : null">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="relative w-full md:w-75 md:mr-2.5">
      <select
        #month
        id="month"
        (input)="choseMonthIndex($event)"
        style="background-position: calc(100% - 2rem) center"
        class="test w-full my-2 md:my-0 md:w-75 h-12 rounded-10 pl-5 border-2 border-gray-stroke outline-primary-base mr-5 appearance-none text-gray-details bg-right bg-no-repeat bg-[url('src/assets/icons/svg-sprites/button-icons-sprite.svg#chevron(viewBox(0,0,22,22))')] fill-gray-dark">
        <option value="" disabled selected>Оберіть місяць</option>
        <option
          *ngFor="let month of monthArray; let i = index"
          [selected]="i === 0 ? true : null"
          value="{{ month.month }}">
          {{ month.month }}
        </option>
      </select>
    </div>
    <div class="w-full my-2 md:my-0 md:w-55">
      <app-button
        class="w-full md:w-55"
        type="button"
        [buttonType]="'Secondary'"
        (click)="setCurrentData(+year.value, month.value)"
        [title]="'Застосувати'"></app-button>
    </div>
  </div>
  <div *ngIf="dataArray.length">
    <app-monthly-reports-card
      *ngIf="cardMonthView"
      [cardMonthly]="cardMonthView"></app-monthly-reports-card>
  </div>

  <div
    class="flex flex-col w-full md:flex-row justify-center mx-auto mt-15 mb-30 px-4 md:px-0">
    <div class="w-full md:w-55 mr-2.5" [routerLink]="reportLink">
      <app-button
        class="w-full md:w-55"
        type="button"
        [buttonType]="'Secondary'"
        [title]="'Інші звіти'"></app-button>
    </div>
    <div
      class="w-full md:w-55 mt-2 md:mt-0 md:ml-2.5"
      [routerLink]="donateLink">
      <app-button
        class="w-full md:w-55"
        type="button"
        [buttonType]="'Donate'"
        [title]="'Допомогти'"></app-button>
    </div>
  </div>
</div>

<div class="loader mx-auto my-20" *ngIf="isLoading">
  <div #loader>
    <div class="pt-10">
      <div
        class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
        <div class="absolute top-0 left-0 w-full h-full">
          <div class="w-full h-full gradient-animation"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="pt-10">
    <div
      class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full gradient-animation"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyBlock>
  <p>Звіти оновлюються адміністрацією сайту...</p>
</ng-template>
