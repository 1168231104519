import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right',
  templateUrl: './circle_triangle_right_fill_icon1.svg',
})
export class RightComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
