import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  FormErrorMessageService,
  FormErrors,
  FormFields,
  IErrorMessages,
} from 'src/app/services/form-error-message.service';

/**
 * Custom input element data
 * @template
 *```
 *<app-input
 *      [control]="name"//controlFormName
 *      [labelName]="'Імʼя'"
 *      [maxLength]="'30'"//not required parameter
 *      inputID="name"//"Id" input
 *      class="w-75">//set particular width required
 *</app-input>
 *```
 */
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputComponent implements OnInit, DoCheck {
  @Input() control: FormControl = new FormControl();
  @Input() type: string = 'text';
  @Input() labelName: string = '';
  @Input() inputID: string = '';
  @Input() maxLength: string = '';
  @Input() darkInput: boolean = false;
  isInvalid = false;

  protected get formFields(): typeof FormFields {
    return FormFields;
  }

  protected get formErrors(): typeof FormErrors {
    return FormErrors;
  }

  constructor(private formErrorMessageService: FormErrorMessageService) {}
  ngDoCheck(): void {
    this.isInvalid =
      this.control.invalid && (this.control.touched || this.control.dirty);
  }

  ngOnInit(): void {}

  protected displayErrorMessage(
    formField: FormFields,
    formError: FormErrors,
  ): IErrorMessages | undefined {
    return this.formErrorMessageService.displayErrorMessage(
      formField,
      formError,
    );
  }
}
