import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PAGE_ROUTES } from '../../../constants/page_routes';

@Component({
  selector: 'app-events-card',
  templateUrl: './events-card.component.html',
  styleUrls: ['./events-card.component.css'],
})
export class EventsCardComponent {
  @Input() cardInfo!: any;
  isHovered: boolean = false;

  constructor(private router: Router) {}

  navigate(id: string) {
    this.router.navigate([PAGE_ROUTES.activities + '/' + id]);
  }

  extractTextFromHtml(html: string): string {
    const regex = /<[^>]*>/g;
    const text = html.replace(regex, '');
    return text.length > 110 ? text.substring(0, 110) : text;
  }
}
