<div class="flex md:content-container md:mx-auto overflow-hidden justify-start">
  <div class="duration-400 transition-all" #sliderTrack>
    <ng-content></ng-content>
  </div>
</div>

<div
  *ngIf="amountOffCardsBlock && amountOffCardsBlock > 1"
  class="flex items-center justify-center"
  [ngClass]="{
    hidden: hideButton[0],
    'md:hidden': hideButton[1],
    'xl:hidden': hideButton[2]
  }">
  <div class="w-full flex justify-center items-center">
    <button (click)="onPrevSlide()" class="pr-5">
      <svg
        class="cursor-pointer"
        [ngClass]="currentIndex > 0 ? 'fill-accent-base' : 'fill-gray-gray2'"
        [icon]="'left-arrow'"
        iconCollection="button-icons-sprite"
        width="40px"
        height="40px"></svg>
    </button>
    <div *ngIf="counterOfPages" class="text-main-text-bold px-5">
      {{ currentIndex + 1 }} /
      {{
        ((isMobile$ | async) && amountOffCardsBlock) || !skipLast
          ? amountOffCardsBlock
          : amountOffCardsBlock! - 1
      }}
    </div>
    <button (click)="onNextSlide()" class="pl-5">
      <svg
        class="cursor-pointer fill-accent-base"
        [ngClass]="{
          'fill-gray-gray2':
            (isMobile$ | async) || !skipLast
              ? currentIndex > this.lastEl - this.amount
              : currentIndex === amountOffCardsBlock! - 2
        }"
        [icon]="'right-arrow'"
        iconCollection="button-icons-sprite"
        width="40px"
        height="40px"></svg>
    </button>
  </div>
</div>
