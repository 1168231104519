import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import IFundMember from 'src/app/models/fundMember.interface';
import IInfoPage from 'src/app/models/infoPage.interface';
import {
  IVolunteer,
  IVolunteerResponse,
} from 'src/app/models/volunteer.interface';
import {
  FundTeamService,
  IFundMemberResponse,
} from 'src/app/services/api/aboutUsPages/FundTeamPages/fund-team.service';
import {
  BannerPageService,
  IBannerPage,
} from 'src/app/services/api/banner-page.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { PortalTextsService } from '../../../../services/api/portal-texts-by-tag.service';

export type Volunteer = {
  title: string;
  text: string;
  imgUrl: string;
};
@Component({
  selector: 'app-fund-team',
  templateUrl: './fund-team.component.html',
  styleUrls: ['./fund-team.component.css'],
})
export class FundTeamComponent implements OnInit {
  pageName = 'fund-team';
  pageBannerData: IBannerPage = {
    title: '',
    text: '',
    imgUrl: '',
    pageName: '',
  };
  isSeeMoreButtonVisible: boolean = false;
  showedVolunteers: Volunteer[] = [];
  showedVolunteerNumber = 6;
  portalTextsData: any[] = [];
  fundMembers: IFundMember[] = [];
  fundWatchers: IFundMember[] = [];
  loading: boolean = true;

  fundTeamLink = PAGE_ROUTES.fundTeam;
  volunteers: IVolunteer[] = [];
  links: { id: number; title: string }[] = [];
  keyDementiaCards: IInfoPage[] = [
    {
      title: 'Долучитися',
      text: 'Долучайтеся до допомоги людям з деменцією та їхнім родинам, підтримуючи фонд “Незабутні”.',
      link: PAGE_ROUTES.join,
    },
    {
      title: 'Вакансії',
      text: 'Слідкуйте за актуальними вакансіями фонду “Незабутні” та долучайтеся до натхненної команди творців змін.',
      link: PAGE_ROUTES.vacancies,
    },
  ];
  constructor(
    private scrollService: ScrollService,
    private apiService: FundTeamService,
    private pageBannerService: BannerPageService,
    private portalText: PortalTextsService,
  ) {}

  ngOnInit(): void {
    this.pageBannerService.getBannersData(this.pageName).subscribe(el => {
      this.pageBannerData.title = el.title;
      this.pageBannerData.text = el.text;
      this.pageBannerData.imgUrl = el.imgUrl;
    });
    this.fetchFundTeam().subscribe((data: IFundMemberResponse[]) => {
      // const membersArray = data.sort((a, b) => a.id - b.id);
      data.forEach(people => {
        const member = {
          img: people.img,
          position: people.position,
          text: people.description,
          title: people.title,
          memberID: people.id,
          email: people.email,
          projectRole: people.projectRole,
        };
        if (member.projectRole === 'FundMember') {
          this.fundMembers.push(member);
        }
        if (member.projectRole === 'FundWatcher') {
          this.fundWatchers.push(member);
        }
        this.loading = false;
      });
      this.scrollService.setLoaded(true);
    });
    this.fetchVolunteerTeam().subscribe((data: IVolunteerResponse[]) => {
      this.volunteers = data.map(
        (fundMember): IVolunteer => ({
          imgUrl: fundMember.imagePath,
          text: fundMember.text,
          title: fundMember.title,
        }),
      );
      if (this.volunteers.length > this.showedVolunteerNumber) {
        this.showedVolunteers = this.volunteers.slice(
          0,
          this.showedVolunteerNumber,
        );
        this.isSeeMoreButtonVisible = true;
      } else {
        this.showedVolunteers = this.volunteers;
      }
    });
    this.portalText
      .getPortalTextsByTag(this.portalText.getCurrentUrl())
      .subscribe(res => {
        this.portalTextsData = res.sort((a, b) => a.id - b.id);
        this.links = res.map(link => ({
          id: link.id,
          title: this.firsLetterUpper(link.title),
        }));
        const index = this.links.findIndex(link => link.id === 402);
        if (index !== -1) {
          const [movedLink] = this.links.splice(index, 1);
          const middleIndex = Math.floor(this.links.length / 2);
          this.links.splice(middleIndex, 0, movedLink);
        }
      });
  }

  firsLetterUpper(str: string) {
    const firstLetter = str.charAt(0).toUpperCase();
    const lowerStr = str.toLowerCase().slice(1);
    return firstLetter + lowerStr;
  }

  seeMore() {
    this.showedVolunteers = this.volunteers;
    this.isSeeMoreButtonVisible = false;
  }

  fetchFundTeam(): Observable<IFundMemberResponse[]> {
    return this.apiService.getFundTeam();
  }
  fetchVolunteerTeam(): Observable<IVolunteerResponse[]> {
    return this.apiService.getVolunteerTeam();
  }
}
