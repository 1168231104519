<form
  class="w-full md:w-[410px] xl:w-145 min-h-[624px] border-2 bg-white border-gray-stroke rounded-30 p-10">
  <div class="flex justify-between items-center">
    <h3 class="text-h2-heading uppercase text-primary-base">
      {{ formsData.title }}
    </h3>
    <svg
      width="19px"
      height="19px"
      [icon]="'close'"
      (click)="onClosePopUp()"
      iconCollection="button-icons-sprite"
      class="text-gray-dark cursor-pointer"></svg>
  </div>

  <div
    class="text-main-text text-gray-dark mt-5 mb-10"
    [innerHtml]="formsData.bodyText"></div>
  <div class="flex items-center justify-center mb-10">
    <img
      class="w-[308px] h-[308px] overflow-hidden flex object-cover rounded-30"
      src="{{ formsData.imageURL }}"
      alt="{{ formsData.title }}" />
  </div>

  <app-button
    [title]="'Надіслати ще лист'"
    [type]="'submit'"
    (click)="onClose()"
    class="text-main-text-bold"></app-button>
</form>
