import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IHelpCard } from '../../models/help-card.interface';
import { PAGE_ROUTES } from '../../constants/page_routes';
import {
  HowICanHelpBlockService,
  IHowICanHelpBlock,
} from '../../services/api/other/how-i-can-help-block.service';

@Component({
  selector: 'app-other-method-to-join',
  templateUrl: './other-method-to-join.component.html',
  styleUrls: ['./other-method-to-join.component.css'],
})
export class OtherMethodToJoinComponent implements OnInit {
  protected helpCards: IHelpCard[] = [];
  textBlock: { title: string; text: string } = { title: '', text: '' };
  routerLink = PAGE_ROUTES.join;
  cards: any = [
    {
      imageLink: '../assets/images/other-method-to-join/regular-payment.png',
      route: '/get-involved/to-help',
      text: 'Регулярний платіж',
      queryParams: { subscribe: 'monthly' },
    },
    {
      imageLink: '../assets/images/other-method-to-join/make-donate.png',
      route: '/get-involved/organize-a-fundraiser',
      text: 'Організувати збір коштів',
    },
    {
      imageLink: '../assets/images/other-method-to-join/become-volonteer.png',
      route: '/get-involved/become-a-volunteer',
      text: 'Стати волонтером',
    },
  ];

  @Input()
  public inputCards: any = this.cards;
  @Input()
  public type: string = '';

  protected get borderType(): string {
    return this.type === 'secondary' ? 'border-2 ' : '';
  }
  protected get BackgroundColorType(): string {
    return this.type === 'primary'
      ? 'bg-primary-base'
      : this.type === 'secondary'
      ? 'bg-gray-white'
      : '';
  }

  protected get HeadingColorType(): string {
    return this.type === 'primary'
      ? 'text-gray-white'
      : this.type === 'secondary'
      ? 'text-primary-base'
      : '';
  }

  protected get ParagraphColorType(): string {
    return this.type === 'primary'
      ? 'text-gray-white'
      : this.type === 'secondary'
      ? 'text-gray-dark'
      : '';
  }

  constructor(
    private getDataServ: HowICanHelpBlockService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getDataServ.getBlockData().subscribe((data: IHowICanHelpBlock) => {
      this.textBlock = data.description;
      data.cards.forEach(cards => {
        const newCards: IHelpCard = {
          imageLink: cards.image,
          route: cards.link,
          text: cards.text,
        };
        this.helpCards.push(newCards);
        this.cdr.markForCheck();
      });
    });
  }
}
