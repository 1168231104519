<div
  (click)="navigate(cardInfo.id)"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false"
  class="w-full md:w-105 xl:w-95 group cursor-pointer rounded overflow-hidden">
  <img
    alt="image"
    class="w-full object rounded-t-3xl h-75 object-cover"
    src="{{ cardInfo.pictureUrl }}" />

  <div
    class="rounded-t-none h-80 md:h-73 xl:h-85.75 xl:p-0 p-4 md:px-10 flex flex-col items-center justify-between border-2 xl:border-t-0 rounded-30 xl:rounded-t-none xl:rounded-b-30 group-hover:border-secondary-base">
    <div>
      <div class="xl:mt-4.25 leading-5">
        <img
          alt="calender icon"
          class="inline-block"
          src="./assets/images/news/calendar.png" />
        <p class="pl-2 inline-block text-details text-gray-details">
          <time id="datetime">{{
            cardInfo.date | date: 'dd MMMM yyyy ' : 'UTC+3' : 'uk-UA'
          }}</time>
        </p>
      </div>
      <h2
        class="wrapper w-full h-18 xl:w-75 pt-3 text-main-text-bold text-gray-dark">
        {{ cardInfo.title }}
      </h2>
      <p
        class="wrapper-text md:flex w-full xl:w-75 pt-2.5 text-gray-dark text-smaller-main"
        [innerText]="extractTextFromHtml(cardInfo.text)"></p>
    </div>
    <app-link-button
      [isHovered]="isHovered"
      [linkButtonType]="'Primary'"
      [title]="'Детальніше'"
      class="w-full md:w-75 xl:h-13 xl:m-10 xl:mt-0">
    </app-link-button>
  </div>
</div>
