import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-media-center',
  templateUrl: './media-center.component.html',
  styleUrls: ['./media-center.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCenterComponent {
  textValue: string = 'info@nezabutni.org';
  isCopy: boolean = false;
  pageName: string = 'media-centre';
  cardTag: string = 'about-us/news-and-media/media-centre';
  changeIcon() {
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
    }, 1000);
  }
}
