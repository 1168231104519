import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlEditorComponent } from './html-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { EditorPhoneLinkComponent } from './editor-phone-link/editor-phone-link.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [HtmlEditorComponent, EditorPhoneLinkComponent],
  exports: [HtmlEditorComponent, EditorPhoneLinkComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatIconModule,
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Жирний',
        italic: 'Курсив',
        code: 'Code',
        underline: 'Підкреслений',
        strike: 'Закреслений',
        blockquote: 'Фіолетовий блок',
        bullet_list: 'Ненумерований список',
        ordered_list: 'Нумерований список',
        heading: 'Заголовки',
        h1: 'Заголовок 1',
        h2: 'Заголовок 2',
        h3: 'Заголовок 3',
        h4: 'Заголовок 4',
        h5: 'Заголовок 5',
        h6: 'Заголовок 6',
        links: 'Телефон/імейл',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',
        insertLink: 'Посилання',
        removeLink: 'Видалити посилання',
        insertImage: 'Insert Image',
        // pupups, forms, others...
        url: 'URL',
        text: 'Текст',
        openInNewTab: 'Відкриття в новому вікні',
        insert: 'Зберегти',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Видалити',
        horizontal_rule: 'Горизонтальна лінія',
        format_clear: 'Очистити форматування',
      },
    }),
    FormsModule,
  ],
})
export class NgXEditorModule {}
