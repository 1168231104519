<div class="relative flex flex-col">
  <!-- Scrollbar's styles are placed in CSS file -->
  <textarea
    name="textAreaID"
    id="textAreaID"
    [maxlength]="maxLength"
    class="block w-full h-42 py-2.5 px-5 mb-2 text-gray-dark rounded-10 border-2 border-gray-stroke outline-0 focus:outline-none focus:border-primary-base resize-none peer"
    [ngClass]="{ 'border-accent-base': isInvalid }"
    [formControl]="control"
    (click)="showTextAreaLengthMessage()"></textarea>

  <label
    for="textAreaID"
    class="absolute text-main-text text-gray-details bg-white duration-300 transform top-2 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-primary-base peer-placeholder-shown:text-main-text peer-placeholder-shown:-translate-y-1 peer-placeholder-shown:top-3.5 peer-focus:top-2 peer-focus:text-details peer-focus:-translate-y-4 left-3 cursor-text"
    [ngClass]="{
      'text-attention-base peer-focus:text-attention-base': isInvalid,
      '-translate-y-4 text-details': textAreaLength
    }"
    >{{ labelName }}</label
  >

  <span
    class="absolute top-2.5 right-2.5 text-attention-base text-main-text bg-gray-card w-7 rounded-full text-center"
    *ngIf="isInvalid"
    >!</span
  >

  <div
    class="text-details text-attention-base -bottom-6 left-1 animate-error-appear"
    *ngIf="control.touched && control.hasError('required')">
    {{
      displayErrorMessage(formFields.Name, formErrors.Required)?.errorMessage
    }}
  </div>

  <span
    *ngIf="
      (isShowTextAreaLengthMessage | async) &&
      !(control.touched && control.hasError('required'))
    "
    class="text-details text-gray-details"
    >Використано символів {{ textAreaLength }}/2000.
  </span>
</div>
