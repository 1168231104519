import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CopyButtonComponent } from '../components/copy-button/copy-button.component';

@Directive({
  selector: '[appPhoneLink]',
  standalone: false,
})
export class PhoneLinkDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      const links = this.el.nativeElement.querySelectorAll('a[href^="tel:"]');
      links.forEach((link: any) => {
        if (!result.matches) {
          const span = this.renderer.createElement('span');
          span.textContent = link.innerText;
          this.renderer.insertBefore(link.parentNode, span, link);
          this.renderer.removeChild(link.parentNode, link);
        } else {
          const newLink = this.renderer.createElement('a');
          newLink.href = link.href;
          newLink.textContent = link.textContent;
          newLink.setAttribute('class', link.getAttribute('class'));
          this.renderer.insertBefore(link.parentNode, newLink, link);
          this.renderer.removeChild(link.parentNode, link);
        }
      });
    });
    const emailLinks =
      this.el.nativeElement.querySelectorAll('a[href^="mailto:"]');
    emailLinks.forEach((link: any) => {
      const copyButtonComponentRef =
        this.viewContainerRef.createComponent(CopyButtonComponent);
      copyButtonComponentRef.instance.textValue = link.innerText;
      copyButtonComponentRef.instance.showText = true;
      const parentElement = link.parentNode;
      parentElement.insertBefore(
        copyButtonComponentRef.location.nativeElement,
        link.nextSibling,
      );
      this.renderer.removeChild(link.parentNode, link);
    });
  }
}
