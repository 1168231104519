import { Component, Input, OnInit } from '@angular/core';
import IResearchCard from 'src/app/models/researchCard.interface';
import { otherPublicationsCards } from 'src/mockData//researchCardMockDate';
import { OtherPublicationCard } from '../../../../../../services/api/programs-page/research.service';

@Component({
  selector: 'app-other-publications-card',
  templateUrl: './other-publications-card.component.html',
  styleUrls: ['./other-publications-card.component.css'],
})
export class OtherPublicationsCardComponent implements OnInit {
  @Input() cards: OtherPublicationCard[] = [];
  otherPublicationsCards: IResearchCard[] = otherPublicationsCards;
  constructor() {}

  ngOnInit(): void {}
}
