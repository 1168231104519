<div class="NgxEditor__MenuItem">
  <div
    class="NgxEditor__MenuItem--IconContainer p-1"
    [class.NgxEditor__MenuItem--Active]="isActive || showPopup"
    [class.NgxEditor--Disabled]="isDisabled"
    (mousedown)="onClick($event)"
    title="Телефон">
    <mat-icon *ngIf="!isActive">settings_phone</mat-icon>
    <mat-icon *ngIf="isActive">phonelink_erase</mat-icon>
  </div>

  <!-- popup -->
  <div *ngIf="showPopup" class="NgxEditor__Popup">
    <form
      class="NgxEditor__Popup--Form"
      [formGroup]="form"
      (ngSubmit)="insertLink($event)">
      <div class="NgxEditor__Popup--FormGroup">
        <div class="NgxEditor__Popup--Col">
          <label class="NgxEditor__Popup--Label">
            Телефон
            <input
              class="border-2"
              [ngClass]="{
                'border-red': !href.valid,
                'border-gray-card': href.valid
              }"
              id="href"
              placeholder="+300000000000"
              formControlName="href"
              autocomplete="off" />
          </label>
          <div
            *ngIf="!href.invalid && href.touched"
            class="NgxEditor__HelpText NgxEditor__HelpText--Error">
            {{ href.errors?.['required'] && "Обов'язкове поле" }}
            {{ href.errors?.['phoneNumberValidator'] && 'Невірний формат' }}
          </div>
        </div>
      </div>

      <div class="NgxEditor__Popup--FormGroup">
        <div class="NgxEditor__Popup--Col">
          <label class="NgxEditor__Popup--Label">
            Текст
            <input
              type="text"
              formControlName="text"
              autocomplete="off"
              class="border-2 border-gray-card" />
          </label>
          <div
            *ngIf="text.touched && text.invalid"
            class="NgxEditor__HelpText NgxEditor__HelpText--Error">
            {{ text.errors?.['required'] && "Обов'язкове поле" }}
          </div>
        </div>
      </div>

      <button
        class="border-2 border-gray-dark bg-secondary-base text-gray-white p-2 rounded-sm disabled:bg-gray-card"
        type="submit"
        [disabled]="!form.valid">
        Зберегти
      </button>
    </form>
  </div>
</div>
