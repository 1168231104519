<div class="w-82 md:w-210 xl:w-195">
  <ul
    class="flex flex-row h-18.8 justify-start text-h3-360 md:text-h3-subheading text-accent-base">
    <li
      *ngFor="let tab of tabs; let i = index"
      class="flex justify-center py-4 md:py-5.5 xl:pt-6.5 xl:pb-5.5 px-9.5 items-center w-41 md:w-106 xl:w-97.5 hover:opacity-900 list-none cursor-pointer"
      [ngClass]="{
        'border-2 border-b-2 border-b-primary-base rounded-t-20 md:rounded-t-30 text-primary-base':
          tab.active
      }"
      (click)="selectTab(tab)">
      {{ tab.tabTitle }}
    </li>
  </ul>
  <ng-content></ng-content>
</div>
