<section
  class="flex flex-col xl:flex-row gap-5 px-4 md:px-15 xl:px-0 justify-center content-container"
  *ngIf="combinedData; else loader">
  <h1
    class="text-primary-base uppercase text-h1-360 md:text-h1-heading w-full md:w-95 mr-5">
    {{ combinedData.after_banner_text.title }}
  </h1>
  <div
    class="text-gray-dark text-smaller-main md:text-main-text w-full md:w-195"
    appPhoneLink
    [innerHtml]="combinedData.after_banner_text.bodyText"></div>
</section>

<section *ngIf="links.length; else loader" class="mt-20 px-4 md:px-15 xl:px-0">
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="links"></app-table-of-content>
</section>

<section
  *ngIf="combinedData"
  [id]="combinedData.first_block.key"
  class="py-30 px-4 md:px-0 flex flex-col gap-5 content-container">
  <h2 class="text-h2-360 md:text-h2-heading text-primary-base uppercase">
    {{ combinedData.first_block.title }}
  </h2>
  <div class="">
    <img
      [src]="combinedData.first_block.imageURL"
      [alt]="combinedData.first_block.title"
      class="w-[328px] md:w-145 h-[226px] md:h-100 rounded-30 md:float-right md:ml-5 mb-10 mx-auto md:mb-5" />
    <div class="" [innerHtml]="combinedData.first_block.bodyText"></div>
  </div>
</section>

<section
  *ngIf="combinedData"
  [id]="combinedData.second_block.key"
  class="bg-gray-card py-30">
  <div class="content-container px-4 md:px-0 flex flex-col gap-5">
    <h2 class="text-h2-360 md:text-h2-heading text-primary-base uppercase">
      {{ combinedData.second_block.title }}
    </h2>
    <div appPhoneLink [innerHtml]="combinedData.second_block.bodyText"></div>
    <div class="w-70 mt-15">
      <app-link-button
        [url]="combinedData.second_block.buttonURL"
        [title]="combinedData.second_block.buttonName"
        [linkButtonType]="'Secondary'">
      </app-link-button>
    </div>
  </div>
</section>

<section
  *ngIf="combinedData"
  [id]="combinedData.third_block.key"
  class="py-30 content-container px-4 md:px-0 flex flex-col gap-5">
  <div class="flex flex-col gap-5">
    <h2 class="text-h2-360 md:text-h2-heading text-primary-base uppercase">
      {{ combinedData.third_block.title }}
    </h2>
    <div class="flex flex-col xl:flex-row gap-5">
      <div class="">
        <img
          [src]="combinedData.third_block.imageURL"
          [alt]="combinedData.third_block.title"
          class="w-[328px] md:w-145 h-[226px] md:h-100 rounded-30 md:float-right md:ml-5 mb-10 mx-auto md:mb-5" />
        <div
          appPhoneLink
          class=""
          [innerHtml]="combinedData.third_block.bodyText"></div>
      </div>
    </div>
  </div>
</section>

<section
  *ngIf="combinedData"
  [id]="combinedData.fourth_block.key"
  class="bg-gray-card py-30">
  <div class="content-container px-4 md:px-0 flex flex-col gap-5">
    <h2 class="text-h2-360 md:text-h2-heading text-primary-base uppercase">
      {{ combinedData.fourth_block.title }}
    </h2>
    <div appPhoneLink [innerHtml]="combinedData.fourth_block.bodyText"></div>
  </div>
</section>

<section class="py-30 content-container px-4 md:px-0 flex flex-col">
  <app-accordion
    *ngIf="AccordionData.length"
    [AccordionsList]="AccordionData"
    [classList]="'mb-30'"></app-accordion>
  <div class="w-[326px] mx-auto">
    <div (click)="downloadPdfFile()">
      <app-button
        [title]="'Завантажити ключові факти'"
        [buttonType]="'Primary'"
        [svg]="true">
        <svg
          width="20px"
          height="20px"
          icon="download"
          iconCollection="button-icons-sprite"
          class="svg fill-gray-white mt-1 mr-2"></svg>
      </app-button>
    </div>
  </div>
</section>

<section class="bg-primary-base pt-15 pb-30">
  <div class="content-container">
    <div class="mt-15">
      <app-info-cards [cards]="keyDementiaCards"></app-info-cards>
    </div>
  </div>
</section>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
