import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PressRelaseCardInterface } from 'src/app/models/press-relase-card.interface';
import { PressReleasesApiService } from 'src/app/services/api/aboutUsPages/NewsAndMedia/press-releases-api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-press-releases',
  templateUrl: './press-releases.component.html',
  styleUrls: ['./press-releases.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PressReleasesComponent implements OnInit, OnDestroy {
  pressReleasesArray: PressRelaseCardInterface[] = [];
  subArray: PressRelaseCardInterface[] = [];
  showAllReleases: boolean = false;
  skip: number = 0;
  take: number = 9;
  subs!: Subscription;

  constructor(
    private pressReleaseService: PressReleasesApiService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getData(this.skip, this.take);
  }

  getData(skip: number | string, take: number | string) {
    this.subs = this.pressReleaseService.getData(skip, take).subscribe(data => {
      this.subArray = data;
      this.pressReleasesArray = [...data];
      this.checkLength();
      this.cdr.markForCheck();
    });
  }

  checkLength() {
    if (this.subArray.length <= 9) {
      this.showAllReleases = false;
    } else {
      this.showAllReleases = true;
      this.take += 9;
    }
  }

  getMoreHangler() {
    if (this.subArray.length >= 8) {
      this.getData(0, this.take);
    }
    if (!this.showAllReleases) {
      this.getData(0, 9);
    }
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
// this.subArray = data.map(item => ({
//   id: item.id,
//   date: item.dateOfPublication,
//   link: item.id.toString(),
//   title: item.title,
//   text: item.shortText,
// }));
