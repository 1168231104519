import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DonateComponent } from './pages/donate/donate.component';
import { ResultPaymentComponent } from './pages/donate/result-payment/result-payment.component';
import { SalivonOleksandrComponent } from './pages/salivon-oleksandr/salivon-oleksandr.component';

import { MediaAboutUsComponent } from './pages/about-us/news-and-media/media-about-us/media-about-us.component';
import { NotFoundComponent } from './pages/404-not-found/404-not-found.component';
import { NewsComponent } from './pages/about-us/news-and-media/news/news.component';
import { MediaCenterComponent } from './pages/about-us/news-and-media/media-center/media-center.component';
import { StatisticComponent } from './pages/about-us/news-and-media/statistic/statistic.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HelpDuringTheWarComponent } from './pages/about-us/our-programs/help-during-the-war/help-during-the-war.component';
import { SupportForFamiliesComponent } from './pages/about-us/our-programs/support-for-families/support-for-families.component';
import { FriendlySocietyComponent } from './pages/about-us/our-programs/friendly-society/friendly-society.component';
import { StudyComponent } from './pages/about-us/our-programs/study/study.component';
import { ResearchComponent } from './pages/about-us/our-programs/research/research.component';
import { OurProgramsComponent } from './pages/about-us/our-programs/our-programs.component';
import { NewsAndMediaComponent } from './pages/about-us/news-and-media/news-and-media.component';
import { FundTeamComponent } from './pages/about-us/our-people/fund-team/fund-team.component';
import { SupervisoryBoardComponent } from './pages/about-us/our-people/supervisory-board/supervisory-board.component';
import { AdvisoryGroupComponent } from './pages/about-us/our-people/advisory-group/advisory-group.component';
import { OurPartnersComponent } from './pages/about-us/our-people/our-partners/our-partners.component';
import { VacanciesComponent } from './pages/about-us/our-people/vacancies/vacancies.component';
import { OurPeopleComponent } from './pages/about-us/our-people/our-people.component';
import { ContactsComponent } from './pages/about-us/about-fund/contacts/contacts.component';
import { OurIdeaComponent } from './pages/about-us/about-fund/our-idea/our-idea.component';
import { ReportsComponent } from './pages/about-us/about-fund/reports/reports.component';
import { AboutDementiaComponent } from './pages/about-dementia/about-dementia.component';
// import { WhatDementiaIsComponent } from './pages/about-dementia/info/what-dementia-is/what-dementia-is.component';
import { FAQComponent } from './pages/about-dementia/about-dementia-second-level/faq/faq.component';

import { MainPageComponent } from './pages/main-page/main-page.component';

import { NeedHelpComponent } from './pages/need-help/need-help.component';
import { HelpWithMedicineComponent } from './pages/need-help/help-with-medicine/help-with-medicine.component';
import { PsychologicalSupportComponent } from './pages/need-help/psychological-support/psychological-support.component';
import { LegalAssistanceComponent } from './pages/need-help/legal-assistance/legal-assistance.component';
import { CareConsultationComponent } from './pages/need-help/care-consultation/care-consultation.component';
import { RehabilitationProgramComponent } from './pages/need-help/rehabilitation-program/rehabilitation-program.component';
import { CrisisAssistanceComponent } from './pages/need-help/crisis-assistance/crisis-assistance.component';
import { InformationalAssistanceComponent } from './pages/need-help/informational-assistance/informational-assistance.component';
import { HelpWithGeneratorsComponent } from './pages/need-help/crisis-assistance/help-with-generators/help-with-generators.component';
import { HelpWithEvacuationComponent } from './pages/need-help/crisis-assistance/help-with-evacuation/help-with-evacuation.component';
import { HelpAbroadComponent } from './pages/need-help/crisis-assistance/help-abroad/help-abroad.component';

import { IWorryAboutMemoryComponent } from './pages/need-help/what-to-do-if/i-worry-about-memory/i-worry-about-memory.component';
import { JustDiagnosedComponent } from './pages/need-help/what-to-do-if/just-diagnosed/just-diagnosed.component';
import { MedicalEmployeeComponent } from './pages/need-help/what-to-do-if/medical-employee/medical-employee.component';
import { ProfessionalCaregiverComponent } from './pages/need-help/what-to-do-if/professional-caregiver/professional-caregiver.component';
import { RelativeHasDementiaComponent } from './pages/need-help/what-to-do-if/relative-has-dementia/relative-has-dementia.component';
import { JoinComponent } from './pages/join/join.component';
import { FindInformationComponent } from './pages/find-information/find-information.component';
import { TaxReportsComponent } from './pages/about-us/about-fund/reports/tax-reports/tax-reports.component';
import { DocumentsReportsComponent } from './pages/about-us/about-fund/reports/documents-reports/documents-reports.component';
import { AnnualReportsComponent } from './pages/about-us/about-fund/reports/annual-reports/annual-reports.component';
import { BecomeVolunteerComponent } from './pages/join/become-volunteer/become-volunteer.component';
import { FundMemberComponent } from './pages/about-us/our-people/fund-team/fund-member/fund-member.component';
import { BreadcrumbFundMemberResolver } from './resolvers/breadcrumb-fund-member.resolver';
import { MonthlyReportsComponent } from './pages/about-us/about-fund/reports/monthly-reports/monthly-reports.component';
import { ProductsComponent } from './components/products/products.component';
import { FilterComponent } from './components/filter/filter.component';
import { InformationComponent } from './components/information/information.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { BecomePartnerComponent } from 'src/app/pages/join/become-partner/become-partner.component';
import { DetailedNewsComponent } from './pages/about-us/news-and-media/news/detailed-news/detailed-news.component';

import { TestBackendComponent } from './pages/test-backend/test-backend.component';
import { PressReleasesComponent } from 'src/app/pages/about-us/news-and-media/media-center/press-releases/press-releases.component';
import { PressReleasesDetailsComponent } from 'src/app/pages/about-us/news-and-media/media-center/press-releases/press-releases-details/press-releases-details.component';
import { GlossaryComponent } from 'src/app/pages/about-us/news-and-media/media-center/glossary/glossary.component';
import { HowToTalkAboutDementiaInTheMediaComponent } from 'src/app/pages/about-us/news-and-media/media-center/how-to-talk-about-dementia-in-the-media/how-to-talk-about-dementia-in-the-media.component';
import { KeyFactsAboutDementiaComponent } from 'src/app/pages/about-us/news-and-media/media-center/key-facts-about-dementia/key-facts-about-dementia.component';
import { AboutDementiaThirdLevelComponent } from 'src/app/pages/about-dementia/about-dementia-third-level/about-dementia-third-level.component';

import { BreadcrumbAboutDementiaThirdLevelResolver } from 'src/app/resolvers/breadcrumb-about-dementia-third-level.resolver';

import { WhatDementiaIsComponent } from 'src/app/pages/about-dementia/about-dementia-second-level/what-dementia-is/what-dementia-is.component';
import { ProphylaxisComponent } from 'src/app/pages/about-dementia/about-dementia-second-level/prophylaxis/prophylaxis.component';
import { DementiaTypesComponent } from 'src/app/pages/about-dementia/about-dementia-second-level/dementia-types/dementia-types.component';
import { DiagnosisTherapyComponent } from 'src/app/pages/about-dementia/about-dementia-second-level/diagnosis-therapy/diagnosis-therapy.component';
import { BREADCRUMBS } from 'src/app/constants/breadcrumbs_name';
import { ROUTE_PART_NAMES } from 'src/app/constants/route_part_names';
import { DoctorConsultationComponent } from 'src/app/pages/need-help/doctor-consultation/doctor-consultation.component';
import { OrganizeFundraiserComponent } from 'src/app/pages/join/organize-fundraiser/organize-fundraiser.component';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { UnderConstructionPageComponent } from './pages/under-construction-page/under-construction-page.component';
import { BreadcrumbPressReleasesDetailsResolver } from 'src/app/resolvers/breadcrumb-press-releases-details.resolver';
import { UnsubscribePageComponent } from 'src/app/pages/unsubscribe-page/unsubscribe-page.component';
import { BreadcrumbDetailedNewsResolver } from 'src/app/resolvers/breadcrumb-detailed-news.resolver';
import { ResearchDetailPageComponent } from 'src/app/pages/about-us/our-programs/research/research-detail-page/research-detail-page.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { DetailedEventsComponent } from './pages/activities/detailed-events/detailed-events.component';
import { breadcumbDetailedEventResolver } from './resolvers/breadcumb-detailed-event.resolver';
import { ResearchResolver } from './resolvers/research.resolver';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    data: {
      breadcrumb: BREADCRUMBS.main,
    },
  },
  {
    path: 'find-info',
    component: SalivonOleksandrComponent,
    data: {
      breadcrumb: 'Допомогти1',
    },
  },

  {
    path: 'sai',
    component: SalivonOleksandrComponent,
    data: {
      breadcrumb: 'Допомогти7',
    },
  },

  {
    path: ROUTE_PART_NAMES.aboutDementia,
    data: {
      breadcrumb: BREADCRUMBS.aboutDementia,
    },
    children: [
      {
        path: ROUTE_PART_NAMES.faq,
        component: FAQComponent,
        data: {
          breadcrumb: BREADCRUMBS.faq,
        },
      },
      {
        path: ROUTE_PART_NAMES.whatDementiaIs,
        data: {
          breadcrumb: BREADCRUMBS.whatDementiaIs,
        },

        children: [
          {
            path: ':thirdId',
            component: AboutDementiaThirdLevelComponent,
            resolve: { apiData: BreadcrumbAboutDementiaThirdLevelResolver },
          },
          {
            path: '',
            component: WhatDementiaIsComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: ROUTE_PART_NAMES.dementiaTypes,
        data: {
          breadcrumb: BREADCRUMBS.dementiaTypes,
        },

        children: [
          {
            path: ':thirdId',
            component: AboutDementiaThirdLevelComponent,
            resolve: { apiData: BreadcrumbAboutDementiaThirdLevelResolver },
          },
          {
            path: '',
            component: DementiaTypesComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: ROUTE_PART_NAMES.prophylaxis,
        data: {
          breadcrumb: BREADCRUMBS.prophylaxis,
        },

        children: [
          {
            path: ':thirdId',
            component: AboutDementiaThirdLevelComponent,
            resolve: { apiData: BreadcrumbAboutDementiaThirdLevelResolver },
          },
          {
            path: '',
            component: ProphylaxisComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: ROUTE_PART_NAMES.diagnosis,
        data: {
          breadcrumb: BREADCRUMBS.diagnosis,
        },

        children: [
          {
            path: ':thirdId',
            component: AboutDementiaThirdLevelComponent,
            resolve: { apiData: BreadcrumbAboutDementiaThirdLevelResolver },
          },
          {
            path: '',
            component: DiagnosisTherapyComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: '',
        component: AboutDementiaComponent,
        pathMatch: 'full',
      },
    ],
  },

  {
    path: ROUTE_PART_NAMES.getSupport,
    data: {
      breadcrumb: BREADCRUMBS.needHelp,
    },
    children: [
      {
        path: ROUTE_PART_NAMES.helpWithMedicine,
        data: {
          breadcrumb: BREADCRUMBS.helpWithMedicine,
        },
        children: [
          { path: '', component: HelpWithMedicineComponent, pathMatch: 'full' },
        ],
      },
      {
        path: ROUTE_PART_NAMES.psychologicalSupport,
        data: {
          breadcrumb: BREADCRUMBS.psychologicalSupport,
        },
        children: [
          {
            path: '',
            component: PsychologicalSupportComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: ROUTE_PART_NAMES.legalAssistance,
        data: {
          breadcrumb: BREADCRUMBS.legalAssistance,
        },
        children: [
          { path: '', component: LegalAssistanceComponent, pathMatch: 'full' },
        ],
      },
      {
        path: ROUTE_PART_NAMES.medicalAssistance,
        data: {
          breadcrumb: BREADCRUMBS.doctorConsultation,
        },
        children: [
          {
            path: '',
            component: DoctorConsultationComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: ROUTE_PART_NAMES.careConsultation,
        data: {
          breadcrumb: BREADCRUMBS.careConsultation,
        },
        children: [
          { path: '', component: CareConsultationComponent, pathMatch: 'full' },
        ],
      },
      {
        path: ROUTE_PART_NAMES.rehabilitationProgram,
        data: {
          breadcrumb: BREADCRUMBS.rehabilitationProgram,
        },
        children: [
          {
            path: '',
            component: RehabilitationProgramComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: ROUTE_PART_NAMES.informationalAssistance,
        data: {
          breadcrumb: BREADCRUMBS.informationalAssistance,
        },
        children: [
          {
            path: '',
            component: InformationalAssistanceComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: ROUTE_PART_NAMES.crisisAssistance,
        data: {
          breadcrumb: BREADCRUMBS.crisisAssistance,
        },
        children: [
          {
            path: ROUTE_PART_NAMES.helpWithGenerators,
            component: HelpWithGeneratorsComponent,
            data: {
              breadcrumb: BREADCRUMBS.helpWithGenerators,
            },
          },
          {
            path: ROUTE_PART_NAMES.helpAbroad,
            component: HelpAbroadComponent,
            data: {
              breadcrumb: BREADCRUMBS.helpAbroad,
            },
          },
          {
            path: ROUTE_PART_NAMES.helpWithEvacuation,
            component: HelpWithEvacuationComponent,
            data: {
              breadcrumb: BREADCRUMBS.helpWithEvacuation,
            },
          },
          {
            path: '',
            component: CrisisAssistanceComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: ROUTE_PART_NAMES.whatToDoIf,
        data: {
          breadcrumb: BREADCRUMBS.whatToDoIf,
        },
        children: [
          {
            path: ROUTE_PART_NAMES.iWorryAboutMemory,

            data: {
              breadcrumb: BREADCRUMBS.iWorryAboutMemory,
            },
            children: [
              {
                path: ':thirdId',
                component: AboutDementiaThirdLevelComponent,
                resolve: { apiData: BreadcrumbAboutDementiaThirdLevelResolver },
              },
              {
                path: '',
                component: IWorryAboutMemoryComponent,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: ROUTE_PART_NAMES.relativeHasDementia,

            data: {
              breadcrumb: BREADCRUMBS.relativeHasDementia,
            },
            children: [
              {
                path: ':thirdId',
                component: AboutDementiaThirdLevelComponent,
                resolve: { apiData: BreadcrumbAboutDementiaThirdLevelResolver },
              },
              {
                path: '',
                component: RelativeHasDementiaComponent,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: ROUTE_PART_NAMES.IHaveDementia,

            data: {
              breadcrumb: BREADCRUMBS.justDiagnosed,
            },
            children: [
              {
                path: ':thirdId',
                component: AboutDementiaThirdLevelComponent,
                resolve: { apiData: BreadcrumbAboutDementiaThirdLevelResolver },
              },
              {
                path: '',
                component: JustDiagnosedComponent,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: ROUTE_PART_NAMES.medicalEmployee,
            component: MedicalEmployeeComponent,
            data: {
              breadcrumb: BREADCRUMBS.medicalEmployee,
            },
          },
          {
            path: ROUTE_PART_NAMES.professionalCaregiver,
            component: ProfessionalCaregiverComponent,
            data: {
              breadcrumb: BREADCRUMBS.professionalCaregiver,
            },
          },
          {
            path: ROUTE_PART_NAMES.relativeHasDementia,
            component: RelativeHasDementiaComponent,
            data: {
              breadcrumb: BREADCRUMBS.relativeHasDementia,
            },
          },
          {
            path: '',
            redirectTo: PAGE_ROUTES.getSupport,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: '',
        component: NeedHelpComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: ROUTE_PART_NAMES.join,
    data: {
      breadcrumb: BREADCRUMBS.join,
    },
    children: [
      {
        path: ROUTE_PART_NAMES.donate,

        data: {
          breadcrumb: BREADCRUMBS.donate,
        },
        children: [
          { path: '', component: DonateComponent, pathMatch: 'full' },
          {
            path: 'resultPayment/:id',
            component: ResultPaymentComponent,
            data: {
              breadcrumb: BREADCRUMBS.resultPayment,
            },
          },
        ],
      },
      {
        path: ROUTE_PART_NAMES.becomeVolunteer,
        component: BecomeVolunteerComponent,
        data: {
          breadcrumb: BREADCRUMBS.becomeVolunteer,
        },
      },
      {
        path: ROUTE_PART_NAMES.becomePartner,
        component: BecomePartnerComponent,
        data: {
          breadcrumb: BREADCRUMBS.becomePartner,
        },
      },
      {
        path: ROUTE_PART_NAMES.organizeFundraising,
        component: OrganizeFundraiserComponent,
        data: {
          breadcrumb: BREADCRUMBS.organizeFundraising,
        },
      },
      { path: '', component: JoinComponent, pathMatch: 'full' },
    ],
  },
  {
    path: ROUTE_PART_NAMES.findInformation,
    data: {
      breadcrumb: BREADCRUMBS.findInformation,
    },
    children: [
      { path: '', component: FindInformationComponent, pathMatch: 'full' },
    ],
  },
  {
    path: ROUTE_PART_NAMES.activities,
    data: {
      breadcrumb: BREADCRUMBS.activities,
    },
    children: [
      {
        path: ':id',
        component: DetailedEventsComponent,
        resolve: {
          apiData: breadcumbDetailedEventResolver,
        },
      },
      {
        path: '',
        component: ActivitiesComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: ROUTE_PART_NAMES.aboutUs,
    data: {
      breadcrumb: BREADCRUMBS.aboutUs,
    },
    children: [
      {
        path: ROUTE_PART_NAMES.ourIdea,
        component: OurIdeaComponent,
        data: {
          breadcrumb: BREADCRUMBS.ourIdea,
        },
      },
      {
        path: ROUTE_PART_NAMES.contacts,
        component: ContactsComponent,
        data: {
          breadcrumb: BREADCRUMBS.contacts,
        },
      },
      {
        path: ROUTE_PART_NAMES.reports,
        data: {
          breadcrumb: BREADCRUMBS.reports,
        },
        children: [
          {
            path: ROUTE_PART_NAMES.financialStatements,
            component: TaxReportsComponent,
            data: {
              breadcrumb: BREADCRUMBS.reportsTax,
            },
          },
          {
            path: ROUTE_PART_NAMES.reportsYearly,
            component: AnnualReportsComponent, // Need to add the component AnnualReportsComponent, instead of this when the content appears
            data: {
              breadcrumb: BREADCRUMBS.reportsYearly,
            },
          },
          {
            path: ROUTE_PART_NAMES.foundingDocuments,
            component: DocumentsReportsComponent,
            data: {
              breadcrumb: BREADCRUMBS.reportsDocs,
            },
          },
          {
            path: ROUTE_PART_NAMES.reportsMonthly,
            component: MonthlyReportsComponent,
            data: {
              breadcrumb: BREADCRUMBS.reportsMonthly,
            },
          },
          { path: '', component: ReportsComponent, pathMatch: 'full' },
        ],
      },
      {
        path: ROUTE_PART_NAMES.ourPeople,
        data: {
          breadcrumb: BREADCRUMBS.ourPeople,
        },
        children: [
          {
            path: ROUTE_PART_NAMES.fundTeam,
            data: {
              breadcrumb: BREADCRUMBS.fundTeam,
            },
            children: [
              {
                path: ':id',
                component: FundMemberComponent,
                resolve: { apiData: BreadcrumbFundMemberResolver },
              },
              {
                path: '',
                component: FundTeamComponent,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: ROUTE_PART_NAMES.supervisoryBoard,
            component: SupervisoryBoardComponent,
            data: {
              breadcrumb: BREADCRUMBS.supervisoryBoard,
            },
          },
          {
            path: ROUTE_PART_NAMES.advisoryGroup,
            component: AdvisoryGroupComponent,
            data: {
              breadcrumb: BREADCRUMBS.advisoryGroup,
            },
          },
          {
            path: ROUTE_PART_NAMES.ourPartners,
            component: OurPartnersComponent,
            data: {
              breadcrumb: BREADCRUMBS.ourPartners,
            },
          },
          {
            path: ROUTE_PART_NAMES.vacancies,
            component: VacanciesComponent,
            data: {
              breadcrumb: BREADCRUMBS.vacancies,
            },
          },
          { path: '', component: OurPeopleComponent, pathMatch: 'full' },
        ],
      },
      {
        path: ROUTE_PART_NAMES.ourPrograms,
        data: {
          breadcrumb: BREADCRUMBS.ourPrograms,
        },
        children: [
          {
            path: ROUTE_PART_NAMES.helpDuringTheWar,
            component: HelpDuringTheWarComponent,
            data: {
              breadcrumb: BREADCRUMBS.helpDuringTheWar,
            },
          },
          {
            path: ROUTE_PART_NAMES.advocacy,
            component: SupportForFamiliesComponent,
            data: {
              breadcrumb: BREADCRUMBS.supportForFamilies,
            },
          },
          {
            path: ROUTE_PART_NAMES.friendlySociety,
            component: FriendlySocietyComponent,
            data: {
              breadcrumb: BREADCRUMBS.friendlySociety,
            },
          },
          {
            path: ROUTE_PART_NAMES.study,
            component: StudyComponent,
            data: {
              breadcrumb: BREADCRUMBS.study,
            },
          },
          {
            path: ROUTE_PART_NAMES.research,
            data: {
              breadcrumb: BREADCRUMBS.research,
            },
            children: [
              {
                path: ':id',
                component: ResearchDetailPageComponent,
                resolve: { apiData: ResearchResolver },
              },
              {
                path: '',
                component: ResearchComponent,
                pathMatch: 'full',
              },
            ],
          },
          { path: '', component: OurProgramsComponent, pathMatch: 'full' },
        ],
      },
      {
        path: ROUTE_PART_NAMES.newsAndMedia,
        data: {
          breadcrumb: BREADCRUMBS.newsAndMedia,
        },
        children: [
          {
            path: ROUTE_PART_NAMES.news,
            data: {
              breadcrumb: BREADCRUMBS.news,
            },
            children: [
              {
                path: ':id',
                component: DetailedNewsComponent,
                resolve: {
                  apiData: BreadcrumbDetailedNewsResolver,
                },
              },
              { path: '', component: NewsComponent, pathMatch: 'full' },
            ],
          },
          {
            path: ROUTE_PART_NAMES.mediaAboutUs,
            component: MediaAboutUsComponent,
            data: {
              breadcrumb: BREADCRUMBS.mediaAboutUs,
            },
          },
          {
            path: ROUTE_PART_NAMES.statistic,
            component: StatisticComponent,
            data: {
              breadcrumb: BREADCRUMBS.statistic,
            },
          },
          {
            path: ROUTE_PART_NAMES.mediaCenter,
            data: {
              breadcrumb: BREADCRUMBS.mediaCenter,
            },
            children: [
              {
                path: ROUTE_PART_NAMES.pressReleases,
                data: {
                  breadcrumb: BREADCRUMBS.pressReleases,
                },
                children: [
                  {
                    path: ':id',
                    component: PressReleasesDetailsComponent,
                    resolve: {
                      apiData: BreadcrumbPressReleasesDetailsResolver,
                    },
                  },
                  {
                    path: '',
                    component: PressReleasesComponent,
                    pathMatch: 'full',
                  },
                ],
              },
              {
                path: ROUTE_PART_NAMES.glossary,
                data: { breadcrumb: BREADCRUMBS.glossary },
                component: GlossaryComponent,
              },
              {
                path: ROUTE_PART_NAMES.howToTalkAboutDementiaInTheMedia,
                data: {
                  breadcrumb: BREADCRUMBS.howToTalkAboutDementiaInTheMedia,
                },
                children: [
                  {
                    path: '',
                    component: HowToTalkAboutDementiaInTheMediaComponent,
                    pathMatch: 'full',
                  },
                ],
              },
              {
                path: ROUTE_PART_NAMES.keyFactsAboutDementia,
                data: {
                  breadcrumb: BREADCRUMBS.keyFactsAboutDementia,
                },
                children: [
                  {
                    path: '',
                    component: KeyFactsAboutDementiaComponent,
                    pathMatch: 'full',
                  },
                ],
              },
              { path: '', component: MediaCenterComponent, pathMatch: 'full' },
            ],
          },
          { path: '', component: NewsAndMediaComponent, pathMatch: 'full' },
        ],
      },
      {
        path: '',
        component: AboutUsComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: ROUTE_PART_NAMES.donate,
    component: DonateComponent,
    data: {
      breadcrumb: BREADCRUMBS.donate,
    },
  },
  // { path: 'sai', component: SalivonOleksandrComponent },
  //
  // { path: 'product', component: ProductsComponent },
  // { path: 'filter', component: FilterComponent },
  // { path: 'information', component: InformationComponent },
  // { path: 'pagination', component: PaginationComponent },
  {
    path: ROUTE_PART_NAMES.pageInDevelopment,
    component: UnderConstructionPageComponent,
  },
  {
    path: ROUTE_PART_NAMES.unsubscribePage,
    component: UnsubscribePageComponent,
  },
  // { path: 'about', component: AboutDementiaComponent },
  // { path: 'test-backend', component: TestBackendComponent },
  {
    path: 'admin-nezabutni',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
