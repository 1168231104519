import { Component, Input, OnInit } from '@angular/core';
import { TabsHorizontal } from 'src/app/models/tabs-horizontal';
import { TextContentModel } from '../../services/api/text-content.service';

@Component({
  selector: 'app-about-dementia-content',
  templateUrl: './about-dementia-content.component.html',
  styleUrls: ['./about-dementia-content.component.css'],
})
export class AboutDementiaContentComponent implements OnInit {
  @Input() tabData: TextContentModel[] = [];
  @Input() textData!: TextContentModel;
  mainTabData: TabsHorizontal[] = [];

  constructor() {}

  ngOnInit(): void {
    this.mainTabData = this.tabsDataPrepared(this.tabData);
  }

  tabsDataPrepared(array: TextContentModel[]) {
    return array.map((el: TextContentModel) => ({
      ...el,
      linkTo: el.buttonURL,
      text: el.bodyText,
    }));
  }
}
