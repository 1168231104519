import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  FormErrorMessageService,
  FormErrors,
  FormFields,
  IErrorMessages,
} from '../../../services/form-error-message.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-input-adm',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './input-adm.component.html',
  styleUrl: './input-adm.component.css',
})
export class InputAdmComponent implements OnInit, DoCheck {
  @Input() control: FormControl = new FormControl();
  @Input() type: string = 'text';
  @Input() labelName: string = '';
  @Input() inputID: string = '';
  @Input() maxLength: string = '';
  @Input() darkInput: boolean = false;
  isInvalid = false;

  protected get formFields(): typeof FormFields {
    return FormFields;
  }

  protected get formErrors(): typeof FormErrors {
    return FormErrors;
  }

  constructor(private formErrorMessageService: FormErrorMessageService) {}
  ngDoCheck(): void {
    this.isInvalid =
      this.control.invalid && (this.control.touched || this.control.dirty);
  }

  ngOnInit(): void {}

  protected displayErrorMessage(
    formField: FormFields,
    formError: FormErrors,
  ): IErrorMessages | undefined {
    return this.formErrorMessageService.displayErrorMessage(
      formField,
      formError,
    );
  }
}
