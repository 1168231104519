<main>
  <section>
    <app-page-banner-item [pageName]="pageName"></app-page-banner-item>
    <div class="flex justify-center my-30 md:mb-20" *ngIf="combinedData">
      <div
        appPhoneLink
        class="content-container px-4 md:px-0 text-smaller-main md:text-main-text"
        [innerHtml]="combinedData.after_banner_text.bodyText"></div>
    </div>
  </section>

  <section class="flex justify-center mb-28">
    <div class="content-container px-4 md:px-0" *ngIf="combinedData">
      <h2
        class="text-h2-360 md:text-h2-heading uppercase text-primary-base mb-4.75">
        {{ combinedData.after_banner_text.title }}
      </h2>
      <ul class="text-smaller-main md:text-main-text" *ngIf="ideaCards.length">
        <li
          *ngFor="let card of ideaCards"
          class="flex justify-between items-center p-4 xl:py-7.5 xl:px-5 md:p-5 mb-2 md:mb-5 bg-gray-low rounded-20">
          <img
            class="block w-13.5 md:w-25 h-13.5 md:h-25"
            [src]="card.imageURL"
            [alt]="card.title" />
          <div class="flex flex-col w-full md:w-170 xl:w-256">
            <h4 class="text-smaller-bold md:text-main-text-bold mb-3">
              {{ card.title }}
            </h4>
            <div appPhoneLink [innerHtml]="card.bodyText"></div>
          </div>
        </li>
      </ul>
    </div>
  </section>

  <section class="flex justify-center mb-30" *ngIf="combinedData">
    <div class="content-container px-4 md:px-0">
      <h2
        class="text-h2-360 md:text-h2-heading uppercase text-primary-base mb-4.75">
        {{ combinedData.first_block.title }}
      </h2>
      <div
        class="flex flex-col text-smaller-main md:text-main-text mb-19.5"
        appPhoneLink
        [innerHtml]="combinedData.first_block.bodyText"></div>
      <div *ngIf="combinedData" class="mb-19.5">
        <h3 class="text-h3-360 md:text-h3-subheading text-primary-base mb-5.5">
          {{ combinedData.second_block.title }}
        </h3>
        <div
          appPhoneLink
          [innerHtml]="combinedData.second_block.bodyText"></div>
      </div>

      <div *ngIf="combinedData" class="mb-19.5">
        <div>
          <app-adaptive-page-slider
            *ngIf="imageListFirst.length"
            [skipLast]="false"
            [counterOfPages]="true"
            [amountOffCardsBlock]="imageListFirst.length"
            [hideButton]="[false, false, false]"
            class="hidden md:block relative w-full md:w-[410px] xl:w-[480px] overflow-hidden md:float-right">
            <div
              class="flex justify-start items-center ml-5 mb-5 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
              <img
                *ngFor="let image of imageListFirst"
                [src]="image.imageUrl"
                [alt]="image.imageUrl"
                class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[380px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[676px] object-cover rounded-[30px] border-2 border-gray-card" />
            </div>
          </app-adaptive-page-slider>
        </div>
        <h3 class="text-h3-360 md:text-h3-subheading text-primary-base mb-5.5">
          {{ combinedData.third_block.title }}
        </h3>
        <div appPhoneLink [innerHtml]="combinedData.third_block.bodyText"></div>
        <div class="w-full flex mt-10 md:hidden justify-center">
          <app-adaptive-page-slider
            *ngIf="imageListFirst.length"
            [skipLast]="false"
            [counterOfPages]="true"
            [amountOffCardsBlock]="imageListFirst.length"
            [hideButton]="[false, false, false]"
            class="relative w-full md:w-[410px] xl:w-[580px] overflow-hidden md:float-right">
            <div
              class="flex justify-start items-center ml-5 mb-5 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
              <img
                *ngFor="let image of imageListFirst"
                [src]="image.imageUrl"
                [alt]="image.imageUrl"
                class="shrink-0 w-[264px] h-[470px] object-cover rounded-[30px] border-2 border-gray-card" />
            </div>
          </app-adaptive-page-slider>
        </div>
      </div>
      <div *ngIf="combinedData" class="mb-19.5">
        <h3
          class="text-h3-360 md:text-h3-subheading text-primary-base mb-5.5 md:mb-4.5">
          {{ combinedData.fourth_block.title }}
        </h3>
        <div
          appPhoneLink
          [innerHtml]="combinedData.fourth_block.bodyText"></div>
      </div>
      <div
        *ngIf="combinedData"
        class="text-smaller-main md:text-main-text p-5 md:p-10 bg-gray-low rounded-20"
        appPhoneLink
        [innerHtml]="combinedData.last_block.bodyText"></div>
    </div>
  </section>

  <app-other-method-to-join
    [type]="'primary'"
    [inputCards]="cards"></app-other-method-to-join>
</main>
