import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  TextCardModel,
  TextCardService,
} from '../../../../services/api/text-card.service';
import { TextContentService } from '../../../../services/api/text-content.service';

interface IAccordionList {
  title: string;
  HTML: SafeHtml;
}
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FAQComponent implements OnInit {
  faq: IAccordionList[] = [];
  pageTextData: TextCardModel[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private textCardS: TextCardService,
    private readonly contentService: TextContentService,
  ) {}

  ngOnInit(): void {
    this.textCardS.getTextsCardByTag('faq').subscribe(res => {
      this.pageTextData = res.sort((a, b) => a.id - b.id);
    });
    this.getTextContent();
  }

  getTextContent() {
    this.contentService.getTextsContentByPageName('faq').subscribe(res => {
      this.faq = res
        .map(faq => ({
          id: faq.id,
          title: faq.title,
          HTML: this.sanitizer.bypassSecurityTrustHtml(faq.bodyText),
          key: 'accordion',
          tag: faq.pageName,
          orderId: faq.orderId,
        }))
        .sort((a, b) => a.orderId! - b.orderId!);
    });
  }
}
