<app-page-banner-item [pageName]="pageName"></app-page-banner-item>

<section class="py-30 px-4 md:px-15 xl:px-0">
  <div class="content-container flex flex-col gap-10" *ngIf="combinedData">
    <div
      class="text-gray-dark text-main-text"
      appPhoneLink
      [innerHtml]="combinedData.after_banner.bodyText"></div>
    <app-table-of-content
      class="flex mx-auto w-full"
      [links]="links"></app-table-of-content>
  </div>
</section>
<section
  *ngIf="combinedData"
  id="{{ combinedData.first_block.key }}"
  class="content-container pb-30 px-4 md:px-0">
  <div>
    <div class="flex flex-col xl:flex-row gap-5">
      <div *ngIf="combinedData">
        <img
          [src]="combinedData.first_block.imageURL"
          [alt]="combinedData.first_block.title"
          class="hidden xl:block xl:w-[380px] xl:h-[475px] rounded-20 xl:float-right xl:ml-10 xl-10 mx-auto xl:mb-5 object-cover object-bottom" />
        <h2 class="text-h2-heading text-primary-base uppercase">
          {{ combinedData.first_block.title }}
        </h2>
        <div
          class="text-gray-dark text-main-text mt-5"
          appPhoneLink
          [innerHTML]="combinedData.first_block.bodyText"></div>
        <app-button
          (click)="downloadPdfFile()"
          [title]="combinedData.first_block.buttonName"
          [buttonType]="'Primary'"
          [svg]="true"
          class="w-70 inline-block mt-10">
        </app-button>
      </div>
      <img
        [src]="combinedData.first_block.imageURL"
        [alt]="combinedData.first_block.title"
        class="xl:hidden w-[328px] h-[409px] md:w-[410px] md:h-[513px] rounded-20 md:float-right md:ml-10 mb-10 mx-auto md:mb-5 object-cover object-bottom" />
    </div>
  </div>
</section>
<section *ngIf="combinedData">
  <div [id]="combinedData.second_block.key" class="content-container py-30">
    <h2 class="text-h2-heading text-primary-base uppercase">
      {{ combinedData.second_block.title }}
    </h2>
    <div
      appPhoneLink
      class="text-gray-dark text-main-text mt-5"
      [innerHtml]="combinedData.second_block.bodyText"></div>
    <a
      *ngIf="
        combinedData.second_block.buttonURL &&
        combinedData.second_block.buttonURL.length
      "
      target="_blank"
      [href]="combinedData.second_block.buttonURL"
      class="flex justify-center mt-10 w-full md:w-75 text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer">
      <div class="text-main-text font-semibold">
        {{ combinedData.second_block.buttonName }}
      </div>
    </a>
  </div>
</section>

<section class="bg-gray-card py-30 px-[16px] md:px-0" *ngIf="combinedData">
  <div
    [id]="combinedData.third_block.key"
    class="content-container flex flex-col">
    <h2 class="text-h2-heading text-primary-base uppercase">
      {{ combinedData.third_block.title }}
    </h2>
    <div
      class="text-gray-dark text-main-text mt-5"
      appPhoneLink
      [innerHtml]="combinedData.third_block.bodyText"></div>
    <div
      *ngIf="thirdBlockUrl"
      class="rounded-30 relative pb-[56.25%] pr-[30px] h-0 overflow-hidden mt-10">
      <iframe
        class="w-full h-full top-0 left-0 md:w-210 xl:w-295 md:h-[473px] xl:h-166.25 absolute"
        [src]="thirdBlockUrl"
        frameborder="0"
        allowfullscreen></iframe>
    </div>
  </div>
</section>

<section class="py-30 px-4 md:px-15 xl:px-0" *ngIf="combinedData">
  <div [id]="combinedData.fourth_block.key" class="content-container">
    <h2 class="text-h2-heading text-primary-base uppercase">
      {{ combinedData.fourth_block.title }}
    </h2>
    <div
      class="text-gray-dark text-main-text mt-5"
      appPhoneLink
      [innerHtml]="combinedData.fourth_block.bodyText"></div>
    <app-accordion
      [AccordionsList]="accordionData"
      [options]="{ multi: false }"
      [classList]="'mt-10'"></app-accordion>
  </div>
</section>

<app-more-info [moreInfos]="moreInfosAid" [mainText]="mainText"></app-more-info>
