import { Component, Input, OnInit } from '@angular/core';
import IInfluence from '../../models/influence.interface';
import { ButtonType } from '../button/button.component';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { TextContentModel } from '../../services/api/text-content.service';

// type ButtonType2 = 'Donate' | 'Primary' | 'Secondary';
@Component({
  selector: 'app-our-influence',
  templateUrl: './our-influence.component.html',
  styleUrls: ['./our-influence.component.css'],
})
export class OurInfluenceComponent implements OnInit {
  @Input() ourAchievementsTextBlock!: TextContentModel;
  @Input() ourAchievementsCardsBlock!: TextContentModel[];
  buttonType: ButtonType = 'Primary';
  reportLink: string = PAGE_ROUTES.reports;
  influences: any[] = [];
  constructor() {}
  ngOnInit(): void {
    this.influences = this.ourAchievementsCardsBlock.map(
      (el: TextContentModel, i: number) => ({
        ...el,
        imgURL: influences[i].img,
        icon: influences[i].icon,
      }),
    );
  }
}

export const influences = [
  {
    icon: 'cash',
    img: 'assets/images/influences/money.png',
  },
  {
    icon: 'account-group-outline',
    img: 'assets/images/influences/pill.png',
  },
  {
    icon: 'map-marker-outline',
    img: 'assets/images/influences/clock.png',
  },
  {
    icon: 'medication-outline',
    img: 'assets/images/influences/people.png',
  },
  {
    icon: 'clock-outline',
    img: 'assets/images/influences/heart.png',
  },
  {
    icon: 'file-document-edit-outline',
    img: 'assets/images/influences/search.png',
  },
];
