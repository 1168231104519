<ng-template #banner>
  <div *ngIf="bannerData">
    <img
      [src]="bannerData.imgUrl"
      [alt]="bannerData.title"
      class="h-150 xl:h-auto w-full object-cover overflow-hidden" />
    <div
      class="flex justify-center md:hidden relative -top-20 text-gray-white text-h1-heading">
      {{ bannerData.title | uppercase }}
    </div>
  </div>
</ng-template>

<ng-container *ngIf="bannerLoading; else banner">
  <div class="pt-30">
    <div
      class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full gradient-animation"></div>
      </div>
    </div>
  </div>
</ng-container>

<div
  *ngIf="portalTextBlock"
  class="content-container text-start flex px-4 md:px-0 sm:mt-30 mt-20">
  <div>
    <app-block-text
      class="content-container text-start flex px-4 md:px-0 my-0"
      [text]="portalTextBlock"></app-block-text>
  </div>
</div>

<ng-container *ngIf="cards.length === 0; else content">
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-container>

<ng-template #content>
  <app-info-cards [cards]="cards" [classList]="'flex my-30'"></app-info-cards>
</ng-template>
