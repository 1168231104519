import { Component, OnDestroy, OnInit } from '@angular/core';
import IResearchCard from 'src/app/models/researchCard.interface';
import { researchCardMockDate } from 'src/mockData/researchCardMockDate';
import {
  researchCardModel,
  researchDataModel,
  ResearchService,
} from '../../../../services/api/programs-page/research.service';
import { combineLatest, Subscription } from 'rxjs';
import {
  TextContentModel,
  TextContentService,
} from '../../../../services/api/text-content.service';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.css'],
})
export class ResearchComponent implements OnInit, OnDestroy {
  pageName: string = 'about-us/programs/research';
  links: { id: string; title: string }[] = [];
  combinedData: {
    research: researchDataModel;
    otherPublication: any;
    textContent: TextContentModel[];
  } | null = null;
  publicationsArray: researchCardModel[] = [];
  subs: Subscription = new Subscription();

  constructor(
    private apiService: ResearchService,
    private textContentService: TextContentService,
  ) {}

  ngOnInit(): void {
    const sub = combineLatest([
      this.apiService.getResearchPublications(),
      this.apiService.getOtherPublications(),
      this.textContentService.getTextsContentByPageName(this.pageName),
    ]).subscribe(([research, otherPublication, textContent]) => {
      this.combinedData = {
        research: { ...research, key: 'research' },
        otherPublication,
        textContent: textContent.sort((a, b) => a.orderId! - b.orderId!),
      };
      this.links.push({
        id: this.combinedData!.research.key!,
        title: this.combinedData!.research.title,
      });
      this.links.push({
        id: this.combinedData!.textContent[0].key,
        title: this.combinedData!.textContent[0].title,
      });
      this.links.push({
        id: this.combinedData!.textContent[1].key,
        title: this.combinedData!.textContent[1].title,
      });
      const cardsArray = this.combinedData.research.fundResearchSecond;
      this.publicationsArray = cardsArray.slice(0, 3);
    });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  showMore() {
    this.publicationsArray = this.combinedData!.research.fundResearchSecond;
  }
}
