import {
  Component,
  ContentChild,
  ElementRef,
  AfterContentInit,
  HostListener,
  Output,
  EventEmitter,
  ViewChild,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-custom-editor',
  templateUrl: './custom-editor.component.html',
  styleUrls: ['./custom-editor.component.css'],
})
export class CustomEditorComponent implements AfterContentInit {
  @ViewChild('editableDiv') editableDiv!: ElementRef;
  @ContentChild('myElement', { static: false }) myElement!: ElementRef;
  @Input() edit: boolean = false;
  @Input() menu?: boolean = true;
  @Input() removeBtn?: boolean = true;
  @Input() backGround?: string = 'bg-gray-card';
  @Input() limit?: number;
  @Input() buttonPosition?: string;
  @Input() editLikeHtml?: boolean = false;
  @Output() editedText: any = new EventEmitter();
  lenghtValidator: boolean = false;
  textLenght: number = 0;
  selectedText: string = '';
  isEditing!: boolean;
  text: string = '';
  inputText: string = '';
  classList: string = '';
  constructor(private elementRef: ElementRef) {}

  public ngAfterContentInit() {
    if (this.myElement) {
      this.inputText = this.text = this.myElement.nativeElement.innerHTML;
      this.classList = this.myElement.nativeElement.classList.value;
      this.textLenght = this.inputText.length;
    }
  }
  @HostListener('dblclick')
  startEditing() {
    if (this.edit) {
      this.isEditing = true;
      this.inputText = this.text = this.myElement.nativeElement.innerHTML;
      this.lenghtValidator = false;
      this.textLenght = this.inputText.length;
    }
  }

  @HostListener('click', ['$event'])
  hostClick(event: Event) {
    event.stopPropagation();
  }

  @HostListener('window:click', ['$event'])
  stopEditing(event: Event) {
    if (event.target !== this.elementRef.nativeElement) {
      this.isEditing = false;
      this.text = this.inputText;
      this.sendText(this.text);
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      event.preventDefault();
    }
  }

  // @HostListener('paste', ['$event'])
  // onPaste(event: ClipboardEvent) {
  //   event.preventDefault();
  // }

  changeText(event: Event) {
    const text = event!.target as HTMLElement;
    this.textLenght = text.innerText.length;
    if (text.innerText.length > this.limit! || text.innerText.length <= 1) {
      this.lenghtValidator = true;
    }
    if (text.innerText.length <= this.limit! && text.innerText.length > 1) {
      this.lenghtValidator = false;
      this.inputText = text.innerText;
      this.sendText(text.innerText);
    }
    if (!this.limit) {
      this.inputText = text.innerText;
      this.sendText(text.innerText);
    }
  }

  sendText(text: string): string {
    if (text) {
      this.editedText.emit({
        text: text,
        editState: this.isEditing,
        remove: false,
      });
    }
    return text;
  }

  selected() {
    const selectedText = this.getSelectedText();
    if (selectedText != '') {
      this.selectedText = selectedText;
    }
  }

  getSelectedText(): string {
    const selectedText = window.getSelection()!.toString();
    return selectedText;
  }

  public editText(event: string) {
    if (this.selectedText !== '' && this.selectedText !== ' ') {
      const newText = this.text.replace(this.selectedText, event);
      this.editableDiv.nativeElement.innerHTML = newText;
      this.inputText = this.text = newText;
      this.sendText(newText);
      this.resetSelection();
    }
  }

  resetSelection(): void {
    const selection = window.getSelection();
    selection!.removeAllRanges();
  }

  public makeStandart(event: string) {
    this.inputText = this.text = event;
    this.sendText(event);
  }

  public removeText() {
    this.editedText.emit({
      text: this.text,
      editState: this.isEditing,
      remove: true,
    });
  }

  public breakText(event: boolean) {
    const myElement = this.editableDiv.nativeElement;
    const selection = window.getSelection();
    if (event) {
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const textNode = document.createElement('br');
        range.insertNode(textNode);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
    this.text = this.inputText = myElement.innerHTML.toString();
    this.sendText(this.text);
  }

  public pressEnter(event: KeyboardEvent) {
    if (event.code === 'Enter' && this.menu) {
      event.preventDefault();
      this.breakText(true);
    }
  }
}
