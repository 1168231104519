import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fund-member-card-item',
  templateUrl: './fund-member-card-item.component.html',
  styleUrls: ['./fund-member-card-item.component.css'],
})
export class FundMemberCardItemComponent implements OnInit {
  @Input() text: string = '';
  @Input() title: string = '';
  @Input() imgUrl: string = '';
  @Input() url: string = '';
  constructor() {}

  ngOnInit(): void {}
}
