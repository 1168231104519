import { Component, Input, OnInit } from '@angular/core';
import IInfoPage from '../../models/infoPage.interface';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
})
export class InfoCardComponent implements OnInit {
  constructor() {}
  @Input() card!: IInfoPage;
  @Input() queryParams!: any;
  ngOnInit(): void {}
}
