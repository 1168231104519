import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  LegalAssistanceCardInterface,
  LegalAssistanceService,
} from 'src/app/services/api/getSupportPages/legal-assistance-api.service';
import { OpenPdf } from 'src/app/services/pdfOpen.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  TextContentModel,
  TextContentService,
} from '../../../services/api/text-content.service';
import {
  DocsReportModel,
  ReportsDocsService,
} from '../../../services/api/aboutUsPages/AboutFondPages/ReportsAndDocsPages/reports-docs.service';

@Component({
  selector: 'app-legal-assistance',
  templateUrl: './legal-assistance.component.html',
  styleUrls: ['./legal-assistance.component.css'],
})
export class LegalAssistanceComponent implements OnInit, OnDestroy {
  pageName = 'legal-assistance';
  combinedData: {
    first_block: TextContentModel;
    first_block_second: TextContentModel;
    second_block: TextContentModel;
    third_block: TextContentModel;
    fourth_block: TextContentModel;
    [key: string]: TextContentModel;
  } | null = null;
  pageData: TextContentModel[] = [];
  subs: Subscription = new Subscription();
  accordionData: any[] = [];
  links: { id: string; title: string }[] = [];
  secondBlockUrl: SafeResourceUrl | null = null;
  pageDocsData: DocsReportModel[] | null = null;

  legalHelpCards: LegalAssistanceCardInterface = {
    title: '',
    description: '',
    cards: [],
  };
  textForMoreInfo: { mainHeader: string; mainContent: string }[] = [];

  constructor(
    private openPdf: OpenPdf,
    private pageService: LegalAssistanceService,
    private _sanitizer: DomSanitizer,
    private apiService: TextContentService,
    private getDocs: ReportsDocsService,
  ) {}

  ngOnInit(): void {
    this.getPageTextData();
    this.getPageDocs();
    this.getCards();
  }

  getCards() {
    const sub = this.pageService
      .getDataCards(this.pageName)
      .subscribe((res: LegalAssistanceCardInterface) => {
        this.legalHelpCards = res;
        const text = {
          mainHeader: res.title,
          mainContent: '',
        };
        this.textForMoreInfo.push(text);
      });
    this.subs.add(sub);
  }

  getPageDocs() {
    const sub = this.getDocs.getReportsByType(this.pageName).subscribe(res => {
      this.pageDocsData = res.sort((a, b) => a.orderId - b.orderId);
    });
    this.subs.add(sub);
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.combinedData = {
          first_block: this.pageData.find(el => el.key === 'first_block')!,
          first_block_second: this.pageData.find(
            el => el.key === 'first_block_second',
          )!,
          second_block: this.pageData.find(el => el.key === 'second_block')!,
          third_block: this.pageData.find(el => el.key === 'third_block')!,
          fourth_block: this.pageData.find(el => el.key === 'fourth_block')!,
        };
        this.accordionData = this.accordionPreparing(
          this.pageData.filter(el => el.key === 'accordion'),
        );
        this.secondBlockUrl = this.sanitizedLink(
          this.combinedData!.second_block.buttonURL,
        );
        this.links = this.pageData
          .filter(el => el.groupType === 'main_text')
          .map(el => ({ id: el.key, title: el.title }));
      });
    this.subs.add(sub);
  }

  accordionPreparing(arr: TextContentModel[]) {
    return arr
      .map(faq => ({
        id: faq.id,
        title: faq.title,
        HTML: this._sanitizer.bypassSecurityTrustHtml(faq.bodyText),
        key: 'accordion',
        tag: faq.pageName,
        orderId: faq.orderId,
      }))
      .sort((a, b) => a.orderId! - b.orderId!);
  }

  sanitizedLink(url: string) {
    const link = `https://www.youtube-nocookie.com/embed/${url}`;
    return this._sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  public downloadPdfFile(): void {
    if (this.pageDocsData![0].documentUrl)
      this.openPdf.getPDF(
        this.pageDocsData![0].documentUrl,
        'Legal assistance',
      );
  }
}
