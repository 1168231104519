import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAboutDementiaThirdLevelPage } from 'src/app/models/aboutDementia.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AboutDementiaThirdLevelPageService {
  private apiUrl: string = environment.apiUrl;
  private adminUrl: string = this.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getData(linkTranslit: string): Observable<IAboutDementiaThirdLevelPage> {
    const url = `${this.apiUrl}/AboutDementia/third-level-page?linkTranslit=${linkTranslit}`;
    return this.http.get<IAboutDementiaThirdLevelPage>(url);
  }

  deleteThirdLevelPageTextCard(id: number) {
    return this.http.delete(
      `${this.adminUrl}DeleteThirdLevelTextCard?id=${id}`,
    );
  }

  addThirdLevelPageTextCard(data: any) {
    return this.http.post(`${this.adminUrl}AddThirdLevelTextCard`, data);
  }

  updateThirdLevelPageTextCard(data: any) {
    return this.http.put(`${this.adminUrl}UpdateThirdLevelTextCard`, data);
  }

  updateThirdLevelUnifiedPage(data: FormData) {
    return this.http.put(`${this.adminUrl}UpdateThirdLevelUnifiedPage`, data);
  }

  updateSecondLevelPageCard(data: any) {
    return this.http.put(`${this.adminUrl}UpdateSecondLevelPageCard`, data);
  }

  updateSecondLevelPageCardOrderId(data: any) {
    return this.http.put(
      `${this.adminUrl}UpdateSecondLevelPageCardOrder`,
      data,
    );
  }

  createSecondthLevelPage(data: FormData) {
    return this.http.post(`${this.adminUrl}AddSecondLevelPageCard`, data);
  }

  deleteSecondthPageCard(id: number) {
    return this.http.delete(
      `${this.adminUrl}/DeleteSecondLevelPageCard?id=${id}`,
    );
  }
}
