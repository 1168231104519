import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { NewsApiService } from 'src/app/services/api/aboutUsPages/NewsAndMedia/news-api.service';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbDetailedNewsResolver  {
  constructor(private newsService: NewsApiService) {}
  fetchPressRelease(id: string): Observable<any> {
    return this.newsService.getNewsById(id);
  }
  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<(any & { breadcrumb: string }) | null> {
    const pressReleaseId = route.paramMap.get('id');
    if (pressReleaseId) {
      return this.fetchPressRelease(pressReleaseId).pipe(
        map(data => {
          return {
            breadcrumb: data.title,
            ...data,
          };
        }),
      );
    } else return of(null);
  }
}
