import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LinkButtonComponent } from 'src/app/admin/components/button/button.component';

@NgModule({
  declarations: [LinkButtonComponent],
  imports: [CommonModule, RouterModule],
  exports: [LinkButtonComponent],
})
export class ButtonModule {}
