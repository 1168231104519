<div class="flex w-95 h-140 relative justify-center">
  <div class="flex mx-auto w-95 h-140 relative">
    <img
      alt="{{ title }}"
      class="absolute right-0 rounded-30 object-cover w-95 h-140"
      src="{{ imgUrl }}" />
    <div
      class="mx-auto w-95 h-52 rounded-b-30 bottom-0 absolute z-10 bg-[linear-gradient(0deg,#6E285F_43%,rgba(110,40,95,0)_100%)]"></div>
  </div>
  <div class="w-75 mx-auto absolute z-20 pb-5 bottom-0">
    <h2 class="text-h2-heading text-gray-white uppercase pb-2 w-75">
      {{ title }}
    </h2>
    <p class="text-gray-white text-smaller-main w-75 pb-2">{{ text }}</p>
    <button
      *ngIf="useModal; else linkMode"
      (click)="openModal()"
      class="text-gray-white text-main-text-bold underline underline-offset-5 cursor-pointer"
      role="button">
      Детальніше
    </button>
    <ng-template #linkMode>
      <a
        [routerLink]="url"
        class="text-gray-white text-main-text-bold w-75 underline underline-offset-5">
        Детальніше
      </a>
    </ng-template>
  </div>
</div>

<!-- Використання нової модалки -->
<app-modal
  *ngIf="isModalOpen"
  [isOpen]="isModalOpen"
  [title]="title"
  [text]="text"
  [description]="description"
  (close)="closeModal()">
</app-modal>
