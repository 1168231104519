<div
  class="flex items-center justify-between w-[106px] cursor-pointer group/select"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin">
  <div class="flex items-center group-hover/select:text-secondary-base">
    <img
      src="{{
        'assets/icons/flags-icon-svg/' + selectedCountry.flagIcon + '.svg'
      }}"
      alt="{{ selectedCountry.name }}"
      class="w-4 h-6" />

    <span class="text-main-text ml-1">{{
      selectedCountry.phoneCountryCode
    }}</span>
  </div>
  <svg
    width="10px"
    height="5px"
    [icon]="'arrow-bottom'"
    iconCollection="button-icons-sprite"
    class="fill-gray-dark group-hover/select:fill-secondary-base"
    [ngClass]="{ 'rotate-180': isOpen }"></svg>
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOffsetX]="-18"
  [cdkConnectedOverlayOffsetY]="10"
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="close()"
  (detach)="close()">
  <div
    class="w-75 h-70 overflow-scroll p-1 bg-white border-gray-stroke border-2 rounded-10 origin-top"
    [@dropDown]
    (@dropDown.done)="onPanelAnimationDone($event)">
    <ng-content></ng-content>
  </div>
</ng-template>
