import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IContactFormData } from 'src/app/models/contact-form.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FundMemberPageService {
  private url: string = environment.apiUrl;
  constructor(private http: HttpClient) {}
  sendEmail(data: IContactFormData): Observable<any> {
    return this.http.post<any>(`${this.url}/Email/team-member`, data, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response',
    });
  }
}
