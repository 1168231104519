<app-page-banner-item [pageName]="pageName"></app-page-banner-item>
<div class="grid mt-30">
  <app-table-of-content
    class="flex mx-auto w-full px-4 md:px-0"
    [links]="links"></app-table-of-content>
</div>

<div
  [id]="combinedData.first_block.key"
  class="content-container py-30 px-4 md:px-0"
  *ngIf="combinedData">
  <h3 class="text-h2-heading text-primary-base uppercase">
    {{ combinedData.first_block.title }}
  </h3>
  <div
    class="mt-5"
    appPhoneLink
    [innerHTML]="combinedData.first_block.bodyText"></div>
  <a
    *ngIf="
      combinedData.first_block.buttonURL &&
      combinedData.first_block.buttonURL.length
    "
    [href]="combinedData.first_block.buttonURL"
    target="_blank"
    class="flex justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer md:w-75 mt-10">
    <div class="text-main-text font-semibold">
      {{ combinedData.first_block.buttonName }}
    </div>
  </a>
</div>
<div
  *ngIf="combinedData"
  [id]="combinedData.second_block.key"
  class="bg-gray-card">
  <div class="content-container py-30 px-4 md:px-0">
    <h3 class="text-h2-heading text-primary-base uppercase">
      {{ combinedData.second_block.title }}
    </h3>
    <div
      appPhoneLink
      class="mt-5 mb-10"
      [innerHTML]="combinedData.second_block.bodyText"></div>

    <div class="rounded-30 relative pb-[56.25%] pr-[30px] h-0 overflow-hidden">
      <iframe
        class="w-full h-full top-0 left-0 md:w-210 xl:w-295 md:h-[473px] xl:h-166.25 absolute"
        [src]="secondBlockUrl"
        frameborder="0"
        allowfullscreen></iframe>
    </div>
  </div>
</div>
<div
  *ngIf="combinedData"
  [id]="combinedData.third_block.key"
  class="content-container px-4 md:px-0">
  <div class="mb-10">
    <h3 class="text-h2-heading text-primary-base uppercase mt-30">
      {{ combinedData.third_block.title }}
    </h3>
    <div
      appPhoneLink
      class="text-gray-dark text-main-text mt-10"
      [innerHtml]="combinedData.third_block.bodyText"></div>
  </div>
  <app-accordion
    [AccordionsList]="accordionData"
    [options]="{ multi: false }"
    [classList]="'mb-30'"></app-accordion>
</div>

<app-more-info [moreInfos]="moreInfosAid" [mainText]="mainText"></app-more-info>
