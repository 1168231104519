<div class="flex flex-col content-container">
  <div class="content-container mb-10">
    <h1
      class="md:text-h1-heading text-h1-360 text-primary-base uppercase md:w-95 px-4 md:px-0">
      Новини
    </h1>
  </div>

  <app-filter
    [ListArray]="['Спочатку нові', 'Спочатку старі']"
    (chosenValue)="valueForFilter($event)">
  </app-filter>
  <div
    *ngIf="cardInfo.length !== 0; else loader"
    class="grid gap-5 xl:grid-cols-3 smd:grid-cols-2 px-4 md:px-0">
    <app-card *ngFor="let card of cardInfo" [cardInfo]="card"></app-card>
  </div>
  <app-pagination
    class="md:mt-20 mt-15"
    (pageChange)="changePage($event)"
    [newsOnPage]="9"
    [pages]="count"></app-pagination>
</div>
<app-subscribe-news></app-subscribe-news>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
