import { Component, OnDestroy, OnInit } from '@angular/core';
import moreIInfo from '../../../models/moreInfo.interface';
import { moreInfoPsychSup } from '../../../../mockData/moreInfoMockData';
import moreIInfoMain from '../../../models/moreInfoMainModel';
import { PAGE_ROUTES } from '../../../constants/page_routes';
import {
  TextContentModel,
  TextContentService,
} from '../../../services/api/text-content.service';
import { Subscription } from 'rxjs';
import { portalImageLinkModel } from '../../../services/api/portal-links.service';

@Component({
  selector: 'app-informational-assistance',
  templateUrl: './informational-assistance.component.html',
  styleUrls: ['./informational-assistance.component.css'],
})
export class InformationalAssistanceComponent implements OnInit, OnDestroy {
  pageName: string = 'get-support/hot-line';
  isHovered = false;

  pageData: TextContentModel[] = [];
  combinedData: {
    banner_text: TextContentModel;
    first_block: TextContentModel;
    second_block: TextContentModel;
    third_block: TextContentModel;
    third_block_second: TextContentModel;
    fourth_block: TextContentModel;
  } | null = null;
  buttonsLink: TextContentModel[] = [];
  subs: Subscription = new Subscription();
  links: { id: string; title: string }[] = [];

  moreInfosAid: moreIInfo[] = moreInfoPsychSup;
  mainText: moreIInfoMain[] = [
    {
      mainHeader: ' Більше інформації про види допомоги',
      mainContent: '',
    },
  ];
  formsData: TextContentModel | null = null;
  formsDataAgreement: TextContentModel | null = null;

  constructor(private contentService: TextContentService) {}

  ngOnInit(): void {
    this.getContactsFormData();
    this.getPageTextData();
  }

  getContactsFormData() {
    this.contentService
      .getTextsContentByPageName('about-us/contacts')
      .subscribe(res => {
        if (res) {
          this.formsData = res.find(el => el.key === 'contacts_form-data')!;
          this.formsDataAgreement = res.find(
            el => el.key === 'contacts_form-agreement',
          )!;
        }
      });
  }

  getPageTextData() {
    const sub = this.contentService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.combinedData = {
          banner_text: this.pageData.find(el => el.key === 'banner_text')!,
          first_block: this.pageData.find(el => el.key === 'first_block')!,
          second_block: this.pageData.find(el => el.key === 'second_block')!,
          third_block: this.pageData.find(el => el.key === 'third_block')!,
          third_block_second: this.pageData.find(
            el => el.key === 'third_block_second',
          )!,
          fourth_block: this.pageData.find(el => el.key === 'fourth_block')!,
        };
        this.links = this.pageData
          .filter(el => el.groupType === 'main_text')
          .map(el => ({ id: el.key, title: el.title }));
        this.buttonsLink = this.pageData.filter(
          el => el.key === 'buttons_link',
        );
      });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
