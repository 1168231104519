import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
})
export class DropdownComponent implements OnInit {
  @Input() ListArray!: string[];
  @Output() OutChosedElem = new EventEmitter();
  public choosedElem = '';
  public showList = false;
  constructor() {}

  ngOnInit(): void {
    this.choosedElem = this.ListArray[0];
  }

  changeValue(opt: string) {
    this.ListArray.unshift(opt);
    this.ListArray = Array.from(new Set(this.ListArray));
    this.choosedElem = opt;
    this.OutChosedElem.emit(this.choosedElem);
    this.showList = false;
  }
}
