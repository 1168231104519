<div
  *ngIf="isOpen"
  class="fixed inset-0 flex items-center justify-center z-50"
  role="dialog"
  aria-modal="true"
  (keydown)="onKeyDown($event)">
  <div
    class="fixed inset-0 bg-gray-900 opacity-75"
    (click)="closeModal()"></div>

  <div
    class="bg-white px-6 pt-0 pb-6 md:px-10 md:pb-10 rounded-[30px] w-[90%] md:min-w-[800px] max-w-lg shadow-lg relative max-h-[70vh] overflow-y-auto modal-content custom-scrollbar">
    <div
      class="sticky top-0 left-0 right-0 pt-6 md:pt-10 pb-2 md:pb-4 bg-white z-20">
      <button
        class="absolute top-3 right-0 cursor-pointer w-8 h-8 flex items-center justify-center rounded-full text-accent-base text-4xl hover:text-accent-additional"
        (click)="closeModal()"
        aria-label="Закрити модальне вікно">
        ×
      </button>
      <h2 class="text-xl font-bold text-primary-base">{{ title }}</h2>
      <p class="text-gray-600 text-smaller-main mb-4">{{ text }}</p>
    </div>

    <div class="relative">
      <p class="text-gray-dark text-main-text leading-relaxed">
        {{ description }}
      </p>

      <div
        class="sticky -bottom-6 md:-bottom-10 left-0 right-0 h-10 bg-gradient-to-t from-white to-transparent z-10"></div>
    </div>
  </div>
</div>
