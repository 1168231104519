<div class="inline-flex items-baseline gap-1 shrink-0">
  <ng-container *ngIf="!isCopy">
    <button
      class="w-6 h-6 md:w-7 md:h-7 self-end"
      (click)="changeIcon()"
      cdkCopyToClipboard="{{ textValue }}"
      aria-label="copyEmail">
      <svg
        width="20px"
        height="24px"
        icon="copy"
        iconCollection="button-icons-sprite"
        class="fill-accent-base hover:fill-secondary-base active:fill-secondary-additional w-full h-full duration-300"></svg>
    </button>
  </ng-container>
  <ng-container *ngIf="isCopy">
    <button
      class="w-6 h-6 md:w-7 md:h-7 relative self-end"
      (click)="changeIcon()"
      cdkCopyToClipboard="{{ textValue }}"
      aria-label="copyEmail">
      <svg
        width="20px"
        height="24px"
        icon="checkBox"
        iconCollection="button-icons-sprite"
        class="fill-secondary-base w-full h-full"></svg>
    </button>
    <div
      id="pop-over"
      class="bg-gray-white absolute -translate-x-10 translate-y-8 text-gray-medium w-28.5 h-6 rounded-30 text-details flex justify-center items-center z-10">
      Скопійовано
    </div>
  </ng-container>
  <a
    *ngIf="showText"
    class="md:pointer-events-none pt-0.5"
    [ngClass]="textColor"
    href="mailto:info@nezabutni.org"
    >{{ textValue }}</a
  >
</div>
