<section class="py-30 relative" *ngIf="aboutFondTextBlock.length">
  <div
    class="flex justify-start xl:justify-between flex-wrap gap-5 bg-gray-card rounded-t-30 px-4 md:px-7.5 xl:px-25 py-10 md:pt-10 xl:pt-20 md:pb-15 mx-2 md:mx-7.5 xl:mx-auto xl:w-295">
    <h2
      class="w-70 text-primary-base text-h2-heading md:text-h1-heading uppercase">
      {{ aboutFondTextBlock[0].title }}
    </h2>

    <div
      class="xl:w-170 text-gray-dark text-smaller-main md:text-main-text"
      [innerHTML]="aboutFondTextBlock[0].bodyText"></div>
  </div>
  <div class="bg-contain bg-center bg-no-repeat xl:max-w-[100%]">
    <img
      [src]="aboutFondTextBlock[0].imageURL"
      [alt]="aboutFondTextBlock[0].title"
      class="md:hidden mx-auto w-full" />
    <img
      [src]="aboutFondTextBlock[0].imageURL"
      [alt]="aboutFondTextBlock[0].title"
      class="hidden mx-auto md:block" />
  </div>
  <div
    class="w-full xl:content-container my-0 mx-auto flex flex-col justify-between">
    <div
      class="bg-gray-card rounded-b-30 pt-10 md:pt-15 pb-4 md:pb-10 xl:pb-20 px-4 md:px-7.5 xl:px-25 mx-2 md:mx-7.5 xl:mx-0"
      [innerHTML]="aboutFondTextBlock[1].bodyText"></div>
  </div>
</section>
