<div [id]="cardBlockId">
  <div class="text-primary-base text-h2-heading-bold text-center md:text-left">
    {{ cardBlockTitle | uppercase }}
  </div>
  <div
    *ngIf="cardList"
    class="flex flex-wrap gap-5 mt-10 justify-center md:justify-start">
    <div *ngFor="let subCard of cardList.subMenuCards">
      <app-page-link-card [card]="subCard"></app-page-link-card>
    </div>
  </div>
</div>
