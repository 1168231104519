export const ourIdeaMockData = {
  videoURL: 'https://www.youtube-nocookie.com/embed/VZYcP5h7K5g',
  pageMenuLinks: [
    {
      id: 'who_are',
      title: 'Хто такі “Незабутні”',
    },
    {
      id: 'our_history',
      title: 'Наша історія',
    },
    {
      id: 'our_values',
      title: 'Наші цінності',
    },
    {
      id: 'our_symbol',
      title: 'Наш символ',
    },
  ],
};
