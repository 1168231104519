import { Component, Input, OnInit } from '@angular/core';

import { IHelpCard } from '../../../models/help-card.interface';
import { TextContentModel } from '../../../services/api/text-content.service';

@Component({
  selector: 'app-help-card',
  templateUrl: './help-card.component.html',
  styleUrls: ['./help-card.component.css'],
})
export class HelpCardComponent implements OnInit {
  @Input()
  public card!: TextContentModel;

  @Input()
  public type: string | undefined;

  protected get borderType(): string {
    return this.type === 'secondary' ? 'border-2 ' : '';
  }

  constructor() {}

  ngOnInit(): void {}
}
