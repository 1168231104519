import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { TabsHorizontal } from 'src/app/models/tabs-horizontal';
import { BreakpointObserverService } from 'src/app/services/breakpoint-observer.service';

@Component({
  selector: 'app-tabs-horizontal',
  templateUrl: './tabs-horizontal.component.html',
  styleUrls: ['./tabs-horizontal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsHorizontalComponent implements OnInit, OnChanges {
  public isMobile$ = this.breakpoinService.isMobile();
  public isTablet$ = this.breakpoinService.isTablet();
  activeTab!: TabsHorizontal;
  @Input() tabsArray!: TabsHorizontal[];

  constructor(
    private breakpoinService: BreakpointObserverService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.makeFirstActive();
  }

  public selectItem(tab: TabsHorizontal) {
    this.tabsArray.forEach(tab => {
      tab.active = false;
      this.cdr.markForCheck();
    });
    tab.active = true;
    this.setTabData(tab);
    this.cdr.markForCheck();
  }

  makeFirstActive() {
    this.isMobile$.subscribe(res => {
      if (!res) {
        this.activeTab = this.tabsArray[0];
        this.activeTab.active = true;
        this.setTabData(this.activeTab);
        this.cdr.detectChanges();
      }
    });
  }

  setTabData(tab: any) {
    this.activeTab = tab;
    this.cdr.detectChanges();
  }

  public ngOnChanges() {
    this.makeFirstActive();
  }
}
