<div class="mx-4 my-2 rounded-10 p-4 border-2 border-gray-gray2">
  <ngx-editor-menu
    [editor]="editor"
    [toolbar]="toolbar"
    [customMenuRef]="customMenu"
    [colorPresets]="colorPresets"></ngx-editor-menu>
  <ngx-editor
    class="custom-editor"
    [placeholder]="placeholder"
    [editor]="editor"
    [(ngModel)]="text">
  </ngx-editor>
  <div class="flex justify-end px-5 pt-2">
    <button
      (click)="saveChanges($event)"
      class="p-5 text-gray-white rounded-10 bg-accent-base active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent">
      {{ buttonText }}
    </button>
  </div>
</div>
<ng-template #customMenu>
  <app-custom-menu [editor]="editor"></app-custom-menu>
</ng-template>
