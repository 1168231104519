import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.css'],
})
export class CopyButtonComponent implements OnInit {
  constructor(private cdr: ChangeDetectorRef) {}
  @Input() textColor: string = '';
  @Input() showText?: boolean = true;
  @Input() textValue: string = 'info@nezabutni.org';
  isCopy: boolean = false;
  ngOnInit(): void {}
  changeIcon() {
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
      this.cdr.detectChanges();
    }, 1000);
  }
}
