import { Component, OnInit } from '@angular/core';
import { IHelpCard } from 'src/app/models/help-card.interface';
import { helpCardsForOurPartners } from 'src/mockData/ourPartners_helpCards_MockData';
import {
  TextContentModel,
  TextContentService,
} from '../../../../services/api/text-content.service';

@Component({
  selector: 'app-our-partners',
  templateUrl: './our-partners.component.html',
  styleUrls: ['./our-partners.component.css'],
})
export class OurPartnersComponent implements OnInit {
  pageName: string = 'about-us/our-people/our-partners';
  helpCards: TextContentModel[] = helpCardsForOurPartners;
  textBlock: { title: string; text: string } = {
    title: 'ЯК Я МОЖУ ДОПОМОГТИ?',
    text: 'Зміни починаються з мене, з тебе і з маленьких та великих кроків. Навчання, пошук та обмін інформацією про деменцію вже можуть стати першим зерном глобальних змін. Доєднуйтесь до створення дружнього до деменції середовища',
  };
  combinedData: any | null = null;
  pageData: TextContentModel[] = [];
  constructor(private apiService: TextContentService) {}

  ngOnInit(): void {
    this.getPageTextData();
  }
  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res;
        this.combinedData = {
          first_block: this.pageData.find(el => el.key === 'first_block'),
          second_block: this.pageData.find(el => el.key === 'second_block'),
          visioners: this.pageData
            .filter(el => el.groupType === 'visioners')
            .sort((a, b) => a.orderId! - b.orderId!),
        };
      });
  }
}
