import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  concatMap,
  delay,
  mergeMap,
  Observable,
  of,
  Subject,
  timer,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  data: MessageModel[] = [];
  private newDataSubject = new Subject<any>();
  constructor() {
    this.newDataSubject
      .pipe(
        mergeMap(newData => {
          if (newData) {
            this.data.push(newData);
            return timer(5000).pipe(
              mergeMap(() => {
                const index = this.data.indexOf(newData);
                if (index !== -1) {
                  this.data.splice(index, 1);
                }
                return of(this.data);
              }),
            );
          }
          return of(this.data);
        }),
      )
      .subscribe();
  }

  getData(): Observable<any[]> {
    return of(this.data);
  }

  newMessage(newData: MessageModel) {
    this.newDataSubject.next(newData);
  }
}

export interface MessageModel {
  message: string;
  style: 'success' | 'error';
}
