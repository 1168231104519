<div class="xl:w-295 w-full md:px-15 mx-auto py-30 px-5 xl:px-0">
  <div class="flex flex-col items-center mx-auto">
    <div class="flex items-start mb-15 xl:flex-row xl:flex-nowrap flex-col">
      <h3
        class="text-primary-base text-h1-heading w-full md:w-95 pt-1 mb-5 md:mb-5 list">
        {{ textData.title | uppercase }}
      </h3>
      <div
        class="text-lg w-full xl:w-195 text-gray-dark main-text"
        [innerHTML]="textData.bodyText"></div>
    </div>
  </div>
  <app-tabs-horizontal [tabsArray]="mainTabData"></app-tabs-horizontal>
</div>
