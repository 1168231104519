<app-page-banner-item [pageName]="pageName"></app-page-banner-item>

<section class="py-30 px-4 md:px-15 xl:px-0">
  <div class="content-container flex flex-col gap-10" *ngIf="links">
    <app-table-of-content
      class="flex mx-auto w-full"
      [links]="links"></app-table-of-content>
  </div>
  <div
    id="{{ combinedBlocks!.first_block[0].key }}"
    class="content-container mt-30"
    *ngIf="combinedBlocks">
    <h2 class="text-h2-heading text-primary-base uppercase">
      {{ combinedBlocks!.first_block[0].title }}
    </h2>
    <div class="mt-5 flex flex-col gap-10">
      <div
        appPhoneLink
        class="flex flex-col gap-5"
        [innerHtml]="combinedBlocks.first_block[0].bodyText"></div>
      <div class="flex flex-col gap-5">
        <div class="flex flex-col md:flex-row gap-5">
          <div class="gap-5 w-full">
            <img
              [src]="combinedBlocks.first_block[1].imageURL"
              [alt]="combinedBlocks.first_block[0].title"
              class="w-82 md:w-102.5 xl:w-145 h-[224px] md:h-[282px] xl:h-100 rounded-30 md:float-right md:ml-5 md:mb-5" />
            <div
              appPhoneLink
              class="w-full"
              [innerHtml]="combinedBlocks.first_block[1].bodyText"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-gray-card py-30 px-[16px] md:px-0" *ngIf="combinedBlocks">
  <div
    id="{{ combinedBlocks!.secondth_block.key }}"
    class="content-container flex flex-col">
    <h2 class="text-h2-heading text-primary-base uppercase">
      {{ combinedBlocks!.secondth_block.title }}
    </h2>
    <div
      appPhoneLink
      class="text-gray-dark text-main-text mt-5"
      [innerHtml]="combinedBlocks.secondth_block.bodyText"></div>
    <div
      *ngIf="pageDocsData"
      class="flex flex-col md:flex-wrap md:flex-row gap-5 mt-10">
      <a
        *ngFor="let doc of pageDocsData"
        target="_blank"
        class="flex flex-col w-full md:w-95 h-39 justify-between border-2 border-gray-stroke cursor-pointer py-7.75 pl-10 rounded-30 group hover:border-secondary-base duration-300 bg-white"
        href="{{ doc.documentUrl }}">
        <div class="text-main-text-bold w-75">
          {{ doc.title }}
        </div>
        <div
          class="flex flex-row justify-start text-accent-base cursor-pointer group-hover:text-secondary-base active:text-secondary-additional duration-300">
          <p class="text-smaller-bold pr-3">Відкрити PDF</p>
          <svg
            class="fill-accent-base group-hover:fill-secondary-base active:fill-secondary-additional group-hover:ml-2 duration-300"
            [icon]="'right-arrow-small'"
            iconCollection="button-icons-sprite"
            height="26"
            width="30"></svg>
        </div>
      </a>
    </div>
  </div>
</section>

<section class="py-30 px-4 md:px-15 xl:px-0" *ngIf="combinedBlocks">
  <div id="{{ combinedBlocks.third_block[0].key }}" class="content-container">
    <h2 class="text-h2-heading text-primary-base uppercase">
      {{ combinedBlocks.third_block[0].title! }}
    </h2>
    <div
      appPhoneLink
      class="flex flex-col gap-5 mt-5 text-gray-dark text-main-text"
      [innerHtml]="combinedBlocks.third_block[0].bodyText"></div>
    <div
      class="rounded-30 relative pb-[56.25%] pr-[30px] h-0 overflow-hidden mt-10">
      <iframe
        class="w-full h-full top-0 left-0 md:w-210 xl:w-295 md:h-[473px] xl:h-166.25 absolute"
        [src]="safedYoutubeUrl"
        frameborder="0"
        allowfullscreen></iframe>
    </div>
    <div
      class="flex flex-col mt-20 text-gray-dark text-main-text"
      [innerHtml]="combinedBlocks.third_block[1].bodyText"></div>
    <app-adaptive-page-slider
      *ngIf="imageListFirst.length"
      [skipLast]="false"
      [counterOfPages]="true"
      [amountOffCardsBlock]="imageListFirst.length"
      [hideButton]="[false, false, false]"
      class="relative w-full md:w-[410px] xl:w-[580px] overflow-hidden">
      <div
        class="flex justify-start items-center mt-10 mb-10 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
        <img
          *ngFor="let image of imageListFirst"
          [src]="image.imageUrl"
          [alt]="image.imageUrl"
          class="shrink-0 w-[280px] sm:w-[300px] md:w-[410px] xl:w-[380px] h-[350px] sm:h-[512px] xl:h-[474px] object-cover rounded-[30px] border-2 border-gray-card" />
      </div>
    </app-adaptive-page-slider>
    <div class="flex flex-col mt-20 text-gray-dark text-main-text">
      <h3 class="text-h3-subheading text-primary-base">
        {{ combinedBlocks.third_block[2].title }}
      </h3>
      <div class="flex flex-col md:flex-row gap-10 md:gap-5 mt-5">
        <div
          class="flex flex-col gap-5 w-full md:w-102.5 xl:w-145"
          appPhoneLink
          [innerHtml]="combinedBlocks.third_block[2].bodyText"></div>
        <app-adaptive-page-slider
          *ngIf="imageListSecond.length"
          [skipLast]="false"
          [counterOfPages]="true"
          [amountOffCardsBlock]="imageListSecond.length"
          [hideButton]="[false, false, false]"
          class="relative w-full md:w-[410px] xl:w-[580px] overflow-hidden">
          <div
            class="flex justify-start items-center mt-10 mb-10 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
            <img
              *ngFor="let image of imageListSecond"
              [src]="image.imageUrl"
              [alt]="image.imageUrl"
              class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[480px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[600px] object-cover rounded-[30px] border-2 border-gray-card" />
          </div>
        </app-adaptive-page-slider>
      </div>
    </div>
  </div>
  <div
    id="{{ combinedBlocks.fourth_block.key }}"
    class="content-container mt-30">
    <h2 class="text-h2-heading text-primary-base uppercase mb-5">
      {{ combinedBlocks.fourth_block.title }}
    </h2>
    <img
      src="{{ combinedBlocks.fourth_block.imageURL }}"
      alt="{{ combinedBlocks.fourth_block.title }}"
      class="w-82 md:w-102.5 xl:w-145 h-[224px] md:h-[282px] xl:h-100 rounded-30 md:mb-5 md:ml-5 md:float-right" />
    <div appPhoneLink [innerHtml]="combinedBlocks.fourth_block.bodyText"></div>
    <div class="mt-30 flex flex-col gap-10">
      <app-link-button
        [url]="combinedBlocks.fourth_block.buttonURL"
        [linkButtonType]="'Primary'"
        [title]="'Зв’язатися з фондом'"
        class="w-[328px] sm:w-[280px] flex justify-center items-center mx-auto">
      </app-link-button>
    </div>
  </div>
</section>

<div class="py-30 bg-primary-base">
  <app-info-cards [cards]="helpWithMedicineCards"></app-info-cards>
</div>
