<app-page-banner-item [pageName]="pageName"> </app-page-banner-item>

<section class="mb-30 container pt-30">
  <app-table-of-content [links]="links"></app-table-of-content>
</section>
<section
  *ngIf="combinedData"
  id="{{ combinedData.first_block.key }}"
  class="content-container py-30 px-4 md:px-0">
  <div>
    <div class="flex flex-col xl:flex-row gap-5">
      <div *ngIf="combinedData">
        <img
          [src]="combinedData.first_block.imageURL"
          [alt]="combinedData.first_block.title"
          class="hidden xl:block xl:w-[380px] xl:h-[475px] rounded-20 xl:float-right xl:ml-10 xl-10 mx-auto xl:mb-5 object-cover object-bottom" />
        <h2 class="text-h2-heading text-primary-base uppercase">
          {{ combinedData.first_block.title }}
        </h2>
        <div
          class="text-gray-dark text-main-text mt-5"
          appPhoneLink
          [innerHTML]="combinedData.first_block.bodyText"></div>
        <app-button
          (click)="downloadPdfFile()"
          [title]="combinedData.first_block.buttonName"
          [buttonType]="'Primary'"
          [svg]="true"
          class="w-70 inline-block mt-10">
          <svg
            width="24px"
            height="24px"
            icon="download"
            iconCollection="button-icons-sprite"
            class="svg fill-gray-white"></svg>
        </app-button>
      </div>
      <img
        [src]="combinedData.first_block.imageURL"
        [alt]="combinedData.first_block.title"
        class="xl:hidden w-[328px] h-[409px] md:w-[410px] md:h-[513px] rounded-20 md:float-right md:ml-10 mb-10 mx-auto md:mb-5 object-cover object-bottom" />
    </div>
  </div>
  <div
    class="mt-15"
    appPhoneLink
    [innerHTML]="combinedData.first_block_second.bodyText"></div>
</section>
<section
  *ngIf="combinedData"
  id="{{ combinedData.second_block.key }}"
  class="bg-gray-low">
  <div class="content-container py-30 px-4 md:px-0">
    <h2 class="text-h2-heading text-primary-base uppercase mb-4 md:mb-10">
      {{ combinedData.second_block.title }}
    </h2>
    <div appPhoneLink [innerHTML]="combinedData.second_block.bodyText"></div>
    <div
      class="rounded-30 relative pb-[56.25%] pr-[30px] h-0 overflow-hidden mt-10">
      <iframe
        *ngIf="combinedData.second_block.buttonURL"
        class="w-full h-full top-0 left-0 md:w-210 xl:w-295 md:h-[473px] xl:h-166.25 absolute"
        [src]="secondBlockUrl"
        frameborder="0"
        allowfullscreen></iframe>
    </div>
  </div>
</section>
<section
  *ngIf="combinedData"
  id="{{ combinedData.third_block.key }}"
  class="content-container py-30 px-4 md:px-0">
  <h2 class="text-h2-heading text-primary-base uppercase mb-4 md:mb-10">
    {{ combinedData.third_block.title }}
  </h2>
  <div appPhoneLink [innerHTML]="combinedData.third_block.bodyText"></div>
  <a
    *ngIf="
      combinedData.third_block.buttonURL &&
      combinedData.third_block.buttonURL.length
    "
    [href]="combinedData.third_block.buttonURL"
    target="_blank"
    ><app-button
      class="w-75 flex mt-4 md:mt-10"
      [title]="combinedData.third_block.buttonName"
      [buttonType]="'Primary'">
    </app-button
  ></a>
</section>
<section
  *ngIf="combinedData"
  id="{{ combinedData.fourth_block.key }}"
  class="content-container py-30 px-4 md:px-0">
  <h3 class="text-h2-heading text-primary-base uppercase">
    {{ combinedData.fourth_block.title }}
  </h3>
  <div
    class="mt-5 mb-10"
    [innerHtml]="combinedData.fourth_block.bodyText"></div>
  <app-accordion [AccordionsList]="accordionData"></app-accordion>
</section>
<div class="bg-primary-base">
  <app-more-info
    [moreInfos]="legalHelpCards.cards"
    [mainText]="textForMoreInfo"></app-more-info>
</div>
