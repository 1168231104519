import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left',
  templateUrl: './circle_triangle_left_fill_icon.svg',
})
export class LeftComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
