<ng-content *ngIf="!isEditing"></ng-content>
<ng-container *ngIf="isEditing">
  <div class="relative {{ classList }}">
    <div
      *ngIf="!editLikeHtml"
      #editableDiv
      (input)="changeText($event)"
      (keyup)="pressEnter($event)"
      (mouseup)="selected()"
      [innerHTML]="text"
      [ngClass]="{
        'pt-8': menu,
        'pt-2.5': !menu,
        'text-warning-base bg-warning-base bg-opacity-40': lenghtValidator
      }"
      class=" rounded-10 bg-opacity-60 {{ backGround }} {{ classList }}"
      contenteditable="true"></div>
    <div
      *ngIf="editLikeHtml"
      #editableDiv
      (input)="changeText($event)"
      (keyup)="pressEnter($event)"
      (mouseup)="selected()"
      [innerText]="text"
      [ngClass]="{
        'pt-8': menu,
        'pt-2.5': !menu,
        'text-warning-base bg-warning-base bg-opacity-40': lenghtValidator
      }"
      class=" rounded-10 bg-opacity-60 {{ backGround }} {{ classList }}"
      contenteditable="true"></div>
    <div
      *ngIf="lenghtValidator && textLenght > limit!"
      class="absolute -bottom-5 text-details text-warning-base lowercase">
      Довжина тексту зараз:{{ textLenght }}, а можна {{ limit }}
    </div>
    <div
      *ngIf="lenghtValidator && textLenght <= 1"
      class="absolute -bottom-5 text-details text-warning-base lowercase">
      Довжина тексту зараз:{{ textLenght }}, не можна менше 1
    </div>
    <app-editor-menu
      (addBreack)="breakText($event)"
      (allText)="makeStandart($event)"
      (edditedText)="editText($event)"
      *ngIf="menu"
      [selected]="selectedText"
      [text]="text"></app-editor-menu>
    <div
      (click)="stopEditing($event)"
      [ngClass]="[
        removeBtn ? 'right-12' : 'right-0',
        buttonPosition ? buttonPosition : '-top-5'
      ]"
      class="lowercase text-main-text text-gray-white absolute cursor-pointer text-center -top-5 w-10 h-10 rounded-full bg-primary-base border-2 border-gray-white p-1"
      title="save">
      🖫
    </div>
    <div
      (click)="removeText()"
      *ngIf="removeBtn"
      class="lowercase text-main-text absolute cursor-pointer right-0 text-center -top-5 w-10 h-10 rounded-full bg-warning-base border-2 border-gray-white p-1"
      title="delete">
      &#10060;
    </div>
  </div>
</ng-container>
