import IFundMember from 'src/app/models/fundMember.interface';

export const fundMembersMock: any[] = [
  {
    memberID: '1',
    title: 'Ірина Шевченко',
    position: 'Засновниця та директорка',
    text: `Однією з перших задач організації я бачу створення програми допомоги людям з деменцією і їх сім'ям. Щоб кожна родина, яка зіштовхнулася з діагнозом "деменція" могла отримати якісну та своєчасну допомогу та підтримку. А люди з деменцією сприймалися суспільством з повагою та розумінням./pЦим преєктом я хочу об'єднати людей, небайдужих до деменції. Я бачу велику потребу в єдиній платформі, на якій всі, кого якимось чином стосується ця проблема, могли вчитись, ділитись та спільно шукати рішення./pВ мене багато думок та ідей, що можна зробити прямо зараз і в майбутньому. І далі я буду писати про ті напрацювання, які вже маю./p Зараз я шукаю однодумців, щоб разом працювати над створенням цього преєкту. Разом ми будемо шукати рішення і втілювати їх в життя!`,
    img: 'assets/images/fund-members/fund-member-shevchenko.jpg',
  },
  {
    memberID: '2',
    title: 'Ірина Зборовська',
    position: 'Співзасновниця та волонтерка фонду.',
    text: `Однією з перших задач організації я бачу створення програми допомоги людям з деменцією і їх сім'ям. Щоб кожна родина, яка зіштовхнулася з діагнозом "деменція" могла отримати якісну та своєчасну допомогу та підтримку. А люди з деменцією сприймалися суспільством з повагою та розумінням./pЦим преєктом я хочу об'єднати людей, небайдужих до деменції. Я бачу велику потребу в єдиній платформі, на якій всі, кого якимось чином стосується ця проблема, могли вчитись, ділитись та спільно шукати рішення./pВ мене багато думок та ідей, що можна зробити прямо зараз і в майбутньому. І далі я буду писати про ті напрацювання, які вже маю./p Зараз я шукаю однодумців, щоб разом працювати над створенням цього преєкту. Разом ми будемо шукати рішення і втілювати їх в життя!`,
    img: 'assets/images/fund-members/fund-member-zborovska.jpg',
  },
  {
    memberID: '3',
    title: 'Марта Лебеденко',
    position: 'Засновниця та волонтерка фонду, Голова Правління фонду',
    text: `Однією з перших задач організації я бачу створення програми допомоги людям з деменцією і їх сім'ям. Щоб кожна родина, яка зіштовхнулася з діагнозом "деменція" могла отримати якісну та своєчасну допомогу та підтримку. А люди з деменцією сприймалися суспільством з повагою та розумінням./pЦим преєктом я хочу об'єднати людей, небайдужих до деменції. Я бачу велику потребу в єдиній платформі, на якій всі, кого якимось чином стосується ця проблема, могли вчитись, ділитись та спільно шукати рішення./pВ мене багато думок та ідей, що можна зробити прямо зараз і в майбутньому. І далі я буду писати про ті напрацювання, які вже маю./p Зараз я шукаю однодумців, щоб разом працювати над створенням цього преєкту. Разом ми будемо шукати рішення і втілювати їх в життя!`,
    img: 'assets/images/fund-members/fund-member-lebedenko.jpg',
  },
  {
    memberID: '4',
    title: 'Марта Лебеденко',
    position: 'Засновниця та волонтерка фонду, Голова Правління фонду',
    text: `Однією з перших задач організації я бачу створення програми допомоги людям з деменцією і їх сім'ям. Щоб кожна родина, яка зіштовхнулася з діагнозом "деменція" могла отримати якісну та своєчасну допомогу та підтримку. А люди з деменцією сприймалися суспільством з повагою та розумінням./pЦим преєктом я хочу об'єднати людей, небайдужих до деменції. Я бачу велику потребу в єдиній платформі, на якій всі, кого якимось чином стосується ця проблема, могли вчитись, ділитись та спільно шукати рішення./pВ мене багато думок та ідей, що можна зробити прямо зараз і в майбутньому. І далі я буду писати про ті напрацювання, які вже маю./p Зараз я шукаю однодумців, щоб разом працювати над створенням цього преєкту. Разом ми будемо шукати рішення і втілювати їх в життя!`,
    img: 'assets/images/fund-members/fund-member-lebedenko2.jpg',
  },
  {
    memberID: '5',
    title: 'Ірина шевченко',
    position: 'Засновниця та волонтерка фонду, Голова Правління фонду',
    text: `Однією з перших задач організації я бачу створення програми допомоги людям з деменцією і їх сім'ям. Щоб кожна родина, яка зіштовхнулася з діагнозом "деменція" могла отримати якісну та своєчасну допомогу та підтримку. А люди з деменцією сприймалися суспільством з повагою та розумінням./pЦим преєктом я хочу об'єднати людей, небайдужих до деменції. Я бачу велику потребу в єдиній платформі, на якій всі, кого якимось чином стосується ця проблема, могли вчитись, ділитись та спільно шукати рішення./pВ мене багато думок та ідей, що можна зробити прямо зараз і в майбутньому. І далі я буду писати про ті напрацювання, які вже маю./p Зараз я шукаю однодумців, щоб разом працювати над створенням цього преєкту. Разом ми будемо шукати рішення і втілювати їх в життя!`,
    img: 'assets/images/fund-members/fund-member-shevchenko2.jpg',
  },
];
