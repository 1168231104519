import { map, Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  researchDetails,
  ResearchService,
} from '../services/api/programs-page/research.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResearchResolver {
  constructor(private apiService: ResearchService) {}

  fetchData(id: string): Observable<researchDetails> {
    return this.apiService.getPageDataByLinkTranslit(id);
  }
  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<(researchDetails & { breadcrumb: string }) | null> {
    const researchId = route.paramMap.get('id');
    if (researchId) {
      return this.fetchData(researchId).pipe(
        map(data => {
          return {
            breadcrumb: data.title,
            ...data,
          };
        }),
      );
    } else return of(null);
  }
}
