<div
  class="xl:w-[380px] md:w-[410px] w-[328px] rounded overflow-hidden relative">
  <img class="w-full rounded-t-3xl" [src]="moreInfo.img" alt="image" />
  <div
    class="flex flex-col items-center justify-between rounded-b-30 h-70 bg-gray-white">
    <div>
      <h2 class="px-10 pt-5 text-h3-subheading text-gray-dark">
        {{ moreInfo.header }}
      </h2>
      <p class="px-10 pt-2.5 text-gray-dark text-details">
        {{ moreInfo.content }}
      </p>
    </div>
    <app-link-button
      class="w-75 h-12 m-10 mt-0"
      [title]="'Детальніше'"
      [linkButtonType]="'Primary'"
      routerLink="{{ moreInfo.link }}">
    </app-link-button>
  </div>
</div>
