import { Component, OnDestroy, OnInit } from '@angular/core';
import { TextContentService } from '../../../../services/api/text-content.service';
import {
  portalImageLinkModel,
  PortalLinksService,
} from '../../../../services/api/portal-links.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-support-for-families',
  templateUrl: './support-for-families.component.html',
  styleUrls: ['./support-for-families.component.css'],
})
export class SupportForFamiliesComponent implements OnInit, OnDestroy {
  pageName: string = 'about-us/programs/advocacy';
  subs: Subscription = new Subscription();
  combinedData: any | null = null;
  imageListFirst: portalImageLinkModel[] = [];
  imageListSecond: portalImageLinkModel[] = [];
  links: { id: string; title: string }[] | null = null;
  constructor(
    private apiService: TextContentService,
    private portalImageLinkService: PortalLinksService,
  ) {}

  ngOnInit(): void {
    this.getPageTextData();
    this.getPortalImageLinks();
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.combinedData = {
          after_banner_text: res.find(el => el.key === 'after_banner_text'),
          first_block: res
            .filter(el => el.key === 'first_block')
            .sort((a, b) => a.orderId! - b.orderId!),
          second_block: res.find(el => el.key === 'second_block'),
          last_block: res.find(el => el.key === 'last_block'),
        };
        this.links = res
          .sort((a, b) => a.id - b.id)
          .filter(el => el.groupType === 'main_text')
          .map(el => ({ id: el.key, title: el.title }));
      });
    this.subs.add(sub);
  }

  getPortalImageLinks() {
    const sub = this.portalImageLinkService
      .getPortalLinksByPageName(this.pageName)
      .subscribe(res => {
        this.imageListFirst = res.filter(el => el.tag === 'first_gallery');
        this.imageListSecond = res.filter(el => el.tag === 'second_gallery');
      });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
