import { ISocialLink } from 'src/app/models/social-link.interface';

export const socialLinksMockData: ISocialLink[] = [
  {
    link: 'https://www.instagram.com/nezabutni_/',
    image: 'instagram',
    width: '24px',
    height: '24px',
  },
  {
    image: 'facebook',
    link: 'https://www.facebook.com/nezabutni.org',
    width: '18px',
    height: '30px',
  },
  {
    image: 'youtube',
    link: 'https://www.youtube.com/channel/UCIhdADy2Q8xzImxhzQK549w/featured',
    width: '26px',
    height: '20px',
  },
  {
    image: 'telegram',
    link: 'https://t.me/NezabutniCF',
    width: '30px',
    height: '22px',
  },
  {
    image: 'viber',
    link: 'https://invite.viber.com/?g2=AQBrcrOiC1eYD0%2FwbncaUKw%2BDHJik%2FgYMT8bUv%2F0H3d28l1XNHIZLBdrDQKEoglQ',
    width: '26px',
    height: '29px',
  },
];
