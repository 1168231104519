import { Component, OnInit } from '@angular/core';
import { ReportsCardInterface } from '../reports-card/reports-card.component';
import { statutoryDocsMockData } from 'src/mockData/statutoryDocsMockData';

@Component({
  selector: 'app-documents-reports',
  templateUrl: './documents-reports.component.html',
  styleUrls: ['./documents-reports.component.css'],
})
export class DocumentsReportsComponent {
  type: string = 'founding';

  emptyPortalBlock() {
    return {
      id: 74,
      tag: this.type,
      title: '',
      text: '',
    };
  }
}
