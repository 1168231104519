import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CountryList } from 'src/app/components/forms/phone-input/data/country-list';
import { Country } from 'src/app/models/country.model';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css'],
})
export class PhoneInputComponent implements OnInit, DoCheck {
  @Input() control: FormControl = new FormControl();
  @Input() countryCode: FormControl = new FormControl();
  selectedCountry: Country = {
    name: 'Ukraine',
    nameUa: 'Україна',
    countryCode: 'ua',
    phoneCountryCode: '+380',
    flagIcon: 'ua',
    placeholder: '00-000-00-00',
    mask: '00-000-00-00',
    preferred: true,
  };
  isInvalid = false;

  countryList: Country[] = [];

  filteredCountries = CountryList;

  constructor() {}

  ngOnInit(): void {
    this.countryList = CountryList;
  }

  setCurrentCountry(event: Country) {
    this.selectedCountry = event;
    this.clear();
  }

  phone!: string;
  clear() {
    this.phone = '';
  }

  ngDoCheck(): void {
    this.isInvalid =
      this.control.invalid && (this.control.touched || this.control.dirty);
  }

  inputValue(event: Event) {
    const searchValue = (event.target as HTMLInputElement).value;
    this.filteredCountries = this.countryList.filter(
      el =>
        el.name
          .concat(el.phoneCountryCode)
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()) ||
        el.nameUa
          .concat(el.phoneCountryCode)
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()),
    );
    console.log(this.filteredCountries);
    console.log(searchValue);
  }
}
