import { Component, Input, OnInit } from '@angular/core';
import IInfoPage from '../../models/infoPage.interface';

@Component({
  selector: 'app-info-cards',
  templateUrl: './info-cards.component.html',
})
export class InfoCardsComponent implements OnInit {
  constructor() {}
  @Input() cards: IInfoPage[] = [];

  ngOnInit(): void {}
}
