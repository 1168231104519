import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-fund',
  templateUrl: './about-fund.component.html',
  styleUrls: ['./about-fund.component.css'],
})
export class AboutFundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
