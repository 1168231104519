import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IsHoverService } from 'src/app/services/main-menu.service';
@Component({
  selector: 'app-submenu-items-list',
  templateUrl: './submenu-items-list.component.html',
  styleUrls: ['./submenu-items-list.component.css'],
})
export class SubmenuItemsListComponent implements OnInit, OnDestroy {
  @Input() links?: any = [];
  @Input() title: string = '';
  @Input() titleLink: string = '';
  @Input() titleLinkId: string = '';
  @Input() queryParams?: any;
  isHovered = false;
  subscription!: Subscription;
  constructor(private isHover: IsHoverService) {}

  ngOnInit(): void {
    this.subscription = this.isHover.currentHover.subscribe(
      hover => (this.isHovered = hover),
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  stop(event: Event) {
    event.stopPropagation();
    this.isHover.changeHover(false);
  }
}
