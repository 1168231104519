import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-link-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class LinkButtonComponent implements OnInit {
  @Input() title: string = '';
  @Input() svg?: boolean = false;
  @Input() type?: ButtonType = 'button';
  @Input() url?: string;
  @Input() isHovered?: boolean = false;
  @Input() disabled?: boolean = false;
  name = 'Angular';
  showFirstIcon = true;
  constructor() {}

  ngOnInit(): void {}
}

export type ButtonType = 'button' | 'outlined' | 'green' | 'disabled';
