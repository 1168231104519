import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { debounceTime, fromEvent, map } from 'rxjs';
import { BreakpointObserverService } from 'src/app/services/breakpoint-observer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Незабутні';
  // prevScrollPos = window.pageYOffset;
  @Input() url: String = '';
  isMobile$ = this.breakpoinService.isMobile();
  isTablet$ = this.breakpoinService.isTablet();
  isLaptop$ = this.breakpoinService.isLaptop();
  constructor(
    private router: Router,
    private breakpoinService: BreakpointObserverService,
  ) {}
  @ViewChild('navbar') navbar!: ElementRef;

  showBtn$ = fromEvent(document, 'scroll').pipe(
    debounceTime(50),
    map(
      () => window.pageYOffset > 20 || document.documentElement.scrollTop > 500,
    ),
  );

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationEnd) {
        this.url = routerEvent.urlAfterRedirects;
      }
    });
  }
  ngAfterViewInit() {
    // window.onscroll = () => {
    //   const currentScrollPos = window.pageYOffset;
    //   const navbar = this.navbar.nativeElement;
    //   if (this.prevScrollPos > currentScrollPos) {
    //     navbar.classList.add('top-18');
    //     navbar.classList.remove('top-0');
    //   } else {
    //     navbar.classList.remove('top-18');
    //     navbar.classList.add('top-0');
    //   }
    //   this.prevScrollPos = currentScrollPos;
    // };
  }
}
