import { Component, Input, OnInit } from '@angular/core';
import { TextContentModel } from '../../services/api/text-content.service';

@Component({
  selector: 'app-wide-card',
  templateUrl: './wide-card.component.html',
  styleUrls: ['./wide-card.component.css'],
})
export class WideCardComponent implements OnInit {
  @Input() cards: TextContentModel[] = [];
  constructor() {}

  ngOnInit(): void {}
}
