import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OpenPdf {
  constructor() {}

  getPDF(link: string, title: string): void {
    const newWindow = window.open(link, '_blank');
    if (newWindow) {
      newWindow.document.title = title;
    }
  }
}
