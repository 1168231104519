import {
  AfterContentInit,
  Component,
  ContentChildren,
  OnDestroy,
  OnInit,
  QueryList,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css'],
})
export class TabsComponent implements OnInit, AfterContentInit, OnDestroy {
  private _paramsSub$!: Subscription;
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter(tab => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }

    this._setMonthlyPaymentTabActive();
  }

  ngOnDestroy(): void {
    this._paramsSub$ && this._paramsSub$.unsubscribe();
  }

  selectTab(tab: TabComponent) {
    this.tabs.toArray().forEach(tab => (tab.active = false));
    tab.active = true;
  }

  // Set monthly payment tab active when it sets in query params
  private _setMonthlyPaymentTabActive(): void {
    this._paramsSub$ = this.activatedRoute.queryParams.subscribe(
      queryParams => {
        if (queryParams['subscribe'] === 'monthly') {
          this.selectTab(this.tabs.last);
        } else {
          this.selectTab(this.tabs.first);
        }
      },
    );
  }
}
