import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent implements OnInit {
  @Input() options!: any;
  @Input() AccordionsList!: any[];
  @Input() classList?: string;
  @Input() mobile?: boolean;
  @Input() mobileMenu?: boolean;
  @Input() vacancy?: boolean = false;
  @Output() accordionToggle: EventEmitter<any> = new EventEmitter<any>();
  config: any;
  isCopy: boolean = false;
  constructor(private cdr: ChangeDetectorRef) {}

  changeIcon(item: any) {
    item.isCopy = true;
    setTimeout(() => {
      item.isCopy = false;
      this.cdr.detectChanges();
    }, 1000);
  }

  ngOnInit() {
    this.AccordionsList.forEach(el => {
      el.active = false;
    });
    this.config = this.mergeConfig(this.options);
  }

  mergeConfig(options: any) {
    const config = {
      multi: true,
    };
    return { ...config, ...options };
  }

  toggle(index: number) {
    if (!this.config.multi) {
      this.AccordionsList.filter(
        (menu, i) => i !== index && menu.active,
      ).forEach(menu => (menu.active = !menu.active));
    }
    this.AccordionsList[index].active = !this.AccordionsList[index].active;
  }
  togglePoint(index: number) {
    this.accordionToggle.emit();
    if (!this.config.multi) {
      this.AccordionsList.filter(
        (menu, i) => i !== index && menu.active,
      ).forEach(menu => (menu.active = false));
    }
    this.AccordionsList[index].active = !this.AccordionsList[index].active;
  }

  stopEventBubbling(e: MouseEvent): void {
    e.stopPropagation();
  }
}
