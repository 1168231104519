import { Component, Input, OnInit } from '@angular/core';
import { PressRelaseCardInterface } from 'src/app/models/press-relase-card.interface';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';

@Component({
  selector: 'app-press-release-card',
  templateUrl: './press-release-card.component.html',
  styleUrls: ['./press-release-card.component.css'],
})
export class PressReleaseCardComponent implements OnInit {
  pressReleasesLink = PAGE_ROUTES.pressReleases;
  @Input() PRCard!: PressRelaseCardInterface;

  isHovered: boolean = false;
  constructor() {}
  ngOnInit(): void {}
}
