<div class="content-container flex flex-col text-gray-dark">
  <div class="flex flex-col xl:flex-row xl:justify-between mb-30 xl:mb-15">
    <div class="flex w-90 md:w-100" *ngIf="portalTextBlock; else loader">
      <h2
        class="text-primary-base text-h1-360 md:text-h1-heading-bold mb-5 ml-4 md:ml-0 h-fit">
        {{ portalTextBlock.title || portalTextBlock.tag | uppercase }}
      </h2>
    </div>
    <div
      class="flex flex-col w-82 md:w-210 xl:w-195 text-smaller-main md:text-main-text mx-4 md:mx-0 h-fit">
      <p
        *ngIf="portalTextBlock"
        class="mb-6 md:mb-7 xl:mb-7.5 last:mb-0 last:xl:mb-0"
        [innerHTML]="portalTextBlock.text"></p>
    </div>
  </div>
  <div
    class="flex flex-col md:flex-row md:flex-wrap items-center md:gap-5"
    *ngIf="reportsData.length && !isLoadingDocs; else loader">
    <div *ngFor="let report of reportsData" class="group">
      <div
        (click)="onOpenPDF(report.documentUrl, report.title)"
        class="flex flex-col w-82 md:w-102.5 xl:w-95 h-30.5 md:h-46 justify-between border-2 border-gray-stroke cursor-pointer p-4.5 md:px-10 xl:px-10 md:pt-8 xl:pt-7.75 md:pb-7 mb-2.5 md:mb-0 rounded-20 md:rounded-30 group-hover:border-secondary-base duration-300">
        <div class="text-smaller-bold md:text-main-text-bold xl:w-75">
          {{ report.title }}
        </div>
        <div
          class="flex flex-row justify-start text-accent-base cursor-pointer group-hover:text-secondary-base active:text-secondary-additional duration-300">
          <p class="text-smaller-bold pr-3">Відкрити</p>
          <svg
            class="xl:mb-1 fill-accent-base group-hover:fill-secondary-base active:fill-secondary-additional duration-300"
            [icon]="'right-arrow-small'"
            iconCollection="button-icons-sprite"
            height="26"
            width="30"></svg>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!reportsData.length && !isLoadingDocs">
    <p class="content-container">Звіти оновлюються адміністрацією сайту...</p>
  </ng-container>
</div>

<ng-template #loader>
  <div class="pt-10" *ngIf="isLoadingDocs">
    <div
      class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full gradient-animation"></div>
      </div>
    </div>
  </div>
</ng-template>
