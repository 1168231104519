import { Component, Input, OnInit } from '@angular/core';
import { currentResearchInfoMockDate } from 'src/mockData/currentResearchInfoMockDate';
import ICurrentResearchInfo from 'src/app/models/currentResearchInfo.interface';
import { TextContentModel } from '../../../../../../services/api/text-content.service';

@Component({
  selector: 'app-current-research-info',
  templateUrl: './current-research-info.component.html',
  styleUrls: ['./current-research-info.component.css'],
})
export class CurrentResearchInfoComponent implements OnInit {
  @Input() linkId: { readonly id: string; readonly title: string } | null =
    null;
  @Input() blockData: TextContentModel | null = null;
  currentResearchInfo: ICurrentResearchInfo[] = currentResearchInfoMockDate;

  constructor() {}

  ngOnInit(): void {}
}
