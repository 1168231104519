import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import IInfoPage from 'src/app/models/infoPage.interface';

export const keyDementiaCardsConfig: IInfoPage[] = [
  {
    title: 'Як говорити про деменцію у медіа',
    text: 'Важливу роль відіграє правильне висвітлення теми деменції у суспільстві.',
    link: PAGE_ROUTES.howToTalkAboutDementiaInTheMedia,
  },

  {
    title: 'Глосарій',
    text: 'Терміни, які варто або не варто використовувати, говорячи про деменцію.',
    link: PAGE_ROUTES.glossary,
  },
];
