import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { OpenPdf } from 'src/app/services/pdfOpen.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  IAboutDementiaThirdLevelPage,
  IAboutDementiaThirdLevelPageMainText,
} from '../../../../../models/aboutDementia.interface';
import { researchDetails } from '../../../../../services/api/programs-page/research.service';

@Component({
  selector: 'app-research-detail-page',
  templateUrl: './research-detail-page.component.html',
  styleUrls: ['./research-detail-page.component.css'],
})
export class ResearchDetailPageComponent implements OnInit, OnDestroy {
  pageData: researchDetails | null = null;
  subs: Subscription = new Subscription();
  constructor(
    private openPdf: OpenPdf,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const sub = this.route.data.subscribe(data => {
      this.pageData = data['apiData'] as researchDetails & {
        breadcrumb: string;
      };
    });
    this.subs.add(sub);
  }

  public downloadPdfFile(link: string): void {
    this.openPdf.getPDF(link, 'Research one');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
