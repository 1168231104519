import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { nameValidator } from 'src/app/components/forms/validators/name-validator';
import { emailValidator } from 'src/app/components/forms/validators/email-validator';
import { OpenPdf } from 'src/app/services/pdfOpen.service';

@Component({
  selector: 'app-crissis-assistance-form-help',
  templateUrl: './crissis-assistance-form-help.component.html',
  styleUrls: ['./crissis-assistance-form-help.component.css'],
})
export class CrissisAssistanceFormHelpComponent implements OnInit {
  formCrissisHelp!: FormGroup;
  name = new FormControl('', [Validators.required, nameValidator]);
  email = new FormControl('', [Validators.required, emailValidator]);
  message = new FormControl('');

  constructor(
    private router: Router,
    public fb: FormBuilder,
    private openPdf: OpenPdf,
  ) {}

  ngOnInit(): void {
    this.formCrissisHelp = this.fb.group({
      name: this.name,
      email: this.email,
      message: this.message,
      read: [false, Validators.requiredTrue],
    });
  }
  onOpenPublic_offerPDF(link: string, title: string): void {
    this.openPdf.getPDF(link, title);
  }
  onSubmit() {
    console.log(this.formCrissisHelp.value);
  }
}
