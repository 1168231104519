import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import IDetailedNews from 'src/app/models/detailed-news.interface';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subscription } from 'rxjs';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { PressReleaseCardByIdInterfaceResponse } from 'src/app/services/api/aboutUsPages/NewsAndMedia/press-releases-api.service';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-press-releases-details',
  templateUrl: './press-releases-details.component.html',
  styleUrls: ['./press-releases-details.component.css'],
})
export class PressReleasesDetailsComponent implements OnInit {
  private routeSubscription: Subscription | null = null;
  title = 'Release title';
  detailedNewsPressRealize: any[] = [];
  pressReleasesLink = PAGE_ROUTES.pressReleases;
  glossaryLink = PAGE_ROUTES.glossary;
  constructor(
    private route: ActivatedRoute,
    private clipboard: Clipboard,
  ) {}
  memberID = this.route.snapshot.params['id'];

  ngOnInit(): void {
    this.routeSubscription = this.route.data.subscribe(data => {
      const apiData = data[
        'apiData'
      ] as PressReleaseCardByIdInterfaceResponse & {
        breadcrumb: string;
      };

      this.detailedNewsPressRealize = [
        {
          id: apiData.id,
          date: apiData.dateOfPublication,
          text: apiData.fullText,
          title: apiData.title,
          documentUrl: apiData.documentUrl,
        },
      ];
    });
  }

  TitleSet(): string {
    return this.title;
  }

  copyMail() {
    this.clipboard.copy('media@nezabutni.org');
  }
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
