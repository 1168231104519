import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iconfilter',
  templateUrl: './7853746_funnel_kashifarif_filter_tool_data_icon.svg',
})
export class IconfilterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
