import { Component, Input, OnInit } from '@angular/core';
import IResearchCard from 'src/app/models/researchCard.interface';
import { researchCardMockDate } from 'src/mockData/researchCardMockDate';
import { researchCardModel } from '../../../../../../services/api/programs-page/research.service';
@Component({
  selector: 'app-research-card',
  templateUrl: './research-card.component.html',
  styleUrls: ['./research-card.component.css'],
})
export class ResearchCardComponent implements OnInit {
  @Input() researchCards: researchCardModel[] = [];
  constructor() {}

  ngOnInit(): void {}
}
