import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  TextContentModel,
  TextContentService,
} from '../../../services/api/text-content.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-our-programs',
  templateUrl: './our-programs.component.html',
  styleUrls: ['./our-programs.component.css'],
})
export class OurProgramsComponent implements OnInit, OnDestroy {
  pageName: string = 'about-us/programs';
  cards: TextContentModel[] = [];
  subs: Subscription = new Subscription();
  constructor(private textContent: TextContentService) {}

  ngOnInit(): void {
    const sub = this.textContent
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.cards = res.sort((a, b) => a.id - b.id);
      });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
