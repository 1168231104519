import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

export interface PortalTextModel {
  id: number;
  title: string;
  tag: string;
  text: string;
}

@Injectable({
  providedIn: 'root',
})
export class PortalTextsService {
  private url: string = environment.apiUrl + '/PortalText/';
  adminUrl: string = environment.apiUrl + '/AdminPanel/UpdatePortalText';
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  getPortalTextByTag(tag: string): Observable<PortalTextModel> {
    return this.http.get<PortalTextModel>(
      this.url + `GetPortalTextByTag?tag=${encodeURIComponent(tag)}`,
    );
  }

  getPortalTextsByTag(tag: string): Observable<PortalTextModel[]> {
    return this.http.get<PortalTextModel[]>(
      this.url + `GetPortalTextsByTag?tag=${encodeURIComponent(tag)}`,
    );
  }

  getCurrentUrl(): string {
    return this.router.url;
  }

  updatePortalText(portalTextData: PortalTextModel) {
    return this.http.put(this.adminUrl, portalTextData);
  }
}
