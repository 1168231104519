<div
  *ngIf="cardMonthly"
  class="mt-10 content-container flex flex-col px-4 md:px-0">
  <div class="w-full">
    <img
      class="h-full w-full md:h-[410px] md:w-[410px] xl:h-145 xl:w-145 bg-gray-card object-cover md:float-left mx-auto md:pb-0 md:mr-10 rounded-30 mb-5 md:mb-10"
      [src]="cardMonthly.imgUrl"
      alt="{{ cardMonthly.title }}" />
    <div class="text-primary-base text-h2-heading uppercase pb-5">
      {{ cardMonthly.title }}
    </div>
    <div class="flex flex-row pb-15">
      <div class="w-[270px] mr-10">
        <span>{{ cardMonthly.helpedTitleText }}</span
        ><br />
        <span class="text-h1-narrow text-accent-additional">{{
          cardMonthly.helped
        }}</span>
      </div>
      <div class="">
        <span>{{ cardMonthly.summTitleText }}</span
        ><br />
        <span class="text-h1-narrow text-accent-additional">{{
          cardMonthly.summ
        }}</span>
      </div>
    </div>
    <p
      class="text-gray-dark md:text-main-text text-smaller-main mb-10"
      [innerHTML]="cardMonthly.pageText"
      appPhoneLink></p>
  </div>
</div>
