import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { alternativePaidCard } from 'src/app/models/alternativePaidCard.interface';
import { TextContentModel } from '../../services/api/text-content.service';

@Component({
  selector: 'app-card-alternative',
  templateUrl: './card-alternative.component.html',
  styleUrls: ['./card-alternative.component.css'],
})
export class CardAlternativeComponent implements OnInit {
  hovered: boolean = false;
  @ViewChild('cardWidth', { static: true, read: ElementRef })
  cardWidth!: ElementRef;
  @Input()
  card!: TextContentModel;

  constructor() {}

  ngOnInit(): void {}
}
