<div class="mt-5 mb-10">
  <app-information [title]="title" [text]="text"></app-information>
</div>
<div *ngIf="eventsCardsArray; else loader" class="content-container">
  <div class="grid gap-5 xl:grid-cols-3 smd:grid-cols-2 px-4 md:px-0">
    <app-events-card
      *ngFor="let event of eventsCardsArray"
      [cardInfo]="event"></app-events-card>
  </div>
  <div class="flex justify-center">
    <app-pagination
      class="md:mt-15 mt-10"
      [newsOnPage]="6"
      (pageChange)="changePage($event)"
      [pages]="count"></app-pagination>
  </div>
</div>

<app-subscribe-news></app-subscribe-news>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
