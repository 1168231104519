<footer class="bg-gray-card pt-16 pb-19.75 xl:h-[482px] h-auto text-gray-dark">
  <div class="content-container">
    <div
      class="flex flex-col md:flex-row gap-15 md:gap-12 xl:gap-24.5 items-center md:items-start pt-8 wrap md:justify-center">
      <img
        class="shrink-0 w-[154px] md:w-[169px] xl:w-50.5"
        width="202"
        height="281"
        src="assets/images/svg-images/footer/footer_logo.svg"
        alt="Logo nezabutni" />
      <div
        class="flex flex-col md:flex-row md:gap-x-5 xl:gap-x-23 md:gap-y-4 flex-wrap items-center md:items-start xl:pt-5">
        <address
          class="flex text-gray-dark flex-col not-italic md:w-48 md:shrink-0 xl:w-52">
          <div
            class="flex text-details md:text-smaller-main xl:text-main-text gap-2.5 md:gap-3.5 xl:gap-2.5 w-[328px] md:flex-col xl:w-full">
            <div class="flex gap-1 shrink-0">
              <svg
                width="28px"
                height="28px"
                icon="phone"
                iconCollection="button-icons-sprite"
                class="fill-accent-base w-6 h-6 md:w-7 md:h-7"></svg>
              <a
                class="underline text-accent-base md:text-gray-dark md:no-underline md:pointer-events-none pt-0.5 xl:pt-0"
                href="tel:0800308830">
                0 800 30 88 30</a
              >
            </div>
            <app-copy-button></app-copy-button>
          </div>
          <ul
            class="flex gap-y-4.5 gap-x-5 flex-wrap justify-between md:justify-start w-full md:w-48 pt-6 md:pt-8">
            <li *ngFor="let socialLink of footerSocialLinks">
              <app-social-link
                class="inline-block w-12 h-12 shrink-0"
                [socialLink]="socialLink"></app-social-link>
            </li>
          </ul>
        </address>
        <nav
          class="flex pt-15 text-details w-80 md:pt-0 md:text-smaller-main xl:text-main-text md:w-86 xl:w-135">
          <ul
            class="flex gap-y-5 gap-x-7 justify-center flex-wrap md:justify-between">
            <li
              *ngFor="let footerLink of footerLinks"
              class="basis-30 shrink-0 whitespace-pre-line md:basis-32 xl:whitespace-normal xl:basis-60 hover:text-secondary-base">
              <a [href]="footerLink.link">{{ footerLink.text }}</a>
            </li>
          </ul>
        </nav>
        <div
          *ngIf="pageData.length"
          class="flex flex-col text-center text-details text-gray-details pt-15 md:pt-11 xl:pt-6 xl:ml-0 gap-2.5 justify-center items-center basis-20 md:basis-[680px] md:flex-row md:text-main-text md:shrink-0 md:justify-between md:-ml-34">
          <div
            (click)="
              onOpenPublic_offerPDF(
                offertLink!.buttonURL,
                'Договір публічної оферти'
              )
            "
            class="hover:text-secondary-base cursor-pointer"
            target="_blank"
            rel="noopener noreferrer">
            Публічна оферта
          </div>
          <span class="hidden md:inline-block">•</span>
          <div
            (click)="
              onOpenPublic_offerPDF(
                politicsLink!.buttonURL,
                'Політика конфіденційності Незабутні'
              )
            "
            class="hover:text-secondary-base cursor-pointer"
            target="_blank"
            rel="noopener noreferrer">
            Політика конфіденційності
          </div>
          <span class="hidden md:inline-block">•</span>
          <span>© {{ currentYear | date: 'YYYY' }} «Незабутні»</span>
        </div>
      </div>
    </div>
  </div>
</footer>
