import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rehabilitation-program',
  templateUrl: './rehabilitation-program.component.html',
  styleUrls: ['./rehabilitation-program.component.css'],
})
export class RehabilitationProgramComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
