import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ourIdeaMockData } from 'src/mockData/aboutUs_OurIdea_MockData';
import {
  TextContentModel,
  TextContentService,
} from '../../../../services/api/text-content.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-our-idea',
  templateUrl: './our-idea.component.html',
  styleUrls: ['./our-idea.component.css'],
})
export class OurIdeaComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer') videoPlayer!: ElementRef;
  pageName: string = 'about-us/our-idea';
  safeURL: SafeResourceUrl;
  play: boolean = false;
  combinedData: {
    first_block: TextContentModel;
    second_block: TextContentModel;
    third_block: TextContentModel;
    fourth_block: TextContentModel;
    last_block: TextContentModel;
    [key: string]: TextContentModel;
  } | null = null;
  links: { id: string; title: string }[] | null = null;
  subs: Subscription = new Subscription();

  constructor(
    private _sanitizer: DomSanitizer,
    private apiService: TextContentService,
  ) {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
      ourIdeaMockData.videoURL,
    );
  }

  ngOnInit(): void {
    this.getPageTextData();
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.combinedData = {
          first_block: res.find(el => el.key === 'first_block')!,
          second_block: res.find(el => el.key === 'second_block')!,
          third_block: res.find(el => el.key === 'third_block')!,
          fourth_block: res.find(el => el.key === 'fourth_block')!,
          last_block: res.find(el => el.key === 'last_block')!,
        };
        this.links = res
          .sort((a, b) => a.id - b.id)
          .filter(el => el.groupType === 'main_text')
          .map(el => ({ id: el.key, title: el.title }));
      });
    this.subs.add(sub);
  }

  playVideo() {
    this.play = !this.play;
    if (this.play) {
      this.videoPlayer.nativeElement.play();
    } else {
      this.videoPlayer.nativeElement.pause();
    }
  }

  onVideoEnded() {
    this.play = false;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
