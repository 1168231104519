<app-page-banner-item
  id="page-top"
  [pageName]="pageName"></app-page-banner-item>

<section class="grid mt-30">
  <app-table-of-content
    class="flex mx-auto w-full px-4 md:px-0"
    [links]="cards"></app-table-of-content>
</section>

<section class="content-container text-start flex mt-30 px-4 md:px-0">
  <div appPhoneLink [innerHTML]="lightText"></div>
</section>
<section
  class="content-container text-start flex mt-15 text-main-text bg-gray-card p-5 md:p-10 rounded-30">
  <div appPhoneLink [innerHTML]="darkText"></div>
</section>

<section
  *ngFor="let card of cards; let i = index"
  class="content-container text-start flex flex-col mb-30 px-4 md:px-0 mt-15">
  <app-page-link-cards-block
    [cardBlockId]="card.id"
    [cardBlockTitle]="card.title"
    [cardList]="sectionCards[i]"></app-page-link-cards-block>
</section>
<!--
<section class="content-container text-start flex flex-col mb-30 px-4 md:px-0">
  <app-page-link-cards-block
    [cardBlockId]="cards[1].id"
    [cardBlockTitle]="cards[1].title"
    [cardList]="sectionCards[1]"></app-page-link-cards-block>
</section>
<section class="content-container text-start flex flex-col mb-30 px-4 md:px-0">
  <app-page-link-cards-block
    [cardBlockId]="cards[2].id"
    [cardBlockTitle]="cards[2].title"
    [cardList]="sectionCards[2]"></app-page-link-cards-block>
</section>
<section class="content-container text-start flex flex-col mb-30 px-4 md:px-0">
  <app-page-link-cards-block
    [cardBlockId]="cards[3].id"
    [cardBlockTitle]="cards[3].title"
    [cardList]="sectionCards[3]"></app-page-link-cards-block>
</section>
<section class="content-container text-start flex flex-col mb-30 px-4 md:px-0">
  <app-page-link-cards-block
    [cardBlockId]="cards[4].id"
    [cardBlockTitle]="cards[4].title"
    [cardList]="sectionCards[4]"></app-page-link-cards-block>
</section> -->
