import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageReaderComponent } from 'src/app/admin/components/image-reader/image-reader.component';

@NgModule({
  declarations: [ImageReaderComponent],
  imports: [CommonModule],
  exports: [ImageReaderComponent],
})
export class ImageReaderModule {}
