<form
  class="w-145 h-156 border-2 border-gray-stroke bg-white rounded-30 p-10"
  [formGroup]="formCrissisHelp"
  (ngSubmit)="onSubmit()">
  <h2 class="text-h2-heading text-primary-base uppercase mb-10">
    Напишіть нам для евакуації
  </h2>

  <div class="text-main-text text-gray-dark">
    <div class="mb-5">
      <input
        type="text"
        name=""
        id=""
        placeholder="Ваше імʼя"
        formControlName="name"
        class="w-125 h-12 py-2.5 px-5 rounded-10 border-2 border-gray-stroke outline-0 focus:border-accent-base invalid:text-accent-base" />
    </div>

    <div class="mb-5">
      <input
        type="email"
        name=""
        id=""
        placeholder="E-mail"
        formControlName="email"
        class="w-125 h-12 py-2.5 px-5 rounded-10 border-2 border-gray-stroke outline-0 focus:border-accent-base invalid:text-accent-base" />
    </div>

    <div class="mb-5">
      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="Текст повідомлення"
        class="w-125 h-42 py-2.5 px-5 mb-3 rounded-10 border-2 border-gray-stroke outline-0 focus:border-accent-base resize-none"
        formControlName="message"></textarea>
    </div>
  </div>

  <div>
    <div class="flex flex-row justify-start mb-5 text-details">
      <div class="mr-4">
        <label
          for="checkboxRead"
          class="flex w-8 h-8 rounded-lg border-2 border-gray-stroke cursor-pointer"
          [ngClass]="{ 'bg-accent-base': formCrissisHelp.get('read')?.valid }">
          <span
            class="text-gray-white"
            *ngIf="formCrissisHelp.get('read')?.valid">
            <svg
              width="26px"
              height="26px"
              [icon]="'checkBox'"
              iconCollection="button-icons-sprite"
              class="fill-gray-white"></svg>
          </span>
        </label>
        <input
          class="hidden"
          id="checkboxRead"
          type="checkbox"
          formControlName="read" />
      </div>
      <div class="text-block">
        Натискаючи Відправити я погоджуюся з тим, що введена мною особиста
        інформація буде використана згідно з
        <span
          class="cursor-pointer text-accent-base"
          (click)="
            onOpenPublic_offerPDF(
              '/assets/pdf/terms_and_conditions.pdf',
              'Політика конфіденційності Незабутні'
            )
          "
          >Політикою конфіденційності Фонду</span
        >.
      </div>
    </div>
  </div>

  <app-button [title]="'Надіслати заявку'" [type]="'submit'"></app-button>
</form>
