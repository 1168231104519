import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css'],
})
export class ImageSliderComponent implements OnInit {
  showCarousel: boolean = false;
  @Input() arrayListImage?: any = [
    { src: '../../../../../assets/images/advocacy/4.jpg' },
    { src: '../../../../../assets/images/advocacy/1.jpg' },
    { src: '../../../../../assets/images/advocacy/2.jpg' },
  ];
  constructor() {}

  ngOnInit(): void {
    this.updateShowCarousel();
  }

  onChangeSlide(value: string) {
    if (value === 'nextSlide') {
      const deletedElement = this.arrayListImage.shift();
      this.arrayListImage.push(deletedElement);
    }
    if (value === 'prevSlide') {
      const deletedElement = this.arrayListImage.pop();
      this.arrayListImage.unshift(deletedElement);
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateShowCarousel();
  }

  private updateShowCarousel(): void {
    const screenWidth = window.innerWidth;
    this.showCarousel = this.arrayListImage.length >= 4 || screenWidth <= 960;
  }
}
