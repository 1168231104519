import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, Observable } from 'rxjs';

export interface portalImageLinkModel {
  id?: number;
  tag: string;
  pageName: string;
  imageUrl: string;
  orderId: number;
  file?: File;
}

@Injectable({
  providedIn: 'root',
})
export class PortalLinksService {
  private url: string =
    environment.apiUrl + '/PortalImageLink/GetPortalImageLinksByTag';
  adminUrl: string = environment.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getPortalLinksByPageName(tag: string): Observable<portalImageLinkModel[]> {
    return this.http
      .get<
        portalImageLinkModel[]
      >(environment.apiUrl + `/PortalImageLink/GetPortalImageLinksByPageName?pageName=${encodeURIComponent(tag)}`)
      .pipe(map(data => data.sort((a, b) => a.orderId - b.orderId)));
  }

  getPortalLinksByTag(tag: string): Observable<portalImageLinkModel[]> {
    return this.http
      .get<portalImageLinkModel[]>(this.url + `?tag=${encodeURIComponent(tag)}`)
      .pipe(map(data => data.sort((a, b) => a.orderId - b.orderId)));
  }

  updatePortalLink(data: FormData) {
    return this.http.put(this.adminUrl + 'UpdatePortalImageLink', data);
  }

  createPortalLink(data: FormData) {
    return this.http.post(this.adminUrl + 'AddPortalImageLink', data);
  }

  deletePortalLink(data: { id: number; url: string }) {
    return this.http.delete(
      this.adminUrl +
        'DeletePortalImageLink' +
        `?id=${data.id}&documentURL=${data.url}`,
    );
  }

  savePortalLinksOrder(data: portalImageLinkModel[]) {
    return this.http.put(this.adminUrl + 'UpdatePortalImageLinksOrder', data);
  }
}
