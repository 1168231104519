import { Component, Input, OnInit } from '@angular/core';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { TextContentModel } from '../../services/api/text-content.service';

@Component({
  selector: 'app-our-projects-block',
  templateUrl: './our-projects-block.component.html',
  styleUrls: ['./our-projects-block.component.css'],
})
export class OurProjectsBlockComponent implements OnInit {
  @Input() textContentData: TextContentModel[] = [];
  ourActivitiesTextBlock!: TextContentModel;
  helpWithMedicineBlock!: TextContentModel;
  helpWithGeneratorsBlock!: TextContentModel;
  psychologicalSupportBlock!: TextContentModel;
  kafeUnforgettableBlock!: TextContentModel;
  nationalDementiaPlanBlock!: TextContentModel;

  constructor() {}
  ourProgramsLink: string = PAGE_ROUTES.ourPrograms;
  legalAssistanceLink: string = PAGE_ROUTES.advocacy;
  psychologicalSupportLink: string = PAGE_ROUTES.psychologicalSupport;
  crisisAssistanceLink: string = PAGE_ROUTES.crisisAssistance;
  helpWithMedicineLink: string = PAGE_ROUTES.helpWithMedicine;
  ngOnInit(): void {
    this.ourActivitiesTextBlock = this.textContentData.find(
      el => el.key === 'main_our-activities',
    )!;
    this.helpWithMedicineBlock = this.textContentData.find(
      el => el.key === 'main_our-activities_help-with-medication',
    )!;
    this.helpWithGeneratorsBlock = this.textContentData.find(
      el => el.key === 'main_our-activities_help-with-generators',
    )!;
    this.psychologicalSupportBlock = this.textContentData.find(
      el => el.key === 'main_our-activities_psychological-support',
    )!;
    this.kafeUnforgettableBlock = this.textContentData.find(
      el => el.key === 'main_our-activities_kafe-unforgettable',
    )!;
    this.nationalDementiaPlanBlock = this.textContentData.find(
      el => el.key === 'main_our-activities_national-dementia-plan',
    )!;
  }
}
