import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface IHowICanHelpBlock {
  description: { text: string; title: string };
  cards: { title: string; text: string; image: string; link: string }[];
}

@Injectable({
  providedIn: 'root',
})
export class HowICanHelpBlockService {
  private url = environment.apiUrl + '/HowCanIHelp';
  constructor(private http: HttpClient) {}

  getBlockData(): Observable<IHowICanHelpBlock> {
    return this.http.get<IHowICanHelpBlock>(this.url);
  }
}
