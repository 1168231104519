import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import {
  ActivitiesModel,
  ServerPageActivitiesModel,
} from '../../../admin/pages/activities-adm/activities';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesPageService {
  headers = new HttpHeaders({
    Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')!),
  });
  url: string = environment.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getAllEvents(): Observable<ActivitiesModel[]> {
    return this.http.get<ActivitiesModel[]>(this.url + 'GetAllEvents', {
      headers: this.headers,
    });
  }

  createEvents(data: ActivitiesModel): Observable<ActivitiesModel> {
    return this.http.post<ActivitiesModel>(this.url + 'AddEvent', data, {
      headers: this.headers,
    });
  }

  getPaginatedEvents(
    pageNumber: number,
    cardsPerPage: number,
    asc: boolean,
  ): Observable<ServerPageActivitiesModel> {
    const offset = (pageNumber - 1) * cardsPerPage;
    const take = cardsPerPage;
    return this.http.get<ServerPageActivitiesModel>(
      this.url + 'GetEvents?' + `offset=${offset}&take=${take}&asc=${asc}`,
    );
  }

  getEventById(id: number | string): Observable<ActivitiesModel> {
    return this.http.get<ActivitiesModel>(this.url + `GetEventById?id=${id}`, {
      headers: this.headers,
    });
  }

  deleteEvents(card: ActivitiesModel): Observable<ActivitiesModel> {
    return this.http.delete<ActivitiesModel>(this.url + 'DeleteEvent', {
      headers: this.headers,
      body: card,
    });
  }

  updateEvents(data: ActivitiesModel): Observable<ActivitiesModel> {
    return this.http.put<ActivitiesModel>(this.url + 'UpdateEvent', data, {
      headers: this.headers,
    });
  }
}
