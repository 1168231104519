import { Country } from 'src/app/models/country.model';

export const CountryList: Country[] = [
  {
    name: 'Afghanistan',
    nameUa: 'Афганістан',
    countryCode: 'af',
    phoneCountryCode: '+93',
    flagIcon: 'af',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Albania',
    nameUa: 'Албанія',
    countryCode: 'al',
    phoneCountryCode: '+355',
    flagIcon: 'al',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Algeria',
    nameUa: 'Алжир',
    countryCode: 'dz',
    phoneCountryCode: '+213',
    flagIcon: 'dz',
    placeholder: '0000 00 00 00',
    mask: '0000 00 00 00',
    preferred: false,
  },
  {
    name: 'American Samoa',
    nameUa: 'Американське Самоа',
    countryCode: 'as',
    phoneCountryCode: '+1(684)',
    flagIcon: 'as',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Andorra',
    nameUa: 'Андорра',
    countryCode: 'ad',
    phoneCountryCode: '+376',
    flagIcon: 'ad',
    placeholder: '000 000',
    mask: '000 000',
    preferred: false,
  },
  {
    name: 'Angola',
    nameUa: 'Анґола',
    countryCode: 'ao',
    phoneCountryCode: '+244',
    flagIcon: 'ao',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Anguilla',
    nameUa: 'Анґілья',
    countryCode: 'ai',
    phoneCountryCode: '+1(264)',
    flagIcon: 'ai',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Antigua and Barbuda',
    nameUa: 'Антиґуа і Барбуда',
    countryCode: 'ag',
    phoneCountryCode: '+1(268)',
    flagIcon: 'ag',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Argentina',
    nameUa: 'Аргентина',
    countryCode: 'ar',
    phoneCountryCode: '+54',
    flagIcon: 'ar',
    placeholder: '000 00-0000-0000',
    mask: '000 00-0000-0000',
    preferred: false,
  },
  {
    name: 'Armenia',
    nameUa: 'Вірменія',
    countryCode: 'am',
    phoneCountryCode: '+374',
    flagIcon: 'am',
    placeholder: '000 000000',
    mask: '000 000000',
    preferred: false,
  },
  {
    name: 'Aruba',
    nameUa: 'Аруба (Нідерланди)',
    countryCode: 'aw',
    phoneCountryCode: '+297',
    flagIcon: 'aw',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Australia',
    nameUa: 'Австралія',
    countryCode: 'au',
    phoneCountryCode: '+61',
    flagIcon: 'au',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Austria',
    nameUa: 'Австрія',
    countryCode: 'at',
    phoneCountryCode: '+43',
    flagIcon: 'at',
    placeholder: '0000 000000',
    mask: '0000 000000',
    preferred: false,
  },
  {
    name: 'Azerbaijan',
    nameUa: 'Азербайджан',
    countryCode: 'az',
    phoneCountryCode: '+994',
    flagIcon: 'az',
    placeholder: '000 000 00 00',
    mask: '000 000 00 00',
    preferred: false,
  },
  {
    name: 'Bahamas',
    nameUa: 'Багамські Острови',
    countryCode: 'bs',
    phoneCountryCode: '+1(242)',
    flagIcon: 'bs',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Bahrain',
    nameUa: 'Бахрейн',
    countryCode: 'bh',
    phoneCountryCode: '+973',
    flagIcon: 'bh',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Bangladesh',
    nameUa: 'Бангладеш',
    countryCode: 'bd',
    phoneCountryCode: '+880',
    flagIcon: 'bd',
    placeholder: '00000-000000',
    mask: '00000-000000',
    preferred: false,
  },
  {
    name: 'Barbados',
    nameUa: 'Барбадос',
    countryCode: 'bb',
    phoneCountryCode: '+1(246)',
    flagIcon: 'bb',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'belarus',
    nameUa: 'білорусь',
    countryCode: 'by',
    phoneCountryCode: '+375',
    flagIcon: 'by',
    placeholder: '0 000 000-00-00',
    mask: '0 000 000-00-00',
    preferred: false,
  },
  {
    name: 'Belgium',
    nameUa: 'Бельгія',
    countryCode: 'be',
    phoneCountryCode: '+32',
    flagIcon: 'be',
    placeholder: '0000 00 00 00',
    mask: '0000 00 00 00',
    preferred: false,
  },
  {
    name: 'Belize',
    nameUa: 'Беліз',
    countryCode: 'bz',
    phoneCountryCode: '+501',
    flagIcon: 'bz',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Benin',
    nameUa: 'Бенін',
    countryCode: 'bj',
    phoneCountryCode: '+229',
    flagIcon: 'bj',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Bermuda',
    nameUa: 'Бермудські острови (ВБ)',
    countryCode: 'bm',
    phoneCountryCode: '+1(441)',
    flagIcon: 'bm',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Bhutan',
    nameUa: 'Бутан',
    countryCode: 'bt',
    phoneCountryCode: '+975',
    flagIcon: 'bt',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Bolivia',
    nameUa: 'Болівія',
    countryCode: 'bo',
    phoneCountryCode: '+591',
    flagIcon: 'bo',
    placeholder: '00000000',
    mask: '00000000',
    preferred: false,
  },
  {
    name: 'Bosnia and Herzegovina',
    nameUa: 'Боснія і Герцеговина',
    countryCode: 'ba',
    phoneCountryCode: '+387',
    flagIcon: 'ba',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Botswana',
    nameUa: 'Ботсвана',
    countryCode: 'bw',
    phoneCountryCode: '+267',
    flagIcon: 'bw',
    placeholder: '00 000 000',
    mask: '00 000 000',
    preferred: false,
  },
  {
    name: 'Brazil',
    nameUa: 'Бразилія',
    countryCode: 'br',
    phoneCountryCode: '+55',
    flagIcon: 'br',
    placeholder: '(00) 00000-0000',
    mask: '(00) 00000-0000',
    preferred: false,
  },
  {
    name: 'British Indian Ocean Territory',
    nameUa: 'Британська територія в Індійському океані',
    countryCode: 'io',
    phoneCountryCode: '+246',
    flagIcon: 'io',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'British Virgin Islands',
    nameUa: 'Британські Віргінські острови (ВБ)',
    countryCode: 'vg',
    phoneCountryCode: '+1(284)',
    flagIcon: 'vg',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Brunei',
    nameUa: 'Бруней',
    countryCode: 'bn',
    phoneCountryCode: '+673',
    flagIcon: 'bn',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Bulgaria',
    nameUa: 'Болгарія',
    countryCode: 'bg',
    phoneCountryCode: '+359',
    flagIcon: 'bg',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Burkina Faso',
    nameUa: 'Буркіна-Фасо',
    countryCode: 'bf',
    phoneCountryCode: '+226',
    flagIcon: 'bf',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Burundi',
    nameUa: 'Бурунді',
    countryCode: 'bi',
    phoneCountryCode: '+257',
    flagIcon: 'bi',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Cambodia',
    nameUa: 'Камбоджа',
    countryCode: 'kh',
    phoneCountryCode: '+855',
    flagIcon: 'kh',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Cameroon',
    nameUa: 'Камерун',
    countryCode: 'cm',
    phoneCountryCode: '+237',
    flagIcon: 'cm',
    placeholder: '0 00 00 00 00',
    mask: '0 00 00 00 00',
    preferred: false,
  },
  {
    name: 'Canada',
    nameUa: 'Канада',
    countryCode: 'ca',
    phoneCountryCode: '+1',
    flagIcon: 'ca',
    placeholder: '(000) 000-0000',
    mask: '(000) 000-0000',
    preferred: false,
  },
  {
    name: 'Cape Verde',
    nameUa: 'Кабо-Верде',
    countryCode: 'cv',
    phoneCountryCode: '+238',
    flagIcon: 'cv',
    placeholder: '000 00 00',
    mask: '000 00 00',
    preferred: false,
  },
  {
    name: 'Caribbean Netherlands',
    nameUa: 'Карибські Нідерланди',
    countryCode: 'bq',
    phoneCountryCode: '+599',
    flagIcon: 'bq',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Cayman Islands',
    nameUa: 'Кайманові Острови',
    countryCode: 'ky',
    phoneCountryCode: '+1(345)',
    flagIcon: 'ky',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Central African Republic',
    nameUa: 'Центральноафриканська Республіка',
    countryCode: 'cf',
    phoneCountryCode: '+236',
    flagIcon: 'cf',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Chad',
    nameUa: 'Чад',
    countryCode: 'td',
    phoneCountryCode: '+235',
    flagIcon: 'td',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Chile',
    nameUa: 'Чилі',
    countryCode: 'cl',
    phoneCountryCode: '+56',
    flagIcon: 'cl',
    placeholder: '0 0000 0000',
    mask: '0 0000 0000',
    preferred: false,
  },
  {
    name: 'China',
    nameUa: 'Китай',
    countryCode: 'cn',
    phoneCountryCode: '+86',
    flagIcon: 'cn',
    placeholder: '000 0000 0000',
    mask: '000 0000 0000',
    preferred: false,
  },
  {
    name: 'Christmas Island',
    nameUa: 'Острів Різдва (Австралія)',
    countryCode: 'cx',
    phoneCountryCode: '+61',
    flagIcon: 'cx',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Cocos Islands',
    nameUa: 'Кокосові Острови (Австралія)',
    countryCode: 'cc',
    phoneCountryCode: '+61',
    flagIcon: 'cc',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Colombia',
    nameUa: 'Колумбія',
    countryCode: 'co',
    phoneCountryCode: '+57',
    flagIcon: 'co',
    placeholder: '000 0000000',
    mask: '000 0000000',
    preferred: false,
  },
  {
    name: 'Comoros',
    nameUa: 'Коморські Острови',
    countryCode: 'km',
    phoneCountryCode: '+269',
    flagIcon: 'km',
    placeholder: '000 00 00',
    mask: '000 00 00',
    preferred: false,
  },
  {
    name: 'Congo (DRC)',
    nameUa: 'Демократична Республіка Конго',
    countryCode: 'cd',
    phoneCountryCode: '+243',
    flagIcon: 'cd',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Republic of the Congo',
    nameUa: 'Республіка Конго',
    countryCode: 'cg',
    phoneCountryCode: '+242',
    flagIcon: 'cg',
    placeholder: '00 000 0000',
    mask: '00 000 0000',
    preferred: false,
  },
  {
    name: 'Cook Islands',
    nameUa: 'Острови Кука (Нова Зеландія)',
    countryCode: 'ck',
    phoneCountryCode: '+682',
    flagIcon: 'ck',
    placeholder: '00 0000',
    mask: '00 0000',
    preferred: false,
  },
  {
    name: 'Costa Rica',
    nameUa: 'Коста-Рика',
    countryCode: 'cr',
    phoneCountryCode: '+506',
    flagIcon: 'cr',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Croatia',
    nameUa: 'Хорватія',
    countryCode: 'hr',
    phoneCountryCode: '+385',
    flagIcon: 'hr',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Cuba',
    nameUa: 'Куба',
    countryCode: 'cu',
    phoneCountryCode: '+53',
    flagIcon: 'cu',
    placeholder: '00 0000000',
    mask: '00 0000000',
    preferred: false,
  },
  {
    name: 'Curacao',
    nameUa: 'Кюрасао',
    countryCode: 'cw',
    phoneCountryCode: '+599',
    flagIcon: 'cw',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Cyprus',
    nameUa: 'Кіпр',
    countryCode: 'cy',
    phoneCountryCode: '+357',
    flagIcon: 'cy',
    placeholder: '00 000000',
    mask: '00 000000',
    preferred: false,
  },
  {
    name: 'Czech Republic',
    nameUa: 'Чехія',
    countryCode: 'cz',
    phoneCountryCode: '+420',
    flagIcon: 'cz',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Denmark',
    nameUa: 'Данія',
    countryCode: 'dk',
    phoneCountryCode: '+45',
    flagIcon: 'dk',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Djibouti',
    nameUa: 'Джибуті',
    countryCode: 'dj',
    phoneCountryCode: '+253',
    flagIcon: 'dj',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Dominica',
    nameUa: 'Домініка',
    countryCode: 'dm',
    phoneCountryCode: '+1(767)',
    flagIcon: 'dm',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Dominican Republic',
    nameUa: 'Домініканська Республіка',
    countryCode: 'do',
    phoneCountryCode: '+1(809)',
    flagIcon: 'do',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'East Timor',
    nameUa: 'Східний Тімор',
    countryCode: 'tl',
    phoneCountryCode: '+670',
    flagIcon: 'tl',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Ecuador',
    nameUa: 'Еквадор',
    countryCode: 'ec',
    phoneCountryCode: '+593',
    flagIcon: 'ec',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Egypt',
    nameUa: 'Єгипет',
    countryCode: 'eg',
    phoneCountryCode: '+20',
    flagIcon: 'eg',
    placeholder: '0000 000 0000',
    mask: '0000 000 0000',
    preferred: false,
  },
  {
    name: 'El Salvador',
    nameUa: 'Сальвадор',
    countryCode: 'sv',
    phoneCountryCode: '+503',
    flagIcon: 'sv',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Equatorial Guinea',
    nameUa: 'Екваторіальна Гвінея',
    countryCode: 'gq',
    phoneCountryCode: '+240',
    flagIcon: 'gq',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Eritrea',
    nameUa: 'Еритрея',
    countryCode: 'er',
    phoneCountryCode: '+291',
    flagIcon: 'er',
    placeholder: '00 000 000',
    mask: '00 000 000',
    preferred: false,
  },
  {
    name: 'Estonia',
    nameUa: 'Естонія',
    countryCode: 'ee',
    phoneCountryCode: '+372',
    flagIcon: 'ee',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Ethiopia',
    nameUa: 'Ефіопія',
    countryCode: 'et',
    phoneCountryCode: '+251',
    flagIcon: 'et',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Falkland Islands',
    nameUa: 'Фолклендські Острови (ВБ)',
    countryCode: 'fk',
    phoneCountryCode: '+500',
    flagIcon: 'fk',
    placeholder: '00000',
    mask: '00000',
    preferred: false,
  },
  {
    name: 'Faroe Islands',
    nameUa: 'Фарерські Острови',
    countryCode: 'fo',
    phoneCountryCode: '+298',
    flagIcon: 'fo',
    placeholder: '000000',
    mask: '000000',
    preferred: false,
  },
  {
    name: 'Fiji',
    nameUa: 'Фіджі',
    countryCode: 'fj',
    phoneCountryCode: '+679',
    flagIcon: 'fj',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Finland',
    nameUa: 'Фінляндія',
    countryCode: 'fi',
    phoneCountryCode: '+358',
    flagIcon: 'fi',
    placeholder: '000 0000000',
    mask: '000 0000000',
    preferred: false,
  },
  {
    name: 'France',
    nameUa: 'Франція',
    countryCode: 'fr',
    phoneCountryCode: '+33',
    flagIcon: 'fr',
    placeholder: '00 00 00 00 00',
    mask: '00 00 00 00 00',
    preferred: false,
  },
  {
    name: 'French Guiana',
    nameUa: 'Французька Ґвіана',
    countryCode: 'gf',
    phoneCountryCode: '+594',
    flagIcon: 'gf',
    placeholder: '0000 00 00 00',
    mask: '0000 00 00 00',
    preferred: false,
  },
  {
    name: 'French Polynesia',
    nameUa: 'Французька Полінезія',
    countryCode: 'pf',
    phoneCountryCode: '+689',
    flagIcon: 'pf',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Gabon',
    nameUa: 'Габон',
    countryCode: 'ga',
    phoneCountryCode: '+241',
    flagIcon: 'ga',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Gambia',
    nameUa: 'Гамбія',
    countryCode: 'gm',
    phoneCountryCode: '+220',
    flagIcon: 'gm',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Georgia',
    nameUa: 'Грузія',
    countryCode: 'ge',
    phoneCountryCode: '+995',
    flagIcon: 'ge',
    placeholder: '000 00 00 00',
    mask: '000 00 00 00',
    preferred: false,
  },
  {
    name: 'Germany',
    nameUa: 'Німеччина',
    countryCode: 'de',
    phoneCountryCode: '+49',
    flagIcon: 'de',
    placeholder: '00000 0000000',
    mask: '00000 0000000',
    preferred: true,
  },
  {
    name: 'Ghana',
    nameUa: 'Гана',
    countryCode: 'gh',
    phoneCountryCode: '+233',
    flagIcon: 'gh',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Gibraltar',
    nameUa: 'Гібралтар (ВБ)',
    countryCode: 'gi',
    phoneCountryCode: '+350',
    flagIcon: 'gi',
    placeholder: '00000000',
    mask: '00000000',
    preferred: false,
  },
  {
    name: 'Greece',
    nameUa: 'Греція',
    countryCode: 'gr',
    phoneCountryCode: '+30',
    flagIcon: 'gr',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Greenland',
    nameUa: 'Гренландія (Данія)',
    countryCode: 'gl',
    phoneCountryCode: '+299',
    flagIcon: 'gl',
    placeholder: '00 00 00',
    mask: '00 00 00',
    preferred: false,
  },
  {
    name: 'Grenada',
    nameUa: 'Гренада',
    countryCode: 'gd',
    phoneCountryCode: '+1(473)',
    flagIcon: 'gd',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Guadeloupe',
    nameUa: 'Ґваделупа (Франція)',
    countryCode: 'gp',
    phoneCountryCode: '+590',
    flagIcon: 'gp',
    placeholder: '0000 00 00 00',
    mask: '0000 00 00 00',
    preferred: false,
  },
  {
    name: 'Guam',
    nameUa: 'Гуам (США)',
    countryCode: 'gu',
    phoneCountryCode: '+1(671)',
    flagIcon: 'gu',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Guatemala',
    nameUa: 'Гватемала',
    countryCode: 'gt',
    phoneCountryCode: '+502',
    flagIcon: 'gt',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Guernsey',
    nameUa: 'Ґернсі',
    countryCode: 'gg',
    phoneCountryCode: '+44',
    flagIcon: 'gg',
    placeholder: '00000 000000',
    mask: '00000 000000',
    preferred: false,
  },
  {
    name: 'Guinea',
    nameUa: 'Гвінея',
    countryCode: 'gn',
    phoneCountryCode: '+224',
    flagIcon: 'gn',
    placeholder: '000 00 00 00',
    mask: '000 00 00 00',
    preferred: false,
  },
  {
    name: 'Guinea-Bissau',
    nameUa: 'Гвінея-Бісау',
    countryCode: 'gw',
    phoneCountryCode: '+245',
    flagIcon: 'gw',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Guyana',
    nameUa: 'Гаяна',
    countryCode: 'gy',
    phoneCountryCode: '+592',
    flagIcon: 'gy',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Haiti',
    nameUa: 'Гаїті',
    countryCode: 'ht',
    phoneCountryCode: '+509',
    flagIcon: 'ht',
    placeholder: '00 00 0000',
    mask: '00 00 0000',
    preferred: false,
  },
  {
    name: 'Honduras',
    nameUa: 'Гондурас',
    countryCode: 'hn',
    phoneCountryCode: '+504',
    flagIcon: 'hn',
    placeholder: '0000-0000',
    mask: '0000-0000',
    preferred: false,
  },
  {
    name: 'Hong Kong',
    nameUa: 'Гонконг',
    countryCode: 'hk',
    phoneCountryCode: '+852',
    flagIcon: 'hk',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Hungary',
    nameUa: 'Угорщина',
    countryCode: 'hu',
    phoneCountryCode: '+36',
    flagIcon: 'hu',
    placeholder: '(00) 000 0000',
    mask: '(00) 000 0000',
    preferred: false,
  },
  {
    name: 'Iceland',
    nameUa: 'Ісландія',
    countryCode: 'is',
    phoneCountryCode: '+354',
    flagIcon: 'is',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'India',
    nameUa: 'Індія',
    countryCode: 'in',
    phoneCountryCode: '+91',
    flagIcon: 'in',
    placeholder: '00000 00000',
    mask: '00000 00000',
    preferred: false,
  },
  {
    name: 'Indonesia',
    nameUa: 'Індонезія',
    countryCode: 'id',
    phoneCountryCode: '+62',
    flagIcon: 'id',
    placeholder: '0000-000-000',
    mask: '0000-000-000',
    preferred: false,
  },
  {
    name: 'Iran',
    nameUa: 'Іран',
    countryCode: 'ir',
    phoneCountryCode: '+98',
    flagIcon: 'ir',
    placeholder: '0000 000 0000',
    mask: '0000 000 0000',
    preferred: false,
  },
  {
    name: 'Iraq',
    nameUa: 'Ірак',
    countryCode: 'iq',
    phoneCountryCode: '+964',
    flagIcon: 'iq',
    placeholder: '0000 000 0000',
    mask: '0000 000 0000',
    preferred: false,
  },
  {
    name: 'Ireland',
    nameUa: 'Ірландія',
    countryCode: 'ie',
    phoneCountryCode: '+353',
    flagIcon: 'ie',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Isle of Man',
    nameUa: 'Острів Мен (ВБ)',
    countryCode: 'im',
    phoneCountryCode: '+44',
    flagIcon: 'im',
    placeholder: '00000 000000',
    mask: '00000 000000',
    preferred: false,
  },
  {
    name: 'Israel',
    nameUa: 'Ізраїль',
    countryCode: 'il',
    phoneCountryCode: '+972',
    flagIcon: 'il',
    placeholder: '000-000-0000',
    mask: '000-000-0000',
    preferred: false,
  },
  {
    name: 'Italy',
    nameUa: 'Італія',
    countryCode: 'it',
    phoneCountryCode: '+39',
    flagIcon: 'it',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Ivory Coast',
    nameUa: 'Кот-д Івуар',
    countryCode: 'ci',
    phoneCountryCode: '+225',
    flagIcon: 'ci',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Jamaica',
    nameUa: 'Ямайка',
    countryCode: 'jm',
    phoneCountryCode: '+1(876)',
    flagIcon: 'jm',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Japan',
    nameUa: 'Японія',
    countryCode: 'jp',
    phoneCountryCode: '+81',
    flagIcon: 'jp',
    placeholder: '000 0000-0000',
    mask: '000 0000-0000',
    preferred: false,
  },
  {
    name: 'Jersey',
    nameUa: 'Джерсі (ВБ)',
    countryCode: 'je',
    phoneCountryCode: '+44',
    flagIcon: 'je',
    placeholder: '00000 000000',
    mask: '00000 000000',
    preferred: false,
  },
  {
    name: 'Jordan',
    nameUa: 'Йорданія',
    countryCode: 'jo',
    phoneCountryCode: '+962',
    flagIcon: 'jo',
    placeholder: '00 0000 0000',
    mask: '00 0000 0000',
    preferred: false,
  },
  {
    name: 'Kazakhstan',
    nameUa: 'Казахстан',
    countryCode: 'kz',
    phoneCountryCode: '+7',
    flagIcon: 'kz',
    placeholder: '0 (000) 000 0000',
    mask: '0 (000) 000 0000',
    preferred: false,
  },
  {
    name: 'Kenya',
    nameUa: 'Кенія',
    countryCode: 'ke',
    phoneCountryCode: '+254',
    flagIcon: 'ke',
    placeholder: '0000 000000',
    mask: '0000 000000',
    preferred: false,
  },
  {
    name: 'Kiribati',
    nameUa: 'Кірибаті',
    countryCode: 'ki',
    phoneCountryCode: '+686',
    flagIcon: 'ki',
    placeholder: '00000000',
    mask: '00000000',
    preferred: false,
  },
  {
    name: 'Kosovo',
    nameUa: 'Косово',
    countryCode: 'xk',
    phoneCountryCode: '+383',
    flagIcon: 'xk',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Kuwait',
    nameUa: 'Кувейт',
    countryCode: 'kw',
    phoneCountryCode: '+965',
    flagIcon: 'kw',
    placeholder: '000 00000',
    mask: '000 00000',
    preferred: false,
  },
  {
    name: 'Kyrgyzstan',
    nameUa: 'Киргизстан',
    countryCode: 'kg',
    phoneCountryCode: '+996',
    flagIcon: 'kg',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Laos',
    nameUa: 'Лаос',
    countryCode: 'la',
    phoneCountryCode: '+856',
    flagIcon: 'la',
    placeholder: '000 00 000 000',
    mask: '000 00 000 000',
    preferred: false,
  },
  {
    name: 'Latvia',
    nameUa: 'Латвія',
    countryCode: 'lv',
    phoneCountryCode: '+371',
    flagIcon: 'lv',
    placeholder: '00 000 000',
    mask: '00 000 000',
    preferred: false,
  },
  {
    name: 'Lebanon',
    nameUa: 'Ліван',
    countryCode: 'lb',
    phoneCountryCode: '+961',
    flagIcon: 'lb',
    placeholder: '00 000 000',
    mask: '00 000 000',
    preferred: false,
  },
  {
    name: 'Lesotho',
    nameUa: 'Лесото',
    countryCode: 'ls',
    phoneCountryCode: '+266',
    flagIcon: 'ls',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Liberia',
    nameUa: 'Ліберія',
    countryCode: 'lr',
    phoneCountryCode: '+231',
    flagIcon: 'lr',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Libya',
    nameUa: 'Лівія',
    countryCode: 'ly',
    phoneCountryCode: '+218',
    flagIcon: 'ly',
    placeholder: '000-0000000',
    mask: '000-0000000',
    preferred: false,
  },
  {
    name: 'Liechtenstein',
    nameUa: 'Ліхтенштейн',
    countryCode: 'li',
    phoneCountryCode: '+423',
    flagIcon: 'li',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Lithuania',
    nameUa: 'Литва',
    countryCode: 'lt',
    phoneCountryCode: '+370',
    flagIcon: 'lt',
    placeholder: '(0-000) 00000',
    mask: '(0-000) 00000',
    preferred: false,
  },
  {
    name: 'Luxembourg',
    nameUa: 'Люксембург',
    countryCode: 'lu',
    phoneCountryCode: '+352',
    flagIcon: 'lu',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Macau',
    nameUa: 'Макао',
    countryCode: 'mo',
    phoneCountryCode: '+853',
    flagIcon: 'mo',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Macedonia',
    nameUa: 'Македонія',
    countryCode: 'mk',
    phoneCountryCode: '+389',
    flagIcon: 'mk',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Madagascar',
    nameUa: 'Мадагаскар',
    countryCode: 'mg',
    phoneCountryCode: '+261',
    flagIcon: 'mg',
    placeholder: '000 00 000 00',
    mask: '000 00 000 00',
    preferred: false,
  },
  {
    name: 'Malawi',
    nameUa: 'Малаві',
    countryCode: 'mw',
    phoneCountryCode: '+265',
    flagIcon: 'mw',
    placeholder: '0000 00 00 00',
    mask: '0000 00 00 00',
    preferred: false,
  },
  {
    name: 'Malaysia',
    nameUa: 'Малайзія',
    countryCode: 'my',
    phoneCountryCode: '+60',
    flagIcon: 'my',
    placeholder: '000-000 0000',
    mask: '000-000 0000',
    preferred: false,
  },
  {
    name: 'Maldives',
    nameUa: 'Мальдіви',
    countryCode: 'mv',
    phoneCountryCode: '+960',
    flagIcon: 'mv',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Mali',
    nameUa: 'Малі',
    countryCode: 'ml',
    phoneCountryCode: '+223',
    flagIcon: 'ml',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Malta',
    nameUa: 'Мальта',
    countryCode: 'mt',
    phoneCountryCode: '+356',
    flagIcon: 'mt',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Marshall Islands',
    nameUa: 'Маршалові Острови',
    countryCode: 'mh',
    phoneCountryCode: '+692',
    flagIcon: 'mh',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Martinique',
    nameUa: 'Мартініка',
    countryCode: 'mq',
    phoneCountryCode: '+596',
    flagIcon: 'mq',
    placeholder: '(000)00-00-00',
    mask: '(000)00-00-00',
    preferred: false,
  },
  {
    name: 'Mauritania',
    nameUa: 'Мавританія',
    countryCode: 'mr',
    phoneCountryCode: '+222',
    flagIcon: 'mr',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Mauritius',
    nameUa: 'Маврикій',
    countryCode: 'mu',
    phoneCountryCode: '+230',
    flagIcon: 'mu',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Mayotte',
    nameUa: 'Майотта',
    countryCode: 'yt',
    phoneCountryCode: '+262',
    flagIcon: 'yt',
    placeholder: '0000 00 00 00',
    mask: '0000 00 00 00',
    preferred: false,
  },
  {
    name: 'Mexico',
    nameUa: 'Мексика',
    countryCode: 'mx',
    phoneCountryCode: '+52',
    flagIcon: 'mx',
    placeholder: '000 000 000 0000',
    mask: '000 000 000 0000',
    preferred: false,
  },
  {
    name: 'Micronesia',
    nameUa: 'Федеративні Штати Мікронезії',
    countryCode: 'fm',
    phoneCountryCode: '+691',
    flagIcon: 'fm',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Moldova',
    nameUa: 'Молдова',
    countryCode: 'md',
    phoneCountryCode: '+373',
    flagIcon: 'md',
    placeholder: '0000 00 000',
    mask: '0000 00 000',
    preferred: false,
  },
  {
    name: 'Monaco',
    nameUa: 'Монако',
    countryCode: 'mc',
    phoneCountryCode: '+377',
    flagIcon: 'mc',
    placeholder: '00 00 00 00 00',
    mask: '00 00 00 00 00',
    preferred: false,
  },
  {
    name: 'Mongolia',
    nameUa: 'Монголія',
    countryCode: 'mn',
    phoneCountryCode: '+976',
    flagIcon: 'mn',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Montenegro',
    nameUa: 'Чорногорія',
    countryCode: 'me',
    phoneCountryCode: '+382',
    flagIcon: 'me',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Montserrat',
    nameUa: 'Монтсеррат (ВБ)',
    countryCode: 'ms',
    phoneCountryCode: '+1(664)',
    flagIcon: 'ms',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Morocco',
    nameUa: 'Марокко',
    countryCode: 'ma',
    phoneCountryCode: '+212',
    flagIcon: 'ma',
    placeholder: '0000-000000',
    mask: '0000-000000',
    preferred: false,
  },
  {
    name: 'Mozambique',
    nameUa: 'Мозамбік',
    countryCode: 'mz',
    phoneCountryCode: '+258',
    flagIcon: 'mz',
    placeholder: '00 000 0000',
    mask: '00 000 0000',
    preferred: false,
  },
  {
    name: 'Myanmar (Burma)',
    nameUa: 'М’янма (Бірма)',
    countryCode: 'mm',
    phoneCountryCode: '+95',
    flagIcon: 'mm',
    placeholder: '00 000 0000',
    mask: '00 000 0000',
    preferred: false,
  },
  {
    name: 'Namibia',
    nameUa: 'Намібія',
    countryCode: 'na',
    phoneCountryCode: '+264',
    flagIcon: 'na',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Nauru',
    nameUa: 'Науру',
    countryCode: 'nr',
    phoneCountryCode: '+674',
    flagIcon: 'nr',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Nepal',
    nameUa: 'Непал',
    countryCode: 'np',
    phoneCountryCode: '+977',
    flagIcon: 'np',
    placeholder: '000-0000000',
    mask: '000-0000000',
    preferred: false,
  },
  {
    name: 'Netherlands',
    nameUa: 'Нідерланди',
    countryCode: 'nl',
    phoneCountryCode: '+31',
    flagIcon: 'nl',
    placeholder: '00 00000000',
    mask: '00 00000000',
    preferred: false,
  },
  {
    name: 'New Caledonia',
    nameUa: 'Нова Каледонія',
    countryCode: 'nc',
    phoneCountryCode: '+687',
    flagIcon: 'nc',
    placeholder: '00 00 00',
    mask: '00 00 00',
    preferred: false,
  },
  {
    name: 'New Zealand',
    nameUa: 'Нова Зеландія',
    countryCode: 'nz',
    phoneCountryCode: '+64',
    flagIcon: 'nz',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Nicaragua',
    nameUa: 'Нікарагуа',
    countryCode: 'ni',
    phoneCountryCode: '+505',
    flagIcon: 'ni',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Niger',
    nameUa: 'Нігер',
    countryCode: 'ne',
    phoneCountryCode: '+227',
    flagIcon: 'ne',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Nigeria',
    nameUa: 'Нігерія',
    countryCode: 'ng',
    phoneCountryCode: '+234',
    flagIcon: 'ng',
    placeholder: '0000 000 0000',
    mask: '0000 000 0000',
    preferred: false,
  },
  {
    name: 'Niue',
    nameUa: 'Ніуе',
    countryCode: 'nu',
    phoneCountryCode: '+683',
    flagIcon: 'nu',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Norfolk Island',
    nameUa: 'Острів Норфолк',
    countryCode: 'nf',
    phoneCountryCode: '+672',
    flagIcon: 'nf',
    placeholder: '000-000',
    mask: '000-000',
    preferred: false,
  },
  {
    name: 'North Korea',
    nameUa: 'Північна Корея',
    countryCode: 'kp',
    phoneCountryCode: '+850',
    flagIcon: 'kp',
    placeholder: '0000 000 0000',
    mask: '0000 000 0000',
    preferred: false,
  },
  {
    name: 'Northern Mariana Islands',
    nameUa: 'Північні Маріанські острови (США)',
    countryCode: 'mp',
    phoneCountryCode: '+1(670)',
    flagIcon: 'mp',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Norway',
    nameUa: 'Норвегія',
    countryCode: 'no',
    phoneCountryCode: '+47',
    flagIcon: 'no',
    placeholder: '000 00 000',
    mask: '000 00 000',
    preferred: false,
  },
  {
    name: 'Oman',
    nameUa: 'Оман',
    countryCode: 'om',
    phoneCountryCode: '+968',
    flagIcon: 'om',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Pakistan',
    nameUa: 'Пакистан',
    countryCode: 'pk',
    phoneCountryCode: '+92',
    flagIcon: 'pk',
    placeholder: '0000 0000000',
    mask: '0000 0000000',
    preferred: false,
  },
  {
    name: 'Palau',
    nameUa: 'Палау',
    countryCode: 'pw',
    phoneCountryCode: '+680',
    flagIcon: 'pw',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Palestine',
    nameUa: 'Палестина',
    countryCode: 'ps',
    phoneCountryCode: '+970',
    flagIcon: 'ps',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Panama',
    nameUa: 'Панама',
    countryCode: 'pa',
    phoneCountryCode: '+507',
    flagIcon: 'pa',
    placeholder: '0000-0000',
    mask: '0000-0000',
    preferred: false,
  },
  {
    name: 'Papua New Guinea',
    nameUa: 'Папуа Нова Гвінея',
    countryCode: 'pg',
    phoneCountryCode: '+675',
    flagIcon: 'pg',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Paraguay',
    nameUa: 'Парагвай',
    countryCode: 'py',
    phoneCountryCode: '+595',
    flagIcon: 'py',
    placeholder: '0000 000000',
    mask: '0000 000000',
    preferred: false,
  },
  {
    name: 'Peru',
    nameUa: 'Перу',
    countryCode: 'pe',
    phoneCountryCode: '+51',
    flagIcon: 'pe',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Philippines',
    nameUa: 'Філіппіни',
    countryCode: 'ph',
    phoneCountryCode: '+63',
    flagIcon: 'ph',
    placeholder: '0000 000 0000',
    mask: '0000 000 0000',
    preferred: false,
  },
  {
    name: 'Poland',
    nameUa: 'Польща',
    countryCode: 'pl',
    phoneCountryCode: '+48',
    flagIcon: 'pl',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: true,
  },
  {
    name: 'Portugal',
    nameUa: 'Португалія',
    countryCode: 'pt',
    phoneCountryCode: '+351',
    flagIcon: 'pt',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Puerto Rico',
    nameUa: 'Пуерто-Рико (США)',
    countryCode: 'pr',
    phoneCountryCode: '+1(787)',
    flagIcon: 'pr',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Puerto Rico',
    nameUa: 'Пуерто-Рико (США)',
    countryCode: 'pr',
    phoneCountryCode: '+1(939)',
    flagIcon: 'pr',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Qatar',
    nameUa: 'Катар',
    countryCode: 'qa',
    phoneCountryCode: '+974',
    flagIcon: 'qa',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Reunion',
    nameUa: 'Реюньйон',
    countryCode: 're',
    phoneCountryCode: '+262',
    flagIcon: 're',
    placeholder: '0000 00 00 00',
    mask: '0000 00 00 00',
    preferred: false,
  },
  {
    name: 'Romania',
    nameUa: 'Румунія',
    countryCode: 'ro',
    phoneCountryCode: '+40',
    flagIcon: 'ro',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'russia',
    nameUa: 'росія',
    countryCode: 'ru',
    phoneCountryCode: '+7',
    flagIcon: 'ru',
    placeholder: '0 (000) 000-00',
    mask: '0 (000) 000-00',
    preferred: false,
  },
  {
    name: 'Rwanda',
    nameUa: 'Руанда',
    countryCode: 'rw',
    phoneCountryCode: '+250',
    flagIcon: 'rw',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Saint Barthelemy',
    nameUa: 'Сен-Бартельмі (Франція)',
    countryCode: 'bl',
    phoneCountryCode: '+590',
    flagIcon: 'bl',
    placeholder: '0000 00 00 00',
    mask: '0000 00 00 00',
    preferred: false,
  },
  {
    name: 'Saint Helena',
    nameUa: 'Острів Святої Єлени (ВБ)',
    countryCode: 'sh',
    phoneCountryCode: '+290',
    flagIcon: 'sh',
    placeholder: '00000',
    mask: '00000',
    preferred: false,
  },
  {
    name: 'Saint Kitts and Nevis',
    nameUa: 'Сент Кіттс і Невіс',
    countryCode: 'kn',
    phoneCountryCode: '+1(869)',
    flagIcon: 'kn',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Saint Lucia',
    nameUa: 'Сент-Люсія',
    countryCode: 'lc',
    phoneCountryCode: '+1(758)',
    flagIcon: 'lc',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Saint Martin',
    nameUa: 'Сен-Мартен (Франція)',
    countryCode: 'mf',
    phoneCountryCode: '+590',
    flagIcon: 'mf',
    placeholder: '0000 00 00 00',
    mask: '0000 00 00 00',
    preferred: false,
  },
  {
    name: 'Saint Pierre and Miquelon',
    nameUa: 'Сен-П’єр і Мікелон (Франція)',
    countryCode: 'pm',
    phoneCountryCode: '+508',
    flagIcon: 'pm',
    placeholder: '000 00 00',
    mask: '000 00 00',
    preferred: false,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    nameUa: 'Сент-Вінсент і Гренадин',
    countryCode: 'vc',
    phoneCountryCode: '+1(784)',
    flagIcon: 'vc',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Samoa',
    nameUa: 'Самоа',
    countryCode: 'ws',
    phoneCountryCode: '+685',
    flagIcon: 'ws',
    placeholder: '00 00000',
    mask: '00 00000',
    preferred: false,
  },
  {
    name: 'San Marino',
    nameUa: 'Сан-Марино',
    countryCode: 'sm',
    phoneCountryCode: '+378',
    flagIcon: 'sm',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Sao Tome and Principe',
    nameUa: 'Сан-Томе і Принсіпі',
    countryCode: 'st',
    phoneCountryCode: '+239',
    flagIcon: 'st',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Saudi Arabia',
    nameUa: 'Саудівська Аравія',
    countryCode: 'sa',
    phoneCountryCode: '+966',
    flagIcon: 'sa',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Senegal',
    nameUa: 'Сенегал',
    countryCode: 'sn',
    phoneCountryCode: '+221',
    flagIcon: 'sn',
    placeholder: '00 000 00 00',
    mask: '00 000 00 00',
    preferred: false,
  },
  {
    name: 'Serbia',
    nameUa: 'Сербія',
    countryCode: 'rs',
    phoneCountryCode: '+381',
    flagIcon: 'rs',
    placeholder: '000 0000000',
    mask: '000 0000000',
    preferred: false,
  },
  {
    name: 'Seychelles',
    nameUa: 'Сейшельські Острови',
    countryCode: 'sc',
    phoneCountryCode: '+248',
    flagIcon: 'sc',
    placeholder: '0 000 000',
    mask: '0 000 000',
    preferred: false,
  },
  {
    name: 'Sierra Leone',
    nameUa: 'Сьєрра-Леоне',
    countryCode: 'sl',
    phoneCountryCode: '+232',
    flagIcon: 'sl',
    placeholder: '(000) 000000',
    mask: '(000) 000000',
    preferred: false,
  },
  {
    name: 'Singapore',
    nameUa: 'Сінгапур',
    countryCode: 'sg',
    phoneCountryCode: '+65',
    flagIcon: 'sg',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Sint Maarten',
    nameUa: 'Сінт-Мартен',
    countryCode: 'sx',
    phoneCountryCode: '+1(721)',
    flagIcon: 'sx',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Slovakia',
    nameUa: 'Словаччина',
    countryCode: 'sk',
    phoneCountryCode: '+421',
    flagIcon: 'sk',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Slovenia',
    nameUa: 'Словенія',
    countryCode: 'si',
    phoneCountryCode: '+386',
    flagIcon: 'si',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Solomon Islands',
    nameUa: 'Соломонові Острови',
    countryCode: 'sb',
    phoneCountryCode: '+677',
    flagIcon: 'sb',
    placeholder: '00 00000',
    mask: '00 00000',
    preferred: false,
  },
  {
    name: 'Somalia',
    nameUa: 'Сомалі',
    countryCode: 'so',
    phoneCountryCode: '+252',
    flagIcon: 'so',
    placeholder: '0 0000000',
    mask: '0 0000000',
    preferred: false,
  },
  {
    name: 'South Africa',
    nameUa: 'Південна Африка',
    countryCode: 'za',
    phoneCountryCode: '+27',
    flagIcon: 'za',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'South Korea',
    nameUa: 'Південна Корея',
    countryCode: 'kr',
    phoneCountryCode: '+82',
    flagIcon: 'kr',
    placeholder: '000-0000-0000',
    mask: '000-0000-0000',
    preferred: false,
  },
  {
    name: 'South Sudan',
    nameUa: 'Південний Судан',
    countryCode: 'ss',
    phoneCountryCode: '+211',
    flagIcon: 'ss',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Spain',
    nameUa: 'Іспанія',
    countryCode: 'es',
    phoneCountryCode: '+34',
    flagIcon: 'es',
    placeholder: '000 00 00 00',
    mask: '000 00 00 00',
    preferred: false,
  },
  {
    name: 'Sri Lanka',
    nameUa: 'Шрі-Ланка',
    countryCode: 'lk',
    phoneCountryCode: '+94',
    flagIcon: 'lk',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Sudan',
    nameUa: 'Судан',
    countryCode: 'sd',
    phoneCountryCode: '+249',
    flagIcon: 'sd',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Suriname',
    nameUa: 'Суринам',
    countryCode: 'sr',
    phoneCountryCode: '+597',
    flagIcon: 'sr',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Svalbard and Jan Mayen',
    nameUa: 'Свальбард (Норвегія)',
    countryCode: 'sj',
    phoneCountryCode: '+47',
    flagIcon: 'sj',
    placeholder: '000 00 000',
    mask: '000 00 000',
    preferred: false,
  },
  {
    name: 'Swaziland  (Eswatini)',
    nameUa: 'Есватіні',
    countryCode: 'sz',
    phoneCountryCode: '+268',
    flagIcon: 'sz',
    placeholder: '0000 0000',
    mask: '0000 0000',
    preferred: false,
  },
  {
    name: 'Sweden',
    nameUa: 'Швеція',
    countryCode: 'se',
    phoneCountryCode: '+46',
    flagIcon: 'se',
    placeholder: '000-000 00 00',
    mask: '000-000 00 00',
    preferred: false,
  },
  {
    name: 'Switzerland',
    nameUa: 'Швейцарія',
    countryCode: 'ch',
    phoneCountryCode: '+41',
    flagIcon: 'ch',
    placeholder: '000 000 00 00',
    mask: '000 000 00 00',
    preferred: false,
  },
  {
    name: 'Syria',
    nameUa: 'Сирія',
    countryCode: 'sy',
    phoneCountryCode: '+963',
    flagIcon: 'sy',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Taiwan',
    nameUa: 'Тайвань',
    countryCode: 'tw',
    phoneCountryCode: '+886',
    flagIcon: 'tw',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Tajikistan',
    nameUa: 'Таджикистан',
    countryCode: 'tj',
    phoneCountryCode: '+992',
    flagIcon: 'tj',
    placeholder: '000 00 0000',
    mask: '000 00 0000',
    preferred: false,
  },
  {
    name: 'Tanzania',
    nameUa: 'Танзанія',
    countryCode: 'tz',
    phoneCountryCode: '+255',
    flagIcon: 'tz',
    placeholder: '0000 000 000',
    mask: '0000 000 000',
    preferred: false,
  },
  {
    name: 'Thailand',
    nameUa: 'Таїланд',
    countryCode: 'th',
    phoneCountryCode: '+66',
    flagIcon: 'th',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Togo',
    nameUa: 'Того',
    countryCode: 'tg',
    phoneCountryCode: '+228',
    flagIcon: 'tg',
    placeholder: '00 00 00 00',
    mask: '00 00 00 00',
    preferred: false,
  },
  {
    name: 'Tokelau',
    nameUa: 'Токелау',
    countryCode: 'tk',
    phoneCountryCode: '+690',
    flagIcon: 'tk',
    placeholder: '0000',
    mask: '0000',
    preferred: false,
  },
  {
    name: 'Tonga',
    nameUa: 'Тонга',
    countryCode: 'to',
    phoneCountryCode: '+676',
    flagIcon: 'to',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Trinidad and Tobago',
    nameUa: 'Тринідад і Тобаго',
    countryCode: 'tt',
    phoneCountryCode: '+1(868)',
    flagIcon: 'tt',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Tunisia',
    nameUa: 'Туніс',
    countryCode: 'tn',
    phoneCountryCode: '+216',
    flagIcon: 'tn',
    placeholder: '00 000 000',
    mask: '00 000 000',
    preferred: false,
  },
  {
    name: 'Turkey',
    nameUa: 'Туреччина',
    countryCode: 'tr',
    phoneCountryCode: '+90',
    flagIcon: 'tr',
    placeholder: '0000 000 00 00',
    mask: '0000 000 00 00',
    preferred: false,
  },
  {
    name: 'Turkmenistan',
    nameUa: 'Туркменістан',
    countryCode: 'tm',
    phoneCountryCode: '+993',
    flagIcon: 'tm',
    placeholder: '0 00 000000',
    mask: '0 00 000000',
    preferred: false,
  },
  {
    name: 'Turks and Caicos Islands',
    nameUa: 'Острови Теркс і Кайкос (ВБ)',
    countryCode: 'tc',
    phoneCountryCode: '+1(649)',
    flagIcon: 'tc',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Tuvalu',
    nameUa: 'Тувалу',
    countryCode: 'tv',
    phoneCountryCode: '+688',
    flagIcon: 'tv',
    placeholder: '000000',
    mask: '000000',
    preferred: false,
  },
  {
    name: 'U.S. Virgin Islands',
    nameUa: 'Вірґінські Острови',
    countryCode: 'vi',
    phoneCountryCode: '+1(340)',
    flagIcon: 'vi',
    placeholder: '000-0000',
    mask: '000-0000',
    preferred: false,
  },
  {
    name: 'Uganda',
    nameUa: 'Уганда',
    countryCode: 'ug',
    phoneCountryCode: '+256',
    flagIcon: 'ug',
    placeholder: '0000 000000',
    mask: '0000 000000',
    preferred: false,
  },
  {
    name: 'Ukraine',
    nameUa: 'Україна',
    countryCode: 'ua',
    phoneCountryCode: '+380',
    flagIcon: 'ua',
    placeholder: '(00) 000 0000',
    mask: '(00) 000 0000',
    preferred: true,
  },
  {
    name: 'United Arab Emirates',
    nameUa: 'Об’єднані Арабські Емірати',
    countryCode: 'ae',
    phoneCountryCode: '+971',
    flagIcon: 'ae',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'United Kingdom',
    nameUa: 'Велика Британія',
    countryCode: 'gb',
    phoneCountryCode: '+44',
    flagIcon: 'gb',
    placeholder: '00000 00000',
    mask: '00000 00000',
    preferred: false,
  },
  {
    name: 'United States',
    nameUa: 'Сполучені Штати Америки',
    countryCode: 'us',
    phoneCountryCode: '+1',
    flagIcon: 'us',
    placeholder: '(000) 000-0000',
    mask: '(000) 000-0000',
    preferred: false,
  },
  {
    name: 'Uruguay',
    nameUa: 'Уругвай',
    countryCode: 'uy',
    phoneCountryCode: '+598',
    flagIcon: 'uy',
    placeholder: '000 000 000',
    mask: '000 000 000',
    preferred: false,
  },
  {
    name: 'Uzbekistan',
    nameUa: 'Узбекистан',
    countryCode: 'uz',
    phoneCountryCode: '+998',
    flagIcon: 'uz',
    placeholder: '0 00 000 00 00',
    mask: '0 00 000 00 00',
    preferred: false,
  },
  {
    name: 'Vanuatu',
    nameUa: 'Вануату',
    countryCode: 'vu',
    phoneCountryCode: '+678',
    flagIcon: 'vu',
    placeholder: '000 0000',
    mask: '000 0000',
    preferred: false,
  },
  {
    name: 'Vatican City',
    nameUa: 'Ватикан',
    countryCode: 'va',
    phoneCountryCode: '+39',
    flagIcon: 'va',
    placeholder: '000 000 0000',
    mask: '000 000 0000',
    preferred: false,
  },
  {
    name: 'Venezuela',
    nameUa: 'Венесуела',
    countryCode: 've',
    phoneCountryCode: '+58',
    flagIcon: 've',
    placeholder: '0000-0000000',
    mask: '0000-0000000',
    preferred: false,
  },
  {
    name: 'Vietnam',
    nameUa: 'В’єтнам',
    countryCode: 'vn',
    phoneCountryCode: '+84',
    flagIcon: 'vn',
    placeholder: '000 000 00 00',
    mask: '000 000 00 00',
    preferred: false,
  },
  {
    name: 'Wallis and Futuna',
    nameUa: 'Волліс і Футуна',
    countryCode: 'wf',
    phoneCountryCode: '+681',
    flagIcon: 'wf',
    placeholder: '00 00 00',
    mask: '00 00 00',
    preferred: false,
  },
  {
    name: 'Western Sahara',
    nameUa: 'Західна Сахара',
    countryCode: 'eh',
    phoneCountryCode: '+212',
    flagIcon: 'eh',
    placeholder: '0000-000000',
    mask: '0000-000000',
    preferred: false,
  },
  {
    name: 'Yemen',
    nameUa: 'Ємен',
    countryCode: 'ye',
    phoneCountryCode: '+967',
    flagIcon: 'ye',
    placeholder: '0-000-000',
    mask: '0-000-000',
    preferred: false,
  },
  {
    name: 'Zambia',
    nameUa: 'Замбія',
    countryCode: 'zm',
    phoneCountryCode: '+260',
    flagIcon: 'zm',
    placeholder: '00-000-0000',
    mask: '00-000-0000',
    preferred: false,
  },
  {
    name: 'Zimbabwe',
    nameUa: 'Зімбабве',
    countryCode: 'zw',
    phoneCountryCode: '+263',
    flagIcon: 'zw',
    placeholder: '0-000000',
    mask: '0-000000',
    preferred: false,
  },
  {
    name: 'Åland Islands',
    nameUa: 'Аландські острови',
    countryCode: 'ax',
    phoneCountryCode: '+358',
    flagIcon: 'ax',
    placeholder: '00 000 00',
    mask: '00 000 00',
    preferred: false,
  },
];
