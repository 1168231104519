<div
  class="block p-5 md:px-0 md:py-10 content-container rounded-20 md:rounded-30 bg-gray-card">
  <h2
    class="pb-5 uppercase md:px-4 md:pl-25 text-h2-360 md:text-h2-heading text-primary-base">
    Зміст сторінки
  </h2>
  <ul class="flex flex-col gap-5 md:pl-25">
    <li *ngFor="let link of links" class="text-smaller-main md:text-main-text">
      <a
        appScrollToSection
        scrollToSection="{{ link.id }}"
        class="underline underline-offset-4 cursor-fancy cursor-pointer text-gray-dark hover:text-secondary-base hover:no-underline duration-300"
        >{{ link.title }}</a
      >
    </li>
  </ul>
</div>
