<div *ngIf="pagesArray.length * newsOnPage > newsOnPage" class="container my-5">
  <div class="flex justify-center items-center mb-30 gap-7.5">
    <div class="flex justify-center items-center w-5 h-5">
      <div
        *ngIf="currentPage > 1"
        class="fill-accent-base cursor-pointer hover:fill-secondary-base focus:fill-secondary-base">
        <app-left (click)="previous()"></app-left>
      </div>
    </div>

    <div *ngFor="let page of pagesArray">
      <div
        class="inline-block py-1 text-gray-dark hover:font-bold hover:no-underline hover:text-primary-base focus:font-bold focus:no-underline focus:text-primary-base mx-1"
        [ngClass]="{
          'underline cursor-pointer': +page !== currentPage,
          'cursor-not-allowed text-main-text-bold text-primary-base':
            +page === currentPage
        }"
        (click)="setCurrent(page)">
        <span *ngIf="pagesArray.length * newsOnPage > newsOnPage">{{
          page
        }}</span>
      </div>
    </div>
    <div class="flex justify-center items-center w-5 h-5">
      <div
        *ngIf="currentPage !== pagesArray.length"
        class="fill-accent-base cursor-pointer hover:fill-secondary-base focus:fill-secondary-base">
        <app-right (click)="next()"></app-right>
      </div>
    </div>
  </div>
</div>
