import { Component, OnDestroy, OnInit } from '@angular/core';
import IInfoPage from '../../models/infoPage.interface';
import { joinCardConfig } from 'src/mockData/joinCardConfig';
import {
  BannerPageService,
  IBannerPage,
} from '../../services/api/banner-page.service';
import {
  PortalTextModel,
  PortalTextsService,
} from '../../services/api/portal-texts-by-tag.service';
import { Subscription } from 'rxjs';
import {
  NavigationalCardsService,
  navigationalLinksModel,
} from '../../services/api/navigational-cards.service';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css'],
})
export class JoinComponent implements OnInit, OnDestroy {
  pageName: string = 'get-involved';
  subs: Subscription = new Subscription();

  cards: IInfoPage[] = [];
  bannerLoading: boolean = false;
  portalTextBlock!: PortalTextModel | null;
  bannerData: IBannerPage | null = {
    title: '',
    text: '',
    imgUrl: '',
    pageName: '',
    id: null,
    base64Image: '',
  };

  constructor(
    private pageBannerService: BannerPageService,
    private portalText: PortalTextsService,
    private navLinksService: NavigationalCardsService,
  ) {}

  ngOnInit(): void {
    this.getBannerData();
    this.getPageText();
    this.getCards();
  }

  getBannerData() {
    this.bannerLoading = true;
    this.pageBannerService.getBannersData(this.pageName).subscribe(el => {
      this.bannerData = el;
      this.bannerLoading = false;
    });
  }

  getPageText() {
    const sub = this.portalText
      .getPortalTextByTag(this.pageName)
      .subscribe(res => {
        if (res) {
          this.portalTextBlock = res;
        }
      });
    this.subs.add(sub);
  }

  private getCards(): void {
    const sub = this.navLinksService
      .getNavigationalCardsByPageName(this.pageName)
      .subscribe((data: navigationalLinksModel[]) => {
        this.cards = data;
        this.cards.forEach(el => {
          if (el.id === 19 && el.title === 'Регулярний внесок') {
            el.queryParams = {
              subscribe: 'monthly',
            };
          }
        });
      });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
