<div
  class="h-41.5 md:h-50 p-7.5 md:p-10 bg-white text-primary-base w-82 md:w-102.5 xl:w-95 rounded-[20px] md:rounded-30">
  <div class="flex items-center gap-1.5 h-12 md:h-15 mb-0 md:mb-3">
    <svg
      width="40px"
      height="41px"
      [icon]="influence.icon"
      iconCollection="mdi-icons-sprite"
      class="fill-accent-base"></svg>
    <p class="text-h2-heading md:text-h1-narrow text-primary-base pb-px">
      {{ influence.title }}
    </p>
  </div>
  <div class="text-base text-gray-dark">
    {{ influence.bodyText }}
  </div>
</div>
