import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsApiService } from 'src/app/services/api/aboutUsPages/NewsAndMedia/news-api.service';
import { Subscription } from 'rxjs';
import INewsDetailsInfo from 'src/app/models/cardInfo.interface';
import { registerLocaleData } from '@angular/common';
import localeUk from '@angular/common/locales/uk';
@Component({
  selector: 'app-detailed-news',
  templateUrl: './detailed-news.component.html',
  styleUrls: ['./detailed-news.component.css'],
})
export class DetailedNewsComponent implements OnInit, OnDestroy {
  detailedNews!: INewsDetailsInfo;
  newsId: string;
  subscript!: Subscription;
  private routeSubscription: Subscription | null = null;

  constructor(
    private route: ActivatedRoute,
    private newsService: NewsApiService,
  ) {
    registerLocaleData(localeUk, 'uk-UA');
    this.newsId = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.data.subscribe(data => {
      const apiData = data['apiData'] as any & {
        breadcrumb: string;
      };
      this.detailedNews = apiData;
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
