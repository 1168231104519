import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import {
  TextContentModel,
  TextContentService,
} from '../../../../services/api/text-content.service';
import {
  portalImageLinkModel,
  PortalLinksService,
} from '../../../../services/api/portal-links.service';
import {
  DocsReportModel,
  ReportsDocsService,
} from '../../../../services/api/aboutUsPages/AboutFondPages/ReportsAndDocsPages/reports-docs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-friendly-society',
  templateUrl: './friendly-society.component.html',
  styleUrls: ['./friendly-society.component.css'],
})
export class FriendlySocietyComponent implements OnInit, OnDestroy {
  pageName: string = 'about-us/programs/friendly-community';
  combinedBlocks: {
    first_block: TextContentModel[];
    secondth_block: TextContentModel;
    third_block: TextContentModel[];
    fourth_block: TextContentModel;
  } | null = null;
  safedYoutubeUrl: SafeResourceUrl = '';
  portalsData: portalImageLinkModel[] | null = null;
  pageDocsData: DocsReportModel[] | null = null;
  pageTextData: TextContentModel[] = [];
  links: { id: string; title: string }[] | null = null;
  imageListFirst: portalImageLinkModel[] = [];
  imageListSecond: portalImageLinkModel[] = [];

  subs: Subscription = new Subscription();

  helpWithMedicineCards = [
    {
      id: 27,
      title: 'Долучитися',
      link: PAGE_ROUTES.join,
      text: 'Дізнайтеся, як ви можете підтримати людей з деменцією та їхні родини!',
    },
    {
      id: 28,
      title: 'Заходи',
      link: PAGE_ROUTES.activities,
      text: 'Слідкуйте за нашими оновленнями та долучайтеся до подій.',
    },
  ];

  constructor(
    private apiService: TextContentService,
    private _sanitizer: DomSanitizer,
    private portalImageLinkService: PortalLinksService,
    private getDocs: ReportsDocsService,
  ) {}

  ngOnInit(): void {
    this.getPageTextData();
    this.getPortalImageLinks();
    this.getPageDocs();
  }

  private getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageTextData = res;
        this.combinedBlocks = {
          first_block: res
            .filter(el => el.key === 'first_block')
            .sort((a, b) => a.orderId! - b.orderId!),
          secondth_block: res.find(el => el.key === 'secondth_block')!,
          third_block: res
            .filter(el => el.key === 'third-block')
            .sort((a, b) => a.orderId! - b.orderId!),
          fourth_block: res.find(el => el.key === 'fourth_block')!,
        };
        this.links = res
          .filter(el => el.groupType === 'main_text')
          .sort((a, b) => a.id - b.id)
          .map(el => ({ id: el.key, title: el.title }));

        this.safedYoutubeUrl = this.linkYoutubePrepared(
          this.combinedBlocks!.third_block[0],
        );
      });
    this.subs.add(sub);
  }

  getPortalImageLinks() {
    const sub = this.portalImageLinkService
      .getPortalLinksByPageName(this.pageName)
      .subscribe(res => {
        this.portalsData = res;
        this.imageListFirst = res.filter(el => el.tag === 'own_activities');
        this.imageListSecond = res.filter(el => el.tag === 'join_activities');
      });
    this.subs.add(sub);
  }

  getPageDocs() {
    const sub = this.getDocs.getReportsByType(this.pageName).subscribe(res => {
      this.pageDocsData = res.sort((a, b) => a.orderId - b.orderId);
    });
    this.subs.add(sub);
  }

  linkYoutubePrepared(textContentModel: TextContentModel) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube-nocookie.com/embed/${textContentModel.buttonURL}`,
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
