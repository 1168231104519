import { Component } from '@angular/core';

@Component({
  selector: 'app-news-and-media',
  templateUrl: './news-and-media.component.html',
  styleUrls: ['./news-and-media.component.css'],
})
export class NewsAndMediaComponent {
  pageName: string = 'about-us/news-and-media';
}
