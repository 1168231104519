import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import IInfoPage from '../../models/infoPage.interface';
import {
  BannerPageService,
  IBannerPage,
} from '../../services/api/banner-page.service';
import {
  NavigationalCardsService,
  navigationalLinksModel,
} from '../../services/api/navigational-cards.service';
import {
  PortalTextModel,
  PortalTextsService,
} from '../../services/api/portal-texts-by-tag.service';

@Component({
  selector: 'app-universal-nav-page',
  templateUrl: './universal-nav-page.component.html',
  styleUrl: './universal-nav-page.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniversalNavPageComponent implements OnInit, OnDestroy {
  subs: Subscription = new Subscription();
  cards: IInfoPage[] = [];

  @Input() cardsTag: string = '';
  @Input() bannerTag: string = '';
  @Input() portalTextTag: string = '';
  portalTextBlock!: PortalTextModel | null;
  pageBannerData: IBannerPage = {
    title: '',
    text: '',
    imgUrl: '',
    pageName: '',
  };

  constructor(
    private navLinksService: NavigationalCardsService,
    private pageBannerService: BannerPageService,
    private portalText: PortalTextsService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getBannerData();
    this.getCards();
    this.getPageText();
  }

  getPageText() {
    if (this.portalTextTag.length) {
      const sub = this.portalText
        .getPortalTextByTag(this.portalTextTag)
        .subscribe(res => {
          if (res) {
            this.portalTextBlock = res;
            this.cdr.markForCheck();
          }
        });
      this.subs.add(sub);
    }
  }
  getBannerData() {
    const sub = this.pageBannerService
      .getBannersData(this.bannerTag)
      .subscribe(el => {
        this.pageBannerData.title = el.title;
        this.pageBannerData.text = el.text;
        this.pageBannerData.imgUrl = el.imgUrl;
        this.cdr.markForCheck();
      });
    this.subs.add(sub);
  }

  private getCards(): void {
    const sub = this.navLinksService
      .getNavigationalCardsByPageName(this.cardsTag)
      .subscribe((data: navigationalLinksModel[]) => {
        this.cards = data;
        this.cdr.markForCheck();
      });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
