<section
  class="flex flex-col xl:flex-row gap-5 justify-center px-4 md:px-15 xl:px-0 content-container"
  *ngIf="combinedData">
  <h2
    class="text-primary-base uppercase text-h1-360 xl:text-h1-heading w-full xl:w-95 mr-5">
    {{ combinedData.first_block.title }}
  </h2>
  <div
    class="text-gray-dark text-main-text w-full xl:w-195"
    [innerHtml]="combinedData.first_block.bodyText"
    appPhoneLink></div>
</section>

<div class="mt-15 xl:mt-20 px-4 md:px-15 xl:px-0">
  <div
    class="mx-auto relative w-82 md:w-102.5 xl:w-195 overflow-hidden"
    #carousel>
    <!-- Зображення каруселі -->
    <div
      class="carousel-inner flex transition transform duration-300 ease-in-out">
      <img
        *ngFor="let image of imageListFirst"
        [src]="image.imageUrl"
        [alt]="image.imageUrl"
        class="carousel-item flex-[0_0_100%] object-cover w-82 md:w-102.5 xl:w-195 h-[477px] md:h-[597px] xl:h-[1133px] rounded-10 md:rounded-[40px] border-2 border-gray-card" />
    </div>
    <!-- Кнопки каруселі -->
    <div
      *ngIf="imageListFirst.length"
      class="mt-11.5 flex justify-center gap-[36px] items-center">
      <button
        class="cursor-pointer mt-[-6px] disabled:cursor-not-allowed"
        (click)="prevSlide()"
        [disabled]="currentIndex === 0"
        [ngClass]="{
          'pointer-events-none': currentIndex === 0
        }">
        <svg
          class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional"
          [icon]="'left-arrow'"
          iconCollection="button-icons-sprite"
          width="40px"
          height="40px"
          [ngClass]="{
            'fill-gray-gray2 pointer-events-none': currentIndex === 0
          }"></svg>
      </button>
      <div class="text-main-text-bold">
        {{ currentIndex + 1 }} / {{ imageListFirst.length }}
      </div>
      <button
        class="cursor-pointer mt-[-6px] disabled:cursor-not-allowed"
        (click)="nextSlide()"
        [disabled]="currentIndex === imageListFirst.length - 1"
        [ngClass]="{
          'pointer-events-none': currentIndex === imageListFirst.length - 1
        }">
        <svg
          class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional"
          [icon]="'right-arrow'"
          iconCollection="button-icons-sprite"
          width="40px"
          height="40px"
          [ngClass]="{
            'fill-gray-gray2 pointer-events-none':
              currentIndex === imageListFirst.length - 1
          }"></svg>
      </button>
    </div>
  </div>
</div>

<section class="mt-15">
  <div class="w-70 mx-auto" (click)="downloadPdfFile()">
    <div>
      <app-button
        [title]="'Завантажити PDF'"
        [buttonType]="'Primary'"
        [svg]="true">
        <svg
          width="20px"
          height="20px"
          icon="download"
          iconCollection="button-icons-sprite"
          class="svg fill-gray-white mt-1 mr-2"></svg>
      </app-button>
    </div>
  </div>
</section>
<section
  class="flex my-30 items-stretch content-container px-4 md:px-15 xl:px-0"
  *ngIf="combinedData"
  appPhoneLink
  [innerHtml]="combinedData.second_block.bodyText"></section>
