import { SubscribeNewsDataInterface } from 'src/app/models/subscribeNewsData.interface';

export const SubscribeNewsDataMock: SubscribeNewsDataInterface = {
  title: 'Підпишіться на наші новини',
  text: '<p>Будьте в курсі актуальних подій і послуг фонду, а також новин та публікацій про деменцію</p>',
  instagramm: '/',
  facebook: '/',
  telegramm: '/',
  socialText: 'Підпишіться на наші соціальні мережі',
};

export const SubscribeNewsThansMessageDataMock: SubscribeNewsDataInterface = {
  title:
    'На вашу електронну скриньку надійшов лист з підтвердженням про підписку',
  text: '<p>Усі ваші особисті дані захищені. Ви зможете відписатися від новин у будь-який момент через електронну пошту.</p>',
  instagramm: '/',
  facebook: '/',
  telegramm: '/',
  socialText: 'Підпишіться на наші соціальні мережі',
};
