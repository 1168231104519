import { Component, Input, OnInit } from '@angular/core';
import { MedicalAssistanceService } from 'src/app/services/api/getSupportPages/medical-assistance.service';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.css'],
})
export class MoreInfoComponent implements OnInit {
  @Input() moreInfos: any;
  @Input() mainText: any;

  constructor(private pageService: MedicalAssistanceService) {}

  ngOnInit(): void {}
}
