import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import ICardInfo from 'src/app/models/cardInfo.interface';
import { NewsApiService } from 'src/app/services/api/aboutUsPages/NewsAndMedia/news-api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsComponent implements OnInit, OnDestroy {
  cardInfo: ICardInfo[] = [];
  subscr!: Subscription;
  pageNumber = 1;
  cardsPerPage = 9;
  asc: boolean = false;
  count: number = 0;
  constructor(
    private newsApiService: NewsApiService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  getNews() {
    this.subscr = this.newsApiService
      .getNews(this.pageNumber, this.cardsPerPage, this.asc)
      .subscribe(response => {
        this.cardInfo = response.items;
        this.count = response.count;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.subscr.unsubscribe();
  }

  valueForFilter(e: string) {
    if (e === 'Спочатку нові') {
      this.asc = false;
      this.getNews();
    }
    if (e === 'Спочатку старі') {
      this.asc = true;
      this.getNews();
    }
  }

  changePage(event: number) {
    this.pageNumber = event;
    this.getNews();
  }
}
