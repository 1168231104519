<div class="content-container" *ngIf="cards.length > 0; else loader">
  <ul
    class="flex flex-col justify-center smd:grid smd:grid-cols-2 gap-2.5 smd:gap-5 px-4 md:px-0">
    <app-info-card *ngFor="let card of cards" [card]="card"></app-info-card>
  </ul>
</div>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
