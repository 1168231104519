<div
  (click)="navigate(cardInfo.id)"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false"
  class="w-full md:w-105 xl:w-95 group cursor-pointer rounded overflow-hidden">
  <img
    alt="image"
    class="w-full object rounded-t-3xl h-75 object-cover"
    src="{{ cardInfo.imageUrl }}" />

  <div
    class="rounded-t-none h-94 md:h-84 xl:h-95 p-4 md:px-10 flex flex-col items-center justify-between border-2 xl:border-t-0 rounded-30 xl:rounded-t-none xl:rounded-b-30 group-hover:border-secondary-base">
    <div>
      <h2
        class="wrapper h-18 w-full xl:w-75 pt-3.5 text-main-text-bold text-gray-dark">
        {{ cardInfo.title | slice: 0 : 60 }}
        <span *ngIf="cardInfo.title.length >= 60">...</span>
      </h2>
      <div
        class="wrapper-text h-18 md:flex w-full xl:w-75 mb-2.5 text-gray-dark text-smaller-main"
        [innerText]="extractTextFromHtml(cardInfo.description)"></div>
      <p>
        Коли: <span class="text-main-text-bold pl-1">{{ cardInfo.when }}</span>
      </p>
      <p>
        Де: <span class="text-main-text-bold pl-1">{{ cardInfo.where }}</span>
      </p>
    </div>
    <app-link-button
      [isHovered]="isHovered"
      [linkButtonType]="'Primary'"
      [title]="'Детальніше'"
      class="w-full md:w-75 xl:h-14 mx-auto xl:mb-8 xl:mt-0">
    </app-link-button>
  </div>
</div>
