import moreIInfoMain from 'src/app/models/moreInfoMainModel';

export const moreInfoHeaderLegalAid: moreIInfoMain[] = [
  {
    mainHeader: ' Більше інформації про види допомоги',
    mainContent: '',
  },
];

export const moreInfoHeaderPsychSup: moreIInfoMain[] = [
  {
    mainHeader: ' Більше інформації про види допомоги',
    mainContent: '',
  },
];

export const moreInfoHeaderCareConsult: moreIInfoMain[] = [
  {
    mainHeader: ' Більше інформації про види допомоги',
    mainContent:
      'Благодійний фонд “Незабутні” постійно підтримує зв’язок із людьми з деменцією та їхніми родинами. Ми стараємось надавати саме ту допомогу, яка необхідна, і постійно шукаємо можливості робити більше. Читайте, яку допомогу ви можете отримати вже зараз',
  },
];
