import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IHelpCard } from '../../models/help-card.interface';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import {
  HowICanHelpBlockService,
  IHowICanHelpBlock,
} from '../../services/api/other/how-i-can-help-block.service';
import {
  TextContentModel,
  TextContentService,
} from '../../services/api/text-content.service';

@Component({
  selector: 'app-help-block',
  templateUrl: './help-block.component.html',
  styleUrls: ['./help-block.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpBlockComponent implements OnInit {
  @Input() pageName: string = 'main';
  @Input() type: string = '';
  @Input() showTitle: boolean = true;
  @Input() showText: boolean = true;
  @Input() showButton: boolean = true;
  @Input() customTitle: string | null = null;

  loaded: boolean = false;
  howCanIHelpTextBlock!: TextContentModel | null;
  protected helpCards: TextContentModel[] = [];
  textBlock: { title: string; text: string } = { title: '', text: '' };
  routerLink = PAGE_ROUTES.join;

  protected get BackgroundColorType(): string {
    return this.type === 'primary'
      ? 'bg-primary-base'
      : this.type === 'secondary'
        ? 'bg-gray-white'
        : '';
  }

  protected get HeadingColorType(): string {
    return this.type === 'primary'
      ? 'text-gray-white'
      : this.type === 'secondary'
        ? 'text-primary-base'
        : '';
  }

  protected get ParagraphColorType(): string {
    return this.type === 'primary'
      ? 'text-gray-white'
      : this.type === 'secondary'
        ? 'text-gray-dark'
        : '';
  }

  get title(): string {
    return this.customTitle || this.howCanIHelpTextBlock?.title || '';
  }

  constructor(
    private readonly contentService: TextContentService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.contentService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        if (res) {
          this.helpCards = res
            .filter(obj => obj.groupType === 'HowCanIHelp')
            .sort((a, b) => a.id - b.id);
          this.howCanIHelpTextBlock =
            res.find(el => el.key === 'main_how-can-i-help') || null;
          this.loaded = true;
          this.cdr.markForCheck();
        }
      });
  }
}
