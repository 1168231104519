<div
  class="w-full h-25 md:h-30 xl:h-39 bg-gray-white rounded-30 shadow-prevention-card flex items-center px-5">
  <div class="h-16 w-16 mr-4">
    <svg
      width="64px"
      height="64px"
      [icon]="card.icon"
      iconCollection="additional-icons-sprite"
      class="fill-primary-base"></svg>
  </div>

  <p class="text-smaller-main md:text-main-text">
    {{ card.text }}
  </p>
</div>
