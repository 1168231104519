import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HelpWithMedicationService {
  url = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getData(): Observable<any> {
    return this.http.get<any>(this.url + '/Support/help-with-medication').pipe(
      map(el => {
        return {
          mainSectionText: el.mainSectionText?.text || 'Default Text',
          linkSection: el.linkSection?.map((item: any) => ({
            ...item,
            id: item.title,
            title: item.text,
          })),
          helpWithMedicine: el.helpWithMedicine.map((item: any) => {
            const { bodyText, ...rest } = item;
            return { ...rest, text: bodyText };
          }),
          helpWithMedicineCards: el.helpWithMedicineCards.map((item: any) => {
            const { bodyText, ...rest } = item;
            return { ...rest, text: bodyText };
          }),
        };
      }),
    );
  }
}

export interface GetHelpWithMedicationInterface {
  mainSectionText: '';
  linkSection: PortalTextModel[];
  helpWithMedicine: {
    id: number;
    title: string;
    link: string;
    text: string;
  }[];
  helpWithMedicineCards: {
    title: string;
    link: string;
    text: string;
  }[];
}

interface PortalTextModel {
  title: string;
  text: string;
}
