import { IContact } from 'src/app/models/contact.interface';
import { ContactsEnum } from 'src/app/models/contacts.enum';
import { ISocialLink } from 'src/app/models/social-link.interface';

export const contactsConfig: IContact[] = [
  {
    id: ContactsEnum.Call,
    image: 'phone',
    heading: 'Подзвонити',
    contact: '0 800 30 88 30',
    width: '40px',
    height: '40px',
  },
  {
    id: ContactsEnum.Write,
    image: 'mail',
    heading: 'Написати',
    contact: 'info@nezabutni.org',
    width: '40px',
    height: '40px',
  },
  {
    id: ContactsEnum.Address,
    image: 'location',
    heading: 'Адреса офісу',
    contact:
      '03040, Україна, м. Київ,<br> вул. Красилівська 11,<br> офіс "Незабутні"',
    width: '40px',
    height: '40px',
  },
];

export const socialLinksConfig: ISocialLink[] = [
  {
    image: 'instagram',
    link: 'https://www.instagram.com/nezabutni_/',
    width: '24px',
    height: '24px',
  },
  {
    image: 'facebook',
    link: 'https://www.facebook.com/nezabutni.org',
    width: '16px',
    height: '29px',
  },
  {
    image: 'youtube',
    link: 'https://www.youtube.com/channel/UCIhdADy2Q8xzImxhzQK549w/featured',
    width: '28',
    height: '19',
  },
  {
    image: 'telegram',
    link: 'https://t.me/NezabutniCF',
    width: '26px',
    height: '22px',
  },
  {
    image: 'viber',
    link: 'https://invite.viber.com/?g2=AQBrcrOiC1eYD0%2FwbncaUKw%2BDHJik%2FgYMT8bUv%2F0H3d28l1XNHIZLBdrDQKEoglQ',
    width: '26',
    height: '29',
  },
];
