import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import moreIInfo from 'src/app/models/moreInfo.interface';
import moreIInfoMain from 'src/app/models/moreInfoMainModel';
import { moreInfoHeaderCareConsult } from 'src/mockData/moreInfoMainMockData';
import { moreInfoCareConsultation } from 'src/mockData/moreInfoMockData';
import {
  TextContentModel,
  TextContentService,
} from '../../../services/api/text-content.service';
import { Subscription } from 'rxjs';
import {
  DocsReportModel,
  ReportsDocsService,
} from '../../../services/api/aboutUsPages/AboutFondPages/ReportsAndDocsPages/reports-docs.service';
import { OpenPdf } from '../../../services/pdfOpen.service';

@Component({
  selector: 'app-care-consultation',
  templateUrl: './care-consultation.component.html',
  styleUrls: ['./care-consultation.component.css'],
})
export class CareConsultationComponent implements OnInit, OnDestroy {
  pageName: string = 'get-support/care-consultation';
  combinedData: {
    after_banner: TextContentModel;
    first_block: TextContentModel;
    second_block: TextContentModel;
    third_block: TextContentModel;
    fourth_block: TextContentModel;
    [key: string]: TextContentModel;
  } | null = null;
  pageData: TextContentModel[] = [];
  subs: Subscription = new Subscription();
  accordionData: any[] = [];
  links: { id: string; title: string }[] = [];
  thirdBlockUrl: SafeResourceUrl | null = null;
  pageDocsData: DocsReportModel[] | null = null;

  constructor(
    private openPdf: OpenPdf,
    private _sanitizer: DomSanitizer,
    private apiService: TextContentService,
    private getDocs: ReportsDocsService,
  ) {}

  moreInfosAid: moreIInfo[] = moreInfoCareConsultation;
  mainText: moreIInfoMain[] = moreInfoHeaderCareConsult;

  ngOnInit(): void {
    this.getPageTextData();
    this.getPageDocs();
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.combinedData = {
          after_banner: this.pageData.find(el => el.key === 'after_banner')!,
          first_block: this.pageData.find(el => el.key === 'first_block')!,
          second_block: this.pageData.find(el => el.key === 'second_block')!,
          third_block: this.pageData.find(el => el.key === 'third_block')!,
          fourth_block: this.pageData.find(el => el.key === 'fourth_block')!,
        };
        this.accordionData = this.accordionPreparing(
          this.pageData.filter(el => el.key === 'accordion'),
        );
        this.thirdBlockUrl = this.sanitizedLink(
          this.combinedData!.third_block.buttonURL,
        );
        this.links = this.pageData
          .filter(el => el.groupType === 'main_text')
          .map(el => ({ id: el.key, title: el.title }));
      });
    this.subs.add(sub);
  }

  getPageDocs() {
    const sub = this.getDocs.getReportsByType(this.pageName).subscribe(res => {
      this.pageDocsData = res.sort((a, b) => a.orderId - b.orderId);
    });
    this.subs.add(sub);
  }

  accordionPreparing(arr: TextContentModel[]) {
    return arr
      .map(faq => ({
        id: faq.id,
        title: faq.title,
        HTML: this._sanitizer.bypassSecurityTrustHtml(faq.bodyText),
        key: 'accordion',
        tag: faq.pageName,
        orderId: faq.orderId,
      }))
      .sort((a, b) => a.orderId! - b.orderId!);
  }

  sanitizedLink(url: string) {
    const link = `https://www.youtube-nocookie.com/embed/${url}`;
    return this._sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public downloadPdfFile(): void {
    if (this.pageDocsData![0].documentUrl)
      this.openPdf.getPDF(
        this.pageDocsData![0].documentUrl,
        'Legal assistance',
      );
  }
}
