<app-page-banner-item [pageName]="pageName"></app-page-banner-item>
<div *ngIf="combinedData; else loader">
  <div
    class="content-container py-30 md:px-0 px-[16px]"
    [innerHtml]="combinedData.after_banner_text.bodyText"></div>

  <app-table-of-content
    class="flex mx-auto w-full mb-30 md:px-0 px-[16px]"
    [links]="links"></app-table-of-content>

  <div class="content-container mx-auto md:px-0 px-[16px]">
    <h2
      [id]="combinedData.first_block[0].key"
      class="md:text-h2-heading text-h2-360 uppercase text-primary-base mb-5 md:w-145">
      {{ combinedData.first_block[0].title }}
    </h2>
    <div class="">
      <app-adaptive-page-slider
        *ngIf="imageListFirst.length"
        [skipLast]="false"
        [counterOfPages]="true"
        [amountOffCardsBlock]="imageListFirst.length"
        [hideButton]="[false, false, false]"
        class="relative w-full md:w-[410px] xl:w-[580px] overflow-hidden md:float-right">
        <div
          class="flex justify-start items-center ml-5 mb-5 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
          <img
            *ngFor="let image of imageListFirst"
            [src]="image.imageUrl"
            [alt]="image.imageUrl"
            class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[480px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[600px] object-cover rounded-[30px] border-2 border-gray-card" />
        </div>
      </app-adaptive-page-slider>
      <div
        appPhoneLink
        [innerHtml]="combinedData.first_block[0].bodyText"></div>
    </div>
    <div
      class="flex w-full pt-7.5"
      appPhoneLink
      [innerHtml]="combinedData.first_block[1].bodyText"></div>
  </div>

  <div
    class="content-container px-4 md:px-0 flex flex-col mt-20 text-gray-dark text-main-text">
    <h2
      [id]="combinedData.second_block.key"
      class="md:text-h2-heading text-h2-360 uppercase text-primary-base mb-5 md:w-145">
      {{ combinedData.second_block.title }}
    </h2>
    <div
      class="flex flex-col mt-5 md:mt-10 text-gray-dark text-main-text"
      appPhoneLink
      [innerHtml]="combinedData.second_block.bodyText"></div>
    <app-adaptive-page-slider
      *ngIf="imageListSecond.length"
      [skipLast]="false"
      [counterOfPages]="true"
      [amountOffCardsBlock]="imageListSecond.length"
      [hideButton]="[false, false, false]"
      class="relative w-full overflow-hidden">
      <div
        class="flex justify-start items-center mt-10 mb-10 mx-auto md:mb-15 flex-row gap-5 relative w-[340px] md:w-210 xl:w-295">
        <img
          *ngFor="let image of imageListSecond"
          [src]="image.imageUrl"
          [alt]="image.imageUrl"
          class="shrink-0 w-[280px] sm:w-[300px] md:w-[410px] xl:w-[380px] h-[350px] sm:h-[512px] xl:h-[474px] object-cover rounded-[30px] border-2 border-gray-card" />
      </div>
    </app-adaptive-page-slider>
  </div>

  <section class="mt-30 bg-primary-base h-432px py-30">
    <div
      class="content-container md:px-0 px-[16px] md:text-main-text text-smaller-main text-gray-white mb-15"
      appPhoneLink
      [innerHtml]="combinedData.last_block.bodyText"></div>
    <app-link-button
      [url]="combinedData.last_block.buttonURL"
      [linkButtonType]="'Primary'"
      [title]="'Зв’язатися з фондом'"
      class="sm:w-55 flex justify-center items-center mx-auto">
    </app-link-button>
  </section>
</div>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
