<div class="flex items-center justify-between content-container">
  <section class="flex pt-10 pb-20 gap-5">
    <app-submenu-items-list
      class="flex mx-auto w-70"
      [title]="'Про фонд'"
      [links]="aboutFundLinks"
      [titleLink]="ourIdeaLink"></app-submenu-items-list>
    <app-submenu-items-list
      class="flex mx-auto w-70"
      [title]="'Наші люди'"
      [links]="ourPeopleLinks"
      [titleLink]="ourPeopleLink"></app-submenu-items-list>
    <app-submenu-items-list
      class="flex mx-auto w-70"
      [title]="'Напрями діяльності'"
      [links]="ourProgramsLinks"
      [titleLink]="ourProgramsLink"></app-submenu-items-list>
    <app-submenu-items-list
      class="flex mx-auto w-70"
      [title]="'Новини та медіа'"
      [links]="mediaLinks"
      [titleLink]="newsAndMediaLink"></app-submenu-items-list>
  </section>
</div>
