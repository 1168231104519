import { Component, OnInit } from '@angular/core';
import { ISocialLink } from 'src/app/models/social-link.interface';

import { socialLinksConfig } from 'src/mockData/aboutUs_Contacts_Config';
import {
  TextContentModel,
  TextContentService,
} from '../../../../services/api/text-content.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
})
export class ContactsComponent implements OnInit {
  protected contacts: TextContentModel[] = [];
  protected socialLinks: ISocialLink[] = socialLinksConfig;
  pageData!: TextContentModel[];
  pageName: string = 'about-us/contacts';
  pageTitle!: TextContentModel;
  formsData: TextContentModel | null = null;
  formsDataAgreement: TextContentModel | null = null;

  constructor(private readonly contentService: TextContentService) {}

  ngOnInit(): void {
    this.contentService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        if (res) {
          this.pageData = res.sort((a, b) => a.id - b.id);
          this.pageTitle = this.pageData.find(
            el => el.key === 'contacts_contacts-title',
          )!;
          this.contacts = res.filter(el => el.groupType === 'contacts');
          this.formsData = this.pageData.find(
            el => el.key === 'contacts_form-data',
          )!;
          this.formsDataAgreement = this.pageData.find(
            el => el.key === 'contacts_form-agreement',
          )!;
        }
      });
  }
}
