import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advisory-group',
  templateUrl: './advisory-group.component.html',
  styleUrls: ['./advisory-group.component.css'],
})
export class AdvisoryGroupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
