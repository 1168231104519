import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  @Input() isOpen: boolean = false;
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() description: string = '';
  @Output() close = new EventEmitter<void>();

  private focusableElements: HTMLElement[] = [];
  private firstFocusableElement!: HTMLElement;
  private lastFocusableElement!: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    if (this.isOpen) {
      this.openModal();
    }
  }

  openModal(): void {
    this.isOpen = true;
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
    this.setupFocusTrap();
  }

  closeModal(): void {
    this.isOpen = false;
    this.renderer.removeStyle(document.body, 'overflow');
    this.close.emit();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeModal();
    }
    if (event.key === 'Tab') {
      this.handleTabNavigation(event);
    }
  }

  setupFocusTrap(): void {
    setTimeout(() => {
      const modal = this.el.nativeElement.querySelector('.modal-content');
      this.focusableElements = Array.from(
        modal.querySelectorAll(
          'button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])',
        ),
      );

      if (this.focusableElements.length > 0) {
        this.firstFocusableElement = this.focusableElements[0];
        this.lastFocusableElement =
          this.focusableElements[this.focusableElements.length - 1];

        this.firstFocusableElement.focus();
      }
    });
  }

  handleTabNavigation(event: KeyboardEvent): void {
    if (
      event.shiftKey &&
      document.activeElement === this.firstFocusableElement
    ) {
      event.preventDefault();
      this.lastFocusableElement.focus();
    } else if (
      !event.shiftKey &&
      document.activeElement === this.lastFocusableElement
    ) {
      event.preventDefault();
      this.firstFocusableElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeStyle(document.body, 'overflow');
  }
}
