import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  FormErrorMessageService,
  FormErrors,
  FormFields,
  IErrorMessages,
} from 'src/app/services/form-error-message.service';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css'],
})
export class TextareaComponent implements OnInit, DoCheck, OnDestroy {
  public textAreaLength: number = 0;
  public isShowTextAreaLengthMessage = new BehaviorSubject<boolean>(false);
  public isInvalid: boolean = false;
  private valueLengthSub!: Subscription;

  @Input()
  public control: FormControl = new FormControl();
  @Input()
  public maxLength: string = '';
  @Input()
  public textAreaID: string = '';
  @Input()
  public labelName: string = '';

  public get formFields(): typeof FormFields {
    return FormFields;
  }

  public get formErrors(): typeof FormErrors {
    return FormErrors;
  }

  constructor(private formErrorMessageService: FormErrorMessageService) {}

  ngOnInit(): void {
    this.valueLengthSub = this.control.valueChanges.subscribe(
      (value: string) => {
        this.textAreaLength = value.length;
      },
    );
  }

  ngDoCheck(): void {
    this.isInvalid =
      this.control.invalid && (this.control.touched || this.control.dirty);
  }

  ngOnDestroy(): void {
    this.valueLengthSub && this.valueLengthSub.unsubscribe();
  }

  public showTextAreaLengthMessage(): void {
    this.isShowTextAreaLengthMessage.next(true);
  }

  public displayErrorMessage(
    formField: FormFields,
    formError: FormErrors,
  ): IErrorMessages | undefined {
    return this.formErrorMessageService.displayErrorMessage(
      formField,
      formError,
    );
  }
}
