import { Component, OnInit } from '@angular/core';
import {
  MediaAboutUsModel,
  MediaAboutUsService,
} from '../../../../services/api/aboutUsPages/NewsAndMedia/media-about-us.service';
import {
  TextContentModel,
  TextContentService,
} from '../../../../services/api/text-content.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-media-about-us',
  templateUrl: './media-about-us.component.html',
  styleUrls: ['./media-about-us.component.css'],
})
export class MediaAboutUsComponent implements OnInit {
  productsCard: MediaAboutUsModel[] = [];
  productsCardsAll: MediaAboutUsModel[] = [];
  selectedFilter: string = 'Всі публікації';
  page: number = 1;
  text: string =
    'Для нас важливо, щоб тема деменція висвітлювалася в медіа якомога частіше. Адже так ми можемо достукатися до більшої кількості людей та заохотити їх дбати про своє здоров’я та своїх близьких. Тому ми охоче співпрацюємо з різними виданнями та телеканалами. Читайте та дивіться, що кажуть про нас та про деменцію в медіа.';
  title: string = 'Змі про нас';
  isLoading: boolean = true;
  textData$!: Observable<TextContentModel>;

  constructor(
    private readonly apiService: MediaAboutUsService,
    private textService: TextContentService,
  ) {}

  ngOnInit(): void {
    this.apiService.getCards().subscribe(res => {
      const sortedByDate = res.sort(
        (a, b) =>
          new Date(b.publicationDate).getTime() -
          new Date(a.publicationDate).getTime(),
      );
      this.productsCard = sortedByDate;
      this.productsCardsAll = sortedByDate;
      this.isLoading = false;
    });
    this.textData$ = this.textService
      .getTextsContentByPageName('about-us/news-and-media/media-about-us')
      .pipe(
        map(
          elements => elements.find(el => el.key === 'media-about-us_title')!,
        ),
      );
  }

  public changeValueFilter(value: string): void {
    if (value === 'Відео') {
      this.productsCard = this.productsCardsAll.filter(
        product => product.contentType === 'Відео',
      );
      this.selectedFilter = 'Відео';
      return;
    }
    if (value === 'Статті') {
      this.productsCard = this.productsCardsAll.filter(product => {
        return product.contentType === 'Стаття';
      });
      this.selectedFilter = 'Статтi';
    }
    if (value === 'Всі публікації') {
      this.productsCard = this.productsCardsAll;
      this.selectedFilter = 'Всі публікації';
      return;
    }
  }

  pageChange(e: number) {
    this.page = e;
    window.scroll(0, 200);
  }
}
