import { Component, Input, OnInit } from '@angular/core';
import { TextContentModel } from '../../services/api/text-content.service';

@Component({
  selector: 'app-visionary-card',
  templateUrl: './visionary-card.component.html',
  styleUrls: ['./visionary-card.component.css'],
})
export class VisionaryCardComponent implements OnInit {
  @Input() cards: TextContentModel[] = [];
  constructor() {}

  ngOnInit(): void {}
}
