import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  SubscribeNewsDataMock,
  SubscribeNewsThansMessageDataMock,
} from 'src/mockData/subscribeNewsDataMock';
import { SubscribeNewsDataInterface } from 'src/app/models/subscribeNewsData.interface';
import { ISocialLink } from 'src/app/models/social-link.interface';
import { socialLinksMockData } from 'src/mockData/socialLinksMockData';
import { nameValidator } from 'src/app/components/forms/validators/name-validator';
import { emailValidator } from 'src/app/components/forms/validators/email-validator';
import { Observable } from 'rxjs';
import { EmailService } from 'src/app/services/api/email.service';

@Component({
  selector: 'app-subscribe-news',
  templateUrl: './subscribe-news.component.html',
  styleUrls: ['./subscribe-news.component.css'],
})
export class SubscribeNewsComponent implements OnInit {
  submitted: boolean = false;
  success: boolean = false;
  formSubscribeNews!: FormGroup;
  subscribeNewsData: SubscribeNewsDataInterface = SubscribeNewsDataMock;
  SubscribeNewsThansMessageData: SubscribeNewsDataInterface =
    SubscribeNewsThansMessageDataMock;
  socialsLinks: ISocialLink[] = socialLinksMockData;
  constructor(private apiMailService: EmailService) {}
  name = new FormControl('', [Validators.required, nameValidator]);
  email = new FormControl('', [Validators.required, emailValidator]);
  ngOnInit(): void {
    this.formSubscribeNews = new FormGroup({
      userName: this.name,
      userEmail: this.email,
    });
  }

  onSubmit() {
    const form = this.formSubscribeNews.value;
    if (!this.formSubscribeNews.touched) {
      this.formSubscribeNews.controls['userName'].markAsTouched();
      this.formSubscribeNews.controls['userEmail'].markAsTouched();
    }

    if (!this.formSubscribeNews.valid) {
      return false;
    } else {
      this.submitted = true;
      this.sendEmailToFundMember(form).subscribe(
        response => {
          if (response.status === 200) {
            this.success = true;
            this.submitted = true;
            setTimeout(() => {
              this.success = false;
              this.submitted = false;
              this.formSubscribeNews.reset();
            }, 10000);
          }
        },
        error => {
          alert(
            'Помилка сервера, або цей email вже підписаний, спробуйте ще раз ' +
              error.message,
          );
          this.formSubscribeNews.reset();
          this.success = false;
          this.submitted = false;
        },
      );
      return this.success;
    }
  }
  sendEmailToFundMember(body: any): Observable<any> {
    return this.apiMailService.sendEmail(body);
  }
}
