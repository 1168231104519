import { Component, OnInit } from '@angular/core';
import { ReportsCardInterface } from '../reports-card/reports-card.component';
import { taxReportsMockData } from 'src/mockData/taxReportsMockData';

@Component({
  selector: 'app-tax-reports',
  templateUrl: './tax-reports.component.html',
  styleUrls: ['./tax-reports.component.css'],
})
export class TaxReportsComponent {
  type: string = 'financial';

  emptyPortalBlock() {
    return {
      id: 73,
      tag: this.type,
      title: '',
      text: '',
    };
  }
}
