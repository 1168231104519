import { Injectable } from '@angular/core';
import * as errorMessages from 'src/app/constants/form_error_messages.constants';

export enum FormFields {
  Name = 'name',
  Email = 'email',
  Message = 'message',
  Policy = 'policy',
}

export enum FormErrors {
  Required = 'required',
  Email = 'email',
  InvalidName = 'invalidName',
}

export interface IErrorMessages {
  errorMessage: string;
  extendedErrorMessage?: string;
}

type formErrorMessagesType = {
  [key in FormFields]: {
    [key in FormErrors]?: IErrorMessages;
  };
};
@Injectable({
  providedIn: 'root',
})
export class FormErrorMessageService {
  /**
   * This variable have to contain all possible errors in form
   * The structure of this object
   * Field name: {
   *  Error type: { Error message, Extended error message(optional) }
   * }
   */
  private readonly _formErrorMessages: formErrorMessagesType = {
    // Field name errors
    [FormFields.Name]: {
      [FormErrors.Required]: { errorMessage: errorMessages.REQUIRED },
      [FormErrors.InvalidName]: {
        errorMessage: errorMessages.INVALID_SYMBOLS,
        extendedErrorMessage: errorMessages.EXTENDED_INVALID_SYMBOLS,
      },
    },
    // Field email errors
    [FormFields.Email]: {
      [FormErrors.Required]: {
        errorMessage: errorMessages.REQUIRED,
      },
      [FormErrors.Email]: {
        errorMessage: errorMessages.INVALID_EMAIL,
        extendedErrorMessage: errorMessages.EXTENDED_INVALID_EMAIL,
      },
    },
    // Field message errors
    [FormFields.Message]: {
      [FormErrors.Required]: {
        errorMessage: errorMessages.REQUIRED,
      },
    },
    // Field policy errors
    [FormFields.Policy]: {},
  };

  constructor() {}

  public displayErrorMessage(
    formField: FormFields,
    formError: FormErrors,
  ): IErrorMessages | undefined {
    return this._formErrorMessages[formField][formError];
  }
}
