<li
  class="group border-2 border-stoke p-5 md:p-8 xl:p-10 bg-gray-white focus:border-secondary-base hover:border-secondary-base h-64.5 md:h-80 xl:h-73 w-full md:w-102.5 xl:w-145 rounded-20 md:rounded-[25px] xl:rounded-30 duration-300">
  <a
    class="w-full h-full flex flex-col justify-between"
    appScrollToSectionAfterLoadData
    scrollToSection="{{ card.titleLinkId }}"
    routerLink="{{ card.link }}"
    [queryParams]="card.queryParams">
    <div class="h-full">
      <h2
        class="text-primary-base uppercase text-h2-360 md:text-h2-heading mt-[1px] ml-[1px] mb-2.5 flex-none w-full xl:w-124">
        {{ card.title }}
      </h2>
      <p class="grow text-smaller-main md:text-main-text text-gray-dark">
        {{ card.text }}
      </p>
    </div>

    <div class="w-full md:w-86.5 xl:w-55 h-12 content-center flex-none">
      <p
        class="w-full h-full inline-block py-2.5 text-center text-main-text-bold text-gray-white rounded-10 bg-accent-base bg-accent group-hover:bg-secondary-base duration-300">
        Перейти
      </p>
    </div>
  </a>
</li>
