import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  QueryList,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { MainSlideComponent } from './main-slide/main-slide.component';

@Component({
  selector: 'app-main-slider',
  templateUrl: './main-slider.component.html',
  styleUrls: ['./main-slider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSliderComponent implements AfterContentInit {
  public firstSlide: boolean = false;
  public lastSlide: boolean = true;
  public ind: number = 1;
  @ContentChildren(MainSlideComponent)
  slides!: QueryList<MainSlideComponent>;
  container!: ElementRef;
  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentInit() {
    const activeSearch = this.slides.filter(slide => slide.active);
    if (activeSearch.length === 0) {
      setTimeout(() => {
        this.selectedSlide(this.slides.first);
      });
    }
    setInterval(() => {
      if (this.ind !== this.slides.length) {
        this.nextSlide();
      } else {
        this.selectedSlide(this.slides.first);
        this.ind = 1;
        this.lastSlide = true;
        this.firstSlide = false;
        this.cdr.markForCheck();
      }
    }, 8000);
  }

  selectedSlide(slide: MainSlideComponent) {
    this.slides.toArray().forEach(slide => (slide.active = false));
    slide.active = true;
    this.cdr.detectChanges();
  }

  prevSlide() {
    if (this.ind !== 1) {
      this.ind--;
      if (this.ind == 1) {
        this.firstSlide = false;
      }
      if (this.ind !== this.slides.length) {
        this.lastSlide = true;
      }
    }
    this.selectedSlide(this.slides.toArray()[this.ind - 1]);
  }

  nextSlide() {
    if (this.ind !== this.slides.length) {
      this.ind++;
      if (this.ind >= 2) {
        this.firstSlide = true;
      }
    }
    if (this.ind === this.slides.length) {
      this.lastSlide = false;
    }
    this.cdr.markForCheck();
    this.selectedSlide(this.slides.toArray()[this.ind - 1]);
  }
}
