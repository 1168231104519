<ul *ngFor="let otherPublicationsCard of cards">
  <li
    class="py-10 px-10 mx-auto w-full rounded-30 border-2 border-gray-stroke mb-4 group/arrow hover:border-secondary-base cursor-pointer">
    <a [href]="otherPublicationsCard.buttonURL" target="_blank">
      <div class="flex mb-6">
        <svg
          width="21px"
          height="19px"
          icon="book"
          iconCollection="button-icons-sprite"
          class="svg text-gray-details"></svg>
        <span class="text-gray-details text-details px-3">Дата публікації</span>
        <span class="text-gray-details text-details">{{
          otherPublicationsCard.publicationDate
        }}</span>
      </div>
      <h2
        class="text-h3-subheading text-gray-dark mb-5 h-full xl:h-14 wrapper-mob md:wrapper">
        {{ otherPublicationsCard.title }}
      </h2>
      <div class="w-27.5">
        <app-link-button
          [linkButtonType]="'RightArrow'"
          [title]="'Перейти'"></app-link-button>
      </div>
    </a>
  </li>
</ul>
