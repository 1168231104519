<li
  [routerLink]="pressReleasesLink + '/' + PRCard.id"
  class="py-10 px-10 mx-auto w-full rounded-30 border-2 border-gray-stroke mb-4 cursor-pointer hover:border-secondary-base"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false">
  <div class="flex mb-6">
    <svg
      width="21px"
      height="19px"
      icon="book"
      iconCollection="button-icons-sprite"
      class="svg text-gray-details"></svg>
    <span class="text-gray-details text-details px-3">Дата публікації</span>
    <span class="text-gray-details text-details">{{
      PRCard.dateOfPublication | date : 'dd.MM.yyyy'
    }}</span>
  </div>
  <h2 class="text-h3-subheading text-gray-dark">
    {{ PRCard.title }}
  </h2>
  <p class="mt-5 text-gray-dark text-main-text">
    {{ PRCard.shortText }}
  </p>
  <div class="flex mt-2 -mb-2.5">
    <app-link-button
      [title]="'Перейти'"
      [linkButtonType]="'RightArrow'"
      [isHovered]="isHovered"></app-link-button>
  </div>
</li>
