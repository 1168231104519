<app-page-banner-item
  [imgUrl]="pageBannerData.imgUrl"
  [text]="pageBannerData.text"
  [title]="pageBannerData.title">
  <ng-content select=".banner-content"></ng-content>
</app-page-banner-item>

<div *ngIf="portalTextBlock">
  <app-block-text
    class="content-container text-start flex px-4 md:px-0 my-0"
    [text]="portalTextBlock"></app-block-text>
</div>

<ng-container *ngIf="cards.length === 0; else content">
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-container>

<ng-template #content>
  <app-info-cards [cards]="cards" [classList]="'flex my-30'"></app-info-cards>
</ng-template>
