import { Component, OnDestroy, OnInit } from '@angular/core';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import {
  TextContentModel,
  TextContentService,
} from '../../../../services/api/text-content.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.css'],
})
export class StudyComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  pageName: string = 'about-us/programs/education';
  isHovered: boolean = false;
  contactsLink = PAGE_ROUTES.contacts;
  subs: Subscription = new Subscription();
  pageData: TextContentModel[] = [];
  accordionData: any[] = [];

  constructor(
    private textContentService: TextContentService,
    private _sanitizer: DomSanitizer,
  ) {}

  protected links: { readonly id: string; readonly title: string }[] = [];

  ngOnInit(): void {
    const sub = this.textContentService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res
          .sort((a, b) => a.orderId! - b.orderId!)
          .filter(el => el.key !== 'accordion');
        this.links = this.pageData.map(el => ({
          id: el.key,
          title: this.firsLetterUpper(el.title),
        }));
        this.accordionData = this.accordionPreparing(
          res.filter(el => el.key === 'accordion'),
        );
        this.isLoading = false;
      });
    this.subs.add(sub);
  }

  accordionPreparing(arr: TextContentModel[]) {
    return arr
      .map(faq => ({
        id: faq.id,
        title: faq.title,
        HTML: this._sanitizer.bypassSecurityTrustHtml(faq.bodyText),
        key: 'accordion',
        tag: faq.pageName,
        orderId: faq.orderId,
      }))
      .sort((a, b) => a.orderId! - b.orderId!);
  }

  firsLetterUpper(str: string) {
    const firstLetter = str.charAt(0).toUpperCase();
    const lowerStr = str.toLowerCase().slice(1);
    return firstLetter + lowerStr;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
