import { Component } from '@angular/core';

@Component({
  selector: 'app-phone-with-link',
  templateUrl: './phone-with-link.component.html',
  styleUrls: ['./phone-with-link.component.css']
})
export class PhoneWithLinkComponent {

}
