import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AgreementConfig {
  constructor(private http: HttpClient) {}

  getPublicOffer() {
    return this.http.get('/assets/pdf/public-offer.json');
  }

  getTermsAndConditions() {
    return this.http.get('/assets/pdf/testFile.json');
  }

  getFundPolicy() {
    return this.http.get('/assets/pdf/testFile.json');
  }

  getTestFinancialReport() {
    return this.http.get('/assets/pdf/testFile.json');
  }
}
