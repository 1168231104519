import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAboutDementiaSecondLevelPage } from 'src/app/models/aboutDementia.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AboutDementiaSecondLevelPageService {
  private apiUrl: string = environment.apiUrl;
  private adminUrl: string = this.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getData(link: string): Observable<IAboutDementiaSecondLevelPage> {
    const params = new HttpParams().append('link', link);
    return this.http.get<IAboutDementiaSecondLevelPage>(
      `${this.apiUrl}/AboutDementia/second-level-page`,
      { params },
    );
  }

  updateSecondthPageText(data: any) {
    return this.http.put(`${this.adminUrl}UpdateSecondLevelUnifiedPage`, data);
  }
}
