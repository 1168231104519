import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { IAccordionData } from 'src/app/models/accordionModel.interface';
import IGlossary from 'src/app/models/glossary.interface';
import moreIInfo from 'src/app/models/moreInfo.interface';
import moreIInfoMain from 'src/app/models/moreInfoMainModel';
import {
  Data,
  IPsychologicalSupportResponse,
  Jumbotron,
} from 'src/app/models/psychologicalSupport.interface';
import { ISocialLink } from 'src/app/models/social-link.interface';
import { AccordionApiService } from 'src/app/services/api/accordionApi.service';
import { PsychologicalSupportPageService } from 'src/app/services/api/getSupportPages/psychological-support-page.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { moreInfoPsychSup } from 'src/mockData/moreInfoMockData';
import { psychologySocialLinksMockData } from 'src/mockData/psychologySocialLinksMockData';
import {
  TextContentModel,
  TextContentService,
} from '../../../services/api/text-content.service';
import {
  portalImageLinkModel,
  PortalLinksService,
} from '../../../services/api/portal-links.service';

@Component({
  selector: 'app-psychological-support',
  templateUrl: './psychological-support.component.html',
  styleUrls: ['./psychological-support.component.css'],
})
export class PsychologicalSupportComponent implements OnInit, OnDestroy {
  pageName: string = 'get-support/psychological-support';
  socialLinks: ISocialLink[] = psychologySocialLinksMockData;

  combinedData: {
    first_block: TextContentModel;
    second_block: TextContentModel;
    second_block_second: TextContentModel;
    third_block: TextContentModel;
    fourth_block: TextContentModel;
    fourth_block_second: TextContentModel;
    fiveth_block_second: TextContentModel;
    [key: string]: TextContentModel;
  } | null = null;

  // eslint-disable-next-line no-undef
  [key: string]: any;
  pageData: TextContentModel[] = [];
  subs: Subscription = new Subscription();
  imageListFirst: portalImageLinkModel[] = [];
  accordionData: any[] = [];

  thirdBlockUrl: SafeResourceUrl | null = null;
  fourthBlockUrl: SafeResourceUrl | null = null;

  links: { id: string; title: string }[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private apiService: TextContentService,
    private portalImageLinkService: PortalLinksService,
  ) {}

  moreInfosAid: moreIInfo[] = moreInfoPsychSup;
  mainText: moreIInfoMain[] = [
    {
      mainHeader: ' Більше інформації про види допомоги',
      mainContent: '',
    },
  ];
  amountOffCardsBlock = 1;

  ngOnInit(): void {
    this.getPageTextData();
    this.getPortalImageLinks();
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.combinedData = {
          first_block: this.pageData.find(el => el.key === 'first_block')!,
          second_block: this.pageData.find(el => el.key === 'second_block')!,
          second_block_second: this.pageData.find(
            el => el.key === 'second_block_second',
          )!,
          second_block_third: this.pageData.find(
            el => el.key === 'second_block_third',
          )!,
          third_block: this.pageData.find(el => el.key === 'third_block')!,
          fourth_block: this.pageData.find(el => el.key === 'fourth_block')!,
          fourth_block_second: this.pageData.find(
            el => el.key === 'fourth_block_second',
          )!,
          fiveth_block_second: this.pageData.find(
            el => el.key === 'fiveth_block_second',
          )!,
        };
        this.accordionData = this.accordionPreparing(
          this.pageData.filter(el => el.key === 'accordion'),
        );
        this.thirdBlockUrl = this.sanitizedLink(
          this.combinedData.third_block.buttonURL,
        );
        this.fourthBlockUrl = this.sanitizedLink(
          this.combinedData.fourth_block.buttonURL,
        );
        this.links = this.pageData
          .filter(el => el.groupType === 'main_text')
          .map(el => ({ id: el.key, title: el.title }));
      });
    this.subs.add(sub);
  }

  accordionPreparing(arr: TextContentModel[]) {
    return arr
      .map(faq => ({
        id: faq.id,
        title: faq.title,
        HTML: this.sanitizer.bypassSecurityTrustHtml(faq.bodyText),
        key: 'accordion',
        tag: faq.pageName,
        orderId: faq.orderId,
      }))
      .sort((a, b) => a.orderId! - b.orderId!);
  }

  sanitizedLink(url: string) {
    const link = `https://www.youtube-nocookie.com/embed/${url}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getPortalImageLinks() {
    const sub = this.portalImageLinkService
      .getPortalLinksByPageName(this.pageName)
      .subscribe(res => {
        this.imageListFirst = res.filter(el => el.tag === 'first_gallery');
      });
    this.subs.add(sub);
  }
}
