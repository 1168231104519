<ng-container *ngIf="breadcrumbs.length > 1">
  <ol
    class="flex items-center content-container py-[12px] md:py-[18px] px-[16px] md:px-0 lg:py-4.5 flex-wrap gap-y-1">
    <li
      *ngFor="let breadcrumb of breadcrumbs; let i = index"
      class="text-breadcrumb-mobile md:text-smaller-main [:last-child]:no-underline underline-offset-5 min-h-[16px] md:h-6">
      <span
        [ngClass]="{
          'underline text-accent-base cursor-pointer':
            i < breadcrumbs.length - 1
        }"
        routerLink="{{ breadcrumb.url }}"
        routerLinkActive="router-link-active"
        [attr.data-breadcrumb]="breadcrumb.label"
        >{{ breadcrumb.label }}</span
      ><span
        *ngIf="i < breadcrumbs.length - 1"
        class="text-gray-dark px-[6px] md:px-2.5"
        >/</span
      >
    </li>
  </ol>
</ng-container>
