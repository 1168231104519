<section class="mt-30" *ngIf="text !== null; else loader">
  <div class="container">
    <div class="text-gray-dark text-details">
      <h2
        class="text-primary-base uppercase md:text-h2-heading text-h2-360 mb-5">
        {{ text.title }}
      </h2>
      <p
        class="md:text-main-text text-smaller-main"
        [innerHTML]="text.text"></p>
      <ng-container *ngIf="text.whatIsDementiaParagraph">
        <p class="text-main-text mt-8">{{ text.whatIsDementiaParagraph }}</p>
      </ng-container>
      <ng-container *ngIf="text.whatIsDementiaParagraphSecond">
        <p class="text-main-text mt-8">
          {{ text.whatIsDementiaParagraphSecond }}
        </p>
      </ng-container>
    </div>
  </div>
</section>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
