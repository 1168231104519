<section *ngIf="moreInfos; else loader" class="overflow-hidden">
  <div class="bg-primary-base py-30 relative">
    <div *ngFor="let main of mainText">
      <div
        class="flex content-container justify-start flex-wrap gap-5 mb-5 md:mb-0 px-4 md:px-0 text-white">
        <h2
          class="uppercase text-h2-360 md:text-h2-heading xl:w-120 w-full text-start xl:text-left">
          {{ main.mainHeader }}
        </h2>
        <p class="text-smaller-main md:text-main-text w-170" [innerHTML]=""></p>
      </div>
    </div>

    <app-adaptive-slider
      class="flex flex-col items-center mx-auto w-[328px] md:w-210 xl:w-295 overflow-hidden">
      <div
        class="flex justify-between items-center mt-10 mb-10 mx-auto md:mb-15 flex-row gap-5 relative w-[328px] md:w-210 xl:w-295">
        <ng-container *ngFor="let moreInfo of moreInfos">
          <app-more-info-card
            [moreInfo]="moreInfo"
            class="flex"></app-more-info-card>
        </ng-container>
      </div>
    </app-adaptive-slider>
  </div>
</section>
<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
