import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { IAboutDementiaThirdLevelPage } from 'src/app/models/aboutDementia.interface';
import { AboutDementiaThirdLevelPageService } from 'src/app/services/api/aboutDementiaPages/about-dementia-third-level-page.service';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbAboutDementiaThirdLevelResolver  {
  constructor(private apiService: AboutDementiaThirdLevelPageService) {}

  fetchData(id: string): Observable<IAboutDementiaThirdLevelPage> {
    return this.apiService.getData(id);
  }
  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<(any & { breadcrumb: string }) | null> {
    const pressReleaseId = route.paramMap.get('thirdId');
    if (pressReleaseId) {
      return this.fetchData(pressReleaseId).pipe(
        map(data => {
          return {
            breadcrumb: data.header,
            ...data,
          };
        }),
      );
    } else return of(null);
  }
}
