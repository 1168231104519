<app-page-banner-item
  [title]="bannerData.title"
  [text]="bannerData.text"
  [imgUrl]="bannerData.imgUrl"
  id="page-top">
  <div *ngIf="bannerData.button" class="mt-5 px-4 md:px-0">
    <a
      [href]="bannerData.button.url"
      style="width: fit-content; padding: 0.5rem 1rem"
      class="flex text-main-text font-semibold justify-center text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer">
      {{ bannerData.button.text }}
    </a>
  </div>
</app-page-banner-item>

<section class="grid mt-15 md:mt-30">
  <app-table-of-content
    class="flex mx-auto w-full px-4 md:px-0"
    [links]="cards"></app-table-of-content>
</section>

<section
  class="content-container text-start flex flex-col mb-30 px-4 md:px-0 mt-15">
  <div class="text-gray-dark text-main-text md:px-0">
    <h2 class="text-primary-base uppercase text-h1-heading">
      {{ pageData?.title }}
    </h2>
    <p>
      Протягом вересня – місяця підвищення обізнаності про деменцію – фонд
      “Незабутні” реалізував фотопроєкт “МИ Є. Люди з деменцією”. Мета цього
      проєкту — показати, що люди з деменцією в Україні дійсно є, і вони
      заслуговують на гідне та наповнене життя. Вони мають рідних та близьких,
      які намагаються створити для них комфортні умови та підтримувати їхні
      захоплення, нагадуючи собі та іншим: життя продовжується.
    </p>
    <br />
    <p>
      Назва цього проєкту символічна – через ці фото люди з деменцією показують
      нам:
    </p>
    <ol class="list-decimal list-inside pl-5">
      <li>Ми є. Нас багато: аж понад півмільйона людей в Україні.</li>
      <li>
        Ми є люди. Деменція може погіршити нашу пам’ять, але не забрати
        особистість. Ми залишаємося людьми зі своїми думками, почуттями та
        емоціями. Ми хочемо, щоб наші права не порушували, а нас – поважали.
      </li>
      <li>
        У нас є деменція. Так, нам встановили діагноз, який змінив наше життя.
        Але від цього воно не стало сірим та безбарвним. Ми маємо близьких, що
        нас підтримують і допомагають, і ми вдячні їм за це.
      </li>
    </ol>
  </div>
</section>

<section class="content-container text-start flex flex-col px-4 md:px-0 mt-15">
  <app-page-link-cards-block
    [cardBlockId]="cards[0].id"
    [cardBlockTitle]="cards[0].title"></app-page-link-cards-block>
  <h3 class="text-primary-base text-h3-subheading mt-5 mb-5">Ідея</h3>
  <p>
    Вперше фотопроєкт було реалізовано у 2021 році, коли фонд “Незабутні” тільки
    починав свою діяльність. Важливо було привернути увагу суспільства до теми
    деменції, наголосити на її актуальності та показати, що деменція в Україні
    є. І найкраще це могли зробити самі люди з деменцією. <br />
    Тоді засновниця та директорка фонду “Незабутні” Ірина Шевченко спільно з
    фотографинею Анастасією Накорнєєвою та комунікаційною агенцією Постмен
    залучили 10 людей, що мають деменцію, та проживають удома з родиною або в
    закладі стаціонарного догляду, і провели індивідуальні фотосесії та створили
    <a
      href="https://www.youtube.com/watch?v=Mgofi4aZlts"
      target="_blank"
      rel="noopener noreferrer"
      class="text-primary-base underline"
      >відеоролик</a
    >. <br />
    <!-- <iframe
      src="https://www.youtube.com/embed/Mgofi4aZlts?si=l2MlirtoAdlhicJI"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
      class="w-full h-[315px] md:h-[560px] my-5 mx-auto"></iframe> -->
    Згодом світлини були представлені на першому “Кафе “Незабутні”, що відбулося
    у вересні 2021 року. А до 2023 року роботи виставлялися в Театрі драми і
    комедії на лівому березі Дніпра до показів вистави “Батько” разом з акцією
    привернення уваги до теми деменції, яку проводили волонтери фонду.
    <br /><br />
    Нижче світлини з виставок фотопроєкту.
  </p>

  <app-adaptive-page-slider
    *ngIf="exhibitionImages?.length"
    [skipLast]="false"
    [counterOfPages]="true"
    [amountOffCardsBlock]="exhibitionImages.length"
    [hideButton]="[false, false, false]"
    class="relative w-full overflow-hidden mt-10">
    <div class="flex gap-5">
      <img
        *ngFor="let image of exhibitionImages"
        [src]="image.imageUrl"
        alt="Фото з виставки"
        class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[480px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[600px] object-cover rounded-[30px] border-2 border-gray-card" />
    </div>
  </app-adaptive-page-slider>

  <h3 class="text-primary-base text-h3-subheading mt-10 mb-5">
    Фотопроєкт у 2024 році
  </h3>
  <p>
    Через 3 роки Фонд “Незабутні” знову запросив до участі 10 людей з деменцією.
    Та на відміну від попереднього фотопроєкту, цього разу хотілося висвітлити
    історії родин. Показати також погляд доглядальників: як вони сприйняли
    деменцією своєї близької людини, як дають раду викликам і як шукають ресурси
    для підтримки людини з деменцією та самих себе. Адже деменція — це синдром,
    що змінює життя не однієї людини, а і її близьких, це шлях — який простіше
    йти в любові, турботі та підтримці. <br /><br />
    Авторкою фото знову стала Анастасія Накорнєєва, що спільно з фахівчинями та
    волонтерками фонду відвідала 10 родин у Києві для проведення домашніх
    фотосесій. Фонд “Незабутні” залучив також письменницю Олесю Богдан, яка
    проводила інтерв’ю та писала історії родин людей з деменцією.
    <br /><br />
    Мета нової хвилі фотопроєкту “МИ Є. Люди з деменцією” — показати, що кожна
    людина з деменцією та її родина переживає унікальний досвід, сміливо долаючи
    виклики, сповнюючи життя новими захопленнями та ламаючи стереотипи, що зі
    встановленням синдрому активне життя закінчується.
  </p>
</section>

<section
  class="content-container text-start flex flex-col px-4 md:px-0 mt-15 md:mt-30 mb-15 md:mb-30">
  <app-page-link-cards-block
    [cardBlockId]="cards[1].id"
    [cardBlockTitle]="cards[1].title"></app-page-link-cards-block>

  <p class="mt-5">
    Учасниками стали як люди, які проживають удома зі своїми близькими, так і
    люди, які перебувають у геріатричних пансіонатах та отримують професійний
    догляд. Фонд “Незабутні” прагнув показати в цьому фотопроєкті не тільки
    людей з деменцією, а також родинний затишок та любов, які панують в їхніх
    сім'ях. Саме тому фотографиня Анастасія Накорнєєва робила також фотографії
    доглядальників людей з деменцією – найрідніших людей, які турбуються про
    їхній стан, підтримують їх фізично й морально. <br />
    <br />Фонд “Незабутні” залучив також письменницю Олесю Богдан, яка була
    відповідальна за проведення інтерв’ю та формування історій людей з
    деменцією. Мета цього фотопроєкту та основна його відмінність від минулого –
    показати, що кожен досвід людини з деменцією є унікальним та неповторним, і
    фотографії та історії людей з деменцією відображають їхні інтереси та
    захоплення, ламають стереотипи, що зі встановленням синдрому активне життя
    закінчується. Ви можете ознайомитися з результатами нашого фотопроєкту
    нижче.
  </p>

  <section *ngFor="let item of photoProjectData; let i = index" class="py-10">
    <div class="text-start">
      <div
        class="flex flex-col md:flex-row gap-10"
        [ngClass]="{ 'md:flex-row-reverse': i % 2 === 1 }">
        <!-- Текстовий блок -->
        <div class="flex-1 md:w-1/2">
          <h3 class="text-primary-base text-h3-subheading mb-5">
            {{ item.title }}
          </h3>
          <p class="text-main-text">
            {{ item.description }}
          </p>
        </div>
        <!-- Слайдер -->
        <app-adaptive-page-slider
          *ngIf="item.imageList.length"
          [skipLast]="false"
          [counterOfPages]="true"
          [amountOffCardsBlock]="item.imageList.length"
          [hideButton]="[false, false, false]"
          class="relative w-full md:w-[410px] xl:w-[580px] overflow-hidden md:float-right">
          <div class="flex gap-5">
            <img
              *ngFor="let image of item.imageList"
              [src]="image.imageUrl"
              [alt]="item.title"
              class="shrink-0 w-[280px] sm:w-[410px] md:w-[340px] xl:w-[480px] h-[350px] sm:h-[500px] md:h-[424px] xl:h-[600px] object-cover rounded-[30px] border-2 border-gray-card" />
          </div>
        </app-adaptive-page-slider>
      </div>
    </div>
  </section>
</section>

<section class="bg-gray-card py-15 md:py-30 px-4 md:px-15 xl:px-0">
  <div class="content-container flex flex-col gap-10">
    <app-page-link-cards-block
      [cardBlockId]="cards[2].id"
      [cardBlockTitle]="cards[2].title"></app-page-link-cards-block>
    <app-adaptive-page-slider
      *ngIf="clientReviews.length"
      [skipLast]="false"
      [counterOfPages]="true"
      [amountOffCardsBlock]="clientReviews.length"
      [hideButton]="[false, false, false]"
      class="relative w-full overflow-hidden">
      <div class="flex gap-5">
        <div
          *ngFor="let review of clientReviews"
          class="review-card p-5 shadow-md border rounded-lg bg-white w-[300px]">
          <div
            class="w-16 h-16 rounded-full mx-auto mb-5 bg-primary-base flex items-center justify-center overflow-hidden">
            <img
              *ngIf="review.image"
              [src]="review.image"
              alt="{{ review.name }}"
              class="w-full h-full object-cover" />

            <ng-container *ngIf="!review.image">
              <span class="text-lg font-bold text-white">
                {{ review.name ? review.name[0] : '?' }}
              </span>
            </ng-container>
          </div>
          <h4 class="text-primary-base text-h4-heading text-center mb-3">
            {{ review.name }}
          </h4>
          <p class="text-main-text text-center">{{ review.feedback }}</p>
        </div>
      </div>
    </app-adaptive-page-slider>
  </div>
</section>

<section
  class="content-container text-start flex flex-col px-4 md:px-0 py-15 md:py-30">
  <app-page-link-cards-block
    [cardBlockId]="cards[3].id"
    [cardBlockTitle]="cards[3].title"></app-page-link-cards-block>
  <div
    class="grid grid-cols-[380px] md:grid-cols-[380px_380px] xl:grid-cols-[380px_380px_380px] place-content-center gap-5 mt-10">
    <app-fund-member-card-item
      *ngFor="let participant of projectParticipants"
      [text]="participant.position"
      [title]="participant.name"
      [imgUrl]="participant.image"
      [description]="participant.description"
      [useModal]="true">
    </app-fund-member-card-item>
  </div>
  <p class="mt-10">
    До втілення фотопроєкту в 2024 році долучалися також проєктні менеджерки
    фонду Діана Дегтарьова, Анна Григор’єва, Ірина Зборовська, що залучили
    учасників. Координаторка Ольга Бондарєва та волонтерка Катерина Шкварська
    допомогли спланувати та здійснити візити до родин. Над сторінкою про
    фотопроєкт працювала координаторка Анна Мязіна та волонтерка фонду Олена
    Васильчук.
  </p>
</section>

<section class="bg-primary-base">
  <div class="content-container">
    <app-help-block
      [type]="'primary'"
      [customTitle]="'Підтримати фонд'"
      [showText]="false"
      [showButton]="false"></app-help-block>
  </div>
</section>
