import { ROUTE_PART_NAMES } from 'src/app/constants/route_part_names';

export interface PageRoutes {
  main: string;
  aboutDementia: string;
  faq: string;
  photoProject: string;
  dementiaTypes: string;
  prophylaxis: string;
  diagnosis: string;
  whatDementiaIs: string;
  getSupport: string;
  helpWithMedicine: string;
  psychologicalSupport: string;
  legalAssistance: string;
  medicalAssistance: string;
  careConsultation: string;
  informationalAssistance: string;
  crisisAssistance: string;
  helpWithGenerators: string;
  helpAbroad: string;
  helpWithEvacuation: string;
  whatToDoIf: string;
  iWorryAboutMemory: string;
  IHaveDementia: string;
  relativeHasDementia: string;
  join: string;
  donate: string;
  becomeVolunteer: string;
  becomePartner: string;
  organizeFundraising: string;
  aboutUs: string;
  financialStatements: string;
  reports: string;
  reportsYearly: string;
  foundingDocuments: string;
  ourIdea: string;
  reportsMonthly: string;
  contacts: string;
  ourPeople: string;
  fundTeam: string;
  ourPartners: string;
  vacancies: string;
  ourPrograms: string;
  helpDuringTheWar: string;
  advocacy: string;
  friendlySociety: string;
  study: string;
  research: string;
  newsAndMedia: string;
  news: string;
  mediaAboutUs: string;
  statistic: string;
  mediaCenter: string;
  pressReleases: string;
  howToTalkAboutDementiaInTheMedia: string;
  keyFactsAboutDementia: string;
  glossary: string;
  activities: string;
  pageInDevelopment: string;
  unsubscribePage: string;
}
export const PAGE_ROUTES: PageRoutes = {
  main: '/',
  aboutDementia: `/${ROUTE_PART_NAMES.aboutDementia}`,
  faq: `/${ROUTE_PART_NAMES.aboutDementia}/${ROUTE_PART_NAMES.faq}`,
  photoProject: `/${ROUTE_PART_NAMES.aboutDementia}/${ROUTE_PART_NAMES.photoProject}`,
  dementiaTypes: `/${ROUTE_PART_NAMES.aboutDementia}/${ROUTE_PART_NAMES.dementiaTypes}`,
  prophylaxis: `/${ROUTE_PART_NAMES.aboutDementia}/${ROUTE_PART_NAMES.prophylaxis}`,
  diagnosis: `/${ROUTE_PART_NAMES.aboutDementia}/${ROUTE_PART_NAMES.diagnosis}`,
  whatDementiaIs: `/${ROUTE_PART_NAMES.aboutDementia}/${ROUTE_PART_NAMES.whatDementiaIs}`,
  getSupport: `/${ROUTE_PART_NAMES.getSupport}`,
  helpWithMedicine: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.helpWithMedicine}`,
  psychologicalSupport: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.psychologicalSupport}`,
  legalAssistance: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.legalAssistance}`,
  medicalAssistance: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.medicalAssistance}`,
  careConsultation: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.careConsultation}`,
  informationalAssistance: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.informationalAssistance}`,
  crisisAssistance: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.crisisAssistance}`,
  helpWithGenerators: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.crisisAssistance}/${ROUTE_PART_NAMES.helpWithGenerators}`,
  helpAbroad: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.crisisAssistance}/${ROUTE_PART_NAMES.helpAbroad}`,
  helpWithEvacuation: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.crisisAssistance}/${ROUTE_PART_NAMES.helpWithEvacuation}`,
  whatToDoIf: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.whatToDoIf}`,
  iWorryAboutMemory: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.whatToDoIf}/${ROUTE_PART_NAMES.iWorryAboutMemory}`,
  IHaveDementia: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.whatToDoIf}/${ROUTE_PART_NAMES.IHaveDementia}`,
  relativeHasDementia: `/${ROUTE_PART_NAMES.getSupport}/${ROUTE_PART_NAMES.whatToDoIf}/${ROUTE_PART_NAMES.relativeHasDementia}`,
  join: `/${ROUTE_PART_NAMES.join}`,
  donate: `/${ROUTE_PART_NAMES.join}/${ROUTE_PART_NAMES.donate}`,
  becomeVolunteer: `/${ROUTE_PART_NAMES.join}/${ROUTE_PART_NAMES.becomeVolunteer}`,
  becomePartner: `/${ROUTE_PART_NAMES.join}/${ROUTE_PART_NAMES.becomePartner}`,
  organizeFundraising: `/${ROUTE_PART_NAMES.join}/${ROUTE_PART_NAMES.organizeFundraising}`,
  aboutUs: `/${ROUTE_PART_NAMES.aboutUs}`,
  financialStatements: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.reports}/${ROUTE_PART_NAMES.financialStatements}`,
  reports: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.reports}`,
  reportsYearly: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.reports}/${ROUTE_PART_NAMES.reportsYearly}`,
  foundingDocuments: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.reports}/${ROUTE_PART_NAMES.foundingDocuments}`,
  ourIdea: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourIdea}`,
  reportsMonthly: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.reports}/${ROUTE_PART_NAMES.reportsMonthly}`,
  contacts: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.contacts}`,
  ourPeople: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourPeople}`,
  fundTeam: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourPeople}/${ROUTE_PART_NAMES.fundTeam}`,
  ourPartners: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourPeople}/${ROUTE_PART_NAMES.ourPartners}`,
  vacancies: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourPeople}/${ROUTE_PART_NAMES.vacancies}`,
  ourPrograms: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourPrograms}`,
  helpDuringTheWar: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourPrograms}/${ROUTE_PART_NAMES.helpDuringTheWar}`,
  advocacy: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourPrograms}/${ROUTE_PART_NAMES.advocacy}`,
  friendlySociety: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourPrograms}/${ROUTE_PART_NAMES.friendlySociety}`,
  study: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourPrograms}/${ROUTE_PART_NAMES.study}`,
  research: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.ourPrograms}/${ROUTE_PART_NAMES.research}`,
  newsAndMedia: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.newsAndMedia}`,
  news: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.newsAndMedia}/${ROUTE_PART_NAMES.news}`,
  mediaAboutUs: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.newsAndMedia}/${ROUTE_PART_NAMES.mediaAboutUs}`,
  statistic: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.newsAndMedia}/${ROUTE_PART_NAMES.statistic}`,
  mediaCenter: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.newsAndMedia}/${ROUTE_PART_NAMES.mediaCenter}`,
  pressReleases: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.newsAndMedia}/${ROUTE_PART_NAMES.mediaCenter}/${ROUTE_PART_NAMES.pressReleases}`,
  howToTalkAboutDementiaInTheMedia: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.newsAndMedia}/${ROUTE_PART_NAMES.mediaCenter}/${ROUTE_PART_NAMES.howToTalkAboutDementiaInTheMedia}`,
  keyFactsAboutDementia: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.newsAndMedia}/${ROUTE_PART_NAMES.mediaCenter}/${ROUTE_PART_NAMES.keyFactsAboutDementia}`,
  glossary: `/${ROUTE_PART_NAMES.aboutUs}/${ROUTE_PART_NAMES.newsAndMedia}/${ROUTE_PART_NAMES.mediaCenter}/${ROUTE_PART_NAMES.glossary}`,
  activities: `/${ROUTE_PART_NAMES.activities}`,
  pageInDevelopment: `/${ROUTE_PART_NAMES.pageInDevelopment}`,
  unsubscribePage: `/${ROUTE_PART_NAMES.unsubscribePage}`,
};
