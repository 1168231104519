import { Component, Input, OnInit } from '@angular/core';
import { IPreventionCard } from '../../models/prevention-card.interface';
import { TextContentModel } from '../../services/api/text-content.service';

export interface cardsIcons extends TextContentModel {
  icon: string;
  imageLink: string;
  text: string;
}
@Component({
  selector: 'app-prevention-block',
  templateUrl: './prevention-block.component.html',
  styleUrls: ['./prevention-block.component.css'],
})
export class PreventionBlockComponent implements OnInit {
  @Input() preventionTextBlock!: TextContentModel;
  @Input() preventionCardsBlock!: TextContentModel[];
  protected preventionCards!: cardsIcons[];

  constructor() {}

  ngOnInit(): void {
    // @ts-ignore
    this.preventionCards = this.preventionCardsBlock.map(
      (el: TextContentModel, i: number) => {
        return {
          ...el,
          text: el.title,
          icon: cardsConfig[i].icon,
          imageURL: cardsConfig[i].imageLink,
        };
      },
    );
  }
}

export const cardsConfig = [
  {
    icon: 'ic-sport',
    imageLink: '../../../assets/images/prevention_block/dumbbells.png',
    text: 'Фізична активність',
  },
  {
    icon: 'ic-food',
    imageLink: '../../../assets/images/prevention_block/healthy_eating.png',
    text: 'Здорове харчування',
  },
  {
    icon: 'ic-brain',
    imageLink: '../../../assets/images/prevention_block/brain_training.png',
    text: 'Тренування мозку',
  },
  {
    icon: 'ic-stethoscope',
    imageLink: '../../../assets/images/prevention_block/stethoscope.png',
    text: 'Регулярні обстеження',
  },
];
