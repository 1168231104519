<div class="flex w-full md:w-295 items-center justify-between">
  <a
    *ngIf="headerButtonVisible; else elseBlock"
    href="/"
    class="h-10 md:h-13 w-12 md:w-52.5 text-h2-heading overflow-hidden md:flex-grow">
    <img
      class="h-10 md:h-13 w-12 md:w-52.5 object-cover object-left"
      src="assets/images/svg-images/logo.svg"
      alt="Logo nezabutni" />
  </a>
  <ng-template #elseBlock>
    <a href="/" class="mr-6 h-19.75 w-80.5 text-h2-heading mb-33">
      <img
        width="322"
        height="80"
        src="assets/images/not-found/header_logo_notFound.png"
        alt="Logo nezabutni" />
    </a>
  </ng-template>

  <div
    class="flex gap-5 items-center md:flex-shrink-0 pt-1"
    *ngIf="headerButtonVisible">
    <a
      *ngIf="!(isMobile$ | async) && !(isTablet$ | async)"
      (mouseenter)="isHovered = true"
      (mouseleave)="isHovered = false"
      [ngClass]="{
        'text-secondary-base border-secondary-base': isHovered,
        'text-accent-base border-accent-base': !isHovered
      }"
      [routerLink]="contactLink"
      class="md:flex justify-center w-55 hidden py-2 border-2 bg-transparent text-accent-base hover:text-secondary-base active:text-secondary-additional disabled:text-gray-gray2 rounded-10 border-accent-base duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer">
      <div class="mr-1">
        <svg
          width="30px"
          height="30px"
          icon="phone"
          iconCollection="button-icons-sprite"
          [ngClass]="{
            'fill-secondary-base': isHovered,
            'fill-accent-base': !isHovered
          }"
          class="w-6 h-6 md:w-7 md:h-7"></svg>
      </div>
      <div
        class="text-main-text font-semibold flex flex-row justify-center mr-2">
        0 800 30 88 30
      </div>
    </a>
    <app-link-button
      *ngIf="!(isMobile$ | async) && !(isTablet$ | async)"
      [url]="donateLink"
      [linkButtonType]="'Donate'"
      [title]="'Допомогти'"
      class="w-50 h-10 md:h-12 md:w-55">
    </app-link-button>

    <a
      (mouseenter)="isHovered = true"
      (mouseleave)="isHovered = false"
      [ngClass]="{
        'text-secondary-base border-secondary-base': isHovered,
        'text-accent-base border-accent-base': !isHovered
      }"
      href="tel:0800308830"
      class="flex justify-center w-50 md:hidden h-10 items-center border-2 bg-transparent text-accent-base hover:text-secondary-base active:text-secondary-additional disabled:text-gray-gray2 rounded-10 border-accent-base duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer">
      <div class="mt-1 mr-1">
        <svg
          width="30px"
          height="30px"
          icon="phone"
          iconCollection="button-icons-sprite"
          [ngClass]="{
            'fill-secondary-base': isHovered,
            'fill-accent-base': !isHovered
          }"
          class="w-6 h-6 md:w-7 md:h-7"></svg>
      </div>
      <div
        class="text-main-text font-semibold flex flex-row justify-center mr-2">
        0 800 30 88 30
      </div>
    </a>
  </div>
  <app-mobile-main-menu
    class="md:ml-5 md:flex-shrink-0"
    *ngIf="
      (isMobile$ | async) || (isTablet$ | async) || (isLaptop$ | async)
    "></app-mobile-main-menu>
</div>
