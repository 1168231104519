<div
  class="wrapper-item w-82 md:w-210 xl:w-145 h-176 md:h-182.5 xl:h-173 rounded-30 p-5 md:p-10 odd:mr-4 border-[3px] border-gray-stroke flex flex-col justify-between"
  #cardWidth>
  <div class="mb-10">
    <h4 class="text-primary-base text-h2-360 md:text-h2-heading">
      {{ currencyName | uppercase }}
    </h4>
    <div
      class="flex flex-col md:flex-row w-82 md:w-187.5 xl:w-125 py-2.5 md:py-5 xl:py-2.5 items-start md:items-center border-b-2 border-gray-stroke last:border-b-0"
      *ngFor="let el of textFieldsArray">
      <div
        class="w-30 mb-2.5 md:mb-0 md:mr-7.5 text-left text-details-360 md:text-details text-gray-details"
        *ngIf="el.title">
        {{ el.title }}
      </div>

      <div class="flex w-full md:justify-between">
        <div
          class="w-62 md:w-80.5 md:pl-3 text-gray-dark text-left text-smaller-main md:text-main-text"
          *ngIf="el.bodyText"
          [ngClass]="{ 'text-details': el.title === 'IBAN' }">
          {{ el.bodyText }}
        </div>
        <app-copy-button
          [showText]="false"
          [textValue]="el.bodyText"></app-copy-button>
      </div>
    </div>
  </div>
  <a [href]="pdfFile?.imageURL" target="_blank">
    <app-button
      [title]="'Завантажити реквізити'"
      [buttonType]="'Secondary'"
      [svg]="true"
      class="md:w-92.5 xl:w-124">
      <svg
        width="24px"
        height="24px"
        icon="download"
        iconCollection="button-icons-sprite"
        class="svg fill-accent-base hover:fill-secondary-additional active:fill-secondary-additional"></svg>
    </app-button>
  </a>
</div>
