import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MonthData,
  MonthlyCardTextData,
} from 'src/app/models/monthly-reports.interfaces';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { ReportsMonthlyService } from 'src/app/services/api/aboutUsPages/AboutFondPages/ReportsAndDocsPages/ReportsMonthlyPage/reports-monthly.service';
import { Observable, Subscription } from 'rxjs';
import {
  PortalTextModel,
  PortalTextsService,
} from '../../../../../services/api/portal-texts-by-tag.service';

@Component({
  selector: 'app-monthly-reports',
  templateUrl: './monthly-reports.component.html',
  styleUrls: ['./monthly-reports.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlyReportsComponent implements OnInit, OnDestroy {
  subs: Subscription = new Subscription();
  cardMonthView!: MonthData;
  monthlyData$!: Observable<MonthlyCardTextData>;
  dataArray: MonthData[] = [];
  monthArray!: MonthData[];
  reportLink: string = PAGE_ROUTES.reports;
  donateLink: string = PAGE_ROUTES.donate;
  defaultYear!: number;
  defaultMonth!: number;
  yearsArray: number[] = [];
  isLoading: boolean = true;

  constructor(
    private monthlyService: ReportsMonthlyService,
    private cdr: ChangeDetectorRef,
    private portalText: PortalTextsService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const sub = this.monthlyService.getMonthlyReportsArray().subscribe(el => {
      this.dataArray = el;
      if (this.dataArray.length) {
        this.yearsArray = this.getAllYearsData(this.dataArray);
      }
      this.isLoading = false;
      this.cdr.markForCheck();
    });
    this.monthlyData$ = this.portalText.getPortalTextByTag('monthly-reports');
    this.subs.add(sub);
  }

  public getAllYearsData(array: any[]): number[] {
    const yearArray = [
      ...new Set(array.map(data => new Date(data.date!).getFullYear())),
    ].sort((a, b) => b - a);
    this.defaultYear = yearArray[0];
    this.monthArray = this.monthArrayPrepared(this.defaultYear);
    this.defaultMonth = new Date(this.monthArray[0].date).getMonth();
    this.setCurrentData(this.defaultYear, this.monthArray[0].month);
    return yearArray;
  }

  public chosedYear(event: Event): void {
    const value = event.target as HTMLInputElement;
    this.monthArray = this.monthArrayPrepared(+value.value);
  }

  public choseMonthIndex(event: Event): number {
    const value = event.target as HTMLInputElement;
    return +value.value;
  }

  public setCurrentData(year: number, month: string): void {
    const res = this.dataArray.findIndex(
      el => el.month === month && new Date(el.date).getFullYear() === year,
    );
    this.cardMonthView = this.dataArray[res];
  }

  public monthArrayPrepared(year: number): MonthData[] {
    return this.dataArray
      .filter(el => new Date(el.date).getFullYear() === year)
      .sort(
        (a, b) => new Date(b.date).getMonth() - new Date(a.date).getMonth(),
      );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
