<ng-container>
  <a
    *ngIf="linkButtonType === 'Primary'"
    [ngClass]="{ 'bg-secondary-base': isHovered, 'bg-accent-base': !isHovered }"
    [routerLink]="url"
    [type]="type"
    appScrollToSectionAfterLoadData
    scrollToSection="{{ titleLinkId }}"
    class="flex justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer">
    <div class="text-main-text font-semibold">{{ title }}</div>
  </a>

  <a
    *ngIf="linkButtonType === 'PrimaryLink'"
    [ngClass]="{ 'bg-secondary-base': isHovered, 'bg-accent-base': !isHovered }"
    [href]="url"
    [type]="type"
    appScrollToSectionAfterLoadData
    scrollToSection="{{ titleLinkId }}"
    class="flex justify-center w-full text-gray-white rounded-10 bg-accent-base duration-300 ease-in-out active:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-gray2 py-2.5 bg-accent cursor-pointer">
    <div class="text-main-text font-semibold">{{ title }}</div>
  </a>

  <a
    *ngIf="linkButtonType === 'RightArrow'"
    appScrollToSectionAfterLoadData
    scrollToSection="{{ titleLinkId }}"
    [ngClass]="{
      'text-secondary-base': isHovered,
      'text-accent-base': !isHovered
    }"
    [routerLink]="url"
    [type]="type"
    class="group/arrow flex justify-center w-max text-accent-base active:text-secondary-additional hover:text-secondary-base disabled:text-gray-gray2 py-2.5 bg-accent cursor-pointer">
    <div
      class="text-smaller-bold flex items-center gap-3 pr-2 duration-300 group-hover/arrow:gap-5 group-hover/arrow:text-secondary-base group-hover/arrow:pr-0">
      {{ title }}
      <svg
        [ngClass]="{
          'fill-secondary-additional': isHovered,
          'fill-accent-base': !isHovered
        }"
        class="inline-block fill-accent-base duration-300 group-hover/arrow:fill-secondary-additional group-active/arrow:fill-secondary-additional"
        height="16px"
        icon="arrow-right-medium"
        iconCollection="button-icons-sprite"
        width="28px"></svg>
    </div>
  </a>

  <a
    *ngIf="linkButtonType === 'RightArrowBig'"
    [ngClass]="{
      'text-secondary-base': isHovered,
      'text-accent-base': !isHovered
    }"
    [type]="'button'"
    class="group/arrow flex justify-center w-max text-accent-base active:text-secondary-additional hover:text-secondary-base disabled:text-gray-gray2 py-2.5 bg-accent cursor-pointer">
    <div
      class="text-main-text-bold flex items-center gap-3 pr-2 duration-300 group-hover/arrow:gap-5 group-hover/arrow:text-secondary-base group-hover/arrow:pr-0">
      {{ title }}
      <svg
        [ngClass]="{
          'fill-secondary-additional': isHovered,
          'fill-accent-base': !isHovered
        }"
        class="inline-block fill-accent-base duration-300 group-hover/arrow:fill-secondary-additional group-active/arrow:fill-secondary-additional"
        height="16px"
        icon="arrow-right-medium"
        iconCollection="button-icons-sprite"
        width="28px"></svg>
    </div>
  </a>

  <a
    *ngIf="linkButtonType === 'LeftArrow'"
    [ngClass]="{
      'text-secondary-base': isHovered,
      'text-accent-base': !isHovered
    }"
    [routerLink]="url"
    [type]="type"
    class="group/arrow flex justify-center w-max text-accent-base active:text-secondary-additional hover:text-secondary-base disabled:text-gray-gray2 py-2.5 bg-accent cursor-pointer">
    <div
      class="text-smaller-bold flex items-center gap-3 pl-2 duration-300 group-hover/arrow:gap-5 group-hover/arrow:text-secondary-base group-hover/arrow:pl-0">
      <svg
        [ngClass]="{
          'fill-secondary-additional': isHovered,
          'fill-accent-base': !isHovered
        }"
        class="inline-block fill-accent-base duration-300 group-hover/arrow:fill-secondary-additional group-active/arrow:fill-secondary-additional rotate-180"
        height="16px"
        icon="arrow-right-medium"
        iconCollection="button-icons-sprite"
        width="28px"></svg>
      {{ title }}
    </div>
  </a>

  <a
    *ngIf="linkButtonType === 'Donate'"
    [routerLink]="url"
    [type]="type"
    appScrollToSectionAfterLoadData
    scrollToSection="{{ titleLinkId }}"
    class="group/donate relative flex w-full h-10 md:h-12 overflow-hidden text-white bg-no-repeat fill-accent-additional duration-300 ease-in-out hover:animate-none hover:bg-secondary-base active:bg-secondary-additional rounded-10 py-1.5 md:py-2.5 bg-accent-base cursor-pointer">
    <app-icon-flower
      class="absolute -top-1 md:top-0 left-2.5 w-17 h-12 fill-inherit animate-flower-pulse hover:animate-none focus:animate-none group-hover/donate:fill-gray-white group-hover/donate:opacity-30 group-hover/donate:animate-none group-active/donate:fill-gray-white group-active/donate:opacity-30 group-active/donate:animate-none"
      id="animate-area24"></app-icon-flower>
    <div class="z-10 mx-auto text-main-text font-semibold uppercase">
      {{ title }}
    </div>
  </a>
  <a
    *ngIf="linkButtonType === 'Secondary'"
    [ngClass]="{
      'text-secondary-base border-secondary-base': isHovered,
      'text-accent-base border-accent-base': !isHovered
    }"
    [routerLink]="url"
    [type]="type"
    appScrollToSectionAfterLoadData
    class="flex justify-center w-full py-2 border-2 bg-transparent text-accent-base hover:text-secondary-base active:text-secondary-additional disabled:text-gray-gray2 rounded-10 border-accent-base duration-300 ease-in-out hover:border-secondary-base active:border-secondary-additional disabled:border-gray-gray2 cursor-pointer"
    scrollToSection="{{ titleLinkId }}">
    <div class="text-main-text font-semibold flex flex-row justify-center">
      <ng-content *ngIf="svg" select=".svg"></ng-content>
      <div>{{ title }}</div>
    </div>
  </a>
</ng-container>
