<section
  class="content-container text-start px-4 md:px-0"
  *ngFor="let detailedNew of detailedNewsPressRealize">
  <div class="lg:flex justify-between">
    <h3 class="text-h2-heading text-primary-base mb-5 uppercase">
      {{ detailedNew.title }}
    </h3>
    <div class="flex text-gray-details text-main-text mb-5">
      <svg
        width="22px"
        height="20px"
        icon="book"
        iconCollection="button-icons-sprite"
        class="svg items-start fill-gray-details"></svg>
      <span class="ml-2 justify-end shrink-0">{{
        detailedNew.date | date: 'dd.MM.yyyy'
      }}</span>
    </div>
  </div>

  <p
    [innerHTML]="detailedNew.text"
    class="mb-5 text-lg text-gray-dark text-main-text"></p>

  <div class="md:flex lg:justify-center mt-15 mb-25">
    <div class="md:w-70 w-82 md:mr-5 mb-5">
      <a [href]="detailedNew.documentUrl" download>
        <app-button
          [title]="'Завантажити PDF'"
          [buttonType]="'Primary'"
          [svg]="true">
          <svg
            width="25px"
            height="20px"
            icon="download"
            iconCollection="button-icons-sprite"
            class="svg fill-gray-white mt-1 mr-2"></svg>
        </app-button>
      </a>
    </div>
    <app-button
      class="md:w-70 w-82"
      type="button"
      [buttonType]="'Secondary'"
      [routerLink]="pressReleasesLink"
      [title]="'Більше пресрелізів'"></app-button>
  </div>

  <div class="flex items-stretch mb-30">
    <div
      class="bg-primary-base w-[10px] rounded rounded-tl-[10px] rounded-bl-[10px] mr-5 shrink-0"></div>
    <div class="my-5">
      <p class="text-h3-subheading">
        Контакти медіацентру:
        <span
          class="text-accent-base underline cursor-pointer"
          (click)="copyMail()"
          >media&#64;nezabutni.org</span
        >
      </p>
      <p class="text-h3-subheading">
        Говорячи чи пишучи про деменцію, звертайтеся до
        <a [href]="glossaryLink" class="text-accent-base underline"
          >"Глосарію рекомендованих термінів"</a
        >
      </p>
    </div>
  </div>
</section>
