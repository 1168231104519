<app-page-banner-item
  [text]="pageBannerData.text"
  [title]="pageBannerData.title"
  [imgUrl]="pageBannerData.imgUrl"></app-page-banner-item>

<div class="px-4 xl:px-0" *ngIf="links.length; else loader">
  <app-table-of-content
    class="flex mx-auto w-full mt-10"
    [links]="links"></app-table-of-content>
</div>

<section
  class="content-container px-4 md:px-[30px]] xl:px-0 pt-30 text-gray-dark">
  <ng-container *ngIf="portalTextsData.length; else loader">
    <h2
      id="{{ !links.length ? '' : portalTextsData[0].id }}"
      class="text-h2-heading text-primary-base uppercase"
      [innerText]="portalTextsData[0].title"></h2>
    <p class="mt-5 text-main-text" [innerHTML]="portalTextsData[0].text"></p>
  </ng-container>
</section>

<section class="pt-15">
  <div class="pt-30" *ngIf="loading; else fundMembersBlock">
    <div
      class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full gradient-animation"></div>
      </div>
    </div>
  </div>
  <ng-template #fundMembersBlock>
    <div
      *ngIf="fundMembers"
      class="grid grid-cols-[380px] md:grid-cols-[380px_380px] xl:grid-cols-[380px_380px_380px] place-content-center gap-5">
      <app-fund-member-card-item
        *ngFor="let member of fundMembers"
        text="{{ member.position }}"
        title="{{ member.title }}"
        [imgUrl]="member.img"
        [url]="
          fundTeamLink + '/' + member.memberID
        "></app-fund-member-card-item>
    </div>
  </ng-template>
</section>

<section
  class="content-container px-4 md:px-[30px]] xl:px-0 pt-30 text-gray-dark">
  <ng-container *ngIf="portalTextsData.length; else loader">
    <h2
      id="{{ portalTextsData[2].id }}"
      class="text-h2-heading text-primary-base uppercase"
      [innerText]="portalTextsData[2].title"></h2>
    <p class="mt-5 text-main-text" [innerHTML]="portalTextsData[2].text"></p>
  </ng-container>
</section>

<section class="pt-15">
  <div class="pt-30" *ngIf="loading; else fundWatchersBlock">
    <div
      class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full gradient-animation"></div>
      </div>
    </div>
  </div>
  <ng-template #fundWatchersBlock>
    <div
      class="grid grid-cols-[380px] md:grid-cols-[380px_380px] xl:grid-cols-[380px_380px_380px] place-content-center gap-5 mb-15">
      <app-fund-member-card-item
        *ngFor="let member of fundWatchers"
        text="{{ member.position }}"
        title="{{ member.title }}"
        [imgUrl]="member.img"
        [url]="
          fundTeamLink + '/' + member.memberID
        "></app-fund-member-card-item>
    </div>
  </ng-template>
</section>

<section
  id="volonteersBlock"
  class="content-container px-4 md:px-[30px]] xl:px-0 pt-15 text-gray-dark">
  <ng-container *ngIf="portalTextsData.length; else loader">
    <h2
      id="{{ !links.length ? '' : portalTextsData[1].id }}"
      class="text-h2-heading text-primary-base uppercase"
      [innerText]="portalTextsData[1].title"></h2>
    <p class="mt-5 text-main-text" [innerHTML]="portalTextsData[1].text"></p>
  </ng-container>
</section>
<section class="pt-15">
  <div
    *ngIf="showedVolunteers.length; else loader"
    class="grid grid-cols-[380px] md:grid-cols-[380px_380px] xl:grid-cols-[380px_380px_380px] place-content-center gap-5">
    <app-volunteer-card-item
      *ngFor="let member of showedVolunteers"
      text="{{ member.text }}"
      title="{{ member.title }}"
      [imgUrl]="member.imgUrl"></app-volunteer-card-item>
  </div>
</section>
<section
  class="content-container pt-15 pb-30 text-gray-dark flex gap-5 justify-center">
  <app-button
    *ngIf="isSeeMoreButtonVisible"
    title="Дивитись більше"
    buttonType="Secondary"
    class="w-55"
    (click)="seeMore()"></app-button>
</section>
<section class="bg-primary-base py-30">
  <div class="content-container">
    <div class="mt-15">
      <app-info-cards [cards]="keyDementiaCards"></app-info-cards>
    </div>
  </div>
</section>

<ng-template #loader>
  <div class="pt-30">
    <div
      class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full gradient-animation"></div>
      </div>
    </div>
  </div>
</ng-template>
