import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salivon-oleksandr',
  templateUrl: './salivon-oleksandr.component.html',
  styleUrls: ['./salivon-oleksandr.component.css'],
})
export class SalivonOleksandrComponent implements OnInit {
  links = [
    { id: '#detention', title: 'Про деменцію' },
    { id: '#who', title: 'Хто хворіє на деменцію?' },
    { id: '#diagnose', title: 'Діагностика деменції' },
    { id: '#therapy', title: 'Терапія деменції' },
  ];
  links2 = [
    { id: '#dementia', title: 'Про деменцію' },
    { id: '#who', title: 'Хто хворіє на деменцію?' },
    { id: '#diagnose', title: 'Діагностика деменції' },
    { id: '#therapy', title: 'Терапія деменції' },
    { id: '#therapy', title: 'Терапія деменції' },
    { id: '#therapy', title: 'Терапія деменції' },
    { id: '#therapy', title: 'Терапія деменції' },
    { id: '#therapy', title: 'Терапія деменції' },
  ];
  constructor() {}

  ngOnInit(): void {}
}
