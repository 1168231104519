import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import IGlossary from 'src/app/models/glossary.interface';
import { glossaryMockData } from 'src/mockData/glossaryMockData';
import {
  TextContentModel,
  TextContentService,
} from '../../../../../services/api/text-content.service';
import {
  DocsReportModel,
  ReportsDocsService,
} from '../../../../../services/api/aboutUsPages/AboutFondPages/ReportsAndDocsPages/reports-docs.service';
import { Subscription } from 'rxjs';
import { OpenPdf } from '../../../../../services/pdfOpen.service';
import {
  portalImageLinkModel,
  PortalLinksService,
} from '../../../../../services/api/portal-links.service';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css'],
})
export class GlossaryComponent implements OnInit, OnDestroy {
  pageName: string =
    'about-us/news-and-media/media-centre/glossary-of-recommended-terms';
  combinedData: {
    first_block: TextContentModel;
    second_block: TextContentModel;
    [key: string]: TextContentModel;
  } | null = null;
  pageDocsData: DocsReportModel[] | null = null;
  pageData: TextContentModel[] = [];
  subs: Subscription = new Subscription();
  imageListFirst: portalImageLinkModel[] = [];

  @ViewChild('carousel', { static: true })
  carousel!: ElementRef;
  items!: HTMLElement[];
  currentIndex: number = 0;

  constructor(
    private openPdf: OpenPdf,
    private apiService: TextContentService,
    private getDocs: ReportsDocsService,
    private portalImageLinkService: PortalLinksService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getPageTextData();
    this.getPageDocs();
    this.getPortalImageLinks();
  }
  nextSlide(): void {
    if (this.currentIndex < this.items.length - 1) {
      this.currentIndex++;
      this.updateCarousel();
    }
  }

  prevSlide(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateCarousel();
    }
  }

  private updateCarousel(): void {
    const offset = -this.currentIndex * 100;
    this.carousel.nativeElement.querySelector(
      '.carousel-inner',
    ).style.transform = `translateX(${offset}%)`;
  }

  getPageDocs() {
    const sub = this.getDocs.getReportsByType(this.pageName).subscribe(res => {
      this.pageDocsData = res.sort((a, b) => a.orderId - b.orderId);
    });
    this.subs.add(sub);
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.combinedData = {
          first_block: this.pageData.find(el => el.key === 'first_block')!,
          second_block: this.pageData.find(el => el.key === 'second_block')!,
        };
      });
    this.subs.add(sub);
  }

  getPortalImageLinks() {
    const sub = this.portalImageLinkService
      .getPortalLinksByPageName(this.pageName)
      .subscribe(res => {
        this.imageListFirst = res.filter(el => el.tag === 'glossary');
        this.cdr.detectChanges();
        this.items = Array.from(
          this.carousel.nativeElement.querySelectorAll('.carousel-item'),
        );
        this.updateCarousel();
      });
    this.subs.add(sub);
  }

  public downloadPdfFile(): void {
    if (this.pageDocsData![0].documentUrl)
      this.openPdf.getPDF(this.pageDocsData![0].documentUrl, 'glossary');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
