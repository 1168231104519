<section
  *ngIf="helpCards; else loader"
  class="pt-30 pb-15 overflow-hidden"
  [ngClass]="BackgroundColorType">
  <div
    class="flex content-container justify-start flex-wrap gap-5 mb-5 md:mb-0 px-4 md:px-0">
    <h2
      class="text-h2-heading tracking-[0.2px] md:text-h1-heading uppercase w-full"
      [ngClass]="HeadingColorType">
      Інші способи долучитись
    </h2>
  </div>

  <app-adaptive-slider>
    <div
      class="flex justify-between items-center mt-10 mb-10 md:mb-15 flex-row gap-5">
      <a
        *ngFor="let card of inputCards"
        [routerLink]="card.route"
        [queryParams]="card.queryParams"
        aria-label="Дізнатися більше"
        class="group/help">
        <div
          class="w-82 md:w-102.5 xl:w-95 h-87 md:h-106 bg-gray-white rounded-30 flex items-center flex-col">
          <img
            [src]="card.imageLink"
            [alt]="card.text"
            class="w-full xl:w-95 h-65 md:h-75 rounded-t-30" />
          <div
            class="flex justify-between rounded-b-30 border-t-0 border-gray-stroke w-full h-22 md:h-full p-5 md:pl-10 md:pr-9 md:pt-0 group-hover/help:border-secondary-additional group-active/help:border-secondary-additional duration-300"
            [ngClass]="borderType">
            <p
              class="text-smaller-bold md:text-h3-subheading text-gray-dark md:mt-5 w-65">
              {{ card.text }}
            </p>
            <svg
              width="28px"
              height="16px"
              icon="arrow-right-medium"
              iconCollection="button-icons-sprite"
              class="inline-block fill-accent-base group-hover/help:fill-secondary-additional group-active/help:fill-secondary-additional w-7 h-4 mt-1 md:mt-7 duration-300"></svg>
          </div>
        </div>
      </a>
    </div>
  </app-adaptive-slider>
</section>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
