<section *ngIf="pageData; else loader">
  <div
    class="content-container flex flex-col items-center md:items-start gap-30 px-4 md:px-15 xl:px-0 pb-30">
    <div class="w-full md:gap-5">
      <img
        src="{{ pageData!.imageURL }}"
        alt="{{ pageData!.title }}"
        class="rounded-30 w-[328px] md:w-[410px] xl:w-[380px] h-[454px] md:h-[512px] xl:h-[475px] object-cover object-bottom md:float-left mx-auto md:mr-5 mb-5" />

      <h2 class="text-h2-heading text-primary-base uppercase">
        {{ pageData!.title }}
      </h2>
      <div
        class="text-gray-dark text-main-text"
        appPhoneLink
        [innerHtml]="pageData.bodyText"></div>
    </div>
    <div>
      <div class="w-full flex flex-col gap-5">
        <h3 class="text-h3-360 md:text-h3-subheading text-primary-base">
          {{ pageData.fundResearchFourth.title }}
        </h3>
        <div
          class="text-gray-dark text-main-text"
          appPhoneLink
          [innerHtml]="pageData.fundResearchFourth.text"></div>
      </div>
      <app-button
        (click)="downloadPdfFile(pageData.fundResearchFourth.buttonURL)"
        [title]="'Читати повністю'"
        [buttonType]="'Primary'"
        class="w-full md:w-[220px] inline-block mt-10">
      </app-button>
    </div>
  </div>
</section>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
