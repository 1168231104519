import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { ICONS_PATH } from './svg-icon-path';

/**
 * Create svg icons.
 *  @template
 * ```
 *  <svg
 *       width="26px"
 *       height="26px"
 *       [icon]="'checkBox'" // icon's name in sprite
 *       iconCollection="button-icons-sprite" // sprite collection name
 *       class="fill-gray-white">
 *   </svg>
 * ```
 *  or
 * ```
 *   <svg
 *      class="fill-accent-base cursor-pointer group-hover/svg:fill-secondary-base active:fill-secondary-additional"
 *      [icon]="arrayOfIconsAttribute.name" // dynamic icon's name in sprite from list
 *      iconCollection="social-icons-sprite"  // sprite collection name
 *      [attr.width]="arrayOfIconsAttribute.width" // dynamic width attr from list
 *      [attr.height]="arrayOfIconsAttribute.height"> // dynamic height attr from list
 *   </svg>
 * ```
 *@returns An svg image from sprite.
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'svg[icon]',
  template: '<svg:use [attr.href]="href" [attr.test]="href"></svg:use>',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  /** Icon name
   * @type string
   */
  @Input() icon = '';
  /** iconCollection name
   * @type string
   */
  @Input() iconCollection: string = '';

  constructor(@Inject(ICONS_PATH) private readonly path: string) {}

  get href(): string {
    return `${this.path}/${this.iconCollection}.svg#${this.icon}`;
  }
}
