import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[copy-clipboard]',
})
export class CopyClipboardDirective {
  @Input('copy-clipboard')
  public payload: string | undefined;

  @Output()
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  public oncClick(event: MouseEvent) {
    event.preventDefault();

    if (!this.payload) {
      return;
    }

    let listener = (e: ClipboardEvent) => {
      let clipboard = e.clipboardData;

      if (this.payload) {
        clipboard?.setData('text', this.payload.toString());
      }
      e.preventDefault();

      this.copied.emit(this.payload);
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  }

  constructor() {}
}
