<ul
  class="flex items-center justify-between content-container z-10"
  [ngClass]="{ 'hover:before:shading': isHovered }">
  <li
    routerLink="{{ item.link }}"
    *ngFor="let item of menuItems"
    (click)="mainAction($event)"
    (mouseenter)="setIsHovered($event)"
    (mouseleave)="isHovered = false"
    class="group/submenu flex items-center justify-center uppercase text-primary-base text-menu cursor-pointer hover:text-secondary-base h-15 hover:border-b-secondary-base hover:border-b-2">
    {{ item.name }}
    <ng-container [ngSwitch]="item.component">
      <app-submenu-about-dementia
        class="invisible opacity-0 max-h-max overflow-y-clip duration-300 ease-in-out mx-auto w-295 absolute left-1/2 -translate-x-1/2 top-15 normal-case bg-gray-white border-b-gray-medium-low xl:w-[1260px] border-b rounded-b-30 z-50"
        [ngClass]="{
          'group-hover/submenu:visible group-hover/submenu:opacity-100 group-hover/submenu:max-h-max':
            isHovered
        }"
        *ngSwitchCase="'about-dementia'"></app-submenu-about-dementia>
      <app-submenu-need-help
        class="invisible opacity-0 max-h-max overflow-y-clip duration-300 ease-in-out mx-auto w-295 absolute left-1/2 -translate-x-1/2 top-15 normal-case bg-gray-white border-b-gray-medium-low xl:w-[1260px] border-b rounded-b-30 z-50"
        *ngSwitchCase="'get-support'"
        [ngClass]="{
          'group-hover/submenu:visible group-hover/submenu:opacity-100 group-hover/submenu:max-h-max':
            isHovered
        }"></app-submenu-need-help>

      <app-submenu-join
        class="invisible opacity-0 max-h-max overflow-y-clip duration-300 ease-in-out mx-auto w-295 absolute left-1/2 -translate-x-1/2 top-15 normal-case bg-gray-white border-b-gray-medium-low xl:w-[1260px] border-b rounded-b-30 z-50"
        *ngSwitchCase="'join'"
        [ngClass]="{
          'group-hover/submenu:visible group-hover/submenu:opacity-100 group-hover/submenu:max-h-max':
            isHovered
        }"></app-submenu-join>
      <app-submenu-about-us
        class="invisible opacity-0 max-h-max overflow-y-clip duration-300 ease-in-out mx-auto w-295 absolute left-1/2 -translate-x-1/2 top-15 normal-case bg-gray-white border-b-gray-medium-low xl:w-[1260px] border-b rounded-b-30 z-50"
        *ngSwitchCase="'about-us'"
        [ngClass]="{
          'group-hover/submenu:visible group-hover/submenu:opacity-100 group-hover/submenu:max-h-max':
            isHovered
        }"></app-submenu-about-us>
    </ng-container>
  </li>
</ul>
