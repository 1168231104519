<div class="content-container">
  <h2 class="text-primary-base uppercase text-h1-heading w-full md:w-95 mb-10">
    Пресрелізи
  </h2>
  <div class="mb-30" *ngIf="pressReleasesArray.length !== 0; else loader">
    <ul
      *ngFor="
        let releasesCard of showAllReleases
          ? pressReleasesArray.slice(0, 8)
          : pressReleasesArray
      ">
      <app-press-release-card [PRCard]="releasesCard"></app-press-release-card>
    </ul>
    <app-button
      *ngIf="subArray.length >= 8"
      type="submit"
      class="w-56 text-main-text-bold mx-auto mb-15 mt-10 flex justify-center"
      [buttonType]="'Secondary'"
      [title]="showAllReleases ? 'Дивитись більше' : 'Згорнути'"
      (click)="getMoreHangler()">
    </app-button>
  </div>
</div>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
