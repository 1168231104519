import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import IInfoPage from 'src/app/models/infoPage.interface';
import {
  BannerPageService,
  IBannerPage,
} from 'src/app/services/api/banner-page.service';
import {
  NavigationalCardsService,
  navigationalLinksModel,
} from '../../services/api/navigational-cards.service';

@Component({
  selector: 'app-about-dementia',
  templateUrl: './about-dementia.component.html',
  styleUrls: ['./about-dementia.component.css'],
})
export class AboutDementiaComponent {
  pageName: string = 'about-dementia';
}
