<app-under-construction-page *ngIf="!image && !text; else thirdPage">
</app-under-construction-page>
<ng-template #thirdPage>
  <section class="content-container text-start">
    <ul class="mt-10 px-4 mb-30">
      <li class="mb-15 md:mb-30">
        <h3 class="text-h1-360 md:text-h1-heading text-primary-base mb-5">
          {{ title | uppercase }}
        </h3>
        <div
          class="flex md:block flex-col md:flex-row items-center md:items-start">
          <img
            class="w-full order-0 md:order-0 mb-5 md:mb-0 xl:mb-1 md:ml-6 md:float-right rounded-30 md:w-[410px] md:h-[282px] xl:w-[580px] xl:h-[400px] object-cover"
            [src]="image"
            [alt]="title" />
          <div
            class="text-smaller-main md:text-main-text order-0 md:order-1 text-gray-dark main-text mb-10"
            appPhoneLink
            [innerHTML]="text"></div>
          <div
            class="mb-10 order-{{ i + 2 }} md:order-none"
            *ngFor="let card of cards; let i = index">
            <h3
              class="text-h2-360 md:text-h2-heading text- mb-5 text-primary-base">
              {{ card.title | uppercase }}
            </h3>
            <div
              class="text-smaller-main md:text-lg text-gray-dark main-text"
              appPhoneLink
              [innerHTML]="card.text"></div>
          </div>
        </div>
      </li>
    </ul>
  </section>
</ng-template>
