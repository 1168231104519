import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { IAboutDementiaThirdLevelPage } from 'src/app/models/aboutDementia.interface';
import { AboutDementiaThirdLevelPageService } from 'src/app/services/api/aboutDementiaPages/about-dementia-third-level-page.service';

@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticComponent implements OnInit, OnDestroy {
  pageData!: IAboutDementiaThirdLevelPage | any;
  private subs: Subscription = new Subscription();
  private key: string = 'statistics';

  constructor(
    private cdr: ChangeDetectorRef,
    private thirdApiService: AboutDementiaThirdLevelPageService,
  ) {}

  ngOnInit(): void {
    this.getThirdLevelPageData(this.key);
  }

  getThirdLevelPageData(linkTranslit: string) {
    const sub = this.thirdApiService
      .getData(linkTranslit)
      .subscribe((res: IAboutDementiaThirdLevelPage) => {
        this.pageData = res;
        this.cdr.markForCheck();
      });
    this.subs.add(sub);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
