import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, Observable } from 'rxjs';
import ICardInfo from 'src/app/models/cardInfo.interface';
import newsCardInfo from '../../../../models/newsCard.interface';

@Injectable({
  providedIn: 'root',
})
export class NewsApiService {
  urlNewsApi: string = environment.apiUrl + '/News/GetNews';
  adminApi: string = environment.apiUrl + '/AdminPanel/';
  constructor(private http: HttpClient) {}

  getNews(
    pageNumber: number,
    cardsPerPage: number,
    asc: boolean,
  ): Observable<{ items: ICardInfo[]; count: number }> {
    const offset = (pageNumber - 1) * cardsPerPage;
    const take = cardsPerPage;
    return this.http.get<{ items: ICardInfo[]; count: number }>(
      `${this.urlNewsApi}?offset=${offset}&take=${take}&asc=${asc}`,
    );
  }

  getNewsAdmin(): Observable<newsCardInfo[]> {
    return this.http
      .get<{
        items: newsCardInfo[];
      }>(`${this.urlNewsApi}?offset=0&take=1000&asc=false`)
      .pipe(map(el => el.items));
  }

  getNewsById(id: string): Observable<ICardInfo> {
    return this.http.get<ICardInfo>(`${this.urlNewsApi}/${id}`);
  }

  updateNews(data: any) {
    return this.http.put(this.adminApi + 'UpdateNews', data);
  }

  createNews(data: any) {
    return this.http.post(this.adminApi + 'AddNews', data);
  }

  deleteNews(data: any) {
    return this.http.delete(this.adminApi + 'DeleteNews', { body: data });
  }
}
