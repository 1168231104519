import ICurrentResearchInfo from 'src/app/models/currentResearchInfo.interface';
export const currentResearchInfoMockDate: ICurrentResearchInfo[] = [
  {
    title:
      ' Оцінка ефективності управління соціальними послугами для хворих на демецію через механізм громадського контролю',
    text: 'Центр досліджень догляду за літніми людьми (CRAC), заснований у 2021 році в Університеті Едіт Коуен, провів дослідження щодо встановлення пріоритетів, щоб визначити напрями своїх майбутніх досліджень.',
    paragraph: 'Дата проведення:',
    data: 'до 30.08.2023',
  },
  {
    title:
      ' Оцінка ефективності управління соціальними послугами для хворих на демецію через механізм громадського контролю',
    text: 'Центр досліджень догляду за літніми людьми (CRAC), заснований у 2021 році в Університеті Едіт Коуен, провів дослідження щодо встановлення пріоритетів, щоб визначити напрями своїх майбутніх досліджень.',
    paragraph: 'Дата проведення:',
    data: 'до 30.08.2023',
  },
];
