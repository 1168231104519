import { Component, OnInit } from '@angular/core';
import { supportBannerMockData } from 'src/mockData/supportBannerConfig';

export interface ISupportBanner {
  h2: string;
  text: string;
}

@Component({
  selector: 'app-support-banner',
  templateUrl: './support-banner.component.html',
  styleUrls: ['./support-banner.component.css'],
})
export class SupportBannerComponent implements OnInit {
  protected bannerData: ISupportBanner = supportBannerMockData;
  constructor() {}

  ngOnInit(): void {}
}
