import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-link-card',
  templateUrl: './page-link-card.component.html',
  styleUrls: ['./page-link-card.component.css'],
})
export class PageLinkCardComponent implements OnInit {
  @Input() card: any = {};
  constructor() {}

  ngOnInit(): void {}
}
