<section class="py-30 bg-primary-base px-4 md:px-15">
  <div class="flex justify-center md:justify-start xl:justify-center flex-wrap">
    <h2
      class="text-h2-heading md:text-h1-heading text-gray-white uppercase w-full xl:w-95 xl:mr-5">
      {{ preventionTextBlock.title | uppercase }}
    </h2>
    <div
      class="text-gray-white w-full md:w-195 text-smaller-main md:text-main-text mt-5 xl:mt-0"
      [innerHTML]="preventionTextBlock.bodyText"></div>
  </div>
  <div
    class="flex items-center md:justify-center flex-col md:flex-row md:flex-wrap my-15 gap-5">
    <app-prevention-card
      class="w-82 md:w-[calc(50%_-_20px)] xl:w-70 flex"
      *ngFor="let preventionCard of preventionCards"
      [card]="preventionCard"></app-prevention-card>
  </div>

  <app-link-button
    class="flex justify-center mx-auto w-70 md:w-55"
    [title]="'Детальніше'"
    [linkButtonType]="'Primary'"
    [titleLinkId]="'prophylaxis-4048'"
    [routerLink]="preventionTextBlock.buttonURL">
  </app-link-button>
</section>
