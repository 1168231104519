import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SvgIconModuleModule } from '../../../components/svg-icons/svg-icon/svg-icon-module.module';
import {
  IContactForm,
  IContactFormData,
} from '../../../models/contact-form.interface';
import { Observable, Subscription } from 'rxjs';
import { OpenPdf } from '../../../services/pdfOpen.service';
import { Router } from '@angular/router';
import { AgreementConfig } from '../../../../mockData/agreement-docs-config';
import { DomSanitizer } from '@angular/platform-browser';
import { FundMemberPageService } from '../../../services/api/fundMemberPage/fund-member-page.service';
import { PAGE_ROUTES } from '../../../constants/page_routes';
import { nameValidator } from '../../../components/forms/validators/name-validator';
import { emailValidator } from '../../../components/forms/validators/email-validator';
import { InputAdmComponent } from '../input-adm/input-adm.component';
import { TextAreaAdmComponent } from '../text-area-adm/text-area-adm.component';
import { ButtonModule } from '../button/button.module';
import {
  TextContentModel,
  TextContentService,
} from '../../../services/api/text-content.service';
import { MatIconModule } from '@angular/material/icon';
import { CustomEditorModule } from '../custom-editor/custom-editor.module';
import { NgXEditorModule } from '../ngx-editor/ngx-editor.module';
import { ImageReaderModule } from '../image-reader/image-reader.module';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'app-app-contact-form-adm',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SvgIconModuleModule,
    InputAdmComponent,
    TextAreaAdmComponent,
    ButtonModule,
    MatIconModule,
    CustomEditorModule,
    NgXEditorModule,
    ImageReaderModule,
  ],
  templateUrl: './app-contact-form-adm.component.html',
  styleUrl: './app-contact-form-adm.component.css',
})
export class AppContactFormAdmComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() edit: boolean = false;
  @Input() formsData!: TextContentModel;
  @Input() formsDataAgreement!: TextContentModel;
  contactForm!: FormGroup<IContactForm>;
  private pdf: any;
  private fundPolicySub!: Subscription;
  @Input() title: string = 'Напишіть нам';
  @Input() mailTo: string = 'site@nezabutni.org';
  show = false;
  showForm = true;
  showCross = false;
  @Output() closePopUpWindow: EventEmitter<any> = new EventEmitter<any>();
  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
  showSubmitState: boolean = false;
  isEditFormData: boolean = false;
  isEditSubmittedData: boolean = false;
  // eslint-disable-next-line no-undef
  [key: string]: any;
  imageUrlCopy: string = '';

  openPopUp() {
    this.show = true;
    this.showForm = false;
    this.contactForm.reset();
  }
  closePopUp() {
    this.showForm = true;
    this.show = false;
    this.contactForm.reset();
  }

  closeModal() {
    this.closePopUpWindow.emit();
  }
  constructor(
    private openPdf: OpenPdf,
    private router: Router,
    private fb: NonNullableFormBuilder,
    private config: AgreementConfig,
    private sanitazer: DomSanitizer,
    private apiMailService: FundMemberPageService,
    private textContentService: TextContentService,
    private toast: ToastService,
  ) {
    this.showCross = this.router.url.includes(PAGE_ROUTES.fundTeam);
  }

  ngOnInit(): void {
    this._initContactForm();
    this.imageUrlCopy = this.formsData.imageURL;
  }

  ngOnDestroy(): void {
    this.fundPolicySub && this.fundPolicySub.unsubscribe();
  }

  public onSubmit(): void {
    const data: IContactFormData = {
      UserName: this.contactForm.value.name?.trim(),
      UserEmail: this.contactForm.value.email?.trim(),
      Text: this.contactForm.value.message?.trim(),
      EmailTo: this.mailTo,
    };
    if (this.contactForm.valid) {
      this.sendEmailToFundMember(data).subscribe(response => {
        if (response.status === 200) {
          this.openPopUp();
        }
      });
    } else {
      this.validateAllFields(this.contactForm);
    }
  }
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  onOpenPublic_offerPDF(event: Event, link: string, title: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.openPdf.getPDF(link, title);
  }

  private _initContactForm(): void {
    this.contactForm = this.fb.group({
      name: this.fb.control('', [Validators.required, nameValidator]),
      email: this.fb.control('', [Validators.required, emailValidator]),
      message: this.fb.control('', [Validators.required]),
      policy: this.fb.control(false, [
        Validators.required,
        Validators.requiredTrue,
      ]),
    });
  }

  sendEmailToFundMember(id: any): Observable<any> {
    return this.apiMailService.sendEmail(id);
  }

  saveBlock(data: TextContentModel) {
    data.imageURL = data.File ? this.imageUrlCopy : data.imageURL;
    this.textContentService.updateTextContent(data).subscribe({
      next: res => {
        this.toast.newMessage({
          message: 'Успішно збережено',
          style: 'success',
        });
        this.saved.emit(true);
      },
      error: err => {
        this.toast.newMessage({
          message: `Помилка сервера ${err.message}`,
          style: 'error',
        });
      },
    });
  }

  changedText(event: any, dataKey: string) {
    this[dataKey].title = event.text.trim();
  }

  editedText(e: any, variableFlag: string, data: string) {
    this[data].bodyText = e;
    this[variableFlag] = false;
  }

  changedImage(e: string) {
    this.formsData.imageURL = e;
  }

  uploadedFile(e: File) {
    this.formsData.File = e;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.imageUrlCopy = this.formsData.imageURL;
  }

  copyToClickboard() {
    navigator.clipboard.writeText(
      'https://nezabutni.org/assets/pdf/terms_and_conditions.pdf',
    );
  }
}
