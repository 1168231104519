import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { Observable, map } from 'rxjs';
import { PAGE_ROUTES, PageRoutes } from 'src/app/constants/page_routes';
import { AccordionTextInterface } from '../../../models/symptomsData.interface';

@Injectable({
  providedIn: 'root',
})
export class LegalAssistanceService {
  url = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getData(pageName: string): Observable<LegalAssistanceInterface> {
    return this.http
      .get<LegalAssistanceInterface>(this.url + '/Support/' + pageName)
      .pipe(
        map((el: LegalAssistanceInterface) => {
          return {
            consultationsOnLegalIssues: el.consultationsOnLegalIssues,
            helpCard: el.helpCard,
            pageNavigationBlock: el.pageNavigationBlock?.sort(
              (a, b) => a.id - b.id,
            ),
            webinarsOnLegalIssues: el.webinarsOnLegalIssues,
            memoOnLegalIssues: el.memoOnLegalIssues,
            adviceAndLegalInformationText: el.adviceAndLegalInformationText,
            adviceAndLegalInformationAccordion:
              el.adviceAndLegalInformationAccordion,
          };
        }),
      );
  }
  getDataCards(pageName: string): Observable<LegalAssistanceCardInterface> {
    return this.http
      .get<LegalAssistanceCardInterface>(
        this.url + '/HelpCard?PageName=' + pageName,
      )
      .pipe(
        map((el: LegalAssistanceCardInterface) => {
          return {
            title: el.title,
            description: el.description,
            cards: el.cards.map(item => ({
              header: item.header,
              content: item.content,
              img: item.img,
              link: PAGE_ROUTES[item.link as keyof PageRoutes],
              // link: item.link,
            })),
          };
        }),
      );
  }
}

interface Questions {
  id: number;
  tag: string;
  text: string;
  header: string;
}
interface Card {
  content: string;
  link: string;
  header: string;
  img: string;
}
export interface LegalAssistanceCardInterface {
  cards: Card[];
  title: string;
  description: string;
}

export interface LegalAssistanceInterface {
  consultationsOnLegalIssues: {
    text: string;
  };
  helpCard: {
    tag: string;
    text: string;
    title: string;
  };
  webinarsOnLegalIssues: {
    link: string;
    tag: string;
    title: string;
  };
  pageNavigationBlock: any[];
  memoOnLegalIssues: {
    downLoadMemo: {
      text: string;
    };
    imgHTML: {
      text: string;
    };
    questions: Questions[];
    textHTML: {
      text: string;
    };
    titleHTML: {
      title: string;
    };
  };
  adviceAndLegalInformationText: { title: string; bodyText: string };
  adviceAndLegalInformationAccordion: AccordionTextInterface[];
}
