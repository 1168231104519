import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsHoverService {
  private isHover = new BehaviorSubject(false);
  currentHover = this.isHover.asObservable();
  constructor() {}

  changeHover(hover: boolean) {
    this.isHover.next(hover);
  }
}
