import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  TextContentModel,
  TextContentService,
} from '../../../services/api/text-content.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-become-partner',
  templateUrl: './become-partner.component.html',
  styleUrls: ['./become-partner.component.css'],
})
export class BecomePartnerComponent implements OnInit, OnDestroy {
  pageName: string = 'get-involved/become-a-partner';

  combinedData: {
    after_banner_text: TextContentModel;
    first_block: TextContentModel;
    second_block: TextContentModel;
    third_block: TextContentModel;
    fourth_block: TextContentModel;
    fifth_block: TextContentModel;
    [key: string]: TextContentModel;
  } | null = null;

  pageData: TextContentModel[] = [];
  subs: Subscription = new Subscription();

  links: { id: string; title: string }[] = [];
  constructor(private apiService: TextContentService) {}

  ngOnInit(): void {
    this.getPageTextData();
  }

  getPageTextData() {
    const sub = this.apiService
      .getTextsContentByPageName(this.pageName)
      .subscribe(res => {
        this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
        this.combinedData = {
          after_banner_text: this.pageData.find(
            el => el.key === 'after_banner_text',
          )!,
          first_block: this.pageData.find(el => el.key === 'first_block')!,
          second_block: this.pageData.find(el => el.key === 'second_block')!,
          third_block: this.pageData.find(el => el.key === 'third_block')!,
          fourth_block: this.pageData.find(el => el.key === 'fourth_block')!,
          fifth_block: this.pageData.find(el => el.key === 'fifth_block')!,
        };
        this.links = this.pageData
          .filter(el => el.groupType === 'main_text')
          .map(el => ({ id: el.key, title: el.title }));
      });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
