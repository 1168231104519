import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';
import { ITransferToReports } from 'src/app/models/transferToReports.interface';
import { TransferToReportsService } from 'src/app/services/api/donatePage/transfer-to-reports.service';
import { IAccordionData } from 'src/app/models/accordionModel.interface';
import { AccordionApiService } from 'src/app/services/api/accordionApi.service';
import { accordionList } from 'src/mockData/accordionHelpList';
import { requisitesPaid } from 'src/mockData/requisitesPaid';
import { FundPaymentDetailsService } from 'src/app/services/api/donatePage/fund-payment-details.service';
import {
  TextContentModel,
  TextContentService,
} from '../../services/api/text-content.service';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.css'],
})
export class DonateComponent implements OnInit, AfterViewInit, OnDestroy {
  pageName: string = 'get-involved/to-help';
  otherMethodSubscr!: Subscription;
  reportLink: string = PAGE_ROUTES.reports;
  alternativeCardsArray: TextContentModel[] = [];
  mainPaidCardsArray: TextContentModel[] = [];
  reportsCards: TextContentModel[] = [];
  formTabsData: TextContentModel[] = [];

  AccordionData: any[] = [];
  pageData: TextContentModel[] = [];

  constructor(
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private apiService: TextContentService,
  ) {}

  ngOnInit(): void {
    this.getTextData();
  }

  getTextData() {
    this.apiService.getTextsContentByPageName(this.pageName).subscribe(res => {
      this.pageData = res.sort((a, b) => a.orderId! - b.orderId!);
      this.AccordionData = this.accordionPreparing(
        this.pageData.filter(el => el.key === 'accordion'),
      );
      this.alternativeCardsArray = this.pageData.filter(
        el => el.groupType === 'alternative',
      );
      this.mainPaidCardsArray = this.mainPageDataTextPreparing(this.pageData);
      this.reportsCards = this.pageData.filter(
        el => el.groupType === 'reports',
      );
      this.formTabsData = this.pageData.filter(
        el => el.groupType === 'form_tabs_data',
      );
      this.cdRef.detectChanges();
    });
  }

  mainPageDataTextPreparing(textContentArray: TextContentModel[]) {
    return textContentArray.filter(el =>
      el.groupType.startsWith('Requisites_'),
    );
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  public ngOnDestroy() {
    if (this.otherMethodSubscr) {
      this.otherMethodSubscr.unsubscribe();
    }
  }

  accordionPreparing(arr: TextContentModel[]) {
    return arr
      .map(faq => ({
        id: faq.id,
        title: faq.title,
        HTML: this.sanitizer.bypassSecurityTrustHtml(faq.bodyText),
        key: 'accordion',
        tag: faq.pageName,
        orderId: faq.orderId,
      }))
      .sort((a, b) => a.orderId! - b.orderId!);
  }
}
