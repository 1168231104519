<div
  class="bg-[url('src/assets/images/not-found/notFound-bg.png')] min-h-screen min-w-screen pt-19.75 bg-no-repeat bg-center bg-cover">
  <app-header [headerButtonVisible]="false"></app-header>
  <main class="pb-25">
    <div class="container max-w-295 mx-auto">
      <p
        class="text-not-found-title text-primary-base uppercase tracking-not_found_page mt-9 mb-5">
        Тестуємо бекенд. To be continiued...?
      </p>
      <p class="text-main-text text-gray-dark mt-6 mb-17 main__not-found">
        Ми вже працюємо над цією сторінкою- можливо. Це взагалі не точно?
      </p>
      <p class="text-main-text text-gray-dark mt-6 mb-17">
        Environment: {{ environment }}
      </p>
      <p class="text-main-text text-gray-dark mt-6 mb-17">
        Test Data From Backend: {{ data }}
      </p>
      <div class="w-55">
        <app-link-button
          [title]="'На головну'"
          [linkButtonType]="'Primary'"
          routerLink="/">
        </app-link-button>
      </div>
    </div>
  </main>
</div>
