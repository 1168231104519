<div class="mt-5 pb-5 flex items-center">
  <svg
    class="fill-accent-base group-hover/svg:fill-secondary-base active:fill-secondary-additional shrink-0"
    [icon]="contact.buttonURL"
    iconCollection="social-icons-sprite"
    [attr.width]="'40px'"
    [attr.height]="'40px'"></svg>
  <div class="flex flex-col ml-3.75">
    <span
      class="text-gray-dark text-h3-360-regular md:text-main-text mb-1.25"
      >{{ contact.title }}</span
    >
    <span
      class="text-gray-dark w-full text-h3-subheading"
      [innerHtml]="contact.bodyText"></span>
  </div>
</div>
