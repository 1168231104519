import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { IRegisterFormData } from 'src/app/admin/auth/types/register.interface';
import { ILoginFormData } from 'src/app/admin/auth/types/login.interface';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ToastService } from '../../components/toast/toast.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  url: string = environment.apiUrl + '/Auth/Login';
  refreshUrl = environment.apiUrl + '/Auth/RefreshToken';
  isAuthenticatedUser: boolean = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    private toast: ToastService,
  ) {}
  refreshToken(): Observable<{ token: string; refreshToken: string }> {
    const refreshToken = JSON.parse(localStorage.getItem('refreshToken')!);
    return this.http
      .post<{
        token: string;
        refreshToken: string;
      }>(
        this.refreshUrl + `?refreshToken=${encodeURIComponent(refreshToken)}`,
        {},
      )
      .pipe(
        map((res: { token: string; refreshToken: string }) => {
          localStorage.setItem('token', JSON.stringify(res.token));
          localStorage.setItem(
            'refreshToken',
            JSON.stringify(res.refreshToken),
          );
          return res;
        }),
      );
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')!),
    });
  }

  getToken(): string {
    return JSON.parse(localStorage.getItem('token')!);
  }

  public login(
    data: ILoginFormData,
  ): Observable<{ token: string; refreshToken: string }> {
    return this.http.post<{ token: string; refreshToken: string }>(
      this.url,
      data,
    );
  }

  public isAuthenticated(): boolean {
    return this.isAuthenticatedUser;
  }

  public logOut() {
    this.isAuthenticatedUser = false;
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    this.router.navigate(['admin-nezabutni/auth/login']);
  }
}
