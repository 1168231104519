export const BREADCRUMBS = {
  main: 'Головна',
  aboutDementia: 'Про деменцію',
  info: 'Інфо',
  whatDementiaIs: 'Що таке деменція?',
  fiveThingsAboutDementiaToKnow: '5 речей, які треба знати про деменцію',
  symptoms: 'Симптоми',
  dementiaTypes: 'Типи деменції',
  dementiaStages: 'Стадії деменції',
  dementiaAndCovid19: 'Деменція і COVID-19',
  dementiaInUkraine: 'Деменція в Україні',
  catalogAboutDementia: 'Каталог «Про деменцію»',
  earlyDementia: 'Рання деменція',
  faq: 'Часті питання',
  photoProject: 'Фотопроєкт "Ми є. Люди з деменцією"',
  prophylaxis: 'Зменшення ризику та профілактика',
  organizeFundraising: 'Організувати збір коштів',
  riskFactors: 'Фактори ризику',
  howReduceRiskOfDementia: 'Як зменшити ризики розвитку деменції',
  isDementiaHeritable: 'Чи деменція спадкова?',
  diagnosis: 'Діагностика та терапія',
  whyDiagnosisNeed: 'Навіщо мати діагноз?',
  diagnosticProcess: 'Процес діагностики',
  diagnosisAcceptance: 'Прийняття діагнозу',
  passTest: 'Пройти тест',
  therapy: 'Терапія',
  medical: 'Медична',
  nonMedical: 'Немедична',
  personCenteredCare: 'Person-centred care',
  talkingTherapies: 'Talking therapies',
  questionForDoctor: 'Питання лікарю',
  personalStories: 'Персональні історії',
  peopleWithDementia: 'Людей з деменцією',
  relativesAndCaregivers: 'Родичів та доглядальників',
  childrenAndFriends: 'Дітей та друзів',
  needHelp: 'Потрібна допомога?',
  helpWithMedicine: 'Допомога з ліками',
  psychologicalSupport: 'Психологічна підтримка',
  legalAssistance: 'Юридична допомога',
  doctorConsultation: 'Медична допомога',
  careConsultation: 'Допомога з доглядом',
  rehabilitationProgram: 'Реабілітаційна програма',
  informationalAssistance: 'Гаряча лінія “Незабутні”',
  crisisAssistance: 'Кризова допомога',
  helpWithGenerators: 'Допомога з генераторами',
  helpAbroad: 'Допомога за кордоном',
  helpWithEvacuation: 'Допомога з евакуацією',
  whatToDoIf: 'Що робити, якщо',
  iLiveWithDementia: 'Я живу з деменцією',
  iWorryAboutMemory: 'Я турбуюся, що в мене деменція',
  justDiagnosed: 'У мене деменція',
  medicalEmployee: 'Я медичний співробітник',
  professionalCaregiver: 'Я професійний доглядальник',
  relativeHasDementia: 'Я доглядальник, родич або друг',
  join: 'Долучитися',
  donate: 'Допомогти',
  becomeVolunteer: 'Стати волонтером',
  becomePartner: 'Стати партнером',
  findInformation: 'Знайти інформацію',
  activities: 'Заходи',
  aboutUs: 'Про нас',
  aboutFund: 'Про фонд',
  ourIdea: 'Наша ідея',
  history: 'Історія',
  reports: 'Звіти та документи',
  reportsTax: 'Фінансові звіти',
  reportsYearly: 'Річні звіти',
  reportsDocs: 'Установчі документи',
  reportsMonthly: 'Щомісячні звіти',
  help: 'Реквізити',
  contacts: 'Контакти',
  ourPeople: 'Наші люди',
  fundTeam: 'Команда фонду',
  supervisoryBoard: 'Наглядова рада',
  advisoryGroup: 'Консультативна група',
  ourPartners: 'Наші партнери',
  vacancies: 'Вакансії',
  ourPrograms: 'Напрями діяльності',
  helpDuringTheWar: 'Допомога та підтримка',
  supportForFamilies: 'Адвокація',
  friendlySociety: 'Дружнє суспільство',
  study: 'Навчання',
  research: 'Дослідження',
  newsAndMedia: 'Новини та медіа',
  news: 'Новини',
  mediaAboutUs: 'ЗМІ про нас',
  statistic: 'Статистика',
  mediaCenter: 'Медіацентр',
  pressReleases: 'Пресрелізи',
  howToTalkAboutDementiaInTheMedia: 'Як говорити про деменцію у медіа',
  keyFactsAboutDementia: 'Ключові факти про деменцію',
  glossary: 'Глосарій рекомендованих термінів',
  detailedNews: 'Детальні новини',
  detailedNewsPressRealize: 'Пресрелізи від 22.09.2021',
  resultPayment: 'Результат виконання операції',
};
