<div class="absolute top-0 left-0 flex flex-row opacity-60">
  <div
    title="Очистити від всих тегів"
    class="lowercase text-main-text cursor-pointer border-2 border-gray-white bg-gray-dark mx-2 text-gray-white px-2"
    (click)="makeStd()">
    &#11119;
  </div>
  <!--  <div-->
  <!--    title="Жирний"-->
  <!--    class="text-main-text cursor-pointer border-2 border-gray-white bg-gray-dark mx-2 text-gray-white px-2"-->
  <!--    (click)="addBoldTag()">-->
  <!--    <b>B</b>-->
  <!--  </div>-->
  <div
    title="Підкреслений"
    class="lowercase text-main-text cursor-pointer border-2 border-gray-white bg-gray-dark mx-2 text-gray-white px-2"
    (click)="addUnderline()">
    <u><b>U</b></u>
  </div>
  <div
    title="Курсив"
    class="lowercase text-main-text cursor-pointer border-2 border-gray-white bg-gray-dark mx-2 text-gray-white px-2"
    (click)="addItalic()">
    <i><b>i</b></i>
  </div>
  <button
    title="Перенос строки"
    class="lowercase text-main-text cursor-pointer border-2 border-gray-white bg-gray-dark mx-2 text-gray-white px-2"
    (click)="addBrTag()">
    br
  </button>
</div>
