<ng-container>
  <app-carousel
    *ngIf="showCarousel"
    class="mt-15 flex"
    (changeSlide)="onChangeSlide($event)">
    <app-carousel-slide *ngFor="let ListOfImage of arrayListImage">
      <img
        class="rounded-3xl max-w-none w-70 h-[350px] md:w-102.5 md:h-[512px] xl:w-95 xl:h-[474px]"
        src="{{ ListOfImage.src }}" />
    </app-carousel-slide>
  </app-carousel>

  <div class="mt-15 flex justify-between" *ngIf="!showCarousel">
    <div *ngFor="let ListOfImage of arrayListImage">
      <img
        class="rounded-3xl max-w-none w-70 h-[350px] md:w-102.5 md:h-[512px] xl:w-95 xl:h-[474px]"
        src="{{ ListOfImage.src }}" />
    </div>
  </div>
</ng-container>
