import { Component, OnInit } from '@angular/core';
import ILetter from 'src/app/models/letterForDonation.interface';
import { letterForDonationMockData } from 'src/mockData/letterForDonationMockData';

@Component({
  selector: 'app-letter-for-donation',
  templateUrl: './letter-for-donation.component.html',
  styleUrls: ['./letter-for-donation.component.css'],
})
export class LetterForDonationComponent implements OnInit {
  letterText: ILetter[] = letterForDonationMockData;
  constructor() {}

  ngOnInit(): void {}
}
