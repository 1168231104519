import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdaptiveSliderComponent } from './adaptive-slider.component';
import { SvgIconModuleModule } from '../svg-icons/svg-icon/svg-icon-module.module';
import { AdaptivePageSliderComponent } from '../adaptive-page-slider/adaptive-page-slider.component';

@NgModule({
  declarations: [AdaptiveSliderComponent, AdaptivePageSliderComponent],
  imports: [CommonModule, SvgIconModuleModule],
  exports: [AdaptiveSliderComponent, AdaptivePageSliderComponent],
})
export class AdaptiveSliderModule {}
