import { Component, Input, OnInit } from '@angular/core';
import { ISocialLink } from 'src/app/models/social-link.interface';

type SocialLinkType = 'Primary' | 'Secondary';

@Component({
  selector: 'app-social-link',
  templateUrl: './social-link.component.html',
  styleUrls: ['./social-link.component.css'],
})
export class SocialLinkComponent implements OnInit {
  @Input()
  public socialLink!: ISocialLink;

  @Input()
  public type: SocialLinkType = 'Primary';

  constructor() {}

  ngOnInit(): void {}
}
