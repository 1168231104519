import { Component, Input, OnInit } from '@angular/core';

type LinkButtonType =
  | 'Donate'
  | 'Primary'
  | 'PrimaryLink'
  | 'Secondary'
  | 'RightArrow'
  | 'LeftArrow'
  | 'Download'
  | 'RightArrowBig';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.css'],
})
export class LinkButtonComponent implements OnInit {
  @Input() title: string = '';
  @Input() linkButtonType: LinkButtonType = 'Primary';
  @Input() svg?: boolean = false;
  @Input() type?: string;
  @Input() url?: string;
  @Input() isHovered?: boolean = false;
  @Input() titleLinkId: string = '';
  name = 'Angular';
  showFirstIcon = true;

  constructor() {}

  ngOnInit(): void {}
}
