<div class="items-center mx-auto h-full">
  <div
    *ngIf="(isTablet$ | async) === false && (isMobile$ | async) === false"
    class="flex border-2 border-gray-card rounded-30 bg-gray-card overflow-hidden">
    <div class="flex flex-row justify-start">
      <ul class="transition-all duration-200">
        <li
          class="flex md:w-74 md:h-[126px] justify-left items-center cursor-pointer md:px-5 text-h3-subheading border-b-2 border-gray-card last:border-none"
          *ngFor="let tab of tabsArray"
          (click)="selectItem(tab)"
          [ngClass]="{
            'bg-gray-card text-accent-base': tab.active,
            'bg-gray-white text-primary-base hover:text-secondary-base':
              !tab.active
          }">
          {{ tab.title }}
        </li>
      </ul>
      <div>
        <app-tab-horizontal-view
          [tabData]="this.activeTab"></app-tab-horizontal-view>
      </div>
    </div>
  </div>
  <div *ngIf="(isTablet$ | async) || (isMobile$ | async)">
    <app-accordion
      [mobile]="true"
      [AccordionsList]="tabsArray"
      [options]="{ multi: false }">
    </app-accordion>
  </div>
</div>
