import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FundPaymentDetailsService } from 'src/app/services/api/donatePage/fund-payment-details.service';
import { TextContentModel } from '../../services/api/text-content.service';

@Component({
  selector: 'app-bills-card',
  templateUrl: './bills-card.component.html',
  styleUrls: ['./bills-card.component.css'],
})
export class BillsCardComponent implements OnInit {
  @ViewChild('cardWidth', { static: true, read: ElementRef })
  cardWidth!: ElementRef;
  currencyName: string = '';
  textFieldsArray: TextContentModel[] = [];
  pdfFile: TextContentModel | null = null;

  @Input()
  card!: TextContentModel[];
  constructor() {}

  ngOnInit(): void {
    this.textFieldsArray = this.card.filter(el => !el.key.includes('_PDF'));
    this.pdfFile = this.card.find(el => el.key.includes('_PDF'))!;
    this.currencyName = this.findCurrencyName();
  }

  findCurrencyName() {
    return this.card[0].groupType.replace('Requisites_', '');
  }
}
