import { Component, OnInit } from '@angular/core';
import { ActivitiesModel } from '../../admin/pages/activities-adm/activities';
import { ActivitiesPageService } from '../../services/api/activities-page/activities-page.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css'],
})
export class ActivitiesComponent implements OnInit {
  title: string = 'Заходи';
  text: string =
    'Ми оновлюємо календар подій, як тільки з’являються нові заходи. Якщо ви не знайшли захід, що вас цікавить, перевірте календар ще раз через кілька днів.';
  eventsCardsArray!: ActivitiesModel[] | null;
  pageNumber = 1;
  cardsPerPage = 6;
  count!: number;
  constructor(private apiService: ActivitiesPageService) {}

  ngOnInit(): void {
    this.getPaginatedEvents();
  }

  getPaginatedEvents() {
    this.apiService
      .getPaginatedEvents(this.pageNumber, this.cardsPerPage, false)
      .subscribe(res => {
        this.eventsCardsArray = res.items;
        this.count = res.count;
        window.scroll(0, 0);
      });
  }

  changePage(event: number) {
    this.pageNumber = event;
    this.getPaginatedEvents();
  }
}

export const eventsCardConfig: any = [
  {
    id: 1,
    title: 'Тестування мобільного застосунку Family Game Night',
    when: '22.08.2023 19:00',
    where: 'Київ',
    shouldYouRegisterText: 'string',
    description: 'string',
    shortDescription:
      'This group session is designed to support positive health and wellbeing outcomes for a carer, family or friend of a person living with dementia',
    imageUrl: './assets/images/activities/activities-advocacy.png',
    visitEventButtonUrl: 'string',
  },
  {
    id: 2,
    title: 'Тестування мобільного застосунку Family Game Night',
    when: '22.08.2023 19:00',
    where: 'Київ',
    shouldYouRegisterText: 'string',
    description: 'string',
    shortDescription:
      'This group session is designed to support positive health and wellbeing outcomes for a carer, family or friend of a person living with dementia',
    imageUrl:
      'https://stnezabutniprod.blob.core.windows.net/assets/news/news_2_11_09_2023.jpg',
    visitEventButtonUrl: 'string',
  },
  {
    id: 3,
    title: 'Тестування мобільного застосунку Family Game Night',
    when: '22.08.2023 19:00',
    where: 'Київ',
    shouldYouRegisterText: 'string',
    description: 'string',
    shortDescription:
      'This group session is designed to support positive health and wellbeing outcomes for a carer, family or friend of a person living with dementia',
    imageUrl:
      'https://stnezabutniprod.blob.core.windows.net/assets/news/news_2_11_09_2023.jpg',
    visitEventButtonUrl: 'string',
  },
  {
    id: 4,
    title: 'Тестування мобільного застосунку Family Game Night',
    when: '22.08.2023 19:00',
    where: 'Київ',
    shouldYouRegisterText: 'string',
    description: 'string',
    shortDescription:
      'This group session is designed to support positive health and wellbeing outcomes for a carer, family or friend of a person living with dementia',
    imageUrl:
      'https://stnezabutniprod.blob.core.windows.net/assets/news/news_2_11_09_2023.jpg',
    visitEventButtonUrl: 'string',
  },
  {
    id: 5,
    title: 'Тестування мобільного застосунку Family Game Night',
    when: '22.08.2023 19:00',
    where: 'Київ',
    shouldYouRegisterText: 'string',
    description: 'string',
    shortDescription:
      'This group session is designed to support positive health and wellbeing outcomes for a carer, family or friend of a person living with dementia',
    imageUrl:
      'https://stnezabutniprod.blob.core.windows.net/assets/news/news_2_11_09_2023.jpg',
    visitEventButtonUrl: 'string',
  },
  {
    id: 6,
    title: 'Тестування мобільного застосунку Family Game Night',
    when: '22.08.2023 19:00',
    where: 'Київ',
    shouldYouRegisterText: 'string',
    description: 'string',
    shortDescription:
      'This group session is designed to support positive health and wellbeing outcomes for a carer, family or friend of a person living with dementia',
    imageUrl:
      'https://stnezabutniprod.blob.core.windows.net/assets/news/news_2_11_09_2023.jpg',
    visitEventButtonUrl: 'string',
  },
  // {
  //   id: 7,
  //   title: 'Тестування мобільного застосунку Family Game Night',
  //   when: '22.08.2023 19:00',
  //   where: 'Київ',
  //   shouldYouRegisterText: 'string',
  //   description: 'string',
  //   shortDescription:
  //     'This group session is designed to support positive health and wellbeing outcomes for a carer, family or friend of a person living with dementia',
  //   imageUrl:
  //     'https://stnezabutniprod.blob.core.windows.net/assets/news/news_2_11_09_2023.jpg',
  //   visitEventButtonUrl: 'string',
  // },
];
