<div class="content-container" *ngIf="!isLoading; else loader">
  <div class="lg:mb-20 mb-10" *ngIf="textData$ | async as textData">
    <app-information
      [title]="textData.title"
      [text]="textData.bodyText"></app-information>
  </div>

  <app-filter
    [ListArray]="['Всі публікації', 'Відео', 'Статті']"
    (chosenValue)="changeValueFilter($event)"></app-filter>
  <div class="md:grid gap-5 md:grid-cols-2 mb-15" *ngIf="productsCard.length">
    <app-products
      *ngFor="
        let productsCard of productsCard
          | paginate: { itemsPerPage: 4, currentPage: page }
      "
      [productsCard]="productsCard"></app-products>
  </div>
  <app-pagination-media-about-us
    class="md:mt-20 mt-10"
    (pageChange)="pageChange($event)"></app-pagination-media-about-us>
</div>

<ng-template #loader>
  <div class="pt-30">
    <div
      class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full gradient-animation"></div>
      </div>
    </div>
  </div>
</ng-template>
