import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import IInfoPage from 'src/app/models/infoPage.interface';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  IAboutDementiaThirdLevelPage,
  IAboutDementiaThirdLevelPageMainText,
} from 'src/app/models/aboutDementia.interface';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-about-dementia-third-level',
  templateUrl: './about-dementia-third-level.component.html',
  styleUrls: ['./about-dementia-third-level.component.css'],
})
export class AboutDementiaThirdLevelComponent implements OnInit, OnDestroy {
  title = '';
  text = '';
  image = '';
  cards: IInfoPage[] = [];
  lightText = '';
  darkText = '';

  currentBreadcrumb: string | null = 'third';
  private routeSubscription: Subscription | null = null;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.data.subscribe(data => {
      const apiData = data['apiData'] as IAboutDementiaThirdLevelPage & {
        breadcrumb: string;
      };

      this.title = apiData.header;
      this.text = apiData.introText;
      this.image = apiData.imgLink;
      this.cards = apiData.mainText.map(
        (card: IAboutDementiaThirdLevelPageMainText) => ({
          title: card.title,

          text: card.text,
          id: card.thirdLevelUnifiedPageId,
        }),
      );
      this.currentBreadcrumb = apiData.header;
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
