import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css'],
})
export class CookiesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  functionCall(first: any) {
    console.log(first);
  }
  functionCall2(first: any) {
    console.log(first);
  }
}
