import { Component, OnInit, Input } from '@angular/core';
import { BreakpointObserverService } from 'src/app/services/breakpoint-observer.service';
import { PAGE_ROUTES } from 'src/app/constants/page_routes';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  isHovered: boolean = false;
  isMobile$ = this.breakpoinService.isMobile();
  isTablet$ = this.breakpoinService.isTablet();
  isLaptop$ = this.breakpoinService.isLaptop();

  constructor(private breakpoinService: BreakpointObserverService) {}
  donateLink = PAGE_ROUTES.donate;
  contactLink = PAGE_ROUTES.informationalAssistance;

  @Input() headerButtonVisible: Boolean = true;

  ngOnInit(): void {}
}
