import IResearchCard from 'src/app/models/researchCard.interface';
export const researchCardMockDate: IResearchCard[] = [
  {
    text: 'Дата публікації:',
    data: '20.09.2023',
    title: 'ВПЛИВ ВІЙНИ В УКРАЇНІ НА ЖИТТЯ ЛЮДЕЙ З ДЕМЕНЦІЄЮ ТА ЇХНІХ РОДИН',
    link: 'Перейти',
  },
];

export const otherPublicationsCards: IResearchCard[] = [
  {
    text: 'Дата публікації:',
    data: '20.09.2023',
    title:
      'World Alzheimer Report 2022 Life after diagnosis: Navigating treatment, care and support',
    link: 'https://www.alzint.org/resource/world-alzheimer-report-2022/',
  },
  {
    text: 'Дата публікації:',
    data: '20.09.2023',
    title:
      'Оцінка ефективності управління соціальними послугами для хворих на демецію через механізм громадського контролю',
    link: 'https://science.lpnu.ua/uk/dg/vsi-vypusky/vypusk-123-2019/ocinka-efektyvnosti-upravlinnya-socialnymy-poslugamy-dlya-hvoryh-na',
  },
];
