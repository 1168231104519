<section
  class="container text-start text-gray-dark pb-30 px-4 md:px-15 xl:px-0">
  <h3 class="text-h1-heading text-primary-base mb-5 xl:block hidden">
    {{ currentMember.title | uppercase }}
  </h3>
  <div class="flex flex-col md:block">
    <img
      alt="люди посміхаються"
      class="w-full xl:w-[380px] md:w-[407px] md:h-[579px] xl:h-135 md:ml-6 mb-6 md:float-right object-cover float-none rounded-30 self-center"
      src="{{ currentMember.img }}" />
    <h3 class="text-h1-heading text-primary-base mb-5 xl:hidden block">
      {{ currentMember.title | uppercase }}
    </h3>
    <h3 class="text-h3-subheading mb-5">
      {{ currentMember.position }}
    </h3>
    <div [innerHTML]="currentMember.text" class="text-lg main-text pb-7"></div>
    <div
      class="pt-3 text-gray-dark flex md:flex-row flex-col gap-5"
      id="WhyDiagnosisNeed">
      <app-button
        *ngIf="true"
        buttonType="Secondary"
        class="md:w-55 w-full"
        [routerLink]="fundLink"
        title="Вся команда"></app-button>

      <app-button
        buttonType="Primary"
        (click)="openPopUp()"
        class="md:w-55 w-full"
        title="Написати листа"></app-button>
    </div>
  </div>
</section>
<app-subscribe-news></app-subscribe-news>

<section
  *ngIf="showPopUp"
  class="fixed w-screen h-screen z-[150] block top-0 left-0 bg-black/25">
  <div
    class="fixed w-full md:w-[410px] xl:w-145 md:absolute left-1/2 top-0 md:top-1/2 -translate-x-1/2 md:-translate-y-1/2 overflow-y-scroll md:overflow-y-hidden max-h-[100vh]">
    <app-contact-form
      *ngIf="formsDataAgreement && formsData"
      [formsDataAgreement]="formsDataAgreement!"
      [formsData]="formsData!"
      (closePopUpWindow)="closePopUp()"
      [mailTo]="mailTo"
      [title]="'Написати листа'"></app-contact-form>
  </div>
</section>
