<main class="content-container" *ngIf="pageData; else loader">
  <h1
    class="text-h2-heading-bold md:text-h1-heading uppercase text-primary-base hidden md:block">
    {{ this.pageTitle.title }}
  </h1>

  <div
    class="flex justify-between md:flex-row flex-col items-center md:items-start gap-[120px] md:gap-0">
    <address class="not-italic w-[328px] md:w-[384px]">
      <h1
        class="text-h2-heading-bold md:text-h1-heading uppercase text-primary-base block md:hidden">
        {{ this.pageTitle.title }}
      </h1>
      <app-contact-item *ngFor="let contact of contacts" [contact]="contact">
      </app-contact-item>

      <section class="mt-15 md:mt-30">
        <h2
          class="text-h2-360 md:text-h2-heading text-primary-base uppercase mb-10">
          Ми у соціальних мережах
        </h2>
        <div class="flex">
          <app-social-link
            class="w-12 mr-5"
            *ngFor="let socialLink of socialLinks"
            [socialLink]="socialLink"></app-social-link>
        </div>
      </section>
    </address>

    <section class="mb-30 px-4 md:px-0">
      <app-contact-form
        [formsDataAgreement]="formsDataAgreement!"
        [formsData]="formsData!"></app-contact-form>
    </section>
  </div>
</main>

<app-subscribe-news></app-subscribe-news>

<ng-template #loader>
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-template>
